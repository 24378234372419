"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * NXPrivacy API
 * REST API for NXPrivacy
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The PasswordResetConfirmRequest model module.
 * @module model/PasswordResetConfirmRequest
 * @version 1.0.0
 */
class PasswordResetConfirmRequest {
  /**
   * Constructs a new <code>PasswordResetConfirmRequest</code>.
   * Serializer for confirming a password reset attempt.
   * @alias module:model/PasswordResetConfirmRequest
   * @param new_password1 {String} 
   * @param new_password2 {String} 
   * @param uid {String} 
   * @param token {String} 
   */
  constructor(new_password1, new_password2, uid, token) {
    PasswordResetConfirmRequest.initialize(this, new_password1, new_password2, uid, token);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, new_password1, new_password2, uid, token) {
    obj['new_password1'] = new_password1;
    obj['new_password2'] = new_password2;
    obj['uid'] = uid;
    obj['token'] = token;
  }

  /**
   * Constructs a <code>PasswordResetConfirmRequest</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/PasswordResetConfirmRequest} obj Optional instance to populate.
   * @return {module:model/PasswordResetConfirmRequest} The populated <code>PasswordResetConfirmRequest</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new PasswordResetConfirmRequest();
      if (data.hasOwnProperty('new_password1')) {
        obj['new_password1'] = _ApiClient.default.convertToType(data['new_password1'], 'String');
      }
      if (data.hasOwnProperty('new_password2')) {
        obj['new_password2'] = _ApiClient.default.convertToType(data['new_password2'], 'String');
      }
      if (data.hasOwnProperty('uid')) {
        obj['uid'] = _ApiClient.default.convertToType(data['uid'], 'String');
      }
      if (data.hasOwnProperty('token')) {
        obj['token'] = _ApiClient.default.convertToType(data['token'], 'String');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>PasswordResetConfirmRequest</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>PasswordResetConfirmRequest</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of PasswordResetConfirmRequest.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['new_password1'] && !(typeof data['new_password1'] === 'string' || data['new_password1'] instanceof String)) {
      throw new Error("Expected the field `new_password1` to be a primitive type in the JSON string but got " + data['new_password1']);
    }
    // ensure the json data is a string
    if (data['new_password2'] && !(typeof data['new_password2'] === 'string' || data['new_password2'] instanceof String)) {
      throw new Error("Expected the field `new_password2` to be a primitive type in the JSON string but got " + data['new_password2']);
    }
    // ensure the json data is a string
    if (data['uid'] && !(typeof data['uid'] === 'string' || data['uid'] instanceof String)) {
      throw new Error("Expected the field `uid` to be a primitive type in the JSON string but got " + data['uid']);
    }
    // ensure the json data is a string
    if (data['token'] && !(typeof data['token'] === 'string' || data['token'] instanceof String)) {
      throw new Error("Expected the field `token` to be a primitive type in the JSON string but got " + data['token']);
    }
    return true;
  }
}
PasswordResetConfirmRequest.RequiredProperties = ["new_password1", "new_password2", "uid", "token"];

/**
 * @member {String} new_password1
 */
PasswordResetConfirmRequest.prototype['new_password1'] = undefined;

/**
 * @member {String} new_password2
 */
PasswordResetConfirmRequest.prototype['new_password2'] = undefined;

/**
 * @member {String} uid
 */
PasswordResetConfirmRequest.prototype['uid'] = undefined;

/**
 * @member {String} token
 */
PasswordResetConfirmRequest.prototype['token'] = undefined;
var _default = exports.default = PasswordResetConfirmRequest;