const initialState = {
    isAuthenticated: false,
    user: {},
    err: null,
    languages: ["en", "it"],
    language: "en",
    sidebarOpen: false,
}

const registerState = (state = initialState, action) => {
    switch (action.type) {
        case 'DO_REGISTER':
            return {
                ...state,
            }

        case 'DO_RESEND_EMAIL':
            return {
                ...state,
            }

        case 'DO_VERIFY_EMAIL':
            return {
                ...state,
            }
        case 'DO_ACCEPT_INVITE':
            return {
                ...state,
            }
        case 'DO_VERIFY_RESET':
            return {
                ...state,
            }
        default:
            return state
    }
}

export default registerState
