import React,{ useState,useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Visibility from "@material-ui/icons/Visibility";
import IconButton from "@material-ui/core/IconButton";
import CustomButton from "../components/CustomButton";
import ErrorFeedback from "../components/ErrorFeedback";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";
import { login, register, verifyEmail } from "../api";
import { history } from "../store";
import { CssBaseline } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { doLogin, setError, resetAuth, doRegister, doVerifyEmail } from "../actions";


import loginLogo from "../images/loginLogo.png";
import { useStyles } from "../styles/Login.style";

const initialFormState = {
  key: "",
};

function VerifyEmail({ errors , error, isError}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const params = new URLSearchParams(window.location.search);
  const [state, setState] = useState(initialFormState);

  const handleChange = (prop) => (event) =>
    setState({ ...state, [prop]: event.target.value });
  const handleClickShowPassword = () =>
    setState({ ...state, showPassword: !state.showPassword });

  const enterVerify = (e) => {
    e.preventDefault();
    verifyEmail(state.key)
      .then((data) => {
        dispatch(doVerifyEmail(data));
        history.push("/login");
      })
      .catch((err) => {
        dispatch(setError(err));

        // dispatch(resetAuth());
        // history.push("/");
      });
  };
  useEffect(()=>{
    console.log("params",params)
    let key = params.get("key")
    // if (!!key) {
      console.log(key)
      verifyEmail(key)
      .then((data) => {
        dispatch(doVerifyEmail(data));
        history.push("/login");
      })
      .catch((err) => {
        dispatch(setError(err));

        // dispatch(resetAuth());
        // history.push("/");
      });
    // } 
  },[])
  return (
    <div className={classes.container}>
      <CssBaseline />
      <ErrorFeedback />

      <img className={classes.logo} alt="Logo" src={loginLogo} />
    {isError === true?<h2>

      Something went wrong verifying the email...<br/>
      {JSON.stringify(errors)}
    </h2> 
    :<h2>Email successfully verified.<br/>Welcome on board!</h2>}
    </div>
  );
}

const mapStateProps = (state) => ({
  isError: state.errorState.showError,
  error: state.errorState.error,
  errors: state.errorState.errors,
});

export default connect(mapStateProps, {})(VerifyEmail);
