import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Visibility from "@material-ui/icons/Visibility";
import IconButton from "@material-ui/core/IconButton";
import CustomButton from "../components/CustomButton";
import ErrorFeedback from "../components/ErrorFeedback";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";
import { login, getUser } from "../api";
import { history } from "../store";
import { CssBaseline } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { doLogin, setError, resetAuth, setAuth } from "../actions";
import Link from "@material-ui/core/Link";


import loginLogo from "../images/loginLogo.png";
import { useStyles } from "../styles/Login.style";

const initialFormState = {
  email: "",
  password: "",
  showPassword: false,
};

function Login({ errors }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [state, setState] = useState(initialFormState);

  const handleChange = (prop) => (event) =>
    setState({ ...state, [prop]: event.target.value });
  const handleClickShowPassword = () =>
    setState({ ...state, showPassword: !state.showPassword });

  const enterLogin = (e) => {
    e.preventDefault();

    login(state.email, state.password)
      .then((data) => {
        dispatch(doLogin(data));
        getUser()
          .then((data1) => {
            dispatch(setAuth(data1))
            history.push("/home");
          })
          .catch((err) => {
            dispatch(setError(err));
            dispatch(resetAuth());

            // history.push("/home");
          });
      })
      .catch((err) => {
        dispatch(setError(err));

        dispatch(resetAuth());
        history.push("/");
      });
  };

  return (
    <div className={classes.container}>
      <CssBaseline />
      <ErrorFeedback />

      <img className={classes.logo} alt="Logo" src={loginLogo} />

      <form onSubmit={enterLogin} className={classes.form}>
        <TextField
          error={!!errors["email"]}
          helperText={!!errors["email"] && errors["email"]}
          required
          className={classes.form}
          label={t("E-mail")}
          value={state.email}
          variant="filled"
          type="text"
          onChange={handleChange("email")}
        />

        <TextField
          error={!!errors["password"]}
          helperText={!!errors["password"] && errors["password"]}
          required
          className={classes.form}
          label={t("Password")}
          value={state.password}
          variant="filled"
          type={state.showPassword ? "text" : "password"}
          onChange={handleChange("password")}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                >
                  {state.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <div style={{ display: "grid" }}>

          <CustomButton
            name="LET'S GO"
            style={{ marginTop: "20px" }}
            // onClick={enterLogin}
            type="submit"
          />
          <Link
            component="button"
            //color="inherit"
            //  href={`/${pathComponents.slice(0, i + 1).join("/")}`}
            onClick={() => {

              history.push(`/reset-password`);
            }}
            key={`link-reset-password`}
          >
            Forgotten password
          </Link>
          <Link
            component="button"
            //color="inherit"
            //  href={`/${pathComponents.slice(0, i + 1).join("/")}`}
            onClick={() => {

              history.push(`/register`);
            }}
            key={`link-register`}
          >
            Need an account? Sign up
          </Link>
        </div>
      </form>

    </div>
  );
}

const mapStateProps = (state) => ({
  isError: state.errorState.showError,
  error: state.errorState.error,
  errors: state.errorState.errors,
});

export default connect(mapStateProps, {})(Login);
