export const setOrganizationsList = (payload) => {
    return {
        type: 'SET_ORGANIZATION_LIST',
        payload: payload,
    }
}

export const resetOrganizationsList = () => {
    return {
        type: 'RESET_ORGANIZATION_LIST',
    }
}

export const failOrganizationsList = (err) => {
    return {
        type: 'FAIL_ORGANIZATION_LIST',
        payload: {
            err: err,
        },
    }
}
export const setOrganization = (payload) => {
    return {
        type: 'SET_ORGANIZATION',
        payload: payload,
    }
}

export const resetOrganization = () => {
    return {
        type: 'RESET_ORGANIZATION',
    }
}

export const failOrganization = (err) => {
    return {
        type: 'FAIL_ORGANIZATION',
        payload: {
            err: err,
        },
    }
}
export const setOrganizationMembers = (payload) => {
    return {
        type: 'SET_ORGANIZATION_MEMBERS',
        payload: payload,
    }
}

export const resetOrganizationMembers = () => {
    return {
        type: 'RESET_ORGANIZATION_MEMBERS',
    }
}

export const failOrganizationMembers = (err) => {
    return {
        type: 'FAIL_ORGANIZATION_MEMBERS',
        payload: {
            err: err,
        },
    }
}