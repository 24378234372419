"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _DataProcessorSharedRegistry = _interopRequireDefault(require("../model/DataProcessorSharedRegistry"));
var _DataProcessorSharedRegistryRequest = _interopRequireDefault(require("../model/DataProcessorSharedRegistryRequest"));
var _PaginatedDataProcessorSharedRegistryList = _interopRequireDefault(require("../model/PaginatedDataProcessorSharedRegistryList"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * NXPrivacy API
 * REST API for NXPrivacy
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
* DataProcessorSharedRegistries service.
* @module api/DataProcessorSharedRegistriesApi
* @version 1.0.0
*/
class DataProcessorSharedRegistriesApi {
  /**
  * Constructs a new DataProcessorSharedRegistriesApi. 
  * @alias module:api/DataProcessorSharedRegistriesApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || _ApiClient.default.instance;
  }

  /**
   * @param {module:model/DataProcessorSharedRegistryRequest} data_processor_shared_registry_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DataProcessorSharedRegistry} and HTTP response
   */
  dataProcessorSharedRegistriesCreateWithHttpInfo(data_processor_shared_registry_request) {
    let postBody = data_processor_shared_registry_request;
    // verify the required parameter 'data_processor_shared_registry_request' is set
    if (data_processor_shared_registry_request === undefined || data_processor_shared_registry_request === null) {
      throw new Error("Missing the required parameter 'data_processor_shared_registry_request' when calling dataProcessorSharedRegistriesCreate");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _DataProcessorSharedRegistry.default;
    return this.apiClient.callApi('/api/data_processor_shared_registries/', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {module:model/DataProcessorSharedRegistryRequest} data_processor_shared_registry_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DataProcessorSharedRegistry}
   */
  dataProcessorSharedRegistriesCreate(data_processor_shared_registry_request) {
    return this.dataProcessorSharedRegistriesCreateWithHttpInfo(data_processor_shared_registry_request).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} id A unique integer value identifying this DataProcessorSharedRegistry.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  dataProcessorSharedRegistriesDestroyWithHttpInfo(id) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling dataProcessorSharedRegistriesDestroy");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/api/data_processor_shared_registries/{id}/', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} id A unique integer value identifying this DataProcessorSharedRegistry.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  dataProcessorSharedRegistriesDestroy(id) {
    return this.dataProcessorSharedRegistriesDestroyWithHttpInfo(id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Object} opts Optional parameters
   * @param {String} opts.ordering Which field to use when ordering the results.
   * @param {Number} opts.page A page number within the paginated result set.
   * @param {Number} opts.page_size Number of results to return per page.
   * @param {String} opts.search A search term.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PaginatedDataProcessorSharedRegistryList} and HTTP response
   */
  dataProcessorSharedRegistriesListWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = null;
    let pathParams = {};
    let queryParams = {
      'ordering': opts['ordering'],
      'page': opts['page'],
      'page_size': opts['page_size'],
      'search': opts['search']
    };
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _PaginatedDataProcessorSharedRegistryList.default;
    return this.apiClient.callApi('/api/data_processor_shared_registries/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Object} opts Optional parameters
   * @param {String} opts.ordering Which field to use when ordering the results.
   * @param {Number} opts.page A page number within the paginated result set.
   * @param {Number} opts.page_size Number of results to return per page.
   * @param {String} opts.search A search term.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PaginatedDataProcessorSharedRegistryList}
   */
  dataProcessorSharedRegistriesList(opts) {
    return this.dataProcessorSharedRegistriesListWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} id A unique integer value identifying this DataProcessorSharedRegistry.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DataProcessorSharedRegistry} and HTTP response
   */
  dataProcessorSharedRegistriesViewRegistryRetrieveWithHttpInfo(id) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling dataProcessorSharedRegistriesViewRegistryRetrieve");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _DataProcessorSharedRegistry.default;
    return this.apiClient.callApi('/api/data_processor_shared_registries/{id}/view_registry/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} id A unique integer value identifying this DataProcessorSharedRegistry.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DataProcessorSharedRegistry}
   */
  dataProcessorSharedRegistriesViewRegistryRetrieve(id) {
    return this.dataProcessorSharedRegistriesViewRegistryRetrieveWithHttpInfo(id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }
}
exports.default = DataProcessorSharedRegistriesApi;