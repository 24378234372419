import React, { Suspense } from "react";
import Login from "./pages/Login";
import Layout from "./components/Layout";
import ResetPassword1 from "./pages/ResetPassword1";
import DataControllerRegistries from "./pages/DataControllerRegistries";
import DataProcessorRegistries from "./pages/DataProcessorRegistries";
import DataController from "./pages/DataController";
import DataProcessor from "./pages/DataProcessor";
import NewDataProcessor from "./pages/NewDataProcessor";
import DataProtectionOfficers from "./pages/DataProtectionOfficers";
import Home from "./pages/Home";
import Admin from "./pages/Admin";
import NewDataController from "./pages/NewDataController";
// TODO Remove it -- Alle.
import NewRegistry from "./pages/NewRegistry";
import DcrProcessingRegistries from "./pages/DcrProcessingRegistries";
import DprProcessingRegistries from "./pages/DprProcessingRegistries";
import DprRegistry from "./pages/DprRegistry";
import DcrRegistry from "./pages/DcrRegistry";
import NewDcrRegistry from "./pages/NewDcrRegistry";
import NewDprRegistry from "./pages/NewDprRegistry";
import DisplayInfo from "./pages/DisplayInfo";
import Archive from "./pages/Archive";
import Treatment from "./pages/Treatment";
import Profile from "./pages/Profile";
import NoMatch from "./pages/NoMatch";
import { Provider } from "react-redux";
import { Route, Redirect, Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { I18nextProvider } from "react-i18next";
import { history, store } from "./store";
import { ThemeProvider } from "@material-ui/core/styles";
import { doLogin } from "./actions";
import { ApiClient } from "./nxprivacy";

import i18n from "./i18n";
import theme from "./theme";
import DprTreatment from "./pages/DprTreatment";
import DcrTreatment from "./pages/DcrTreatment";
import Register from "./pages/Register";
import VerifyEmail from "./pages/VerifyEmail";
import AcceptInvite from "./pages/AcceptInvite";
import ResendEmail from "./pages/ResendEmail";
import ProductsLogged from "./pages/ProductsLogged";
import ProductsPublic from "./pages/ProductsPublic";
import Organizations from "./pages/Organizations";
import DisplayOrganization from "./pages/DisplayOrganization";
import ResetPassword2 from "./pages/ResetPassword2";
import BillingSuccess from "./pages/BillingSuccess";
import BillingCancel from "./pages/BillingCancel";



export const breadcrumbs = {
  //
  // Home.
  //
  "/home": ["Home"],
  //
  // Profile.
  //
  "/profile": ["Profile"],
  "/subscriptions": ["Subscriptions"],
  //
  // ORGANIZATIONS.
  //
  "/organization": ["Organizations"],
  "/organization/display": [
    "Organization",
    "Display",
  ],
  //
  // DCR.
  //
  "/dcr": ["Data Controller Registries"],

  "/dcr/data-controller": ["Data Controller Registries", "Data Controllers"],

  "/dcr/data-controller/admin": ["Data Controller Registries", "Data Controllers", "Admin"],

  "/dcr/data-controller/display": [
    "Data Controller Registries",
    "Data Controller",
    "Display",
  ],

  "/dcr/data-controller/add-new": [
    "Data Controller Registries",
    "Data Controller",
    "Add New",
  ],

  "/dcr/processing-registries": ["Data Controller Registries", "Processing Registries"],

  "/dcr/processing-registries/registry": [
    "Data Controller Registries",
    "Processing Registries",
    "Processing Activities",
    // "Display"
  ],
  "/dcr/processing-registries/registry/archive": [
    "Data Controller Registries",
    "Processing Registries",
    // "Processing Activities",
    "Archive"
    // "Display"
  ],
  "/dcr/processing-registries/registry/display": [
    "Data Controller Registries",
    "Processing Registries",
    "Processing Activities",
    "Display",
  ],

  "/dcr/processing-registries/registry/processing": [
    "Data Controller Registries",
    "Processing Registries",
    "Processing Activities",
    "Processing Activity",
  ],

  "/dcr/processing-registries/add-new": [
    "Data Controller Registries",
    "Processing Registries",
    "Add New",
  ],
  //
  // DPR.
  //
  "/dpr": ["Data Processor Registries"],

  "/dpr/data-processor": ["Data Processor Registries", "Data Processors"],

  "/dpr/data-processor/admin": ["Data Processor Registries", "Data Processor", "Admin"],

  "/dpr/data-processor/add-new": [
    "Data Processor Registries",
    "Data Processor",
    "Add New",
  ],

  "/dpr/data-processor/display": [
    "Data Processor Registries",
    "Data Processor",
    "Display",
  ],

  "/dpr/processing-registries": ["Data Processor Registries", "Processing Registries"],

  "/dpr/processing-registries/registry": [
    "Data Processor Registries",
    "Processing Registries",
    "Processing Activities",
    // "Display"
  ],
  "/dpr/processing-registries/registry/archive": [
    "Data Processor Registries",
    "Processing Registries",
    // "Processing Activities",
    "Archive"
    // "Display"
  ],
  "/dpr/processing-registries/registry/display": [
    "Data Processor Registries",
    "Processing Registries",
    "Processing Activities",
    "Display",
  ],

  "/dpr/processing-registries/registry/processing": [
    "Data Processor Registries",
    "Processing Registries",
    "Processing Activities",
    "Processing Activity",
  ],

  "/dpr/processing-registries/add-new": [
    "Data Processor Registries",
    "Processing Registries",
    "Add New",
  ],
  //
  // DPO.
  //
  "/dpo": ["Data Protection Officers"],

  //"/dpo/data-controller": ["Data Processor Officers","Data Controller"],

  //"/dpo/data-controller/admin": ["Data Processor Officers","Data Controller","Admin"],

  "/dpo/display": [
    "Data Protection Officers",
    "Display",
  ],
  /*
  "/dpo/data-controller/add-new": [
    "Data Controller Registries",
    "Data Controller",
    "Add New",
  ],

  "/dpo/processing-registries": ["Data Processor Officers","Processing Registries"],

  "/dpo/processing-registries/registry": [
    "Data Controller Registries",
    "Processing Registries",
    "Processing Activities",
    // "Display"
  ],
  "/dpo/processing-registries/registry/display": [
    "Data Controller Registries",
    "Processing Registries",
    "Processing Activities",
    "Display",
  ],

  "/dpo/processing-registries/registry/processing": [
    "Data Controller Registries",
    "Processing Registries",
    "Processing Activities",
    "Processing Activity",
  ],

  "/dpo/processing-registries/add-new": [
    "Data Controller Registries",
    "Processing Registries",
    "Add New",
  ],
  */
};

function App() {
  let defaultClient = ApiClient.instance;

  const PrivateRoute = ({ component: Component, ...rest }) => {
    return (
      <Route
        {...rest}
        render={(props) => {
          let userState = store.getState().authState
          if (userState.isAuthenticated === true)
            return <Layout opened={false} children={props.children} user={userState.user}>
              <Component {...props} />
            </Layout>
          else
            return <Redirect to="/login" />
        }
        }
      />
    );
  };
  const NotInvitedPrivateRoute = ({ component: Component, ...rest }) => {
    return (
      <Route
        {...rest}
        render={(props) => {
          let userState = store.getState().authState
          const noSub = !Object.values(userState.user?.subscriptions || {}).some((sub) => sub.status === "active")

          const isOwner = Object.values(userState.user?.subscriptions || {}).some((sub) => sub.status === "active") &&
            (userState.user?.organizations || {}).some((sub) => sub.owner === true)
          const isInvited = !isOwner && (userState.user?.organizations).length > 0
          const check = !isInvited
          if (userState.isAuthenticated === true && check)
            return <Layout opened={false} children={props.children} user={userState.user}>
              <Component {...props} />
            </Layout>
          else
            return <Redirect to="/profile" />
        }
        }
      />
    );
  };
  const SubscriberOwnerOrInvitedPrivateRoute = ({ component: Component, type, ...rest }) => {
    return (
      <Route
        {...rest}
        render={(props) => {
          let userState = store.getState().authState
          const noSub = !Object.values(userState.user?.subscriptions || {}).some((sub) => sub.status === "active")

          const isOwner = Object.values(userState.user?.subscriptions || {}).some((sub) => sub.status === "active") &&
            (userState.user?.organizations || {}).some((sub) => sub.owner === true)
          const isInvited = !isOwner && (userState.user?.organizations).length > 0
          const check = (isOwner && !noSub) || isInvited
          if (userState.isAuthenticated === true && check)
            return <Layout opened={false} children={props.children} user={userState.user}>
              <Component type={type} {...props} />
            </Layout>
          else
            return <Redirect to="/profile" />
        }
        }
      />
    );
  };

  const SubscriberOnlyPrivateRoute = ({ component: Component, ...rest }) => {
    return (
      <Route
        {...rest}
        render={(props) => {
          let userState = store.getState().authState
          const isSub = Object.values(userState.user?.subscriptions || {}).some((sub) => sub.status === "active")
          const check = isSub
          if (userState.isAuthenticated === true && check)
            return <Layout opened={false} children={props.children} user={userState.user}>
              <Component {...props} />
            </Layout>
          else
            return <Redirect to="/profile" />
        }
        }
      />
    );
  };
  const SubscriberOwnerOnlyPrivateRoute = ({ component: Component, ...rest }) => {
    return (
      <Route
        {...rest}
        render={(props) => {
          let userState = store.getState().authState
          const noSub = !Object.values(userState.user?.subscriptions || {}).some((sub) => sub.status === "active")

          const isOwner = Object.values(userState.user?.subscriptions || {}).some((sub) => sub.status === "active") &&
            (userState.user?.organizations || {}).some((sub) => sub.owner === true)
          const isInvited = !isOwner && (userState.user?.organizations).length > 0
          const check = (isOwner && !noSub)
          if (userState.isAuthenticated === true && check)
            return <Layout opened={false} children={props.children} user={userState.user}>
              <Component {...props} />
            </Layout>
          else
            return <Redirect to="/profile" />
        }
        }
      />
    );
  };
  const AvailableRoutes = () => {
    let res = [];

    const token = JSON.stringify(localStorage.token);

    if (!token) return <Redirect to={"/"} />;
    else {
      res = [
        //
        // HOME.
        //
        <PrivateRoute path={"/home"} component={Home} key={"home"} />,
        //
        // PROFILE.
        //
        <PrivateRoute path={"/profile"} component={Profile} key={"profile"} />,
        <NotInvitedPrivateRoute path={"/subscriptions"} component={ProductsLogged} key={"subscriptions"} />,

        //
        // DCR.
        //
        <SubscriberOwnerOrInvitedPrivateRoute
          path={"/dcr"}
          component={DataControllerRegistries}
          key={"dcr"}
        />,
        <SubscriberOwnerOrInvitedPrivateRoute
          path={"/dcr/data-controller"}
          component={DataController}
          key={"dcrDataController"}
        />,
        <SubscriberOwnerOrInvitedPrivateRoute
          path={"/dcr/data-controller/admin"}
          component={Admin}
          key={"dcrDataControllerAdmin"}
        />,
        <SubscriberOwnerOrInvitedPrivateRoute
          path={"/dcr/data-controller/add-new"}
          component={NewDataController}
          key={"dcrDataControllerNewRegistry"}
        />,
        <SubscriberOwnerOrInvitedPrivateRoute
          path={"/dcr/processing-registries"}
          component={DcrProcessingRegistries}
          key={"dcrProcessingRegistries"}
        />,
        <SubscriberOwnerOrInvitedPrivateRoute
          path={"/dcr/processing-registries/registry"}
          component={DcrRegistry}
          key={"dcrProcessingRegistriesRegistry"}
        />,
        <SubscriberOwnerOrInvitedPrivateRoute
        path={"/dcr/processing-registries/registry/archive"}
        component={Archive}
        key={"dcrProcessingRegistriesRegistryUploads"}
        type={"dcr"}
      />,
        <SubscriberOwnerOrInvitedPrivateRoute
          path={"/dcr/processing-registries/registry/processing"}
          component={DcrTreatment}
          key={"dcrProcessingRegistriesProcessingTreatments"}
        />,
        <SubscriberOwnerOrInvitedPrivateRoute
          path={"/dcr/processing-registries/add-new"}
          component={NewDcrRegistry}
          key={"dcrProcessingRegistriesNewTreatment"}
        />,
        <SubscriberOwnerOrInvitedPrivateRoute
          path={"/dcr/data-controller/display"}
          component={DisplayInfo}
          key={"dcrDataControllerDisplayDataControllerInfo"}

        />,
        <SubscriberOwnerOrInvitedPrivateRoute
          path={"/dcr/processing-registries/registry/display"}
          component={DisplayInfo}
          key={"dcrProcessingRegistriesDisplayRegistryInfo"}
          type={"dcr"}

        />,
        /*
        <SubscriberOwnerOrInvitedPrivateRoute
          path={"/dcr/dpo/display"}
          component={DisplayInfo}
          key={"dcrDisplayDpo"}
        />
        */,
        //
        // DPR.
        //
        <SubscriberOwnerOrInvitedPrivateRoute
          path={"/dpr"}
          component={DataProcessorRegistries}
          key={"dpr"}
        />,
        <SubscriberOwnerOrInvitedPrivateRoute
          path={"/dpr/data-processor"}
          component={DataProcessor}
          key={"dprDataProcessor"}
        />,
        <SubscriberOwnerOrInvitedPrivateRoute
          path={"/dpr/data-processor/display"}
          component={DisplayInfo}
          key={"dprDataProcessorDisplayDataProcessorInfo"}
        />,
        <SubscriberOwnerOrInvitedPrivateRoute
          path={"/dpr/data-processor/admin"}
          component={Admin}
          key={"dprDataProcessorAdmin"}
        />,
        <SubscriberOwnerOrInvitedPrivateRoute
          path={"/dpr/data-processor/add-new"}
          component={NewDataProcessor}
          key={"dprDataProcessorNewRegistry"}
        />,
        <SubscriberOwnerOrInvitedPrivateRoute
          path={"/dpr/processing-registries"}
          component={DprProcessingRegistries}
          key={"dprProcessingRegistries"}
        />,
        <SubscriberOwnerOrInvitedPrivateRoute
          path={"/dpr/processing-registries/registry"}
          component={DprRegistry}
          key={"dprProcessingRegistriesRegistry"}
        />,
        <SubscriberOwnerOrInvitedPrivateRoute
          path={"/dpr/processing-registries/registry/archive"}
          component={Archive}
          key={"dprProcessingRegistriesRegistryUploads"}
          type={"dpr"}
        />,
        <SubscriberOwnerOrInvitedPrivateRoute
          path={"/dpr/processing-registries/registry/processing"}
          component={DprTreatment}
          key={"dprProcessingRegistriesProcessingTreatments"}
        />,
        <SubscriberOwnerOrInvitedPrivateRoute
          path={"/dpr/processing-registries/add-new"}
          component={NewDprRegistry}
          key={"dprProcessingRegistriesNewTreatment"}
        />,
        <SubscriberOwnerOrInvitedPrivateRoute
          path={"/dpr/processing-registries/registry/display"}
          component={DisplayInfo}
          key={"dprProcessingRegistriesDisplayRegistryInfo"}
          type={"dpr"}

        />,
        //
        // DPO.
        //
        <SubscriberOwnerOrInvitedPrivateRoute
          path={"/dpo"}
          //component={DataProtectionOfficers}
          component={DataProtectionOfficers}
          key={"dpo"}
        />,
        <SubscriberOwnerOrInvitedPrivateRoute
          path={"/dpo/display"}
          component={DisplayInfo}
          key={"dpoDisplayDpo"}
        />,
        <SubscriberOnlyPrivateRoute
          path={"/organization"}
          component={Organizations}
          key={"organization"}
        />,
        <SubscriberOwnerOnlyPrivateRoute
          path={"/organization/display"}
          component={DisplayOrganization}
          key={"organizationDisplayDpo"}
        />,
        /*
        <SubscriberOwnerOrInvitedPrivateRoute
          path={"/dpo/data-controller"}
          component={DataController}
          key={"dpoDataController"}
        />
        */,
        /*
        <SubscriberOwnerOrInvitedPrivateRoute
          path={"/dpo/data-controller/admin"}
          component={Admin}
          key={"dpoDataControllerAdmin"}
        />
        */,
        /*
        <SubscriberOwnerOrInvitedPrivateRoute
          path={"/dpo/data-controller/add-new"}
          component={NewDataController}
          key={"dpoDataControllerNewRegistry"}
        />
        */,
        /*
        <SubscriberOwnerOrInvitedPrivateRoute
          path={"/dpo/processing-registries"}
          component={ProcessingRegistries}
          key={"dpoProcessingRegistries"}
        />
        */,
        /*
        <SubscriberOwnerOrInvitedPrivateRoute
          path={"/dpo/processing-registries/registry"}
          component={Registry}
          key={"dpoProcessingRegistriesRegistry"}
        />
        */,
        /*
        <SubscriberOwnerOrInvitedPrivateRoute
          path={"/dpo/processing-registries/registry/processing"}
          component={Treatment}
          key={"dpoProcessingRegistriesProcessingTreatments"}
        />
        */,
        /*
        <SubscriberOwnerOrInvitedPrivateRoute
          path={"/dpo/processing-registries/add-new"}
          component={NewRegistry}
          key={"dpoProcessingRegistriesNewTreatment"}
        />
        */,
        /*
        <SubscriberOwnerOrInvitedPrivateRoute
          path={"/dpo/processing-registries/registry/display"}
          component={DisplayInfo}
          key={"dpoDisplayRegistryInfo"}
        />
        */,
      ];

      return res;
    }
  };
  const token = localStorage.getItem("token");

  if (token) {
    store.dispatch(doLogin({ key: token }));

    let tokenAuth = defaultClient.authentications["tokenAuth"];
    tokenAuth.apiKey = token;
    tokenAuth.apiKeyPrefix = "Token ";
  }

  return (
    <Suspense fallback={null}>
      <I18nextProvider i18n={i18n}>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <ConnectedRouter history={history}>
              <Switch>
                <Route exact path="/">
                  {" "}
                  <Redirect to={"/login"} />{" "}
                </Route>
                <Route path="/login" component={Login} key={"login"} />
                <Route path="/register" component={Register} key={"register"} />
                <Route path="/verify-email" component={VerifyEmail} key={"verify-email"} />
                <Route path="/resend-email" component={ResendEmail} key={"resend-email"} />
                <Route path="/accept-invite" component={AcceptInvite} key={"accept-invite"} />
                <Route
                  path="/reset-password"
                  component={ResetPassword1}
                  key={"reset"}
                />
                <Route
                  path="/confirm-reset-password"
                  component={ResetPassword2}
                  key={"reset"}
                />
                <Route path="/our-products" component={ProductsPublic} key={"products"} />
                <Route path={"/billing/success"} component={BillingSuccess} key={"success"} />
                <Route path={"/billing/cancel"} component={BillingCancel} key={"cancel"} />

                <AvailableRoutes />

                <Route path="/" component={NoMatch} key={"Error"} />
              </Switch>
            </ConnectedRouter>
          </ThemeProvider>
        </Provider>
      </I18nextProvider>
    </Suspense>
  );
}

export default App;
