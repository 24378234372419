import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Visibility from "@material-ui/icons/Visibility";
import IconButton from "@material-ui/core/IconButton";
import CustomButton from "../components/CustomButton";
import ErrorFeedback from "../components/ErrorFeedback";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";
import { acceptInvite, login, register, resetPassword, verifyEmail } from "../api";
import { history } from "../store";
import { CssBaseline } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { doLogin, setError, resetAuth, doRegister, doAcceptInvite } from "../actions";


import loginLogo from "../images/loginLogo.png";
import { useStyles } from "../styles/Login.style";

const initialFormState = {
  key: "",
};

function BillingCancel({ errors, error, isError }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const params = new URLSearchParams(window.location.search);
  const [state, setState] = useState(initialFormState);
  const [counter, setCounter] = useState(5);

  useEffect(() => {
    const timer = setInterval(() => {
      setCounter((prevCounter) => prevCounter - 1);
    }, 1000);

    return () => clearInterval(timer); // Cleanup interval on component unmount

  }, []); // Empty dependency array ensures the effect runs only once after the initial render

  useEffect(() => {
    // Redirect when the counter reaches 0
    if (counter === 0) {
      history.push('/subscriptions'); // Replace '/destination' with your desired redirect path
    }
  }, [counter, history]);


  return (
    <div className={classes.container} style={{textAlign:"center"}}>
      <CssBaseline />
      <ErrorFeedback />

      <img className={classes.logo} alt="Logo" src={loginLogo} />
      <h2>Subscription canceled.</h2><br />Oops! Something went wrong while processing your subscription.  <br />Please wait a moment and try again. <br /> If the issue persists, feel free to contact our support team for assistance.<br />
      <p>Redirecting in {counter} seconds...</p>
      {counter === 0 && (
        <p>
          If the redirection doesn't happen,{' '}
          <a href="/subscriptions">click here</a>.
        </p>
      )}    </div>
  );
}

const mapStateProps = (state) => ({
  isError: state.errorState.showError,
  error: state.errorState.error,
  errors: state.errorState.errors,
});

export default connect(mapStateProps, {})(BillingCancel);
