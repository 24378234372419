"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * NXPrivacy API
 * REST API for NXPrivacy
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The DataControllerInternalReferenceStructure model module.
 * @module model/DataControllerInternalReferenceStructure
 * @version 1.0.0
 */
class DataControllerInternalReferenceStructure {
  /**
   * Constructs a new <code>DataControllerInternalReferenceStructure</code>.
   * @alias module:model/DataControllerInternalReferenceStructure
   * @param id {Number} 
   * @param owner {String} 
   * @param created_at {Date} 
   * @param updated_at {Date} 
   * @param name {String} 
   */
  constructor(id, owner, created_at, updated_at, name) {
    DataControllerInternalReferenceStructure.initialize(this, id, owner, created_at, updated_at, name);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, id, owner, created_at, updated_at, name) {
    obj['id'] = id;
    obj['owner'] = owner;
    obj['created_at'] = created_at;
    obj['updated_at'] = updated_at;
    obj['name'] = name;
  }

  /**
   * Constructs a <code>DataControllerInternalReferenceStructure</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/DataControllerInternalReferenceStructure} obj Optional instance to populate.
   * @return {module:model/DataControllerInternalReferenceStructure} The populated <code>DataControllerInternalReferenceStructure</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new DataControllerInternalReferenceStructure();
      if (data.hasOwnProperty('id')) {
        obj['id'] = _ApiClient.default.convertToType(data['id'], 'Number');
      }
      if (data.hasOwnProperty('owner')) {
        obj['owner'] = _ApiClient.default.convertToType(data['owner'], 'String');
      }
      if (data.hasOwnProperty('created_at')) {
        obj['created_at'] = _ApiClient.default.convertToType(data['created_at'], 'Date');
      }
      if (data.hasOwnProperty('updated_at')) {
        obj['updated_at'] = _ApiClient.default.convertToType(data['updated_at'], 'Date');
      }
      if (data.hasOwnProperty('name')) {
        obj['name'] = _ApiClient.default.convertToType(data['name'], 'String');
      }
      if (data.hasOwnProperty('description')) {
        obj['description'] = _ApiClient.default.convertToType(data['description'], 'String');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>DataControllerInternalReferenceStructure</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>DataControllerInternalReferenceStructure</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of DataControllerInternalReferenceStructure.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['owner'] && !(typeof data['owner'] === 'string' || data['owner'] instanceof String)) {
      throw new Error("Expected the field `owner` to be a primitive type in the JSON string but got " + data['owner']);
    }
    // ensure the json data is a string
    if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
      throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
    }
    // ensure the json data is a string
    if (data['description'] && !(typeof data['description'] === 'string' || data['description'] instanceof String)) {
      throw new Error("Expected the field `description` to be a primitive type in the JSON string but got " + data['description']);
    }
    return true;
  }
}
DataControllerInternalReferenceStructure.RequiredProperties = ["id", "owner", "created_at", "updated_at", "name"];

/**
 * @member {Number} id
 */
DataControllerInternalReferenceStructure.prototype['id'] = undefined;

/**
 * @member {String} owner
 */
DataControllerInternalReferenceStructure.prototype['owner'] = undefined;

/**
 * @member {Date} created_at
 */
DataControllerInternalReferenceStructure.prototype['created_at'] = undefined;

/**
 * @member {Date} updated_at
 */
DataControllerInternalReferenceStructure.prototype['updated_at'] = undefined;

/**
 * @member {String} name
 */
DataControllerInternalReferenceStructure.prototype['name'] = undefined;

/**
 * @member {String} description
 */
DataControllerInternalReferenceStructure.prototype['description'] = undefined;
var _default = exports.default = DataControllerInternalReferenceStructure;