"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * NXPrivacy API
 * REST API for NXPrivacy
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The InvitationRead model module.
 * @module model/InvitationRead
 * @version 1.0.0
 */
class InvitationRead {
  /**
   * Constructs a new <code>InvitationRead</code>.
   * @alias module:model/InvitationRead
   * @param id {Number} 
   * @param key {String} 
   * @param email {String} 
   */
  constructor(id, key, email) {
    InvitationRead.initialize(this, id, key, email);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, id, key, email) {
    obj['id'] = id;
    obj['key'] = key;
    obj['email'] = email;
  }

  /**
   * Constructs a <code>InvitationRead</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/InvitationRead} obj Optional instance to populate.
   * @return {module:model/InvitationRead} The populated <code>InvitationRead</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new InvitationRead();
      if (data.hasOwnProperty('id')) {
        obj['id'] = _ApiClient.default.convertToType(data['id'], 'Number');
      }
      if (data.hasOwnProperty('accepted')) {
        obj['accepted'] = _ApiClient.default.convertToType(data['accepted'], 'Boolean');
      }
      if (data.hasOwnProperty('key')) {
        obj['key'] = _ApiClient.default.convertToType(data['key'], 'String');
      }
      if (data.hasOwnProperty('sent')) {
        obj['sent'] = _ApiClient.default.convertToType(data['sent'], 'Date');
      }
      if (data.hasOwnProperty('email')) {
        obj['email'] = _ApiClient.default.convertToType(data['email'], 'String');
      }
      if (data.hasOwnProperty('created')) {
        obj['created'] = _ApiClient.default.convertToType(data['created'], 'Date');
      }
      if (data.hasOwnProperty('inviter')) {
        obj['inviter'] = _ApiClient.default.convertToType(data['inviter'], 'Number');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>InvitationRead</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>InvitationRead</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of InvitationRead.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['key'] && !(typeof data['key'] === 'string' || data['key'] instanceof String)) {
      throw new Error("Expected the field `key` to be a primitive type in the JSON string but got " + data['key']);
    }
    // ensure the json data is a string
    if (data['email'] && !(typeof data['email'] === 'string' || data['email'] instanceof String)) {
      throw new Error("Expected the field `email` to be a primitive type in the JSON string but got " + data['email']);
    }
    return true;
  }
}
InvitationRead.RequiredProperties = ["id", "key", "email"];

/**
 * @member {Number} id
 */
InvitationRead.prototype['id'] = undefined;

/**
 * @member {Boolean} accepted
 */
InvitationRead.prototype['accepted'] = undefined;

/**
 * @member {String} key
 */
InvitationRead.prototype['key'] = undefined;

/**
 * @member {Date} sent
 */
InvitationRead.prototype['sent'] = undefined;

/**
 * @member {String} email
 */
InvitationRead.prototype['email'] = undefined;

/**
 * @member {Date} created
 */
InvitationRead.prototype['created'] = undefined;

/**
 * @member {Number} inviter
 */
InvitationRead.prototype['inviter'] = undefined;
var _default = exports.default = InvitationRead;