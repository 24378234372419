"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _DataProtectionOfficer = _interopRequireDefault(require("../model/DataProtectionOfficer"));
var _DataProtectionOfficerRequest = _interopRequireDefault(require("../model/DataProtectionOfficerRequest"));
var _PaginatedDataProtectionOfficerList = _interopRequireDefault(require("../model/PaginatedDataProtectionOfficerList"));
var _PatchedDataProtectionOfficerRequest = _interopRequireDefault(require("../model/PatchedDataProtectionOfficerRequest"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * NXPrivacy API
 * REST API for NXPrivacy
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
* DataProtectionOfficers service.
* @module api/DataProtectionOfficersApi
* @version 1.0.0
*/
class DataProtectionOfficersApi {
  /**
  * Constructs a new DataProtectionOfficersApi. 
  * @alias module:api/DataProtectionOfficersApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || _ApiClient.default.instance;
  }

  /**
   * @param {module:model/DataProtectionOfficerRequest} data_protection_officer_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DataProtectionOfficer} and HTTP response
   */
  dataProtectionOfficersCreateWithHttpInfo(data_protection_officer_request) {
    let postBody = data_protection_officer_request;
    // verify the required parameter 'data_protection_officer_request' is set
    if (data_protection_officer_request === undefined || data_protection_officer_request === null) {
      throw new Error("Missing the required parameter 'data_protection_officer_request' when calling dataProtectionOfficersCreate");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _DataProtectionOfficer.default;
    return this.apiClient.callApi('/api/data_protection_officers/', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {module:model/DataProtectionOfficerRequest} data_protection_officer_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DataProtectionOfficer}
   */
  dataProtectionOfficersCreate(data_protection_officer_request) {
    return this.dataProtectionOfficersCreateWithHttpInfo(data_protection_officer_request).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} id A unique integer value identifying this DataProtectionOfficer.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  dataProtectionOfficersDestroyWithHttpInfo(id) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling dataProtectionOfficersDestroy");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/api/data_protection_officers/{id}/', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} id A unique integer value identifying this DataProtectionOfficer.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  dataProtectionOfficersDestroy(id) {
    return this.dataProtectionOfficersDestroyWithHttpInfo(id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Object} opts Optional parameters
   * @param {String} opts.ordering Which field to use when ordering the results.
   * @param {Number} opts.page A page number within the paginated result set.
   * @param {Number} opts.page_size Number of results to return per page.
   * @param {String} opts.search A search term.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PaginatedDataProtectionOfficerList} and HTTP response
   */
  dataProtectionOfficersListWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = null;
    let pathParams = {};
    let queryParams = {
      'ordering': opts['ordering'],
      'page': opts['page'],
      'page_size': opts['page_size'],
      'search': opts['search']
    };
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _PaginatedDataProtectionOfficerList.default;
    return this.apiClient.callApi('/api/data_protection_officers/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Object} opts Optional parameters
   * @param {String} opts.ordering Which field to use when ordering the results.
   * @param {Number} opts.page A page number within the paginated result set.
   * @param {Number} opts.page_size Number of results to return per page.
   * @param {String} opts.search A search term.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PaginatedDataProtectionOfficerList}
   */
  dataProtectionOfficersList(opts) {
    return this.dataProtectionOfficersListWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} id A unique integer value identifying this DataProtectionOfficer.
   * @param {Object} opts Optional parameters
   * @param {module:model/PatchedDataProtectionOfficerRequest} opts.patched_data_protection_officer_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DataProtectionOfficer} and HTTP response
   */
  dataProtectionOfficersPartialUpdateWithHttpInfo(id, opts) {
    opts = opts || {};
    let postBody = opts['patched_data_protection_officer_request'];
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling dataProtectionOfficersPartialUpdate");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _DataProtectionOfficer.default;
    return this.apiClient.callApi('/api/data_protection_officers/{id}/', 'PATCH', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} id A unique integer value identifying this DataProtectionOfficer.
   * @param {Object} opts Optional parameters
   * @param {module:model/PatchedDataProtectionOfficerRequest} opts.patched_data_protection_officer_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DataProtectionOfficer}
   */
  dataProtectionOfficersPartialUpdate(id, opts) {
    return this.dataProtectionOfficersPartialUpdateWithHttpInfo(id, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} id A unique integer value identifying this DataProtectionOfficer.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DataProtectionOfficer} and HTTP response
   */
  dataProtectionOfficersRetrieveWithHttpInfo(id) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling dataProtectionOfficersRetrieve");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _DataProtectionOfficer.default;
    return this.apiClient.callApi('/api/data_protection_officers/{id}/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} id A unique integer value identifying this DataProtectionOfficer.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DataProtectionOfficer}
   */
  dataProtectionOfficersRetrieve(id) {
    return this.dataProtectionOfficersRetrieveWithHttpInfo(id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} id A unique integer value identifying this DataProtectionOfficer.
   * @param {module:model/DataProtectionOfficerRequest} data_protection_officer_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DataProtectionOfficer} and HTTP response
   */
  dataProtectionOfficersUpdateWithHttpInfo(id, data_protection_officer_request) {
    let postBody = data_protection_officer_request;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling dataProtectionOfficersUpdate");
    }
    // verify the required parameter 'data_protection_officer_request' is set
    if (data_protection_officer_request === undefined || data_protection_officer_request === null) {
      throw new Error("Missing the required parameter 'data_protection_officer_request' when calling dataProtectionOfficersUpdate");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _DataProtectionOfficer.default;
    return this.apiClient.callApi('/api/data_protection_officers/{id}/', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} id A unique integer value identifying this DataProtectionOfficer.
   * @param {module:model/DataProtectionOfficerRequest} data_protection_officer_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DataProtectionOfficer}
   */
  dataProtectionOfficersUpdate(id, data_protection_officer_request) {
    return this.dataProtectionOfficersUpdateWithHttpInfo(id, data_protection_officer_request).then(function (response_and_data) {
      return response_and_data.data;
    });
  }
}
exports.default = DataProtectionOfficersApi;