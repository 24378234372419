const initialState = {
    isAuthenticated: false,
    token: "",
    user: {subscriptions:{},organizations:[]},
    err: null,
    languages: ["en", "it"],
    language: "en",
    sidebarOpen: false,
}

const authState = (state = initialState, action) => {
    switch (action.type) {
        case 'DO_LOGIN':
            localStorage.setItem("token", action.payload.key)
            localStorage.setItem("isAuthenticated", true)
            return {
                ...state,
                isAuthenticated: true,
                token: action.payload.key,
            }
        case 'SET_AUTH':
            localStorage.setItem("user", JSON.stringify(action.payload))
            return {
                ...state,
                user: action.payload,
            }
        case 'FAIL_AUTH_CHECK':
            return {
                ...state,
                err: action.payload.err,
            }
        case 'RESET_AUTH':
            localStorage.removeItem("token")
            localStorage.removeItem("user")
            localStorage.removeItem("isAuthenticated")
            localStorage.removeItem("language")
            return initialState
        case 'SET_USER_LANGUAGE':
            localStorage.setItem("language", action.payload.language)
            return {
                ...state,
                language: action.payload.language,
            }
        case 'SET_SIDEBAR_BEHAVIOUR':
            return {
                ...state,
                sidebarOpen: action.payload.sidebarOpen
            }
        default:
            return state
    }
}

export default authState
