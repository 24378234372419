"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * NXPrivacy API
 * REST API for NXPrivacy
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The Subscription model module.
 * @module model/Subscription
 * @version 1.0.0
 */
class Subscription {
  /**
   * Constructs a new <code>Subscription</code>.
   * @alias module:model/Subscription
   * @param subscription_id {String} 
   * @param status {String} 
   * @param cancel_at_period_end {Boolean} 
   */
  constructor(subscription_id, status, cancel_at_period_end) {
    Subscription.initialize(this, subscription_id, status, cancel_at_period_end);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, subscription_id, status, cancel_at_period_end) {
    obj['subscription_id'] = subscription_id;
    obj['status'] = status;
    obj['cancel_at_period_end'] = cancel_at_period_end;
  }

  /**
   * Constructs a <code>Subscription</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/Subscription} obj Optional instance to populate.
   * @return {module:model/Subscription} The populated <code>Subscription</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Subscription();
      if (data.hasOwnProperty('subscription_id')) {
        obj['subscription_id'] = _ApiClient.default.convertToType(data['subscription_id'], 'String');
      }
      if (data.hasOwnProperty('period_start')) {
        obj['period_start'] = _ApiClient.default.convertToType(data['period_start'], 'Date');
      }
      if (data.hasOwnProperty('period_end')) {
        obj['period_end'] = _ApiClient.default.convertToType(data['period_end'], 'Date');
      }
      if (data.hasOwnProperty('status')) {
        obj['status'] = _ApiClient.default.convertToType(data['status'], 'String');
      }
      if (data.hasOwnProperty('cancel_at')) {
        obj['cancel_at'] = _ApiClient.default.convertToType(data['cancel_at'], 'Date');
      }
      if (data.hasOwnProperty('cancel_at_period_end')) {
        obj['cancel_at_period_end'] = _ApiClient.default.convertToType(data['cancel_at_period_end'], 'Boolean');
      }
      if (data.hasOwnProperty('trial_start')) {
        obj['trial_start'] = _ApiClient.default.convertToType(data['trial_start'], 'Date');
      }
      if (data.hasOwnProperty('trial_end')) {
        obj['trial_end'] = _ApiClient.default.convertToType(data['trial_end'], 'Date');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>Subscription</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>Subscription</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of Subscription.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['subscription_id'] && !(typeof data['subscription_id'] === 'string' || data['subscription_id'] instanceof String)) {
      throw new Error("Expected the field `subscription_id` to be a primitive type in the JSON string but got " + data['subscription_id']);
    }
    // ensure the json data is a string
    if (data['status'] && !(typeof data['status'] === 'string' || data['status'] instanceof String)) {
      throw new Error("Expected the field `status` to be a primitive type in the JSON string but got " + data['status']);
    }
    return true;
  }
}
Subscription.RequiredProperties = ["subscription_id", "status", "cancel_at_period_end"];

/**
 * @member {String} subscription_id
 */
Subscription.prototype['subscription_id'] = undefined;

/**
 * @member {Date} period_start
 */
Subscription.prototype['period_start'] = undefined;

/**
 * @member {Date} period_end
 */
Subscription.prototype['period_end'] = undefined;

/**
 * @member {String} status
 */
Subscription.prototype['status'] = undefined;

/**
 * @member {Date} cancel_at
 */
Subscription.prototype['cancel_at'] = undefined;

/**
 * @member {Boolean} cancel_at_period_end
 */
Subscription.prototype['cancel_at_period_end'] = undefined;

/**
 * @member {Date} trial_start
 */
Subscription.prototype['trial_start'] = undefined;

/**
 * @member {Date} trial_end
 */
Subscription.prototype['trial_end'] = undefined;
var _default = exports.default = Subscription;