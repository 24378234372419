import { Close, Check } from '@material-ui/icons';
import React from 'react';

import { useStyles } from "../styles/Button.style";

function Boolean(props) {
  const classes = useStyles();

  return (
    <>
      {props.value === true ? <Check {...props} htmlColor={"green"}/> : <Close {...props} htmlColor={"red"}/>}
    </>
  )
}

export default Boolean;
