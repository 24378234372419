"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _DataProcessorController = _interopRequireDefault(require("./DataProcessorController"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * NXPrivacy API
 * REST API for NXPrivacy
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The PaginatedDataProcessorControllerList model module.
 * @module model/PaginatedDataProcessorControllerList
 * @version 1.0.0
 */
class PaginatedDataProcessorControllerList {
  /**
   * Constructs a new <code>PaginatedDataProcessorControllerList</code>.
   * @alias module:model/PaginatedDataProcessorControllerList
   */
  constructor() {
    PaginatedDataProcessorControllerList.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>PaginatedDataProcessorControllerList</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/PaginatedDataProcessorControllerList} obj Optional instance to populate.
   * @return {module:model/PaginatedDataProcessorControllerList} The populated <code>PaginatedDataProcessorControllerList</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new PaginatedDataProcessorControllerList();
      if (data.hasOwnProperty('count')) {
        obj['count'] = _ApiClient.default.convertToType(data['count'], 'Number');
      }
      if (data.hasOwnProperty('next')) {
        obj['next'] = _ApiClient.default.convertToType(data['next'], 'String');
      }
      if (data.hasOwnProperty('previous')) {
        obj['previous'] = _ApiClient.default.convertToType(data['previous'], 'String');
      }
      if (data.hasOwnProperty('results')) {
        obj['results'] = _ApiClient.default.convertToType(data['results'], [_DataProcessorController.default]);
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>PaginatedDataProcessorControllerList</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>PaginatedDataProcessorControllerList</code>.
   */
  static validateJSON(data) {
    // ensure the json data is a string
    if (data['next'] && !(typeof data['next'] === 'string' || data['next'] instanceof String)) {
      throw new Error("Expected the field `next` to be a primitive type in the JSON string but got " + data['next']);
    }
    // ensure the json data is a string
    if (data['previous'] && !(typeof data['previous'] === 'string' || data['previous'] instanceof String)) {
      throw new Error("Expected the field `previous` to be a primitive type in the JSON string but got " + data['previous']);
    }
    if (data['results']) {
      // data not null
      // ensure the json data is an array
      if (!Array.isArray(data['results'])) {
        throw new Error("Expected the field `results` to be an array in the JSON data but got " + data['results']);
      }
      // validate the optional field `results` (array)
      for (const item of data['results']) {
        _DataProcessorController.default.validateJsonObject(item);
      }
      ;
    }
    return true;
  }
}

/**
 * @member {Number} count
 */
PaginatedDataProcessorControllerList.prototype['count'] = undefined;

/**
 * @member {String} next
 */
PaginatedDataProcessorControllerList.prototype['next'] = undefined;

/**
 * @member {String} previous
 */
PaginatedDataProcessorControllerList.prototype['previous'] = undefined;

/**
 * @member {Array.<module:model/DataProcessorController>} results
 */
PaginatedDataProcessorControllerList.prototype['results'] = undefined;
var _default = exports.default = PaginatedDataProcessorControllerList;