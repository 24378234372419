"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _CountryEnum = _interopRequireDefault(require("./CountryEnum"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * NXPrivacy API
 * REST API for NXPrivacy
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The DataControllerProcessingActivityExtraEUTransferRequest model module.
 * @module model/DataControllerProcessingActivityExtraEUTransferRequest
 * @version 1.0.0
 */
class DataControllerProcessingActivityExtraEUTransferRequest {
  /**
   * Constructs a new <code>DataControllerProcessingActivityExtraEUTransferRequest</code>.
   * @alias module:model/DataControllerProcessingActivityExtraEUTransferRequest
   * @param country {module:model/CountryEnum} 
   * @param warranty {Number} 
   */
  constructor(country, warranty) {
    DataControllerProcessingActivityExtraEUTransferRequest.initialize(this, country, warranty);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, country, warranty) {
    obj['country'] = country;
    obj['warranty'] = warranty;
  }

  /**
   * Constructs a <code>DataControllerProcessingActivityExtraEUTransferRequest</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/DataControllerProcessingActivityExtraEUTransferRequest} obj Optional instance to populate.
   * @return {module:model/DataControllerProcessingActivityExtraEUTransferRequest} The populated <code>DataControllerProcessingActivityExtraEUTransferRequest</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new DataControllerProcessingActivityExtraEUTransferRequest();
      if (data.hasOwnProperty('country')) {
        obj['country'] = _CountryEnum.default.constructFromObject(data['country']);
      }
      if (data.hasOwnProperty('warranty')) {
        obj['warranty'] = _ApiClient.default.convertToType(data['warranty'], 'Number');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>DataControllerProcessingActivityExtraEUTransferRequest</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>DataControllerProcessingActivityExtraEUTransferRequest</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of DataControllerProcessingActivityExtraEUTransferRequest.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    return true;
  }
}
DataControllerProcessingActivityExtraEUTransferRequest.RequiredProperties = ["country", "warranty"];

/**
 * @member {module:model/CountryEnum} country
 */
DataControllerProcessingActivityExtraEUTransferRequest.prototype['country'] = undefined;

/**
 * @member {Number} warranty
 */
DataControllerProcessingActivityExtraEUTransferRequest.prototype['warranty'] = undefined;
var _default = exports.default = DataControllerProcessingActivityExtraEUTransferRequest;