import React from "react";
import CustomButton from "../CustomButton";
import { useTranslation } from "react-i18next";
import DeleteModal from "./DeleteModal";
import Modal from "@material-ui/core/Modal";
import { useStyles } from "../../styles/Modals.style";
import DuplicateModal from "./DuplicateModal";

function GeneralDeleteModal({
    value = null,
    handleClose,
    handleConfirmModal,
    open,
    back = null,
}) {
    const classes = useStyles();
    const { t } = useTranslation();


    return (
        <div className={classes.container}>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                className={classes.modal}
            >
                <>  <DuplicateModal
                    value={value}
                    handleConfirmModal={handleConfirmModal}
                    handleClose={handleClose}
                /></>
            </Modal>
        </div>

    );
}

export default GeneralDeleteModal;
