"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * NXPrivacy API
 * REST API for NXPrivacy
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The DataControllerInternalReferenceStructureRequest model module.
 * @module model/DataControllerInternalReferenceStructureRequest
 * @version 1.0.0
 */
class DataControllerInternalReferenceStructureRequest {
  /**
   * Constructs a new <code>DataControllerInternalReferenceStructureRequest</code>.
   * @alias module:model/DataControllerInternalReferenceStructureRequest
   * @param name {String} 
   */
  constructor(name) {
    DataControllerInternalReferenceStructureRequest.initialize(this, name);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, name) {
    obj['name'] = name;
  }

  /**
   * Constructs a <code>DataControllerInternalReferenceStructureRequest</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/DataControllerInternalReferenceStructureRequest} obj Optional instance to populate.
   * @return {module:model/DataControllerInternalReferenceStructureRequest} The populated <code>DataControllerInternalReferenceStructureRequest</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new DataControllerInternalReferenceStructureRequest();
      if (data.hasOwnProperty('name')) {
        obj['name'] = _ApiClient.default.convertToType(data['name'], 'String');
      }
      if (data.hasOwnProperty('description')) {
        obj['description'] = _ApiClient.default.convertToType(data['description'], 'String');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>DataControllerInternalReferenceStructureRequest</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>DataControllerInternalReferenceStructureRequest</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of DataControllerInternalReferenceStructureRequest.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
      throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
    }
    // ensure the json data is a string
    if (data['description'] && !(typeof data['description'] === 'string' || data['description'] instanceof String)) {
      throw new Error("Expected the field `description` to be a primitive type in the JSON string but got " + data['description']);
    }
    return true;
  }
}
DataControllerInternalReferenceStructureRequest.RequiredProperties = ["name"];

/**
 * @member {String} name
 */
DataControllerInternalReferenceStructureRequest.prototype['name'] = undefined;

/**
 * @member {String} description
 */
DataControllerInternalReferenceStructureRequest.prototype['description'] = undefined;
var _default = exports.default = DataControllerInternalReferenceStructureRequest;