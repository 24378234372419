"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _CustomRegisterRequest = _interopRequireDefault(require("../model/CustomRegisterRequest"));
var _ResendEmailVerificationRequest = _interopRequireDefault(require("../model/ResendEmailVerificationRequest"));
var _RestAuthDetail = _interopRequireDefault(require("../model/RestAuthDetail"));
var _VerifyEmailRequest = _interopRequireDefault(require("../model/VerifyEmailRequest"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * NXPrivacy API
 * REST API for NXPrivacy
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
* Registration service.
* @module api/RegistrationApi
* @version 1.0.0
*/
class RegistrationApi {
  /**
  * Constructs a new RegistrationApi. 
  * @alias module:api/RegistrationApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || _ApiClient.default.instance;
  }

  /**
   * @param {module:model/CustomRegisterRequest} custom_register_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/RestAuthDetail} and HTTP response
   */
  registrationCreateWithHttpInfo(custom_register_request) {
    let postBody = custom_register_request;
    // verify the required parameter 'custom_register_request' is set
    if (custom_register_request === undefined || custom_register_request === null) {
      throw new Error("Missing the required parameter 'custom_register_request' when calling registrationCreate");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _RestAuthDetail.default;
    return this.apiClient.callApi('/api/registration/', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {module:model/CustomRegisterRequest} custom_register_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/RestAuthDetail}
   */
  registrationCreate(custom_register_request) {
    return this.registrationCreateWithHttpInfo(custom_register_request).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {module:model/ResendEmailVerificationRequest} resend_email_verification_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/RestAuthDetail} and HTTP response
   */
  registrationResendEmailCreateWithHttpInfo(resend_email_verification_request) {
    let postBody = resend_email_verification_request;
    // verify the required parameter 'resend_email_verification_request' is set
    if (resend_email_verification_request === undefined || resend_email_verification_request === null) {
      throw new Error("Missing the required parameter 'resend_email_verification_request' when calling registrationResendEmailCreate");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _RestAuthDetail.default;
    return this.apiClient.callApi('/api/registration/resend-email/', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {module:model/ResendEmailVerificationRequest} resend_email_verification_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/RestAuthDetail}
   */
  registrationResendEmailCreate(resend_email_verification_request) {
    return this.registrationResendEmailCreateWithHttpInfo(resend_email_verification_request).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {module:model/VerifyEmailRequest} verify_email_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/RestAuthDetail} and HTTP response
   */
  registrationVerifyEmailCreateWithHttpInfo(verify_email_request) {
    let postBody = verify_email_request;
    // verify the required parameter 'verify_email_request' is set
    if (verify_email_request === undefined || verify_email_request === null) {
      throw new Error("Missing the required parameter 'verify_email_request' when calling registrationVerifyEmailCreate");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _RestAuthDetail.default;
    return this.apiClient.callApi('/api/registration/verify-email/', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {module:model/VerifyEmailRequest} verify_email_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/RestAuthDetail}
   */
  registrationVerifyEmailCreate(verify_email_request) {
    return this.registrationVerifyEmailCreateWithHttpInfo(verify_email_request).then(function (response_and_data) {
      return response_and_data.data;
    });
  }
}
exports.default = RegistrationApi;