import React, { useState, useEffect } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import TableBody from '@material-ui/core/TableBody';
import { history } from "../../store"
import { useDispatch } from "react-redux";
import {
    setOrganization, resetDataController, resetTreatment, setError, setOrganizationMembers, resetOrganization
} from "../../actions";
import { getDirectOrganizationMembers } from "../../api";

import { useStyles } from "../../styles/TableDisplay.style";
import Boolean from "../Boolean";
function OrganizationBodyTab({ row, value, handleClick }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [fetch, setFetch] = useState(false)
    const showInformation = () => {
        dispatch(setOrganization(row))
        setFetch(true)
        // dispatch(resetDataController())
        // dispatch(resetTreatment())


        getDirectOrganizationMembers(row.oid)
            .then((data) => {
                // dispatch(resetDcrTreatmentsList());
                // dispatch(resetTreatmentsList());
                let organizationWithMembers = { ...row, members: data.map((e) => e.user) }
                dispatch(setOrganization(organizationWithMembers))
            })
            .catch((err) => dispatch(setError(err)));


        history.push("/organization/display")
    }
    useEffect(() => {
        if (fetch === true)
           {}
    }, [fetch])
    return (
        <TableBody key={row.oid}>
            <TableRow className={classes.clickableRow} key={row.oid}>
                <TableCell component="th" scope="row" onClick={() => showInformation()}>{row.name}</TableCell>
                <TableCell align="right" onClick={() => showInformation()}><Boolean value={row.is_owner}/></TableCell>
                <TableCell align="right" onClick={() => showInformation()}><Boolean value={row.is_active}/></TableCell>
                <TableCell align="right" onClick={() => showInformation()}>{row.oid}</TableCell>
                <TableCell align="right" style={{ width: "30px" }}>
                    <IconButton color="inherit" className={classes.iconButton} onClick={(e) => handleClick(e, row, value)}><MoreVertIcon /></IconButton>
                </TableCell>
            </TableRow>
        </TableBody>
    )
}

export default OrganizationBodyTab;
