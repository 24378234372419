import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Visibility from "@material-ui/icons/Visibility";
import IconButton from "@material-ui/core/IconButton";
import CustomButton from "../components/CustomButton";
import ErrorFeedback from "../components/ErrorFeedback";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";
import { login, register } from "../api";
import { history } from "../store";
import { CssBaseline } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { doLogin, setError, resetAuth, doRegister } from "../actions";

import Link from "@material-ui/core/Link";

import loginLogo from "../images/loginLogo.png";
import { useStyles } from "../styles/Login.style";

const initialFormState = {
  email: "",
  password1: "",
  password2: "",
  showPassword: false,
};

function Register({ errors, isError }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [state, setState] = useState(initialFormState);

  const handleChange = (prop) => (event) =>
    setState({ ...state, [prop]: event.target.value });
  const handleClickShowPassword = () =>
    setState({ ...state, showPassword: !state.showPassword });

  const enterRegistration = (e) => {
    e.preventDefault();
    register(state.email, state.password1, state.password2)
      .then((data) => {
        dispatch(doRegister(data));
        history.push("/login");
      })
      .catch((err) => {
        dispatch(setError(err));

        // dispatch(resetAuth());
        // history.push("/");
      });
  };

  return (
    <div className={classes.container}>
      <CssBaseline />
      <ErrorFeedback />

      <img className={classes.logo} alt="Logo" src={loginLogo} />

      <form onSubmit={enterRegistration} className={classes.form}>
        <TextField
          error={!!errors["email"]}
          helperText={!!errors["email"] && errors["email"]}
          required
          className={classes.form}
          label={t("E-mail")}
          value={state.email}
          variant="filled"
          type="text"
          onChange={handleChange("email")}
        />

        <TextField
          error={!!errors["password1"]}
          helperText={!!errors["password1"] && errors["password1"]}
          required
          className={classes.form}
          label={t("Password")}
          value={state.password}
          variant="filled"
          type={state.showPassword ? "text" : "password"}
          onChange={handleChange("password1")}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                >
                  {state.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <TextField
          error={!!errors["password2"]}
          helperText={!!errors["password2"] && errors["password2"]}
          required
          className={classes.form}
          label={t("Password")}
          value={state.password}
          variant="filled"
          type={state.showPassword ? "text" : "password"}
          onChange={handleChange("password2")}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                >
                  {state.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <div style={{ display: "grid" }}>

          <CustomButton
            name="SIGN UP"
            style={{ marginTop: "20px" }}
            // onClick={enterRegistration}
            type="submit"
            disabled={state.password1 != state.password2 || state.password1.length < 1}
          />
               <Link
            component="button"
            //color="inherit"
            //  href={`/${pathComponents.slice(0, i + 1).join("/")}`}
            onClick={() => {
              
              history.push(`/resend-email`);
            }}
            key={`link-resend-email`}
          >
            Email not received? Resend
          </Link>
        </div>
      </form>

    </div>
  );
}

const mapStateProps = (state) => ({
  isError: state.errorState.showError,
  error: state.errorState.error,
  errors: state.errorState.errors,
});

export default connect(mapStateProps, {})(Register);
