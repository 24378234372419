"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * NXPrivacy API
 * REST API for NXPrivacy
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The ProcessingActivityTechnicalMeasure model module.
 * @module model/ProcessingActivityTechnicalMeasure
 * @version 1.0.0
 */
class ProcessingActivityTechnicalMeasure {
  /**
   * Constructs a new <code>ProcessingActivityTechnicalMeasure</code>.
   * @alias module:model/ProcessingActivityTechnicalMeasure
   * @param id {Number} 
   */
  constructor(id) {
    ProcessingActivityTechnicalMeasure.initialize(this, id);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, id) {
    obj['id'] = id;
  }

  /**
   * Constructs a <code>ProcessingActivityTechnicalMeasure</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ProcessingActivityTechnicalMeasure} obj Optional instance to populate.
   * @return {module:model/ProcessingActivityTechnicalMeasure} The populated <code>ProcessingActivityTechnicalMeasure</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ProcessingActivityTechnicalMeasure();
      if (data.hasOwnProperty('id')) {
        obj['id'] = _ApiClient.default.convertToType(data['id'], 'Number');
      }
      if (data.hasOwnProperty('ita')) {
        obj['ita'] = _ApiClient.default.convertToType(data['ita'], 'String');
      }
      if (data.hasOwnProperty('eng')) {
        obj['eng'] = _ApiClient.default.convertToType(data['eng'], 'String');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>ProcessingActivityTechnicalMeasure</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ProcessingActivityTechnicalMeasure</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of ProcessingActivityTechnicalMeasure.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['ita'] && !(typeof data['ita'] === 'string' || data['ita'] instanceof String)) {
      throw new Error("Expected the field `ita` to be a primitive type in the JSON string but got " + data['ita']);
    }
    // ensure the json data is a string
    if (data['eng'] && !(typeof data['eng'] === 'string' || data['eng'] instanceof String)) {
      throw new Error("Expected the field `eng` to be a primitive type in the JSON string but got " + data['eng']);
    }
    return true;
  }
}
ProcessingActivityTechnicalMeasure.RequiredProperties = ["id"];

/**
 * @member {Number} id
 */
ProcessingActivityTechnicalMeasure.prototype['id'] = undefined;

/**
 * @member {String} ita
 */
ProcessingActivityTechnicalMeasure.prototype['ita'] = undefined;

/**
 * @member {String} eng
 */
ProcessingActivityTechnicalMeasure.prototype['eng'] = undefined;
var _default = exports.default = ProcessingActivityTechnicalMeasure;