"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * NXPrivacy API
 * REST API for NXPrivacy
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The UserRequest model module.
 * @module model/UserRequest
 * @version 1.0.0
 */
class UserRequest {
  /**
   * Constructs a new <code>UserRequest</code>.
   * @alias module:model/UserRequest
   */
  constructor() {
    UserRequest.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>UserRequest</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/UserRequest} obj Optional instance to populate.
   * @return {module:model/UserRequest} The populated <code>UserRequest</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new UserRequest();
      if (data.hasOwnProperty('first_name')) {
        obj['first_name'] = _ApiClient.default.convertToType(data['first_name'], 'String');
      }
      if (data.hasOwnProperty('last_name')) {
        obj['last_name'] = _ApiClient.default.convertToType(data['last_name'], 'String');
      }
      if (data.hasOwnProperty('is_active')) {
        obj['is_active'] = _ApiClient.default.convertToType(data['is_active'], 'Boolean');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>UserRequest</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>UserRequest</code>.
   */
  static validateJSON(data) {
    // ensure the json data is a string
    if (data['first_name'] && !(typeof data['first_name'] === 'string' || data['first_name'] instanceof String)) {
      throw new Error("Expected the field `first_name` to be a primitive type in the JSON string but got " + data['first_name']);
    }
    // ensure the json data is a string
    if (data['last_name'] && !(typeof data['last_name'] === 'string' || data['last_name'] instanceof String)) {
      throw new Error("Expected the field `last_name` to be a primitive type in the JSON string but got " + data['last_name']);
    }
    return true;
  }
}

/**
 * @member {String} first_name
 */
UserRequest.prototype['first_name'] = undefined;

/**
 * @member {String} last_name
 */
UserRequest.prototype['last_name'] = undefined;

/**
 * Designates whether this user should be treated as active. Unselect this instead of deleting accounts.
 * @member {Boolean} is_active
 */
UserRequest.prototype['is_active'] = undefined;
var _default = exports.default = UserRequest;