"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _DataController = _interopRequireDefault(require("../model/DataController"));
var _DataControllerConcurrentStructure = _interopRequireDefault(require("../model/DataControllerConcurrentStructure"));
var _DataControllerConcurrentStructureRequest = _interopRequireDefault(require("../model/DataControllerConcurrentStructureRequest"));
var _DataControllerExternalProcessor = _interopRequireDefault(require("../model/DataControllerExternalProcessor"));
var _DataControllerExternalProcessorRequest = _interopRequireDefault(require("../model/DataControllerExternalProcessorRequest"));
var _DataControllerInternalReferenceStructure = _interopRequireDefault(require("../model/DataControllerInternalReferenceStructure"));
var _DataControllerInternalReferenceStructureRequest = _interopRequireDefault(require("../model/DataControllerInternalReferenceStructureRequest"));
var _DataControllerRequest = _interopRequireDefault(require("../model/DataControllerRequest"));
var _PaginatedDataControllerConcurrentStructureList = _interopRequireDefault(require("../model/PaginatedDataControllerConcurrentStructureList"));
var _PaginatedDataControllerExternalProcessorList = _interopRequireDefault(require("../model/PaginatedDataControllerExternalProcessorList"));
var _PaginatedDataControllerInternalReferenceStructureList = _interopRequireDefault(require("../model/PaginatedDataControllerInternalReferenceStructureList"));
var _PaginatedDataControllerList = _interopRequireDefault(require("../model/PaginatedDataControllerList"));
var _PatchedDataControllerConcurrentStructureRequest = _interopRequireDefault(require("../model/PatchedDataControllerConcurrentStructureRequest"));
var _PatchedDataControllerExternalProcessorRequest = _interopRequireDefault(require("../model/PatchedDataControllerExternalProcessorRequest"));
var _PatchedDataControllerInternalReferenceStructureRequest = _interopRequireDefault(require("../model/PatchedDataControllerInternalReferenceStructureRequest"));
var _PatchedDataControllerRequest = _interopRequireDefault(require("../model/PatchedDataControllerRequest"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * NXPrivacy API
 * REST API for NXPrivacy
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
* DataControllers service.
* @module api/DataControllersApi
* @version 1.0.0
*/
class DataControllersApi {
  /**
  * Constructs a new DataControllersApi. 
  * @alias module:api/DataControllersApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || _ApiClient.default.instance;
  }

  /**
   * @param {Number} data_controller_id 
   * @param {module:model/DataControllerConcurrentStructureRequest} data_controller_concurrent_structure_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DataControllerConcurrentStructure} and HTTP response
   */
  dataControllersConcurrentStructuresCreateWithHttpInfo(data_controller_id, data_controller_concurrent_structure_request) {
    let postBody = data_controller_concurrent_structure_request;
    // verify the required parameter 'data_controller_id' is set
    if (data_controller_id === undefined || data_controller_id === null) {
      throw new Error("Missing the required parameter 'data_controller_id' when calling dataControllersConcurrentStructuresCreate");
    }
    // verify the required parameter 'data_controller_concurrent_structure_request' is set
    if (data_controller_concurrent_structure_request === undefined || data_controller_concurrent_structure_request === null) {
      throw new Error("Missing the required parameter 'data_controller_concurrent_structure_request' when calling dataControllersConcurrentStructuresCreate");
    }
    let pathParams = {
      'data_controller_id': data_controller_id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _DataControllerConcurrentStructure.default;
    return this.apiClient.callApi('/api/data_controllers/{data_controller_id}/concurrent_structures/', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} data_controller_id 
   * @param {module:model/DataControllerConcurrentStructureRequest} data_controller_concurrent_structure_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DataControllerConcurrentStructure}
   */
  dataControllersConcurrentStructuresCreate(data_controller_id, data_controller_concurrent_structure_request) {
    return this.dataControllersConcurrentStructuresCreateWithHttpInfo(data_controller_id, data_controller_concurrent_structure_request).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} data_controller_id 
   * @param {Number} id A unique integer value identifying this ConcurrentStructure.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  dataControllersConcurrentStructuresDestroyWithHttpInfo(data_controller_id, id) {
    let postBody = null;
    // verify the required parameter 'data_controller_id' is set
    if (data_controller_id === undefined || data_controller_id === null) {
      throw new Error("Missing the required parameter 'data_controller_id' when calling dataControllersConcurrentStructuresDestroy");
    }
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling dataControllersConcurrentStructuresDestroy");
    }
    let pathParams = {
      'data_controller_id': data_controller_id,
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/api/data_controllers/{data_controller_id}/concurrent_structures/{id}/', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} data_controller_id 
   * @param {Number} id A unique integer value identifying this ConcurrentStructure.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  dataControllersConcurrentStructuresDestroy(data_controller_id, id) {
    return this.dataControllersConcurrentStructuresDestroyWithHttpInfo(data_controller_id, id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} data_controller_id 
   * @param {Object} opts Optional parameters
   * @param {String} opts.ordering Which field to use when ordering the results.
   * @param {Number} opts.page A page number within the paginated result set.
   * @param {Number} opts.page_size Number of results to return per page.
   * @param {String} opts.search A search term.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PaginatedDataControllerConcurrentStructureList} and HTTP response
   */
  dataControllersConcurrentStructuresListWithHttpInfo(data_controller_id, opts) {
    opts = opts || {};
    let postBody = null;
    // verify the required parameter 'data_controller_id' is set
    if (data_controller_id === undefined || data_controller_id === null) {
      throw new Error("Missing the required parameter 'data_controller_id' when calling dataControllersConcurrentStructuresList");
    }
    let pathParams = {
      'data_controller_id': data_controller_id
    };
    let queryParams = {
      'ordering': opts['ordering'],
      'page': opts['page'],
      'page_size': opts['page_size'],
      'search': opts['search']
    };
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _PaginatedDataControllerConcurrentStructureList.default;
    return this.apiClient.callApi('/api/data_controllers/{data_controller_id}/concurrent_structures/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} data_controller_id 
   * @param {Object} opts Optional parameters
   * @param {String} opts.ordering Which field to use when ordering the results.
   * @param {Number} opts.page A page number within the paginated result set.
   * @param {Number} opts.page_size Number of results to return per page.
   * @param {String} opts.search A search term.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PaginatedDataControllerConcurrentStructureList}
   */
  dataControllersConcurrentStructuresList(data_controller_id, opts) {
    return this.dataControllersConcurrentStructuresListWithHttpInfo(data_controller_id, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} data_controller_id 
   * @param {Number} id A unique integer value identifying this ConcurrentStructure.
   * @param {Object} opts Optional parameters
   * @param {module:model/PatchedDataControllerConcurrentStructureRequest} opts.patched_data_controller_concurrent_structure_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DataControllerConcurrentStructure} and HTTP response
   */
  dataControllersConcurrentStructuresPartialUpdateWithHttpInfo(data_controller_id, id, opts) {
    opts = opts || {};
    let postBody = opts['patched_data_controller_concurrent_structure_request'];
    // verify the required parameter 'data_controller_id' is set
    if (data_controller_id === undefined || data_controller_id === null) {
      throw new Error("Missing the required parameter 'data_controller_id' when calling dataControllersConcurrentStructuresPartialUpdate");
    }
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling dataControllersConcurrentStructuresPartialUpdate");
    }
    let pathParams = {
      'data_controller_id': data_controller_id,
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _DataControllerConcurrentStructure.default;
    return this.apiClient.callApi('/api/data_controllers/{data_controller_id}/concurrent_structures/{id}/', 'PATCH', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} data_controller_id 
   * @param {Number} id A unique integer value identifying this ConcurrentStructure.
   * @param {Object} opts Optional parameters
   * @param {module:model/PatchedDataControllerConcurrentStructureRequest} opts.patched_data_controller_concurrent_structure_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DataControllerConcurrentStructure}
   */
  dataControllersConcurrentStructuresPartialUpdate(data_controller_id, id, opts) {
    return this.dataControllersConcurrentStructuresPartialUpdateWithHttpInfo(data_controller_id, id, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} data_controller_id 
   * @param {Number} id A unique integer value identifying this ConcurrentStructure.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DataControllerConcurrentStructure} and HTTP response
   */
  dataControllersConcurrentStructuresRetrieveWithHttpInfo(data_controller_id, id) {
    let postBody = null;
    // verify the required parameter 'data_controller_id' is set
    if (data_controller_id === undefined || data_controller_id === null) {
      throw new Error("Missing the required parameter 'data_controller_id' when calling dataControllersConcurrentStructuresRetrieve");
    }
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling dataControllersConcurrentStructuresRetrieve");
    }
    let pathParams = {
      'data_controller_id': data_controller_id,
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _DataControllerConcurrentStructure.default;
    return this.apiClient.callApi('/api/data_controllers/{data_controller_id}/concurrent_structures/{id}/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} data_controller_id 
   * @param {Number} id A unique integer value identifying this ConcurrentStructure.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DataControllerConcurrentStructure}
   */
  dataControllersConcurrentStructuresRetrieve(data_controller_id, id) {
    return this.dataControllersConcurrentStructuresRetrieveWithHttpInfo(data_controller_id, id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} data_controller_id 
   * @param {Number} id A unique integer value identifying this ConcurrentStructure.
   * @param {module:model/DataControllerConcurrentStructureRequest} data_controller_concurrent_structure_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DataControllerConcurrentStructure} and HTTP response
   */
  dataControllersConcurrentStructuresUpdateWithHttpInfo(data_controller_id, id, data_controller_concurrent_structure_request) {
    let postBody = data_controller_concurrent_structure_request;
    // verify the required parameter 'data_controller_id' is set
    if (data_controller_id === undefined || data_controller_id === null) {
      throw new Error("Missing the required parameter 'data_controller_id' when calling dataControllersConcurrentStructuresUpdate");
    }
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling dataControllersConcurrentStructuresUpdate");
    }
    // verify the required parameter 'data_controller_concurrent_structure_request' is set
    if (data_controller_concurrent_structure_request === undefined || data_controller_concurrent_structure_request === null) {
      throw new Error("Missing the required parameter 'data_controller_concurrent_structure_request' when calling dataControllersConcurrentStructuresUpdate");
    }
    let pathParams = {
      'data_controller_id': data_controller_id,
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _DataControllerConcurrentStructure.default;
    return this.apiClient.callApi('/api/data_controllers/{data_controller_id}/concurrent_structures/{id}/', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} data_controller_id 
   * @param {Number} id A unique integer value identifying this ConcurrentStructure.
   * @param {module:model/DataControllerConcurrentStructureRequest} data_controller_concurrent_structure_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DataControllerConcurrentStructure}
   */
  dataControllersConcurrentStructuresUpdate(data_controller_id, id, data_controller_concurrent_structure_request) {
    return this.dataControllersConcurrentStructuresUpdateWithHttpInfo(data_controller_id, id, data_controller_concurrent_structure_request).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {module:model/DataControllerRequest} data_controller_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DataController} and HTTP response
   */
  dataControllersCreateWithHttpInfo(data_controller_request) {
    let postBody = data_controller_request;
    // verify the required parameter 'data_controller_request' is set
    if (data_controller_request === undefined || data_controller_request === null) {
      throw new Error("Missing the required parameter 'data_controller_request' when calling dataControllersCreate");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _DataController.default;
    return this.apiClient.callApi('/api/data_controllers/', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {module:model/DataControllerRequest} data_controller_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DataController}
   */
  dataControllersCreate(data_controller_request) {
    return this.dataControllersCreateWithHttpInfo(data_controller_request).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} id A unique integer value identifying this DataController.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  dataControllersDestroyWithHttpInfo(id) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling dataControllersDestroy");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/api/data_controllers/{id}/', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} id A unique integer value identifying this DataController.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  dataControllersDestroy(id) {
    return this.dataControllersDestroyWithHttpInfo(id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} data_controller_id 
   * @param {module:model/DataControllerExternalProcessorRequest} data_controller_external_processor_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DataControllerExternalProcessor} and HTTP response
   */
  dataControllersExternalProcessorsCreateWithHttpInfo(data_controller_id, data_controller_external_processor_request) {
    let postBody = data_controller_external_processor_request;
    // verify the required parameter 'data_controller_id' is set
    if (data_controller_id === undefined || data_controller_id === null) {
      throw new Error("Missing the required parameter 'data_controller_id' when calling dataControllersExternalProcessorsCreate");
    }
    // verify the required parameter 'data_controller_external_processor_request' is set
    if (data_controller_external_processor_request === undefined || data_controller_external_processor_request === null) {
      throw new Error("Missing the required parameter 'data_controller_external_processor_request' when calling dataControllersExternalProcessorsCreate");
    }
    let pathParams = {
      'data_controller_id': data_controller_id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _DataControllerExternalProcessor.default;
    return this.apiClient.callApi('/api/data_controllers/{data_controller_id}/external_processors/', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} data_controller_id 
   * @param {module:model/DataControllerExternalProcessorRequest} data_controller_external_processor_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DataControllerExternalProcessor}
   */
  dataControllersExternalProcessorsCreate(data_controller_id, data_controller_external_processor_request) {
    return this.dataControllersExternalProcessorsCreateWithHttpInfo(data_controller_id, data_controller_external_processor_request).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} data_controller_id 
   * @param {Number} id A unique integer value identifying this ExternalProcessor.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  dataControllersExternalProcessorsDestroyWithHttpInfo(data_controller_id, id) {
    let postBody = null;
    // verify the required parameter 'data_controller_id' is set
    if (data_controller_id === undefined || data_controller_id === null) {
      throw new Error("Missing the required parameter 'data_controller_id' when calling dataControllersExternalProcessorsDestroy");
    }
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling dataControllersExternalProcessorsDestroy");
    }
    let pathParams = {
      'data_controller_id': data_controller_id,
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/api/data_controllers/{data_controller_id}/external_processors/{id}/', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} data_controller_id 
   * @param {Number} id A unique integer value identifying this ExternalProcessor.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  dataControllersExternalProcessorsDestroy(data_controller_id, id) {
    return this.dataControllersExternalProcessorsDestroyWithHttpInfo(data_controller_id, id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} data_controller_id 
   * @param {Object} opts Optional parameters
   * @param {String} opts.ordering Which field to use when ordering the results.
   * @param {Number} opts.page A page number within the paginated result set.
   * @param {Number} opts.page_size Number of results to return per page.
   * @param {String} opts.search A search term.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PaginatedDataControllerExternalProcessorList} and HTTP response
   */
  dataControllersExternalProcessorsListWithHttpInfo(data_controller_id, opts) {
    opts = opts || {};
    let postBody = null;
    // verify the required parameter 'data_controller_id' is set
    if (data_controller_id === undefined || data_controller_id === null) {
      throw new Error("Missing the required parameter 'data_controller_id' when calling dataControllersExternalProcessorsList");
    }
    let pathParams = {
      'data_controller_id': data_controller_id
    };
    let queryParams = {
      'ordering': opts['ordering'],
      'page': opts['page'],
      'page_size': opts['page_size'],
      'search': opts['search']
    };
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _PaginatedDataControllerExternalProcessorList.default;
    return this.apiClient.callApi('/api/data_controllers/{data_controller_id}/external_processors/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} data_controller_id 
   * @param {Object} opts Optional parameters
   * @param {String} opts.ordering Which field to use when ordering the results.
   * @param {Number} opts.page A page number within the paginated result set.
   * @param {Number} opts.page_size Number of results to return per page.
   * @param {String} opts.search A search term.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PaginatedDataControllerExternalProcessorList}
   */
  dataControllersExternalProcessorsList(data_controller_id, opts) {
    return this.dataControllersExternalProcessorsListWithHttpInfo(data_controller_id, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} data_controller_id 
   * @param {Number} id A unique integer value identifying this ExternalProcessor.
   * @param {Object} opts Optional parameters
   * @param {module:model/PatchedDataControllerExternalProcessorRequest} opts.patched_data_controller_external_processor_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DataControllerExternalProcessor} and HTTP response
   */
  dataControllersExternalProcessorsPartialUpdateWithHttpInfo(data_controller_id, id, opts) {
    opts = opts || {};
    let postBody = opts['patched_data_controller_external_processor_request'];
    // verify the required parameter 'data_controller_id' is set
    if (data_controller_id === undefined || data_controller_id === null) {
      throw new Error("Missing the required parameter 'data_controller_id' when calling dataControllersExternalProcessorsPartialUpdate");
    }
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling dataControllersExternalProcessorsPartialUpdate");
    }
    let pathParams = {
      'data_controller_id': data_controller_id,
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _DataControllerExternalProcessor.default;
    return this.apiClient.callApi('/api/data_controllers/{data_controller_id}/external_processors/{id}/', 'PATCH', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} data_controller_id 
   * @param {Number} id A unique integer value identifying this ExternalProcessor.
   * @param {Object} opts Optional parameters
   * @param {module:model/PatchedDataControllerExternalProcessorRequest} opts.patched_data_controller_external_processor_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DataControllerExternalProcessor}
   */
  dataControllersExternalProcessorsPartialUpdate(data_controller_id, id, opts) {
    return this.dataControllersExternalProcessorsPartialUpdateWithHttpInfo(data_controller_id, id, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} data_controller_id 
   * @param {Number} id A unique integer value identifying this ExternalProcessor.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DataControllerExternalProcessor} and HTTP response
   */
  dataControllersExternalProcessorsRetrieveWithHttpInfo(data_controller_id, id) {
    let postBody = null;
    // verify the required parameter 'data_controller_id' is set
    if (data_controller_id === undefined || data_controller_id === null) {
      throw new Error("Missing the required parameter 'data_controller_id' when calling dataControllersExternalProcessorsRetrieve");
    }
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling dataControllersExternalProcessorsRetrieve");
    }
    let pathParams = {
      'data_controller_id': data_controller_id,
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _DataControllerExternalProcessor.default;
    return this.apiClient.callApi('/api/data_controllers/{data_controller_id}/external_processors/{id}/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} data_controller_id 
   * @param {Number} id A unique integer value identifying this ExternalProcessor.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DataControllerExternalProcessor}
   */
  dataControllersExternalProcessorsRetrieve(data_controller_id, id) {
    return this.dataControllersExternalProcessorsRetrieveWithHttpInfo(data_controller_id, id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} data_controller_id 
   * @param {Number} id A unique integer value identifying this ExternalProcessor.
   * @param {module:model/DataControllerExternalProcessorRequest} data_controller_external_processor_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DataControllerExternalProcessor} and HTTP response
   */
  dataControllersExternalProcessorsUpdateWithHttpInfo(data_controller_id, id, data_controller_external_processor_request) {
    let postBody = data_controller_external_processor_request;
    // verify the required parameter 'data_controller_id' is set
    if (data_controller_id === undefined || data_controller_id === null) {
      throw new Error("Missing the required parameter 'data_controller_id' when calling dataControllersExternalProcessorsUpdate");
    }
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling dataControllersExternalProcessorsUpdate");
    }
    // verify the required parameter 'data_controller_external_processor_request' is set
    if (data_controller_external_processor_request === undefined || data_controller_external_processor_request === null) {
      throw new Error("Missing the required parameter 'data_controller_external_processor_request' when calling dataControllersExternalProcessorsUpdate");
    }
    let pathParams = {
      'data_controller_id': data_controller_id,
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _DataControllerExternalProcessor.default;
    return this.apiClient.callApi('/api/data_controllers/{data_controller_id}/external_processors/{id}/', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} data_controller_id 
   * @param {Number} id A unique integer value identifying this ExternalProcessor.
   * @param {module:model/DataControllerExternalProcessorRequest} data_controller_external_processor_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DataControllerExternalProcessor}
   */
  dataControllersExternalProcessorsUpdate(data_controller_id, id, data_controller_external_processor_request) {
    return this.dataControllersExternalProcessorsUpdateWithHttpInfo(data_controller_id, id, data_controller_external_processor_request).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} data_controller_id 
   * @param {module:model/DataControllerInternalReferenceStructureRequest} data_controller_internal_reference_structure_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DataControllerInternalReferenceStructure} and HTTP response
   */
  dataControllersInternalStructuresCreateWithHttpInfo(data_controller_id, data_controller_internal_reference_structure_request) {
    let postBody = data_controller_internal_reference_structure_request;
    // verify the required parameter 'data_controller_id' is set
    if (data_controller_id === undefined || data_controller_id === null) {
      throw new Error("Missing the required parameter 'data_controller_id' when calling dataControllersInternalStructuresCreate");
    }
    // verify the required parameter 'data_controller_internal_reference_structure_request' is set
    if (data_controller_internal_reference_structure_request === undefined || data_controller_internal_reference_structure_request === null) {
      throw new Error("Missing the required parameter 'data_controller_internal_reference_structure_request' when calling dataControllersInternalStructuresCreate");
    }
    let pathParams = {
      'data_controller_id': data_controller_id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _DataControllerInternalReferenceStructure.default;
    return this.apiClient.callApi('/api/data_controllers/{data_controller_id}/internal_structures/', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} data_controller_id 
   * @param {module:model/DataControllerInternalReferenceStructureRequest} data_controller_internal_reference_structure_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DataControllerInternalReferenceStructure}
   */
  dataControllersInternalStructuresCreate(data_controller_id, data_controller_internal_reference_structure_request) {
    return this.dataControllersInternalStructuresCreateWithHttpInfo(data_controller_id, data_controller_internal_reference_structure_request).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} data_controller_id 
   * @param {Number} id A unique integer value identifying this InternalReferenceStructure.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  dataControllersInternalStructuresDestroyWithHttpInfo(data_controller_id, id) {
    let postBody = null;
    // verify the required parameter 'data_controller_id' is set
    if (data_controller_id === undefined || data_controller_id === null) {
      throw new Error("Missing the required parameter 'data_controller_id' when calling dataControllersInternalStructuresDestroy");
    }
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling dataControllersInternalStructuresDestroy");
    }
    let pathParams = {
      'data_controller_id': data_controller_id,
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/api/data_controllers/{data_controller_id}/internal_structures/{id}/', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} data_controller_id 
   * @param {Number} id A unique integer value identifying this InternalReferenceStructure.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  dataControllersInternalStructuresDestroy(data_controller_id, id) {
    return this.dataControllersInternalStructuresDestroyWithHttpInfo(data_controller_id, id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} data_controller_id 
   * @param {Object} opts Optional parameters
   * @param {String} opts.ordering Which field to use when ordering the results.
   * @param {Number} opts.page A page number within the paginated result set.
   * @param {Number} opts.page_size Number of results to return per page.
   * @param {String} opts.search A search term.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PaginatedDataControllerInternalReferenceStructureList} and HTTP response
   */
  dataControllersInternalStructuresListWithHttpInfo(data_controller_id, opts) {
    opts = opts || {};
    let postBody = null;
    // verify the required parameter 'data_controller_id' is set
    if (data_controller_id === undefined || data_controller_id === null) {
      throw new Error("Missing the required parameter 'data_controller_id' when calling dataControllersInternalStructuresList");
    }
    let pathParams = {
      'data_controller_id': data_controller_id
    };
    let queryParams = {
      'ordering': opts['ordering'],
      'page': opts['page'],
      'page_size': opts['page_size'],
      'search': opts['search']
    };
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _PaginatedDataControllerInternalReferenceStructureList.default;
    return this.apiClient.callApi('/api/data_controllers/{data_controller_id}/internal_structures/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} data_controller_id 
   * @param {Object} opts Optional parameters
   * @param {String} opts.ordering Which field to use when ordering the results.
   * @param {Number} opts.page A page number within the paginated result set.
   * @param {Number} opts.page_size Number of results to return per page.
   * @param {String} opts.search A search term.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PaginatedDataControllerInternalReferenceStructureList}
   */
  dataControllersInternalStructuresList(data_controller_id, opts) {
    return this.dataControllersInternalStructuresListWithHttpInfo(data_controller_id, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} data_controller_id 
   * @param {Number} id A unique integer value identifying this InternalReferenceStructure.
   * @param {Object} opts Optional parameters
   * @param {module:model/PatchedDataControllerInternalReferenceStructureRequest} opts.patched_data_controller_internal_reference_structure_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DataControllerInternalReferenceStructure} and HTTP response
   */
  dataControllersInternalStructuresPartialUpdateWithHttpInfo(data_controller_id, id, opts) {
    opts = opts || {};
    let postBody = opts['patched_data_controller_internal_reference_structure_request'];
    // verify the required parameter 'data_controller_id' is set
    if (data_controller_id === undefined || data_controller_id === null) {
      throw new Error("Missing the required parameter 'data_controller_id' when calling dataControllersInternalStructuresPartialUpdate");
    }
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling dataControllersInternalStructuresPartialUpdate");
    }
    let pathParams = {
      'data_controller_id': data_controller_id,
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _DataControllerInternalReferenceStructure.default;
    return this.apiClient.callApi('/api/data_controllers/{data_controller_id}/internal_structures/{id}/', 'PATCH', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} data_controller_id 
   * @param {Number} id A unique integer value identifying this InternalReferenceStructure.
   * @param {Object} opts Optional parameters
   * @param {module:model/PatchedDataControllerInternalReferenceStructureRequest} opts.patched_data_controller_internal_reference_structure_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DataControllerInternalReferenceStructure}
   */
  dataControllersInternalStructuresPartialUpdate(data_controller_id, id, opts) {
    return this.dataControllersInternalStructuresPartialUpdateWithHttpInfo(data_controller_id, id, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} data_controller_id 
   * @param {Number} id A unique integer value identifying this InternalReferenceStructure.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DataControllerInternalReferenceStructure} and HTTP response
   */
  dataControllersInternalStructuresRetrieveWithHttpInfo(data_controller_id, id) {
    let postBody = null;
    // verify the required parameter 'data_controller_id' is set
    if (data_controller_id === undefined || data_controller_id === null) {
      throw new Error("Missing the required parameter 'data_controller_id' when calling dataControllersInternalStructuresRetrieve");
    }
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling dataControllersInternalStructuresRetrieve");
    }
    let pathParams = {
      'data_controller_id': data_controller_id,
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _DataControllerInternalReferenceStructure.default;
    return this.apiClient.callApi('/api/data_controllers/{data_controller_id}/internal_structures/{id}/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} data_controller_id 
   * @param {Number} id A unique integer value identifying this InternalReferenceStructure.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DataControllerInternalReferenceStructure}
   */
  dataControllersInternalStructuresRetrieve(data_controller_id, id) {
    return this.dataControllersInternalStructuresRetrieveWithHttpInfo(data_controller_id, id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} data_controller_id 
   * @param {Number} id A unique integer value identifying this InternalReferenceStructure.
   * @param {module:model/DataControllerInternalReferenceStructureRequest} data_controller_internal_reference_structure_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DataControllerInternalReferenceStructure} and HTTP response
   */
  dataControllersInternalStructuresUpdateWithHttpInfo(data_controller_id, id, data_controller_internal_reference_structure_request) {
    let postBody = data_controller_internal_reference_structure_request;
    // verify the required parameter 'data_controller_id' is set
    if (data_controller_id === undefined || data_controller_id === null) {
      throw new Error("Missing the required parameter 'data_controller_id' when calling dataControllersInternalStructuresUpdate");
    }
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling dataControllersInternalStructuresUpdate");
    }
    // verify the required parameter 'data_controller_internal_reference_structure_request' is set
    if (data_controller_internal_reference_structure_request === undefined || data_controller_internal_reference_structure_request === null) {
      throw new Error("Missing the required parameter 'data_controller_internal_reference_structure_request' when calling dataControllersInternalStructuresUpdate");
    }
    let pathParams = {
      'data_controller_id': data_controller_id,
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _DataControllerInternalReferenceStructure.default;
    return this.apiClient.callApi('/api/data_controllers/{data_controller_id}/internal_structures/{id}/', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} data_controller_id 
   * @param {Number} id A unique integer value identifying this InternalReferenceStructure.
   * @param {module:model/DataControllerInternalReferenceStructureRequest} data_controller_internal_reference_structure_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DataControllerInternalReferenceStructure}
   */
  dataControllersInternalStructuresUpdate(data_controller_id, id, data_controller_internal_reference_structure_request) {
    return this.dataControllersInternalStructuresUpdateWithHttpInfo(data_controller_id, id, data_controller_internal_reference_structure_request).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Object} opts Optional parameters
   * @param {String} opts.ordering Which field to use when ordering the results.
   * @param {Number} opts.page A page number within the paginated result set.
   * @param {Number} opts.page_size Number of results to return per page.
   * @param {String} opts.search A search term.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PaginatedDataControllerList} and HTTP response
   */
  dataControllersListWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = null;
    let pathParams = {};
    let queryParams = {
      'ordering': opts['ordering'],
      'page': opts['page'],
      'page_size': opts['page_size'],
      'search': opts['search']
    };
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _PaginatedDataControllerList.default;
    return this.apiClient.callApi('/api/data_controllers/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Object} opts Optional parameters
   * @param {String} opts.ordering Which field to use when ordering the results.
   * @param {Number} opts.page A page number within the paginated result set.
   * @param {Number} opts.page_size Number of results to return per page.
   * @param {String} opts.search A search term.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PaginatedDataControllerList}
   */
  dataControllersList(opts) {
    return this.dataControllersListWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} id A unique integer value identifying this DataController.
   * @param {Object} opts Optional parameters
   * @param {module:model/PatchedDataControllerRequest} opts.patched_data_controller_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DataController} and HTTP response
   */
  dataControllersPartialUpdateWithHttpInfo(id, opts) {
    opts = opts || {};
    let postBody = opts['patched_data_controller_request'];
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling dataControllersPartialUpdate");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _DataController.default;
    return this.apiClient.callApi('/api/data_controllers/{id}/', 'PATCH', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} id A unique integer value identifying this DataController.
   * @param {Object} opts Optional parameters
   * @param {module:model/PatchedDataControllerRequest} opts.patched_data_controller_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DataController}
   */
  dataControllersPartialUpdate(id, opts) {
    return this.dataControllersPartialUpdateWithHttpInfo(id, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} id A unique integer value identifying this DataController.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DataController} and HTTP response
   */
  dataControllersRetrieveWithHttpInfo(id) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling dataControllersRetrieve");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _DataController.default;
    return this.apiClient.callApi('/api/data_controllers/{id}/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} id A unique integer value identifying this DataController.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DataController}
   */
  dataControllersRetrieve(id) {
    return this.dataControllersRetrieveWithHttpInfo(id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} id A unique integer value identifying this DataController.
   * @param {module:model/DataControllerRequest} data_controller_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DataController} and HTTP response
   */
  dataControllersUpdateWithHttpInfo(id, data_controller_request) {
    let postBody = data_controller_request;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling dataControllersUpdate");
    }
    // verify the required parameter 'data_controller_request' is set
    if (data_controller_request === undefined || data_controller_request === null) {
      throw new Error("Missing the required parameter 'data_controller_request' when calling dataControllersUpdate");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _DataController.default;
    return this.apiClient.callApi('/api/data_controllers/{id}/', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} id A unique integer value identifying this DataController.
   * @param {module:model/DataControllerRequest} data_controller_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DataController}
   */
  dataControllersUpdate(id, data_controller_request) {
    return this.dataControllersUpdateWithHttpInfo(id, data_controller_request).then(function (response_and_data) {
      return response_and_data.data;
    });
  }
}
exports.default = DataControllersApi;