import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import Input from "../Input";
import CustomButton from "../CustomButton";
//import SelectOption from "../SelectOption";

import { useStyles } from "../../styles/Modals.style";

function NewInviteModal({
  data,
  handleClose,
  handleChange,
  handleConfirmModal,
  value = null,
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(() => { }, [data])

  return (
    <div className={classes.delete}>
      <div style={{ textAlign: "center" }}>
        <p>{t("Invite user")}</p>
      </div>

      <Input
        field="nemailame"
        name={`* ${t("EMAIL")}`}
        value={data.email || ""}
        handle={handleChange("email")}
      />


      <div style={{ marginTop: "20px" }}>
        <CustomButton name="INVITE" onClick={handleConfirmModal} />
        <CustomButton name="CANCEL" value="back" onClick={handleClose} />
      </div>
    </div>
  );
}

export default NewInviteModal;
