"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _PaginatedProcessingActivityPurposeList = _interopRequireDefault(require("../model/PaginatedProcessingActivityPurposeList"));
var _ProcessingActivityPurpose = _interopRequireDefault(require("../model/ProcessingActivityPurpose"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * NXPrivacy API
 * REST API for NXPrivacy
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
* ProcessingPurpose service.
* @module api/ProcessingPurposeApi
* @version 1.0.0
*/
class ProcessingPurposeApi {
  /**
  * Constructs a new ProcessingPurposeApi. 
  * @alias module:api/ProcessingPurposeApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || _ApiClient.default.instance;
  }

  /**
   * @param {Object} opts Optional parameters
   * @param {String} opts.ordering Which field to use when ordering the results.
   * @param {Number} opts.page A page number within the paginated result set.
   * @param {Number} opts.page_size Number of results to return per page.
   * @param {String} opts.search A search term.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PaginatedProcessingActivityPurposeList} and HTTP response
   */
  processingPurposeListWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = null;
    let pathParams = {};
    let queryParams = {
      'ordering': opts['ordering'],
      'page': opts['page'],
      'page_size': opts['page_size'],
      'search': opts['search']
    };
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _PaginatedProcessingActivityPurposeList.default;
    return this.apiClient.callApi('/api/processing_purpose/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Object} opts Optional parameters
   * @param {String} opts.ordering Which field to use when ordering the results.
   * @param {Number} opts.page A page number within the paginated result set.
   * @param {Number} opts.page_size Number of results to return per page.
   * @param {String} opts.search A search term.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PaginatedProcessingActivityPurposeList}
   */
  processingPurposeList(opts) {
    return this.processingPurposeListWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} id A unique integer value identifying this ProcessingActivityPurpose.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ProcessingActivityPurpose} and HTTP response
   */
  processingPurposeRetrieveWithHttpInfo(id) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling processingPurposeRetrieve");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _ProcessingActivityPurpose.default;
    return this.apiClient.callApi('/api/processing_purpose/{id}/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} id A unique integer value identifying this ProcessingActivityPurpose.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ProcessingActivityPurpose}
   */
  processingPurposeRetrieve(id) {
    return this.processingPurposeRetrieveWithHttpInfo(id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }
}
exports.default = ProcessingPurposeApi;