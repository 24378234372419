"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * NXPrivacy API
 * REST API for NXPrivacy
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The StripeEventRequest model module.
 * @module model/StripeEventRequest
 * @version 1.0.0
 */
class StripeEventRequest {
  /**
   * Constructs a new <code>StripeEventRequest</code>.
   * Based on https://stripe.com/docs/api/events/object
   * @alias module:model/StripeEventRequest
   * @param id {String} 
   * @param api_version {String} 
   * @param request {Object.<String, Object>} 
   * @param data {Object.<String, Object>} 
   * @param type {String} 
   * @param livemode {Boolean} 
   */
  constructor(id, api_version, request, data, type, livemode) {
    StripeEventRequest.initialize(this, id, api_version, request, data, type, livemode);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, id, api_version, request, data, type, livemode) {
    obj['id'] = id;
    obj['api_version'] = api_version;
    obj['request'] = request;
    obj['data'] = data;
    obj['type'] = type;
    obj['livemode'] = livemode;
  }

  /**
   * Constructs a <code>StripeEventRequest</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/StripeEventRequest} obj Optional instance to populate.
   * @return {module:model/StripeEventRequest} The populated <code>StripeEventRequest</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new StripeEventRequest();
      if (data.hasOwnProperty('id')) {
        obj['id'] = _ApiClient.default.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('api_version')) {
        obj['api_version'] = _ApiClient.default.convertToType(data['api_version'], 'String');
      }
      if (data.hasOwnProperty('request')) {
        obj['request'] = _ApiClient.default.convertToType(data['request'], {
          'String': Object
        });
      }
      if (data.hasOwnProperty('data')) {
        obj['data'] = _ApiClient.default.convertToType(data['data'], {
          'String': Object
        });
      }
      if (data.hasOwnProperty('type')) {
        obj['type'] = _ApiClient.default.convertToType(data['type'], 'String');
      }
      if (data.hasOwnProperty('livemode')) {
        obj['livemode'] = _ApiClient.default.convertToType(data['livemode'], 'Boolean');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>StripeEventRequest</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>StripeEventRequest</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of StripeEventRequest.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['id'] && !(typeof data['id'] === 'string' || data['id'] instanceof String)) {
      throw new Error("Expected the field `id` to be a primitive type in the JSON string but got " + data['id']);
    }
    // ensure the json data is a string
    if (data['api_version'] && !(typeof data['api_version'] === 'string' || data['api_version'] instanceof String)) {
      throw new Error("Expected the field `api_version` to be a primitive type in the JSON string but got " + data['api_version']);
    }
    // ensure the json data is a string
    if (data['type'] && !(typeof data['type'] === 'string' || data['type'] instanceof String)) {
      throw new Error("Expected the field `type` to be a primitive type in the JSON string but got " + data['type']);
    }
    return true;
  }
}
StripeEventRequest.RequiredProperties = ["id", "api_version", "request", "data", "type", "livemode"];

/**
 * @member {String} id
 */
StripeEventRequest.prototype['id'] = undefined;

/**
 * @member {String} api_version
 */
StripeEventRequest.prototype['api_version'] = undefined;

/**
 * @member {Object.<String, Object>} request
 */
StripeEventRequest.prototype['request'] = undefined;

/**
 * @member {Object.<String, Object>} data
 */
StripeEventRequest.prototype['data'] = undefined;

/**
 * @member {String} type
 */
StripeEventRequest.prototype['type'] = undefined;

/**
 * @member {Boolean} livemode
 */
StripeEventRequest.prototype['livemode'] = undefined;
var _default = exports.default = StripeEventRequest;