import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Visibility from "@material-ui/icons/Visibility";
import IconButton from "@material-ui/core/IconButton";
import CustomButton from "../components/CustomButton";
import ErrorFeedback from "../components/ErrorFeedback";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";
import { login, resendEmail } from "../api";
import { history } from "../store";
import { CssBaseline } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { doLogin, setError, resetAuth, doRegister, doResendEmail } from "../actions";


import loginLogo from "../images/loginLogo.png";
import { useStyles } from "../styles/Login.style";

const initialFormState = {
  email: "",
  password1: "",
  password2: "",
  showPassword: false,
};

function ResendEmail({ errors, isError,error }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [state, setState] = useState(initialFormState);

  const handleChange = (prop) => (event) =>
    setState({ ...state, [prop]: event.target.value });

  const enterResend = (e) => {
    e.preventDefault();
    resendEmail(state.email, state.password1, state.password2)
      .then((data) => {
        dispatch(doResendEmail(data));
        history.push("/login");
      })
      .catch((err) => {
        dispatch(setError(err));

        // dispatch(resetAuth());
        // history.push("/");
      });
  };

  return (
    <div className={classes.container}>
      <CssBaseline />
      <ErrorFeedback />

      <img className={classes.logo} alt="Logo" src={loginLogo} />
      <h2>Resend email</h2>
      <form onSubmit={enterResend} className={classes.form}>
        <TextField
          error={!!errors["email"]}
          helperText={!!errors["email"] && errors["email"]}
          required
          className={classes.form}
          label={t("E-mail")}
          value={state.email}
          variant="filled"
          type="text"
          onChange={handleChange("email")}
        />


        <div style={{ display: "grid" }}>

          <CustomButton
            name="RESEND EMAIL"
            style={{ marginTop: "20px" }}
            // onClick={enterResend}
            type="submit"
            disabled={state.email.length < 1}
          />
      {isError && <p>{JSON.stringify(errors)}</p>}

        </div>
      </form>

    </div>
  );
}

const mapStateProps = (state) => ({
  isError: state.errorState.showError,
  error: state.errorState.error,
  errors: state.errorState.errors,
});

export default connect(mapStateProps, {})(ResendEmail);
