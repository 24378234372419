"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * NXPrivacy API
 * REST API for NXPrivacy
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The SubscriptionItem model module.
 * @module model/SubscriptionItem
 * @version 1.0.0
 */
class SubscriptionItem {
  /**
   * Constructs a new <code>SubscriptionItem</code>.
   * Serializes SubscriptionItem model with attributes pulled from related Subscription instance
   * @alias module:model/SubscriptionItem
   * @param product_id {String} 
   * @param product_name {String} 
   * @param product_description {String} 
   * @param price_id {String} 
   * @param price_nickname {String} 
   * @param price {String} 
   * @param freq {String} 
   * @param subscription_status {String} 
   * @param period_start {Date} 
   * @param period_end {Date} 
   * @param trial_start {Date} 
   * @param trial_end {Date} 
   * @param ended_at {Date} 
   * @param cancel_at {Date} 
   * @param cancel_at_period_end {Boolean} 
   * @param services {Array.<Object>} 
   */
  constructor(product_id, product_name, product_description, price_id, price_nickname, price, freq, subscription_status, period_start, period_end, trial_start, trial_end, ended_at, cancel_at, cancel_at_period_end, services) {
    SubscriptionItem.initialize(this, product_id, product_name, product_description, price_id, price_nickname, price, freq, subscription_status, period_start, period_end, trial_start, trial_end, ended_at, cancel_at, cancel_at_period_end, services);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, product_id, product_name, product_description, price_id, price_nickname, price, freq, subscription_status, period_start, period_end, trial_start, trial_end, ended_at, cancel_at, cancel_at_period_end, services) {
    obj['product_id'] = product_id;
    obj['product_name'] = product_name;
    obj['product_description'] = product_description;
    obj['price_id'] = price_id;
    obj['price_nickname'] = price_nickname;
    obj['price'] = price;
    obj['freq'] = freq;
    obj['subscription_status'] = subscription_status;
    obj['period_start'] = period_start;
    obj['period_end'] = period_end;
    obj['trial_start'] = trial_start;
    obj['trial_end'] = trial_end;
    obj['ended_at'] = ended_at;
    obj['cancel_at'] = cancel_at;
    obj['cancel_at_period_end'] = cancel_at_period_end;
    obj['services'] = services;
  }

  /**
   * Constructs a <code>SubscriptionItem</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/SubscriptionItem} obj Optional instance to populate.
   * @return {module:model/SubscriptionItem} The populated <code>SubscriptionItem</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new SubscriptionItem();
      if (data.hasOwnProperty('product_id')) {
        obj['product_id'] = _ApiClient.default.convertToType(data['product_id'], 'String');
      }
      if (data.hasOwnProperty('product_name')) {
        obj['product_name'] = _ApiClient.default.convertToType(data['product_name'], 'String');
      }
      if (data.hasOwnProperty('product_description')) {
        obj['product_description'] = _ApiClient.default.convertToType(data['product_description'], 'String');
      }
      if (data.hasOwnProperty('price_id')) {
        obj['price_id'] = _ApiClient.default.convertToType(data['price_id'], 'String');
      }
      if (data.hasOwnProperty('price_nickname')) {
        obj['price_nickname'] = _ApiClient.default.convertToType(data['price_nickname'], 'String');
      }
      if (data.hasOwnProperty('price')) {
        obj['price'] = _ApiClient.default.convertToType(data['price'], 'String');
      }
      if (data.hasOwnProperty('freq')) {
        obj['freq'] = _ApiClient.default.convertToType(data['freq'], 'String');
      }
      if (data.hasOwnProperty('subscription_status')) {
        obj['subscription_status'] = _ApiClient.default.convertToType(data['subscription_status'], 'String');
      }
      if (data.hasOwnProperty('period_start')) {
        obj['period_start'] = _ApiClient.default.convertToType(data['period_start'], 'Date');
      }
      if (data.hasOwnProperty('period_end')) {
        obj['period_end'] = _ApiClient.default.convertToType(data['period_end'], 'Date');
      }
      if (data.hasOwnProperty('trial_start')) {
        obj['trial_start'] = _ApiClient.default.convertToType(data['trial_start'], 'Date');
      }
      if (data.hasOwnProperty('trial_end')) {
        obj['trial_end'] = _ApiClient.default.convertToType(data['trial_end'], 'Date');
      }
      if (data.hasOwnProperty('ended_at')) {
        obj['ended_at'] = _ApiClient.default.convertToType(data['ended_at'], 'Date');
      }
      if (data.hasOwnProperty('cancel_at')) {
        obj['cancel_at'] = _ApiClient.default.convertToType(data['cancel_at'], 'Date');
      }
      if (data.hasOwnProperty('cancel_at_period_end')) {
        obj['cancel_at_period_end'] = _ApiClient.default.convertToType(data['cancel_at_period_end'], 'Boolean');
      }
      if (data.hasOwnProperty('services')) {
        obj['services'] = _ApiClient.default.convertToType(data['services'], [Object]);
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>SubscriptionItem</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>SubscriptionItem</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of SubscriptionItem.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['product_id'] && !(typeof data['product_id'] === 'string' || data['product_id'] instanceof String)) {
      throw new Error("Expected the field `product_id` to be a primitive type in the JSON string but got " + data['product_id']);
    }
    // ensure the json data is a string
    if (data['product_name'] && !(typeof data['product_name'] === 'string' || data['product_name'] instanceof String)) {
      throw new Error("Expected the field `product_name` to be a primitive type in the JSON string but got " + data['product_name']);
    }
    // ensure the json data is a string
    if (data['product_description'] && !(typeof data['product_description'] === 'string' || data['product_description'] instanceof String)) {
      throw new Error("Expected the field `product_description` to be a primitive type in the JSON string but got " + data['product_description']);
    }
    // ensure the json data is a string
    if (data['price_id'] && !(typeof data['price_id'] === 'string' || data['price_id'] instanceof String)) {
      throw new Error("Expected the field `price_id` to be a primitive type in the JSON string but got " + data['price_id']);
    }
    // ensure the json data is a string
    if (data['price_nickname'] && !(typeof data['price_nickname'] === 'string' || data['price_nickname'] instanceof String)) {
      throw new Error("Expected the field `price_nickname` to be a primitive type in the JSON string but got " + data['price_nickname']);
    }
    // ensure the json data is a string
    if (data['price'] && !(typeof data['price'] === 'string' || data['price'] instanceof String)) {
      throw new Error("Expected the field `price` to be a primitive type in the JSON string but got " + data['price']);
    }
    // ensure the json data is a string
    if (data['freq'] && !(typeof data['freq'] === 'string' || data['freq'] instanceof String)) {
      throw new Error("Expected the field `freq` to be a primitive type in the JSON string but got " + data['freq']);
    }
    // ensure the json data is a string
    if (data['subscription_status'] && !(typeof data['subscription_status'] === 'string' || data['subscription_status'] instanceof String)) {
      throw new Error("Expected the field `subscription_status` to be a primitive type in the JSON string but got " + data['subscription_status']);
    }
    // ensure the json data is an array
    if (!Array.isArray(data['services'])) {
      throw new Error("Expected the field `services` to be an array in the JSON data but got " + data['services']);
    }
    return true;
  }
}
SubscriptionItem.RequiredProperties = ["product_id", "product_name", "product_description", "price_id", "price_nickname", "price", "freq", "subscription_status", "period_start", "period_end", "trial_start", "trial_end", "ended_at", "cancel_at", "cancel_at_period_end", "services"];

/**
 * @member {String} product_id
 */
SubscriptionItem.prototype['product_id'] = undefined;

/**
 * @member {String} product_name
 */
SubscriptionItem.prototype['product_name'] = undefined;

/**
 * @member {String} product_description
 */
SubscriptionItem.prototype['product_description'] = undefined;

/**
 * @member {String} price_id
 */
SubscriptionItem.prototype['price_id'] = undefined;

/**
 * @member {String} price_nickname
 */
SubscriptionItem.prototype['price_nickname'] = undefined;

/**
 * @member {String} price
 */
SubscriptionItem.prototype['price'] = undefined;

/**
 * @member {String} freq
 */
SubscriptionItem.prototype['freq'] = undefined;

/**
 * @member {String} subscription_status
 */
SubscriptionItem.prototype['subscription_status'] = undefined;

/**
 * @member {Date} period_start
 */
SubscriptionItem.prototype['period_start'] = undefined;

/**
 * @member {Date} period_end
 */
SubscriptionItem.prototype['period_end'] = undefined;

/**
 * @member {Date} trial_start
 */
SubscriptionItem.prototype['trial_start'] = undefined;

/**
 * @member {Date} trial_end
 */
SubscriptionItem.prototype['trial_end'] = undefined;

/**
 * @member {Date} ended_at
 */
SubscriptionItem.prototype['ended_at'] = undefined;

/**
 * @member {Date} cancel_at
 */
SubscriptionItem.prototype['cancel_at'] = undefined;

/**
 * @member {Boolean} cancel_at_period_end
 */
SubscriptionItem.prototype['cancel_at_period_end'] = undefined;

/**
 * @member {Array.<Object>} services
 */
SubscriptionItem.prototype['services'] = undefined;
var _default = exports.default = SubscriptionItem;