"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * NXPrivacy API
 * REST API for NXPrivacy
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The DataProcessorSharedRegistry model module.
 * @module model/DataProcessorSharedRegistry
 * @version 1.0.0
 */
class DataProcessorSharedRegistry {
  /**
   * Constructs a new <code>DataProcessorSharedRegistry</code>.
   * @alias module:model/DataProcessorSharedRegistry
   * @param id {Number} 
   * @param owner {Number} 
   * @param created_at {Date} 
   * @param updated_at {Date} 
   * @param registry {Number} 
   */
  constructor(id, owner, created_at, updated_at, registry) {
    DataProcessorSharedRegistry.initialize(this, id, owner, created_at, updated_at, registry);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, id, owner, created_at, updated_at, registry) {
    obj['id'] = id;
    obj['owner'] = owner;
    obj['created_at'] = created_at;
    obj['updated_at'] = updated_at;
    obj['registry'] = registry;
  }

  /**
   * Constructs a <code>DataProcessorSharedRegistry</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/DataProcessorSharedRegistry} obj Optional instance to populate.
   * @return {module:model/DataProcessorSharedRegistry} The populated <code>DataProcessorSharedRegistry</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new DataProcessorSharedRegistry();
      if (data.hasOwnProperty('id')) {
        obj['id'] = _ApiClient.default.convertToType(data['id'], 'Number');
      }
      if (data.hasOwnProperty('owner')) {
        obj['owner'] = _ApiClient.default.convertToType(data['owner'], 'Number');
      }
      if (data.hasOwnProperty('created_at')) {
        obj['created_at'] = _ApiClient.default.convertToType(data['created_at'], 'Date');
      }
      if (data.hasOwnProperty('updated_at')) {
        obj['updated_at'] = _ApiClient.default.convertToType(data['updated_at'], 'Date');
      }
      if (data.hasOwnProperty('registry')) {
        obj['registry'] = _ApiClient.default.convertToType(data['registry'], 'Number');
      }
      if (data.hasOwnProperty('shared_with')) {
        obj['shared_with'] = _ApiClient.default.convertToType(data['shared_with'], 'Number');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>DataProcessorSharedRegistry</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>DataProcessorSharedRegistry</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of DataProcessorSharedRegistry.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    return true;
  }
}
DataProcessorSharedRegistry.RequiredProperties = ["id", "owner", "created_at", "updated_at", "registry"];

/**
 * @member {Number} id
 */
DataProcessorSharedRegistry.prototype['id'] = undefined;

/**
 * @member {Number} owner
 */
DataProcessorSharedRegistry.prototype['owner'] = undefined;

/**
 * @member {Date} created_at
 */
DataProcessorSharedRegistry.prototype['created_at'] = undefined;

/**
 * @member {Date} updated_at
 */
DataProcessorSharedRegistry.prototype['updated_at'] = undefined;

/**
 * @member {Number} registry
 */
DataProcessorSharedRegistry.prototype['registry'] = undefined;

/**
 * @member {Number} shared_with
 */
DataProcessorSharedRegistry.prototype['shared_with'] = undefined;
var _default = exports.default = DataProcessorSharedRegistry;