import React, { useEffect, useState } from "react";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import HomeHeader from "../components/HomeHeader";
import GeneratePDF from "../components/reportGenerator";
import TableDisplay from "../components/TableDisplay";
import CustomButton from "../components/CustomButton";
import SystemUpdateAltIcon from "@material-ui/icons/SystemUpdateAlt";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import useWindowSize from "../utils";
import { history } from "../store";
import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import {
    getProcessingAdvancedDataType,
    getProcessingAdvancedType,
    getProcessingArtNine,
    getProcessingArtSix,
    getProcessingBasicDataType,
    getProcessingBasicType,
    getProcessingDataSubject,
    getProcessingDataType,
    getProcessingDataWarehouse,
    getProcessingEuTransfer,
    getProcessingOrgMeasures,
    getProcessingPurpose,
    getProcessingStorageLocation,
    // getProcessingStorageMethod,
    getProcessingStorageSupport,
    getProcessingTecMeasures,
    getProcessingWarranty,
    resetTreatment,
    setDcrConcurrentStructures,
    setError,
    setExternalProcessors,
    setDcrInternalStructures,
    setTreatmentsList,
    //setDcrTreatmentsList,
    //resetDcrTreatment,
    setInternalStructures,
    setConcurrentStructures,
    resetExternalProcessors,
    resetControllers,
    resetProcessingAdvancedDataType,
    resetProcessingAdvancedType,
    resetProcessingDataType,
    resetProcessingArtSix,
    resetProcessingArtNine,
    resetProcessingBasicDataType,
    resetProcessingBasicType,
    resetProcessingDataSubject,
    resetProcessingDataWarehouse,
    resetProcessingEuTransfer,
    resetProcessingOrgMeasures,
    resetProcessingPurpose,
    resetProcessingStorageLocation,
    resetProcessingStorageSupport,
    resetProcessingTecMeasures,
    resetProcessingWarranty,
    resetConcurrentStructures,
    resetInternalStructures,
    resetExtraEuTransfer,
    resetDataProtectionOfficers,
    resetDcrRegistryUploads,
    resetDprRegistryUploads,
    setDcrRegistryList,
    setDcrRegistryUploads,
    setDprRegistryUploads,

} from "../actions";
import {
    getDcConcurrentStructures,
    // getExtraEuTransfers,
    getDcInternalStructures,
    getProcessingAdvancedDataTypeApi,
    getProcessingAdvancedTypeApi,
    getProcessingArtNineApi,
    getProcessingArtSixApi,
    getProcessingBasicDataTypeApi,
    getProcessingBasicTypeApi,
    getProcessingDataSubjectApi,
    getProcessingDataTypeApi,
    getProcessingDataWarehouseApi,
    getProcessingEuTransferApi,
    getProcessingOrgMeasuresApi,
    getProcessingPurposeApi,
    getProcessingStorageLocationApi,
    // getProcessingStorageMethodApi,
    getProcessingStorageSupportApi,
    getProcessingTecMeasuresApi,
    getProcessingWarrantyApi,
    getDcrTreatments,
    getDcExternalProcessors,
    getDcrProcessingAdvancedDataTypeApi,
    uploadS3DcrRegistry,
    getS3DcrRegistries,
    getS3DprRegistries
} from "../api";

import { useStylesGlobal } from "../styles/Global.style";
import { useStyles } from "../styles/Registry.style";
import { FileUpload } from "@mui/icons-material";
function Archive({ dcrSelectedRegistry, dprSelectedRegistry, treatmentList, stateForPDF, type }) {
    const { width } = useWindowSize();
    const classes = useStyles();
    const classesGlobal = useStylesGlobal();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [searchValue, setSearchValue] = useState("");

    const handleExport = async (e) => {
        e.preventDefault();
        GeneratePDF("processings_preview", treatmentList.results, t, stateForPDF,false,type);
    };

    const searchOnChange = (e) => {
        setSearchValue(e.target.value);

        getDcrTreatments(dcrSelectedRegistry.id, null, null, null, e.target.value)
            .then((data) => dispatch(setTreatmentsList(data)))
            .catch((err) => dispatch(setError(err)));
    };

    // useEffect(() => {

    //   dispatch(resetDprTreatment());

    //   if (Object.keys(dcrSelectedRegistry).length === 0) {
    //     history.goBack();
    //   }
    // }, [dcrSelectedRegistry, dispatch]);

    useEffect(() => {
        dispatch(resetDcrRegistryUploads())
        dispatch(resetDprRegistryUploads())

        if (type === "dcr") {

            getS3DcrRegistries(dcrSelectedRegistry.id)
                .then((data) => dispatch(setDcrRegistryUploads(data)))
                .catch((err) => dispatch(setError(err)));
        } else if (type === "dpr") {
            getS3DprRegistries(dprSelectedRegistry.id)
                .then((data) => dispatch(setDprRegistryUploads(data)))
                .catch((err) => dispatch(setError(err)));
        }

        // // dispatch(resetTreatment());
        // if (Object.keys(dcrSelectedRegistry).length === 0 && Object.keys(dprSelectedRegistry).length === 0) {
        //     history.goBack();
        // }
    }, [dispatch, dcrSelectedRegistry, dprSelectedRegistry]);

    return (
        <div className={classesGlobal.container}>
            <HomeHeader value={type === "dcr" ? dcrSelectedRegistry?.name : type === "dpr" ? dprSelectedRegistry?.name : ""} />

            <div className={width > 500 ? classes.inner : classes.innerMobile}>
                {/* <CustomButton
                    name="ADD ACTIVITY"
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={() =>
                        history.push("/dcr/processing-registries/add-new")
                    }
                /> */}

                <div className={classes.search}>
                    <div className={classes.searchIcon}>
                        <SearchIcon />
                    </div>

                    <InputBase
                        placeholder="Search…"
                        value={searchValue}
                        classes={{ root: classes.inputRoot, input: classes.inputInput }}
                        onChange={(e) => searchOnChange(e)}
                    />
                </div>

                {/* <CustomButton
                    name="EXPORT PDF"
                    startIcon={<SystemUpdateAltIcon />}
                    onClick={(e) => handleExport(e)}
                />

                <CustomButton
                    name="UPLOAD PDF"
                    startIcon={<FileUpload />}
                    onClick={(e) => handleUpload(e)}
                /> */}
            </div>
            {(type === "dcr" || type === "dpr") && <TableDisplay value={`archive_${type}`} />}
        </div>
    );
}

const mapStateProps = (state) => ({
    dcrSelectedRegistry: state.dcrRegistryState.dcrSelectedRegistry,
    dprSelectedRegistry: state.dprRegistryState.dprSelectedRegistry,
    treatmentList: state.treatmentState.treatmentList,
    stateForPDF: state,
});

export default connect(mapStateProps, {})(Archive);
