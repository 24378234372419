import React,{ useEffect } from "react";
import { useTranslation } from "react-i18next";

import Input from "../Input";
import CustomButton from "../CustomButton";
//import SelectOption from "../SelectOption";

import { useStyles } from "../../styles/Modals.style";

function NewOrganizationModal ({
  data,
  handleClose,
  handleChange,
  handleConfirmModal,
  value = null,
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(() => { },[data])

  return (
    <div className={classes.delete}>
      <div style={{ textAlign: "center" }}>
        {value === "update" ? (
          <p>{t("Update Organization")}</p>
        ) : (
          <p>{t("Insert new Organization")}</p>
        )}
      </div>

      <Input
        field="name"
        name={`* ${t("NAME")}`}
        value={data.name || ""}
        handle={handleChange("name")}
      />


      <div style={{ marginTop: "20px" }}>
        {value === "update" ? (
          <CustomButton name="UPDATE" onClick={handleConfirmModal} />
        ) : (
          <CustomButton name="CREATE" onClick={handleConfirmModal} />
        )}
        <CustomButton name="CANCEL" value="back" onClick={handleClose} />
      </div>
    </div>
  );
}

export default NewOrganizationModal;
