"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _CustomPasswordResetRequest = _interopRequireDefault(require("../model/CustomPasswordResetRequest"));
var _LoginRequest = _interopRequireDefault(require("../model/LoginRequest"));
var _PasswordChangeRequest = _interopRequireDefault(require("../model/PasswordChangeRequest"));
var _PasswordResetConfirmRequest = _interopRequireDefault(require("../model/PasswordResetConfirmRequest"));
var _PatchedUserRequest = _interopRequireDefault(require("../model/PatchedUserRequest"));
var _RestAuthDetail = _interopRequireDefault(require("../model/RestAuthDetail"));
var _Token = _interopRequireDefault(require("../model/Token"));
var _User = _interopRequireDefault(require("../model/User"));
var _UserRequest = _interopRequireDefault(require("../model/UserRequest"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * NXPrivacy API
 * REST API for NXPrivacy
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
* Auth service.
* @module api/AuthApi
* @version 1.0.0
*/
class AuthApi {
  /**
  * Constructs a new AuthApi. 
  * @alias module:api/AuthApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || _ApiClient.default.instance;
  }

  /**
   * Check the credentials and return the REST Token if the credentials are valid and authenticated. Calls Django Auth login method to register User ID in Django session framework  Accept the following POST parameters: username, password Return the REST Framework Token Object's key.
   * @param {module:model/LoginRequest} login_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Token} and HTTP response
   */
  authLoginCreateWithHttpInfo(login_request) {
    let postBody = login_request;
    // verify the required parameter 'login_request' is set
    if (login_request === undefined || login_request === null) {
      throw new Error("Missing the required parameter 'login_request' when calling authLoginCreate");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _Token.default;
    return this.apiClient.callApi('/api/auth/login/', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Check the credentials and return the REST Token if the credentials are valid and authenticated. Calls Django Auth login method to register User ID in Django session framework  Accept the following POST parameters: username, password Return the REST Framework Token Object's key.
   * @param {module:model/LoginRequest} login_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Token}
   */
  authLoginCreate(login_request) {
    return this.authLoginCreateWithHttpInfo(login_request).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Calls Django logout method and delete the Token object assigned to the current User object.  Accepts/Returns nothing.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/RestAuthDetail} and HTTP response
   */
  authLogoutCreateWithHttpInfo() {
    let postBody = null;
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _RestAuthDetail.default;
    return this.apiClient.callApi('/api/auth/logout/', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Calls Django logout method and delete the Token object assigned to the current User object.  Accepts/Returns nothing.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/RestAuthDetail}
   */
  authLogoutCreate() {
    return this.authLogoutCreateWithHttpInfo().then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Calls Django Auth SetPasswordForm save method.  Accepts the following POST parameters: new_password1, new_password2 Returns the success/fail message.
   * @param {module:model/PasswordChangeRequest} password_change_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/RestAuthDetail} and HTTP response
   */
  authPasswordChangeCreateWithHttpInfo(password_change_request) {
    let postBody = password_change_request;
    // verify the required parameter 'password_change_request' is set
    if (password_change_request === undefined || password_change_request === null) {
      throw new Error("Missing the required parameter 'password_change_request' when calling authPasswordChangeCreate");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _RestAuthDetail.default;
    return this.apiClient.callApi('/api/auth/password/change/', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Calls Django Auth SetPasswordForm save method.  Accepts the following POST parameters: new_password1, new_password2 Returns the success/fail message.
   * @param {module:model/PasswordChangeRequest} password_change_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/RestAuthDetail}
   */
  authPasswordChangeCreate(password_change_request) {
    return this.authPasswordChangeCreateWithHttpInfo(password_change_request).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Password reset e-mail link is confirmed, therefore this resets the user's password.  Accepts the following POST parameters: token, uid,     new_password1, new_password2 Returns the success/fail message.
   * @param {module:model/PasswordResetConfirmRequest} password_reset_confirm_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/RestAuthDetail} and HTTP response
   */
  authPasswordResetConfirmCreateWithHttpInfo(password_reset_confirm_request) {
    let postBody = password_reset_confirm_request;
    // verify the required parameter 'password_reset_confirm_request' is set
    if (password_reset_confirm_request === undefined || password_reset_confirm_request === null) {
      throw new Error("Missing the required parameter 'password_reset_confirm_request' when calling authPasswordResetConfirmCreate");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _RestAuthDetail.default;
    return this.apiClient.callApi('/api/auth/password/reset/confirm/', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Password reset e-mail link is confirmed, therefore this resets the user's password.  Accepts the following POST parameters: token, uid,     new_password1, new_password2 Returns the success/fail message.
   * @param {module:model/PasswordResetConfirmRequest} password_reset_confirm_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/RestAuthDetail}
   */
  authPasswordResetConfirmCreate(password_reset_confirm_request) {
    return this.authPasswordResetConfirmCreateWithHttpInfo(password_reset_confirm_request).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Password reset e-mail link is confirmed, therefore this resets the user's password.  Accepts the following POST parameters: token, uid,     new_password1, new_password2 Returns the success/fail message.
   * @param {String} token 
   * @param {String} uidb64 
   * @param {module:model/PasswordResetConfirmRequest} password_reset_confirm_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/RestAuthDetail} and HTTP response
   */
  authPasswordResetConfirmCreate2WithHttpInfo(token, uidb64, password_reset_confirm_request) {
    let postBody = password_reset_confirm_request;
    // verify the required parameter 'token' is set
    if (token === undefined || token === null) {
      throw new Error("Missing the required parameter 'token' when calling authPasswordResetConfirmCreate2");
    }
    // verify the required parameter 'uidb64' is set
    if (uidb64 === undefined || uidb64 === null) {
      throw new Error("Missing the required parameter 'uidb64' when calling authPasswordResetConfirmCreate2");
    }
    // verify the required parameter 'password_reset_confirm_request' is set
    if (password_reset_confirm_request === undefined || password_reset_confirm_request === null) {
      throw new Error("Missing the required parameter 'password_reset_confirm_request' when calling authPasswordResetConfirmCreate2");
    }
    let pathParams = {
      'token': token,
      'uidb64': uidb64
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _RestAuthDetail.default;
    return this.apiClient.callApi('/api/auth/password/reset/confirm/{uidb64}/{token}/', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Password reset e-mail link is confirmed, therefore this resets the user's password.  Accepts the following POST parameters: token, uid,     new_password1, new_password2 Returns the success/fail message.
   * @param {String} token 
   * @param {String} uidb64 
   * @param {module:model/PasswordResetConfirmRequest} password_reset_confirm_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/RestAuthDetail}
   */
  authPasswordResetConfirmCreate2(token, uidb64, password_reset_confirm_request) {
    return this.authPasswordResetConfirmCreate2WithHttpInfo(token, uidb64, password_reset_confirm_request).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Calls Django Auth PasswordResetForm save method.  Accepts the following POST parameters: email Returns the success/fail message.
   * @param {module:model/CustomPasswordResetRequest} custom_password_reset_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/RestAuthDetail} and HTTP response
   */
  authPasswordResetCreateWithHttpInfo(custom_password_reset_request) {
    let postBody = custom_password_reset_request;
    // verify the required parameter 'custom_password_reset_request' is set
    if (custom_password_reset_request === undefined || custom_password_reset_request === null) {
      throw new Error("Missing the required parameter 'custom_password_reset_request' when calling authPasswordResetCreate");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _RestAuthDetail.default;
    return this.apiClient.callApi('/api/auth/password/reset/', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Calls Django Auth PasswordResetForm save method.  Accepts the following POST parameters: email Returns the success/fail message.
   * @param {module:model/CustomPasswordResetRequest} custom_password_reset_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/RestAuthDetail}
   */
  authPasswordResetCreate(custom_password_reset_request) {
    return this.authPasswordResetCreateWithHttpInfo(custom_password_reset_request).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.  Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
   * @param {Object} opts Optional parameters
   * @param {module:model/PatchedUserRequest} opts.patched_user_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/User} and HTTP response
   */
  authUserPartialUpdateWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['patched_user_request'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _User.default;
    return this.apiClient.callApi('/api/auth/user/', 'PATCH', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.  Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
   * @param {Object} opts Optional parameters
   * @param {module:model/PatchedUserRequest} opts.patched_user_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/User}
   */
  authUserPartialUpdate(opts) {
    return this.authUserPartialUpdateWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.  Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/User} and HTTP response
   */
  authUserRetrieveWithHttpInfo() {
    let postBody = null;
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _User.default;
    return this.apiClient.callApi('/api/auth/user/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.  Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/User}
   */
  authUserRetrieve() {
    return this.authUserRetrieveWithHttpInfo().then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.  Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
   * @param {Object} opts Optional parameters
   * @param {module:model/UserRequest} opts.user_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/User} and HTTP response
   */
  authUserUpdateWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['user_request'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _User.default;
    return this.apiClient.callApi('/api/auth/user/', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.  Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
   * @param {Object} opts Optional parameters
   * @param {module:model/UserRequest} opts.user_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/User}
   */
  authUserUpdate(opts) {
    return this.authUserUpdateWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }
}
exports.default = AuthApi;