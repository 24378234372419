"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _DataProcessorProcessingActivity = _interopRequireDefault(require("../model/DataProcessorProcessingActivity"));
var _DataProcessorProcessingActivityExtraEUTransfer = _interopRequireDefault(require("../model/DataProcessorProcessingActivityExtraEUTransfer"));
var _DataProcessorProcessingActivityExtraEUTransferRequest = _interopRequireDefault(require("../model/DataProcessorProcessingActivityExtraEUTransferRequest"));
var _DataProcessorProcessingActivityRequest = _interopRequireDefault(require("../model/DataProcessorProcessingActivityRequest"));
var _DataProcessorRegistry = _interopRequireDefault(require("../model/DataProcessorRegistry"));
var _DataProcessorRegistryRequest = _interopRequireDefault(require("../model/DataProcessorRegistryRequest"));
var _DataProcessorRegistryUpload = _interopRequireDefault(require("../model/DataProcessorRegistryUpload"));
var _PaginatedDataProcessorProcessingActivityExtraEUTransferList = _interopRequireDefault(require("../model/PaginatedDataProcessorProcessingActivityExtraEUTransferList"));
var _PaginatedDataProcessorProcessingActivityList = _interopRequireDefault(require("../model/PaginatedDataProcessorProcessingActivityList"));
var _PaginatedDataProcessorRegistryList = _interopRequireDefault(require("../model/PaginatedDataProcessorRegistryList"));
var _PaginatedDataProcessorRegistryUploadList = _interopRequireDefault(require("../model/PaginatedDataProcessorRegistryUploadList"));
var _PatchedDataProcessorProcessingActivityExtraEUTransferRequest = _interopRequireDefault(require("../model/PatchedDataProcessorProcessingActivityExtraEUTransferRequest"));
var _PatchedDataProcessorProcessingActivityRequest = _interopRequireDefault(require("../model/PatchedDataProcessorProcessingActivityRequest"));
var _PatchedDataProcessorRegistryRequest = _interopRequireDefault(require("../model/PatchedDataProcessorRegistryRequest"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * NXPrivacy API
 * REST API for NXPrivacy
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
* DataProcessorRegistries service.
* @module api/DataProcessorRegistriesApi
* @version 1.0.0
*/
class DataProcessorRegistriesApi {
  /**
  * Constructs a new DataProcessorRegistriesApi. 
  * @alias module:api/DataProcessorRegistriesApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || _ApiClient.default.instance;
  }

  /**
   * @param {module:model/DataProcessorRegistryRequest} data_processor_registry_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DataProcessorRegistry} and HTTP response
   */
  dataProcessorRegistriesCreateWithHttpInfo(data_processor_registry_request) {
    let postBody = data_processor_registry_request;
    // verify the required parameter 'data_processor_registry_request' is set
    if (data_processor_registry_request === undefined || data_processor_registry_request === null) {
      throw new Error("Missing the required parameter 'data_processor_registry_request' when calling dataProcessorRegistriesCreate");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _DataProcessorRegistry.default;
    return this.apiClient.callApi('/api/data_processor_registries/', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {module:model/DataProcessorRegistryRequest} data_processor_registry_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DataProcessorRegistry}
   */
  dataProcessorRegistriesCreate(data_processor_registry_request) {
    return this.dataProcessorRegistriesCreateWithHttpInfo(data_processor_registry_request).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} id A unique integer value identifying this DataProcessorRegistry.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  dataProcessorRegistriesDestroyWithHttpInfo(id) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling dataProcessorRegistriesDestroy");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/api/data_processor_registries/{id}/', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} id A unique integer value identifying this DataProcessorRegistry.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  dataProcessorRegistriesDestroy(id) {
    return this.dataProcessorRegistriesDestroyWithHttpInfo(id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} id A unique integer value identifying this DataProcessorRegistry.
   * @param {module:model/DataProcessorRegistryRequest} data_processor_registry_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DataProcessorRegistry} and HTTP response
   */
  dataProcessorRegistriesDuplicateRegistryUpdateWithHttpInfo(id, data_processor_registry_request) {
    let postBody = data_processor_registry_request;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling dataProcessorRegistriesDuplicateRegistryUpdate");
    }
    // verify the required parameter 'data_processor_registry_request' is set
    if (data_processor_registry_request === undefined || data_processor_registry_request === null) {
      throw new Error("Missing the required parameter 'data_processor_registry_request' when calling dataProcessorRegistriesDuplicateRegistryUpdate");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _DataProcessorRegistry.default;
    return this.apiClient.callApi('/api/data_processor_registries/{id}/duplicate_registry/', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} id A unique integer value identifying this DataProcessorRegistry.
   * @param {module:model/DataProcessorRegistryRequest} data_processor_registry_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DataProcessorRegistry}
   */
  dataProcessorRegistriesDuplicateRegistryUpdate(id, data_processor_registry_request) {
    return this.dataProcessorRegistriesDuplicateRegistryUpdateWithHttpInfo(id, data_processor_registry_request).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Object} opts Optional parameters
   * @param {String} opts.ordering Which field to use when ordering the results.
   * @param {Number} opts.page A page number within the paginated result set.
   * @param {Number} opts.page_size Number of results to return per page.
   * @param {String} opts.search A search term.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PaginatedDataProcessorRegistryList} and HTTP response
   */
  dataProcessorRegistriesListWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = null;
    let pathParams = {};
    let queryParams = {
      'ordering': opts['ordering'],
      'page': opts['page'],
      'page_size': opts['page_size'],
      'search': opts['search']
    };
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _PaginatedDataProcessorRegistryList.default;
    return this.apiClient.callApi('/api/data_processor_registries/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Object} opts Optional parameters
   * @param {String} opts.ordering Which field to use when ordering the results.
   * @param {Number} opts.page A page number within the paginated result set.
   * @param {Number} opts.page_size Number of results to return per page.
   * @param {String} opts.search A search term.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PaginatedDataProcessorRegistryList}
   */
  dataProcessorRegistriesList(opts) {
    return this.dataProcessorRegistriesListWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} id A unique integer value identifying this DataProcessorRegistry.
   * @param {Object} opts Optional parameters
   * @param {module:model/PatchedDataProcessorRegistryRequest} opts.patched_data_processor_registry_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DataProcessorRegistry} and HTTP response
   */
  dataProcessorRegistriesPartialUpdateWithHttpInfo(id, opts) {
    opts = opts || {};
    let postBody = opts['patched_data_processor_registry_request'];
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling dataProcessorRegistriesPartialUpdate");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _DataProcessorRegistry.default;
    return this.apiClient.callApi('/api/data_processor_registries/{id}/', 'PATCH', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} id A unique integer value identifying this DataProcessorRegistry.
   * @param {Object} opts Optional parameters
   * @param {module:model/PatchedDataProcessorRegistryRequest} opts.patched_data_processor_registry_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DataProcessorRegistry}
   */
  dataProcessorRegistriesPartialUpdate(id, opts) {
    return this.dataProcessorRegistriesPartialUpdateWithHttpInfo(id, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} registry_id 
   * @param {module:model/DataProcessorProcessingActivityRequest} data_processor_processing_activity_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DataProcessorProcessingActivity} and HTTP response
   */
  dataProcessorRegistriesProcessingActsCreateWithHttpInfo(registry_id, data_processor_processing_activity_request) {
    let postBody = data_processor_processing_activity_request;
    // verify the required parameter 'registry_id' is set
    if (registry_id === undefined || registry_id === null) {
      throw new Error("Missing the required parameter 'registry_id' when calling dataProcessorRegistriesProcessingActsCreate");
    }
    // verify the required parameter 'data_processor_processing_activity_request' is set
    if (data_processor_processing_activity_request === undefined || data_processor_processing_activity_request === null) {
      throw new Error("Missing the required parameter 'data_processor_processing_activity_request' when calling dataProcessorRegistriesProcessingActsCreate");
    }
    let pathParams = {
      'registry_id': registry_id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _DataProcessorProcessingActivity.default;
    return this.apiClient.callApi('/api/data_processor_registries/{registry_id}/processing_acts/', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} registry_id 
   * @param {module:model/DataProcessorProcessingActivityRequest} data_processor_processing_activity_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DataProcessorProcessingActivity}
   */
  dataProcessorRegistriesProcessingActsCreate(registry_id, data_processor_processing_activity_request) {
    return this.dataProcessorRegistriesProcessingActsCreateWithHttpInfo(registry_id, data_processor_processing_activity_request).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} id A unique integer value identifying this DataProcessorProcessingActivity.
   * @param {Number} registry_id 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  dataProcessorRegistriesProcessingActsDestroyWithHttpInfo(id, registry_id) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling dataProcessorRegistriesProcessingActsDestroy");
    }
    // verify the required parameter 'registry_id' is set
    if (registry_id === undefined || registry_id === null) {
      throw new Error("Missing the required parameter 'registry_id' when calling dataProcessorRegistriesProcessingActsDestroy");
    }
    let pathParams = {
      'id': id,
      'registry_id': registry_id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/api/data_processor_registries/{registry_id}/processing_acts/{id}/', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} id A unique integer value identifying this DataProcessorProcessingActivity.
   * @param {Number} registry_id 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  dataProcessorRegistriesProcessingActsDestroy(id, registry_id) {
    return this.dataProcessorRegistriesProcessingActsDestroyWithHttpInfo(id, registry_id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} id A unique integer value identifying this DataProcessorProcessingActivity.
   * @param {Number} registry_id 
   * @param {module:model/DataProcessorProcessingActivityRequest} data_processor_processing_activity_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DataProcessorProcessingActivity} and HTTP response
   */
  dataProcessorRegistriesProcessingActsDuplicateProcessingUpdateWithHttpInfo(id, registry_id, data_processor_processing_activity_request) {
    let postBody = data_processor_processing_activity_request;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling dataProcessorRegistriesProcessingActsDuplicateProcessingUpdate");
    }
    // verify the required parameter 'registry_id' is set
    if (registry_id === undefined || registry_id === null) {
      throw new Error("Missing the required parameter 'registry_id' when calling dataProcessorRegistriesProcessingActsDuplicateProcessingUpdate");
    }
    // verify the required parameter 'data_processor_processing_activity_request' is set
    if (data_processor_processing_activity_request === undefined || data_processor_processing_activity_request === null) {
      throw new Error("Missing the required parameter 'data_processor_processing_activity_request' when calling dataProcessorRegistriesProcessingActsDuplicateProcessingUpdate");
    }
    let pathParams = {
      'id': id,
      'registry_id': registry_id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _DataProcessorProcessingActivity.default;
    return this.apiClient.callApi('/api/data_processor_registries/{registry_id}/processing_acts/{id}/duplicate_processing/', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} id A unique integer value identifying this DataProcessorProcessingActivity.
   * @param {Number} registry_id 
   * @param {module:model/DataProcessorProcessingActivityRequest} data_processor_processing_activity_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DataProcessorProcessingActivity}
   */
  dataProcessorRegistriesProcessingActsDuplicateProcessingUpdate(id, registry_id, data_processor_processing_activity_request) {
    return this.dataProcessorRegistriesProcessingActsDuplicateProcessingUpdateWithHttpInfo(id, registry_id, data_processor_processing_activity_request).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} processing_id 
   * @param {Number} registry_id 
   * @param {module:model/DataProcessorProcessingActivityExtraEUTransferRequest} data_processor_processing_activity_extra_eu_transfer_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DataProcessorProcessingActivityExtraEUTransfer} and HTTP response
   */
  dataProcessorRegistriesProcessingActsExtraEuTransfersCreateWithHttpInfo(processing_id, registry_id, data_processor_processing_activity_extra_eu_transfer_request) {
    let postBody = data_processor_processing_activity_extra_eu_transfer_request;
    // verify the required parameter 'processing_id' is set
    if (processing_id === undefined || processing_id === null) {
      throw new Error("Missing the required parameter 'processing_id' when calling dataProcessorRegistriesProcessingActsExtraEuTransfersCreate");
    }
    // verify the required parameter 'registry_id' is set
    if (registry_id === undefined || registry_id === null) {
      throw new Error("Missing the required parameter 'registry_id' when calling dataProcessorRegistriesProcessingActsExtraEuTransfersCreate");
    }
    // verify the required parameter 'data_processor_processing_activity_extra_eu_transfer_request' is set
    if (data_processor_processing_activity_extra_eu_transfer_request === undefined || data_processor_processing_activity_extra_eu_transfer_request === null) {
      throw new Error("Missing the required parameter 'data_processor_processing_activity_extra_eu_transfer_request' when calling dataProcessorRegistriesProcessingActsExtraEuTransfersCreate");
    }
    let pathParams = {
      'processing_id': processing_id,
      'registry_id': registry_id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _DataProcessorProcessingActivityExtraEUTransfer.default;
    return this.apiClient.callApi('/api/data_processor_registries/{registry_id}/processing_acts/{processing_id}/extra_eu_transfers/', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} processing_id 
   * @param {Number} registry_id 
   * @param {module:model/DataProcessorProcessingActivityExtraEUTransferRequest} data_processor_processing_activity_extra_eu_transfer_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DataProcessorProcessingActivityExtraEUTransfer}
   */
  dataProcessorRegistriesProcessingActsExtraEuTransfersCreate(processing_id, registry_id, data_processor_processing_activity_extra_eu_transfer_request) {
    return this.dataProcessorRegistriesProcessingActsExtraEuTransfersCreateWithHttpInfo(processing_id, registry_id, data_processor_processing_activity_extra_eu_transfer_request).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} id A unique integer value identifying this DataProcessorProcessingActivityExtraEUTransfer.
   * @param {Number} processing_id 
   * @param {Number} registry_id 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  dataProcessorRegistriesProcessingActsExtraEuTransfersDestroyWithHttpInfo(id, processing_id, registry_id) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling dataProcessorRegistriesProcessingActsExtraEuTransfersDestroy");
    }
    // verify the required parameter 'processing_id' is set
    if (processing_id === undefined || processing_id === null) {
      throw new Error("Missing the required parameter 'processing_id' when calling dataProcessorRegistriesProcessingActsExtraEuTransfersDestroy");
    }
    // verify the required parameter 'registry_id' is set
    if (registry_id === undefined || registry_id === null) {
      throw new Error("Missing the required parameter 'registry_id' when calling dataProcessorRegistriesProcessingActsExtraEuTransfersDestroy");
    }
    let pathParams = {
      'id': id,
      'processing_id': processing_id,
      'registry_id': registry_id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/api/data_processor_registries/{registry_id}/processing_acts/{processing_id}/extra_eu_transfers/{id}/', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} id A unique integer value identifying this DataProcessorProcessingActivityExtraEUTransfer.
   * @param {Number} processing_id 
   * @param {Number} registry_id 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  dataProcessorRegistriesProcessingActsExtraEuTransfersDestroy(id, processing_id, registry_id) {
    return this.dataProcessorRegistriesProcessingActsExtraEuTransfersDestroyWithHttpInfo(id, processing_id, registry_id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} processing_id 
   * @param {Number} registry_id 
   * @param {Object} opts Optional parameters
   * @param {String} opts.ordering Which field to use when ordering the results.
   * @param {Number} opts.page A page number within the paginated result set.
   * @param {Number} opts.page_size Number of results to return per page.
   * @param {String} opts.search A search term.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PaginatedDataProcessorProcessingActivityExtraEUTransferList} and HTTP response
   */
  dataProcessorRegistriesProcessingActsExtraEuTransfersListWithHttpInfo(processing_id, registry_id, opts) {
    opts = opts || {};
    let postBody = null;
    // verify the required parameter 'processing_id' is set
    if (processing_id === undefined || processing_id === null) {
      throw new Error("Missing the required parameter 'processing_id' when calling dataProcessorRegistriesProcessingActsExtraEuTransfersList");
    }
    // verify the required parameter 'registry_id' is set
    if (registry_id === undefined || registry_id === null) {
      throw new Error("Missing the required parameter 'registry_id' when calling dataProcessorRegistriesProcessingActsExtraEuTransfersList");
    }
    let pathParams = {
      'processing_id': processing_id,
      'registry_id': registry_id
    };
    let queryParams = {
      'ordering': opts['ordering'],
      'page': opts['page'],
      'page_size': opts['page_size'],
      'search': opts['search']
    };
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _PaginatedDataProcessorProcessingActivityExtraEUTransferList.default;
    return this.apiClient.callApi('/api/data_processor_registries/{registry_id}/processing_acts/{processing_id}/extra_eu_transfers/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} processing_id 
   * @param {Number} registry_id 
   * @param {Object} opts Optional parameters
   * @param {String} opts.ordering Which field to use when ordering the results.
   * @param {Number} opts.page A page number within the paginated result set.
   * @param {Number} opts.page_size Number of results to return per page.
   * @param {String} opts.search A search term.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PaginatedDataProcessorProcessingActivityExtraEUTransferList}
   */
  dataProcessorRegistriesProcessingActsExtraEuTransfersList(processing_id, registry_id, opts) {
    return this.dataProcessorRegistriesProcessingActsExtraEuTransfersListWithHttpInfo(processing_id, registry_id, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} id A unique integer value identifying this DataProcessorProcessingActivityExtraEUTransfer.
   * @param {Number} processing_id 
   * @param {Number} registry_id 
   * @param {Object} opts Optional parameters
   * @param {module:model/PatchedDataProcessorProcessingActivityExtraEUTransferRequest} opts.patched_data_processor_processing_activity_extra_eu_transfer_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DataProcessorProcessingActivityExtraEUTransfer} and HTTP response
   */
  dataProcessorRegistriesProcessingActsExtraEuTransfersPartialUpdateWithHttpInfo(id, processing_id, registry_id, opts) {
    opts = opts || {};
    let postBody = opts['patched_data_processor_processing_activity_extra_eu_transfer_request'];
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling dataProcessorRegistriesProcessingActsExtraEuTransfersPartialUpdate");
    }
    // verify the required parameter 'processing_id' is set
    if (processing_id === undefined || processing_id === null) {
      throw new Error("Missing the required parameter 'processing_id' when calling dataProcessorRegistriesProcessingActsExtraEuTransfersPartialUpdate");
    }
    // verify the required parameter 'registry_id' is set
    if (registry_id === undefined || registry_id === null) {
      throw new Error("Missing the required parameter 'registry_id' when calling dataProcessorRegistriesProcessingActsExtraEuTransfersPartialUpdate");
    }
    let pathParams = {
      'id': id,
      'processing_id': processing_id,
      'registry_id': registry_id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _DataProcessorProcessingActivityExtraEUTransfer.default;
    return this.apiClient.callApi('/api/data_processor_registries/{registry_id}/processing_acts/{processing_id}/extra_eu_transfers/{id}/', 'PATCH', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} id A unique integer value identifying this DataProcessorProcessingActivityExtraEUTransfer.
   * @param {Number} processing_id 
   * @param {Number} registry_id 
   * @param {Object} opts Optional parameters
   * @param {module:model/PatchedDataProcessorProcessingActivityExtraEUTransferRequest} opts.patched_data_processor_processing_activity_extra_eu_transfer_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DataProcessorProcessingActivityExtraEUTransfer}
   */
  dataProcessorRegistriesProcessingActsExtraEuTransfersPartialUpdate(id, processing_id, registry_id, opts) {
    return this.dataProcessorRegistriesProcessingActsExtraEuTransfersPartialUpdateWithHttpInfo(id, processing_id, registry_id, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} id A unique integer value identifying this DataProcessorProcessingActivityExtraEUTransfer.
   * @param {Number} processing_id 
   * @param {Number} registry_id 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DataProcessorProcessingActivityExtraEUTransfer} and HTTP response
   */
  dataProcessorRegistriesProcessingActsExtraEuTransfersRetrieveWithHttpInfo(id, processing_id, registry_id) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling dataProcessorRegistriesProcessingActsExtraEuTransfersRetrieve");
    }
    // verify the required parameter 'processing_id' is set
    if (processing_id === undefined || processing_id === null) {
      throw new Error("Missing the required parameter 'processing_id' when calling dataProcessorRegistriesProcessingActsExtraEuTransfersRetrieve");
    }
    // verify the required parameter 'registry_id' is set
    if (registry_id === undefined || registry_id === null) {
      throw new Error("Missing the required parameter 'registry_id' when calling dataProcessorRegistriesProcessingActsExtraEuTransfersRetrieve");
    }
    let pathParams = {
      'id': id,
      'processing_id': processing_id,
      'registry_id': registry_id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _DataProcessorProcessingActivityExtraEUTransfer.default;
    return this.apiClient.callApi('/api/data_processor_registries/{registry_id}/processing_acts/{processing_id}/extra_eu_transfers/{id}/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} id A unique integer value identifying this DataProcessorProcessingActivityExtraEUTransfer.
   * @param {Number} processing_id 
   * @param {Number} registry_id 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DataProcessorProcessingActivityExtraEUTransfer}
   */
  dataProcessorRegistriesProcessingActsExtraEuTransfersRetrieve(id, processing_id, registry_id) {
    return this.dataProcessorRegistriesProcessingActsExtraEuTransfersRetrieveWithHttpInfo(id, processing_id, registry_id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} id A unique integer value identifying this DataProcessorProcessingActivityExtraEUTransfer.
   * @param {Number} processing_id 
   * @param {Number} registry_id 
   * @param {module:model/DataProcessorProcessingActivityExtraEUTransferRequest} data_processor_processing_activity_extra_eu_transfer_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DataProcessorProcessingActivityExtraEUTransfer} and HTTP response
   */
  dataProcessorRegistriesProcessingActsExtraEuTransfersUpdateWithHttpInfo(id, processing_id, registry_id, data_processor_processing_activity_extra_eu_transfer_request) {
    let postBody = data_processor_processing_activity_extra_eu_transfer_request;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling dataProcessorRegistriesProcessingActsExtraEuTransfersUpdate");
    }
    // verify the required parameter 'processing_id' is set
    if (processing_id === undefined || processing_id === null) {
      throw new Error("Missing the required parameter 'processing_id' when calling dataProcessorRegistriesProcessingActsExtraEuTransfersUpdate");
    }
    // verify the required parameter 'registry_id' is set
    if (registry_id === undefined || registry_id === null) {
      throw new Error("Missing the required parameter 'registry_id' when calling dataProcessorRegistriesProcessingActsExtraEuTransfersUpdate");
    }
    // verify the required parameter 'data_processor_processing_activity_extra_eu_transfer_request' is set
    if (data_processor_processing_activity_extra_eu_transfer_request === undefined || data_processor_processing_activity_extra_eu_transfer_request === null) {
      throw new Error("Missing the required parameter 'data_processor_processing_activity_extra_eu_transfer_request' when calling dataProcessorRegistriesProcessingActsExtraEuTransfersUpdate");
    }
    let pathParams = {
      'id': id,
      'processing_id': processing_id,
      'registry_id': registry_id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _DataProcessorProcessingActivityExtraEUTransfer.default;
    return this.apiClient.callApi('/api/data_processor_registries/{registry_id}/processing_acts/{processing_id}/extra_eu_transfers/{id}/', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} id A unique integer value identifying this DataProcessorProcessingActivityExtraEUTransfer.
   * @param {Number} processing_id 
   * @param {Number} registry_id 
   * @param {module:model/DataProcessorProcessingActivityExtraEUTransferRequest} data_processor_processing_activity_extra_eu_transfer_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DataProcessorProcessingActivityExtraEUTransfer}
   */
  dataProcessorRegistriesProcessingActsExtraEuTransfersUpdate(id, processing_id, registry_id, data_processor_processing_activity_extra_eu_transfer_request) {
    return this.dataProcessorRegistriesProcessingActsExtraEuTransfersUpdateWithHttpInfo(id, processing_id, registry_id, data_processor_processing_activity_extra_eu_transfer_request).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} registry_id 
   * @param {Object} opts Optional parameters
   * @param {String} opts.ordering Which field to use when ordering the results.
   * @param {Number} opts.page A page number within the paginated result set.
   * @param {Number} opts.page_size Number of results to return per page.
   * @param {String} opts.search A search term.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PaginatedDataProcessorProcessingActivityList} and HTTP response
   */
  dataProcessorRegistriesProcessingActsListWithHttpInfo(registry_id, opts) {
    opts = opts || {};
    let postBody = null;
    // verify the required parameter 'registry_id' is set
    if (registry_id === undefined || registry_id === null) {
      throw new Error("Missing the required parameter 'registry_id' when calling dataProcessorRegistriesProcessingActsList");
    }
    let pathParams = {
      'registry_id': registry_id
    };
    let queryParams = {
      'ordering': opts['ordering'],
      'page': opts['page'],
      'page_size': opts['page_size'],
      'search': opts['search']
    };
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _PaginatedDataProcessorProcessingActivityList.default;
    return this.apiClient.callApi('/api/data_processor_registries/{registry_id}/processing_acts/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} registry_id 
   * @param {Object} opts Optional parameters
   * @param {String} opts.ordering Which field to use when ordering the results.
   * @param {Number} opts.page A page number within the paginated result set.
   * @param {Number} opts.page_size Number of results to return per page.
   * @param {String} opts.search A search term.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PaginatedDataProcessorProcessingActivityList}
   */
  dataProcessorRegistriesProcessingActsList(registry_id, opts) {
    return this.dataProcessorRegistriesProcessingActsListWithHttpInfo(registry_id, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} id A unique integer value identifying this DataProcessorProcessingActivity.
   * @param {Number} registry_id 
   * @param {Object} opts Optional parameters
   * @param {module:model/PatchedDataProcessorProcessingActivityRequest} opts.patched_data_processor_processing_activity_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DataProcessorProcessingActivity} and HTTP response
   */
  dataProcessorRegistriesProcessingActsPartialUpdateWithHttpInfo(id, registry_id, opts) {
    opts = opts || {};
    let postBody = opts['patched_data_processor_processing_activity_request'];
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling dataProcessorRegistriesProcessingActsPartialUpdate");
    }
    // verify the required parameter 'registry_id' is set
    if (registry_id === undefined || registry_id === null) {
      throw new Error("Missing the required parameter 'registry_id' when calling dataProcessorRegistriesProcessingActsPartialUpdate");
    }
    let pathParams = {
      'id': id,
      'registry_id': registry_id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _DataProcessorProcessingActivity.default;
    return this.apiClient.callApi('/api/data_processor_registries/{registry_id}/processing_acts/{id}/', 'PATCH', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} id A unique integer value identifying this DataProcessorProcessingActivity.
   * @param {Number} registry_id 
   * @param {Object} opts Optional parameters
   * @param {module:model/PatchedDataProcessorProcessingActivityRequest} opts.patched_data_processor_processing_activity_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DataProcessorProcessingActivity}
   */
  dataProcessorRegistriesProcessingActsPartialUpdate(id, registry_id, opts) {
    return this.dataProcessorRegistriesProcessingActsPartialUpdateWithHttpInfo(id, registry_id, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} id A unique integer value identifying this DataProcessorProcessingActivity.
   * @param {Number} registry_id 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DataProcessorProcessingActivity} and HTTP response
   */
  dataProcessorRegistriesProcessingActsRetrieveWithHttpInfo(id, registry_id) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling dataProcessorRegistriesProcessingActsRetrieve");
    }
    // verify the required parameter 'registry_id' is set
    if (registry_id === undefined || registry_id === null) {
      throw new Error("Missing the required parameter 'registry_id' when calling dataProcessorRegistriesProcessingActsRetrieve");
    }
    let pathParams = {
      'id': id,
      'registry_id': registry_id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _DataProcessorProcessingActivity.default;
    return this.apiClient.callApi('/api/data_processor_registries/{registry_id}/processing_acts/{id}/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} id A unique integer value identifying this DataProcessorProcessingActivity.
   * @param {Number} registry_id 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DataProcessorProcessingActivity}
   */
  dataProcessorRegistriesProcessingActsRetrieve(id, registry_id) {
    return this.dataProcessorRegistriesProcessingActsRetrieveWithHttpInfo(id, registry_id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} id A unique integer value identifying this DataProcessorProcessingActivity.
   * @param {Number} registry_id 
   * @param {module:model/DataProcessorProcessingActivityRequest} data_processor_processing_activity_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DataProcessorProcessingActivity} and HTTP response
   */
  dataProcessorRegistriesProcessingActsUpdateWithHttpInfo(id, registry_id, data_processor_processing_activity_request) {
    let postBody = data_processor_processing_activity_request;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling dataProcessorRegistriesProcessingActsUpdate");
    }
    // verify the required parameter 'registry_id' is set
    if (registry_id === undefined || registry_id === null) {
      throw new Error("Missing the required parameter 'registry_id' when calling dataProcessorRegistriesProcessingActsUpdate");
    }
    // verify the required parameter 'data_processor_processing_activity_request' is set
    if (data_processor_processing_activity_request === undefined || data_processor_processing_activity_request === null) {
      throw new Error("Missing the required parameter 'data_processor_processing_activity_request' when calling dataProcessorRegistriesProcessingActsUpdate");
    }
    let pathParams = {
      'id': id,
      'registry_id': registry_id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _DataProcessorProcessingActivity.default;
    return this.apiClient.callApi('/api/data_processor_registries/{registry_id}/processing_acts/{id}/', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} id A unique integer value identifying this DataProcessorProcessingActivity.
   * @param {Number} registry_id 
   * @param {module:model/DataProcessorProcessingActivityRequest} data_processor_processing_activity_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DataProcessorProcessingActivity}
   */
  dataProcessorRegistriesProcessingActsUpdate(id, registry_id, data_processor_processing_activity_request) {
    return this.dataProcessorRegistriesProcessingActsUpdateWithHttpInfo(id, registry_id, data_processor_processing_activity_request).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} id A unique integer value identifying this DataProcessorRegistry.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DataProcessorRegistry} and HTTP response
   */
  dataProcessorRegistriesRetrieveWithHttpInfo(id) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling dataProcessorRegistriesRetrieve");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _DataProcessorRegistry.default;
    return this.apiClient.callApi('/api/data_processor_registries/{id}/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} id A unique integer value identifying this DataProcessorRegistry.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DataProcessorRegistry}
   */
  dataProcessorRegistriesRetrieve(id) {
    return this.dataProcessorRegistriesRetrieveWithHttpInfo(id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} id A unique integer value identifying this DataProcessorRegistry.
   * @param {module:model/DataProcessorRegistryRequest} data_processor_registry_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DataProcessorRegistry} and HTTP response
   */
  dataProcessorRegistriesUpdateWithHttpInfo(id, data_processor_registry_request) {
    let postBody = data_processor_registry_request;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling dataProcessorRegistriesUpdate");
    }
    // verify the required parameter 'data_processor_registry_request' is set
    if (data_processor_registry_request === undefined || data_processor_registry_request === null) {
      throw new Error("Missing the required parameter 'data_processor_registry_request' when calling dataProcessorRegistriesUpdate");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _DataProcessorRegistry.default;
    return this.apiClient.callApi('/api/data_processor_registries/{id}/', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} id A unique integer value identifying this DataProcessorRegistry.
   * @param {module:model/DataProcessorRegistryRequest} data_processor_registry_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DataProcessorRegistry}
   */
  dataProcessorRegistriesUpdate(id, data_processor_registry_request) {
    return this.dataProcessorRegistriesUpdateWithHttpInfo(id, data_processor_registry_request).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} registry_id 
   * @param {File} document 
   * @param {Object} opts Optional parameters
   * @param {Boolean} opts.is_verified 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DataProcessorRegistryUpload} and HTTP response
   */
  dataProcessorRegistriesUploadsCreateWithHttpInfo(registry_id, document, opts) {
    opts = opts || {};
    let postBody = null;
    // verify the required parameter 'registry_id' is set
    if (registry_id === undefined || registry_id === null) {
      throw new Error("Missing the required parameter 'registry_id' when calling dataProcessorRegistriesUploadsCreate");
    }
    // verify the required parameter 'document' is set
    if (document === undefined || document === null) {
      throw new Error("Missing the required parameter 'document' when calling dataProcessorRegistriesUploadsCreate");
    }
    let pathParams = {
      'registry_id': registry_id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {
      'document': document,
      'is_verified': opts['is_verified']
    };
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = ['multipart/form-data'];
    let accepts = ['application/json'];
    let returnType = _DataProcessorRegistryUpload.default;
    return this.apiClient.callApi('/api/data_processor_registries/{registry_id}/uploads/', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} registry_id 
   * @param {File} document 
   * @param {Object} opts Optional parameters
   * @param {Boolean} opts.is_verified 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DataProcessorRegistryUpload}
   */
  dataProcessorRegistriesUploadsCreate(registry_id, document, opts) {
    return this.dataProcessorRegistriesUploadsCreateWithHttpInfo(registry_id, document, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} id A unique integer value identifying this DataProcessorRegistryUpload.
   * @param {Number} registry_id 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  dataProcessorRegistriesUploadsDestroyWithHttpInfo(id, registry_id) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling dataProcessorRegistriesUploadsDestroy");
    }
    // verify the required parameter 'registry_id' is set
    if (registry_id === undefined || registry_id === null) {
      throw new Error("Missing the required parameter 'registry_id' when calling dataProcessorRegistriesUploadsDestroy");
    }
    let pathParams = {
      'id': id,
      'registry_id': registry_id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/api/data_processor_registries/{registry_id}/uploads/{id}/', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} id A unique integer value identifying this DataProcessorRegistryUpload.
   * @param {Number} registry_id 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  dataProcessorRegistriesUploadsDestroy(id, registry_id) {
    return this.dataProcessorRegistriesUploadsDestroyWithHttpInfo(id, registry_id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} id A unique integer value identifying this DataProcessorRegistryUpload.
   * @param {Number} registry_id 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DataProcessorRegistryUpload} and HTTP response
   */
  dataProcessorRegistriesUploadsDocumentRetrieveWithHttpInfo(id, registry_id) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling dataProcessorRegistriesUploadsDocumentRetrieve");
    }
    // verify the required parameter 'registry_id' is set
    if (registry_id === undefined || registry_id === null) {
      throw new Error("Missing the required parameter 'registry_id' when calling dataProcessorRegistriesUploadsDocumentRetrieve");
    }
    let pathParams = {
      'id': id,
      'registry_id': registry_id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _DataProcessorRegistryUpload.default;
    return this.apiClient.callApi('/api/data_processor_registries/{registry_id}/uploads/{id}/document/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} id A unique integer value identifying this DataProcessorRegistryUpload.
   * @param {Number} registry_id 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DataProcessorRegistryUpload}
   */
  dataProcessorRegistriesUploadsDocumentRetrieve(id, registry_id) {
    return this.dataProcessorRegistriesUploadsDocumentRetrieveWithHttpInfo(id, registry_id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} registry_id 
   * @param {Object} opts Optional parameters
   * @param {String} opts.ordering Which field to use when ordering the results.
   * @param {Number} opts.page A page number within the paginated result set.
   * @param {Number} opts.page_size Number of results to return per page.
   * @param {String} opts.search A search term.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PaginatedDataProcessorRegistryUploadList} and HTTP response
   */
  dataProcessorRegistriesUploadsListWithHttpInfo(registry_id, opts) {
    opts = opts || {};
    let postBody = null;
    // verify the required parameter 'registry_id' is set
    if (registry_id === undefined || registry_id === null) {
      throw new Error("Missing the required parameter 'registry_id' when calling dataProcessorRegistriesUploadsList");
    }
    let pathParams = {
      'registry_id': registry_id
    };
    let queryParams = {
      'ordering': opts['ordering'],
      'page': opts['page'],
      'page_size': opts['page_size'],
      'search': opts['search']
    };
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _PaginatedDataProcessorRegistryUploadList.default;
    return this.apiClient.callApi('/api/data_processor_registries/{registry_id}/uploads/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} registry_id 
   * @param {Object} opts Optional parameters
   * @param {String} opts.ordering Which field to use when ordering the results.
   * @param {Number} opts.page A page number within the paginated result set.
   * @param {Number} opts.page_size Number of results to return per page.
   * @param {String} opts.search A search term.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PaginatedDataProcessorRegistryUploadList}
   */
  dataProcessorRegistriesUploadsList(registry_id, opts) {
    return this.dataProcessorRegistriesUploadsListWithHttpInfo(registry_id, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} id A unique integer value identifying this DataProcessorRegistryUpload.
   * @param {Number} registry_id 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DataProcessorRegistryUpload} and HTTP response
   */
  dataProcessorRegistriesUploadsRetrieveWithHttpInfo(id, registry_id) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling dataProcessorRegistriesUploadsRetrieve");
    }
    // verify the required parameter 'registry_id' is set
    if (registry_id === undefined || registry_id === null) {
      throw new Error("Missing the required parameter 'registry_id' when calling dataProcessorRegistriesUploadsRetrieve");
    }
    let pathParams = {
      'id': id,
      'registry_id': registry_id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _DataProcessorRegistryUpload.default;
    return this.apiClient.callApi('/api/data_processor_registries/{registry_id}/uploads/{id}/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} id A unique integer value identifying this DataProcessorRegistryUpload.
   * @param {Number} registry_id 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DataProcessorRegistryUpload}
   */
  dataProcessorRegistriesUploadsRetrieve(id, registry_id) {
    return this.dataProcessorRegistriesUploadsRetrieveWithHttpInfo(id, registry_id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }
}
exports.default = DataProcessorRegistriesApi;