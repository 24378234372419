import React, { useState, useEffect } from 'react';
import { addToCart, getProducts } from '../api';
import Box from "../components/Box";
import HomeHeader from "../components/HomeHeader";
import { history } from '../store';

import { useStyles } from "../styles/Home.style";
import {
  setError,
} from "../actions";
import Product from '../components/Product';
import { useDispatch } from "react-redux";
import ErrorFeedback from "../components/ErrorFeedback";
import { CssBaseline } from "@material-ui/core";
import { useStyles as useStylesLogin } from "../styles/Login.style";
import loginLogo from "../images/loginLogo.png";

function ProductsLogged() {

  const dispatch = useDispatch();

  const classes = useStyles();
  const classesLogin = useStylesLogin();

  const [products, setProducts] = useState([])
  useEffect(() => {
    getProducts()
      .then((data) => setProducts(data))
      .catch((err) => dispatch(setError(err)));
  }, [])
  const goToCheckout = (product) => {
    history.push("/login")
  }
  return (
    <div className={classesLogin.container}>
    <CssBaseline />
    <ErrorFeedback />

    <img className={classesLogin.logo} alt="Logo" src={loginLogo} />
    <div className={classes.container}>
      {/* <HomeHeader value="subscriptions" /> */}

      <div className={classes.inner}>
        {products.map((pr) => <Product key={pr.price_id} product={pr} onClick={() => goToCheckout(pr)} />)}
        {/* <Box value="Data Controller Registries" onClick={() => history.push("/dcr")} />
        <Box value="Data Processor Registries" onClick={() => history.push("/dpr")} />
        <Box value="Data Protection Officers" onClick={() => history.push("/dpo")} /> */}
      </div>
    </div>
    </div>
  );
}

export default ProductsLogged;
