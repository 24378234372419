import React from 'react';
import { useTranslation } from "react-i18next";

import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
// import { useStyles } from "../styles/DpoHeaderTab.style";


function ArchiveHeaderTab() {
    //   const classes = useStyles(); 
    const { t } = useTranslation();

    return (
        <TableHead>
            <TableRow>
                <TableCell>{t("ID")}</TableCell>
                <TableCell align="right">{t("DOCUMENT")}</TableCell>
                {/* <TableCell align="right">{t("REGISTRY")}</TableCell> */}
                <TableCell align="right">{t("OWNER")}</TableCell>
            </TableRow>
        </TableHead>
    )
}

export default ArchiveHeaderTab;
