import React, { useState, useEffect } from "react";
import Navbar from "./header/Navbar";
import Sidebar from "./sidebar/Sidebar";
import BottomNavbar from "./header/BottomNavbar";
import ErrorFeedback from "./ErrorFeedback";
import { connect } from "react-redux";
import { getUser } from "../api";
import { history } from "../store";
import { useDispatch } from "react-redux";
import { setAuth, resetAuth, setError, setSidebarBehaviour } from "../actions";

import { useStyles } from "../styles/Layout.style";

function Layout({ children, isLogged, token, sidebarOpen, language, user }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(sidebarOpen);
  // const [counter, setCounter] = useState(Date.now());

  const left = !!open ? "250px" : "0px";

  const handleDrawer = () => setOpen(!open);

  const setSidebarOpen = (open) => {
    setOpen(open);
    dispatch(setSidebarBehaviour(open));
  };
  // React.useEffect(() => { console.log(counter); setCounter(Date.now()) }, [children])
  useEffect(() => {
    if (token !== "") {
      getUser()
        .then((data) => dispatch(setAuth(data)))
        .catch((err) => {
          dispatch(setError(err));
          dispatch(resetAuth());

          history.push("/home");
        });
    }
  }, [token, dispatch]);

  return (
    <div className={classes.root} >
      <Sidebar open={open} user={user} />
      <BottomNavbar
        isLogged={isLogged}
        setOpen={setSidebarOpen}
        handleDrawer={() => handleDrawer()}
        open={open}
        language={language}
      />
      <Navbar isLogged={isLogged} setOpen={setSidebarOpen} />
      <ErrorFeedback />
      <div
        style={{ left: left, width: `calc(100% - ${left})` }}
        className={classes.mainContainer}
      >
        {children}
      </div>
      ;
    </div>
  );
}

const mapStateProps = (state) => ({
  token: state.authState.token,
  isLogged: state.authState.isAuthenticated,
  sidebarOpen: state.authState.sidebarOpen,
  language: state.authState.language,
  user: state.authState.user
});

export default connect(mapStateProps, {})(Layout);
