export const setDcrRegistryList = (payload) => {
    return {
        type: 'SET_DCR_REGISTRY_LIST',
        payload: payload,
    }
}

export const resetDcrRegistryList = () => {
    return {
        type: 'RESET_DCR_REGISTRY_LIST',
    }
}

export const failUploadDcrRegistryList = (err) => {
    return {
        type: 'FAIL_DCR_REGISTRY_LIST',
        payload: {
            err: err,
        },
    }
}

export const setDcrRegistry = (payload) => {
    return {
        type: 'SET_DCR_REGISTRY',
        payload: payload,
    }
}

export const resetDcrRegistry = () => {
    return {
        type: 'RESET_DCR_REGISTRY',
    }
}

export const failUploadDcrRegistry = (err) => {
    return {
        type: 'FAIL_DCR_REGISTRY',
        payload: {
            err: err,
        },
    }
}
export const setDcrRegistryUploads = (payload) => {
    return {
        type: 'SET_DCR_REGISTRY_UPLOADS',
        payload: payload,
    }
}

export const resetDcrRegistryUploads = () => {
    return {
        type: 'RESET_DCR_REGISTRY_UPLOADS',
    }
}

export const failUploadDcrRegistryUploads = (err) => {
    return {
        type: 'FAIL_DCR_REGISTRY_UPLOADS',
        payload: {
            err: err,
        },
    }
}
export const setDcrRegistryUploadedReport = (payload) => {
    return {
        type: 'SET_DCR_REGISTRY_UPLOADED_REPORT',
        payload: payload,
    }
}

export const resetDcrRegistryUploadedReport = () => {
    return {
        type: 'RESET_DCR_REGISTRY_UPLOADED_REPORT',
    }
}

export const failUploadDcrRegistryUploadedReport = (err) => {
    return {
        type: 'FAIL_DCR_REGISTRY_UPLOADED_REPORT',
        payload: {
            err: err,
        },
    }
}