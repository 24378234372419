import jsPDF from "jspdf";
import "jspdf-autotable";
import {
  booleans,
  manyToMany,
  oneToMany,
  othersToAdd,
  riskLevel,
  sysAdmin,
} from "../api";
import logo from "../images/logo.png";
import { getCountryValue } from "../utils";
import { serializer } from "../utils";

const GeneratePDF = (value, list, t, state, upload = false, type = "") => {
  // console.log("VALUE->", value,type)
  // STATE OPTIONS
  const dcrSelectedRegistry = state.dcrRegistryState.dcrSelectedRegistry;
  const dprSelectedRegistry = state.dprRegistryState.dprSelectedRegistry;
  const selectedRegistryOld = state.registryState.selectedRegistry;
  const selectedRegistry = type === "dcr" ? dcrSelectedRegistry : type === "dpr" ? dprSelectedRegistry : selectedRegistryOld

  const selectedDpo = state.dpoState.selectedDpo;
  const selectedDataController =
    state.dataControllerState.selectedDataController;
  const selectedDataProcessor = state.dataProcessorState.selectedDataProcessor;
  //const selectedTreatment = state.treatmentState.selectedTreatment;

  //const registryList = state.registryState.registryList;
  //const dcrRegistryList = state.dcrRegistryState.dcrRegistryList;
  //const dprRegistryList = state.dprRegistryState.dprRegistryList;
  //const dpoList = state.dpoState.dpoList;
  const dataControllerList = state.dataControllerState.dataControllerList;
  const dataProcessorList = state.dataProcessorState.dataProcessorList;
  //const treatmentList = state.treatmentState.treatmentList;

  const processingOptions = state.processingState;
  const language = state.authState.language;

  // DOC OPTIONS
  const doc = new jsPDF();
  const sizeLogo = 20;
  const width = doc.internal.pageSize.getWidth();
  const height = doc.internal.pageSize.getHeight();
  const xText = width / 2;
  const yText = 15;
  const xLogo = 14;
  const yLogo = yText / 2;
  const mainColor = [0, 122, 133];
  const grayColor = [0, 0, 0];
  const lightColor = [255, 255, 255];
  const marginTop = sizeLogo + 30;

  const keywords = {
    data_controllers: ["name", "email", "city", "country"],

    data_processors: ["name", "email", "city", "country"],

    data_protection_officers: [
      "name",
      "family_name",
      "company_name",
      "email",
      "phone_number",
    ],

    dcr_processing_registries: [
      "name",
      "data_controller_name",
      // "data_protection_officer",
      "processing_count",
      "data_protection_officer_obj.name",
      "data_protection_officer_obj.family_name",
      "data_protection_officer_obj.company_name",
    ],

    dpr_processing_registries: [
      "name",
      "data_controller_name",
      // "data_protection_officer",
      "processing_count",
      "data_protection_officer_obj.name",
      "data_protection_officer_obj.family_name",
      "data_protection_officer_obj.company_name",
    ],

    processings: [
      "internal_identifier",
      "is_enabled",
      "processing_name",
      "processing_description",
    ],
  };
  const singlePreview = [
    "processings_preview",
    "processing",
    "data_controller",
    "data_processor",
    "data_protection_officer",
  ];
  const tableColumns = () => {
    const columns = [];
    if (list.length > 0) {
      Object.keys(list[0]).map((e) => {
        if (keywords[value].some((k) => k === e))
          columns.push({
            header: getFields(e),
            dataKey: e,
          });
        else if (keywords[value].some((k) => k.split(".")[0] === e)) {
          let keys = keywords[value].filter((k) => k.split(".")[0] === e);
          keys.map((k) => {
            //let datakey = k.split(".");
            columns.push({
              header: `DPO ${getFields(k.split(".")[1])}`,
              dataKey: k,
              displayProperty: k,
            });
          });
        }
        return null;
      });
    }

    return columns;
  };

  const getFields = (str) => {
    // str?.charAt(0).toUpperCase() + str?.slice(1).replace(/_/g, " ")

    return t(str?.replace(/_/g, " ").toUpperCase());
  };
  const getTranslatedContent = (val) => {
    if (val?.name !== undefined) return val.name;
    else {
      if (language === "en" && val?.eng !== undefined) return val.eng;
      else if (language === "it" && val?.ita !== undefined) return val.ita;
    }
    return "";
  };
  const renderArrayValues = (key, values) => {
    let res = [];
    try {
      res = values.map((val) => {
        let obj = null;
        switch (key) {
          case "concurrent_structures":
            obj = Object.values(
              processingOptions?.concurrentStructures?.results
            ).find((itm) => itm.id === val);
            return getTranslatedContent(obj);
          case "processing_purposes":
            obj = Object.values(processingOptions?.purpose?.results).find(
              (itm) => itm.id === val
            );
            return getTranslatedContent(obj);
          case "processing_types":
            obj = Object.values(processingOptions?.basicType?.results).find(
              (itm) => itm.id === val
            );
            return getTranslatedContent(obj);
          case "advanced_processing_types":
            obj = Object.values(processingOptions?.advancedType?.results).find(
              (itm) => itm.id === val
            );
            return getTranslatedContent(obj);
          case "data_subjects":
            obj = Object.values(processingOptions?.dataSubject?.results).find(
              (itm) => itm.id === val
            );
            return getTranslatedContent(obj);
          case "basic_data_types":
            obj = Object.values(processingOptions?.basicDataType?.results).find(
              (itm) => itm.id === val
            );
            return getTranslatedContent(obj);
          case "eu_transfers":
            obj = Object.values(processingOptions?.euTransfer?.results).find(
              (itm) => itm.id === val
            );
            return getTranslatedContent(obj);
          case "storage_supports":
            obj = Object.values(
              processingOptions?.storageSupport?.results
            ).find((itm) => itm.id === val);
            return getTranslatedContent(obj);
          case "storage_locations":
            obj = Object.values(
              processingOptions?.storageLocation?.results
            ).find((itm) => itm.id === val);
            return getTranslatedContent(obj);
          case "data_warehouses":
            obj = Object.values(processingOptions?.dataWarehouse?.results).find(
              (itm) => itm.id === val
            );
            return getTranslatedContent(obj);
          case "technical_measures":
            obj = Object.values(processingOptions?.tecMeasures?.results).find(
              (itm) => itm.id === val
            );
            return getTranslatedContent(obj);
          case "organizational_measures":
            obj = Object.values(processingOptions?.orgMeasures?.results).find(
              (itm) => itm.id === val
            );
            return getTranslatedContent(obj);
          case "external_processors":
            obj = Object.values(
              processingOptions?.externalProcessors?.results
            ).find((itm) => itm.id === val);
            return getTranslatedContent(obj);
          case "controllers":
            if (processingOptions?.controllers?.results != undefined) {
              obj = Object.values(
                processingOptions?.controllers?.results
              ).find((itm) => itm.id === val);
              return getTranslatedContent(obj);
            }
          case "special_data_categories":
            obj = Object.values(
              processingOptions?.advancedDataType?.results
            ).find((itm) => itm.id === val);
            return getTranslatedContent(obj);
          default:
            break;
        }
      });
    } catch (e) {
      console.log("ERROR in RENDER MULTIPLE", key, e);
    }
    return res.join(", ");
  };
  const renderSingleValue = (key, value) => {
    let obj = null;
    let res = "";
    try {
      switch (key) {
        case "country":
          res = getCountryValue(value);
          break;
        case "warranty":
          obj = Object.values(processingOptions.warranty?.results).find(
            (itm) => itm.id === value
          );
          res = getTranslatedContent(obj);
          break;
        case "data_controller":
          obj = Object.values(dataControllerList?.results).find(
            (itm) => itm.id === value
          );
          res = getTranslatedContent(obj);
          break;
        case "data_processor":
          obj = Object.values(dataProcessorList?.results).find(
            (itm) => itm.id === value
          );
          res = getTranslatedContent(obj);
          break;
        case "art6_gdpr_base":
          obj = Object.values(processingOptions.artSix?.results).find(
            (itm) => itm.id === value
          );
          res = getTranslatedContent(obj);
          break;
        case "art9_gdpr_base":
          obj = Object.values(processingOptions.artNine?.results).find(
            (itm) => itm.id === value
          );
          res = getTranslatedContent(obj);
          break;
        case "internal_instructions":
          obj = Object.values(
            processingOptions.internalInstruction?.results
          ).find((itm) => itm.id === value);
          res = getTranslatedContent(obj);
          break;
        case "system_administrator":
          res = t(sysAdmin[value]);
          break;
        case "risk_level":
          res = t(riskLevel[value]);
          break;
        case "data_protection_officers":
          obj = Object.values(
            processingOptions.dataProtectionOfficers?.results
          ).find((itm) => itm.id === value);
          res = getTranslatedContent(obj);
          break;
        case "internal_reference_structure":
          obj = Object.values(
            processingOptions.internalStructures?.results
          ).find((itm) => itm.id === value);
          res = getTranslatedContent(obj);
          break;
        default:
          break;
      }
    } catch (e) {
      console.log("ERROR in RENDER SINGLE", key, e);
    }
    return res;
  };
  const getVal = (k, v) => {
    if (!!v) {
      if (manyToMany.some((e) => e === k)) {
        return renderArrayValues(k, v);
      } else if (oneToMany.some((e) => e === k)) {
        return renderSingleValue(k, v);
      } else if (othersToAdd.some((e) => e === k)) {
        return v;
      } else if (booleans.some((e) => e === k)) {
        if (v === true) return t("YES");
        else return t("NO");
      } else return v;
    } else return t("---");
  };
  const getFullNameDpo = (obj) => {
    let res = "";
    if (!!obj && obj !== undefined) {
      if (!!obj.name) res += ` ${obj.name}`;
      if (!!obj.family_name) res += ` ${obj.family_name}`;
      if (!!obj.company_name) {
        if (res !== "") res += ",";
        res += ` ${obj.company_name}`;
      }
    }
    return res;
  };
  let raw = undefined
  const renderSinglePreview = (value, list) => {
    list.map((obj) => {
      var tcol = [t("Details"), t("Values")];
      var trows = [];
      switch (value) {
        case "processings_preview":
          doc.setTextColor(...mainColor);
          switch (type) {
            case "dcr":
              doc.text(
                `${t("Data Controller Registry")}: ${selectedRegistry.name}`,
                xText,
                yText + 20,
                {
                  align: "center",
                }
              );
              doc.text(
                `${t("Data Controller")}: ${selectedRegistry.data_controller_name}`,
                xText,
                yText + 25,
                {
                  align: "center",
                }
              );
              break;
            case "dpr":
              doc.text(
                `${t("Data Processor Registry")}: ${selectedRegistry.name}`,
                xText,
                yText + 20,
                {
                  align: "center",
                }
              );
              doc.text(
                `${t("Data Processor")}: ${selectedRegistry.data_processor_name}`,
                xText,
                yText + 25,
                {
                  align: "center",
                }
              );
              break;
            default:
              break;
          }

          doc.text(
            `${t("Data Protection Officer")}: ${getFullNameDpo(
              selectedRegistry?.data_protection_officer_obj
            )} 
          `,
            xText,
            yText + 35,
            {
              align: "center",
            }
          );
          doc.text(
            `${t("Processing")}: ${obj.internal_identifier}`,
            xText,
            yText + 40,
            {
              align: "center",
            }
          );
          break;
        case "processing":

          doc.setTextColor(...mainColor);
          switch (type) {
            case "dcr":
              doc.text(
                `${t("Data Controller Registry")}: ${selectedRegistry.name}`,
                xText,
                yText + 20,
                {
                  align: "center",
                }
              );
              doc.text(
                `${t("Data Controller")}: ${selectedRegistry.data_controller_name}`,
                xText,
                yText + 25,
                {
                  align: "center",
                }
              );
              break;
            case "dpr":
              doc.text(
                `${t("Data Processor Registry")}: ${selectedRegistry.name}`,
                xText,
                yText + 20,
                {
                  align: "center",
                }
              );
              doc.text(
                `${t("Data Processor")}: ${selectedRegistry.data_processor_name}`,
                xText,
                yText + 25,
                {
                  align: "center",
                }
              );
              break;
            default:
              break;
          }

         
          doc.text(
            `${t("Data Protection Officer")}: ${getFullNameDpo(
              selectedRegistry?.data_protection_officer_obj
            )} 
            `,
            xText,
            yText + 30,
            {
              align: "center",
            }
          );
          doc.text(
            `${t("Processing")}: ${obj.internal_identifier}`,
            xText,
            yText + 35,
            {
              align: "center",
            }
          );
          break;
        case "data_controller":
          doc.setTextColor(...mainColor);
          doc.text(
            `${t("Data Controller")}: ${selectedDataController.name}`,
            xText,
            yText + 20,
            {
              align: "center",
            }
          );
          break;
        case "data_processor":
          doc.setTextColor(...mainColor);
          doc.text(
            `${t("Data Processor")}: ${selectedDataProcessor.name}`,
            xText,
            yText + 20,
            {
              align: "center",
            }
          );
          break;
        case "data_protection_officer":
          doc.setTextColor(...mainColor);

          doc.text(
            `${t("Data Protection Officer")}: ${getFullNameDpo(selectedDpo)} 
              `,
            xText,
            yText + 20,
            {
              align: "center",
            }
          );
          break;
        default:
          break;
      }
      doc.setTextColor(...grayColor);
      let serializedObj = serializer(value, obj);
      for (var key in serializedObj) {
        if (key !== "id" && key !== "extra_eu_transfers_list") {
          var temp = [getFields(key), getVal(key, serializedObj[key])];
          trows.push(temp);
        }
      }

      doc.autoTable({
        theme: "grid", // "striped"
        headStyles: { fillColor: mainColor },
        bodyStyles: { fillColor: lightColor },
        footStyles: { fillColor: mainColor },
        // columnStyles: { name: { halign: "center", fillColor: grayColor } }, // Cells in first column centered and green
        // margin: { top: marginTop },
        startY: marginTop + 10,
        body: trows,
        columns: tcol,
        foot: [tcol],
      });
      if (["processing", "processings_preview"].some((k) => k === value)) {
        var extraCol = [t("Country"), t("Warranty")];

        if (
          !!serializedObj.extra_eu_transfers_list &&
          serializedObj.extra_eu_transfers_list !== undefined &&
          serializedObj.extra_eu_transfers_list.length > 0
        ) {
          var extraRows = serializedObj.extra_eu_transfers_list.map((e) => [
            e.country,
            renderSingleValue("warranty", e.warranty),
          ]);
          doc.addPage("a4", "p");
          doc.text(t("Extra EU List"), xText, marginTop + 10, {
            align: "center",
          });
          doc.autoTable({
            theme: "grid", // "striped"
            headStyles: { fillColor: mainColor },
            bodyStyles: { fillColor: lightColor },
            footStyles: { fillColor: mainColor },
            // columnStyles: { name: { halign: "center", fillColor: grayColor } }, // Cells in first column centered and green
            // margin: { top: marginTop },
            startY: marginTop + 20,
            body: extraRows,
            columns: extraCol,
            foot: [extraCol],
          });
        }
      }
      // doc.addPage("a4", "p");
    });
    if (upload === false)
      doc.save(`report_${value}.pdf`);
    raw = doc.output("blob")
    // doc.save(`report_${value}.pdf`);
  };

  function main() {
    doc.setFont("Roboto", "sans-serif");
    doc.setTextColor(...mainColor);
    doc.text("REPORT", xText, yText, {
      align: "center",
    });
    doc.setTextColor(...grayColor);
    doc.text(`${t(value.replace(/_/g, " ").toUpperCase())}`, xText, yText + 5, {
      align: "center",
    });
    var image = new Image();
    image.src = logo;
    doc.addImage(image, "PNG", xLogo, yLogo, sizeLogo, sizeLogo,undefined,'FAST'); //base64 image, format, x-coordinate, y-coordinate, width, height
    let finalY = 0;
    if (singlePreview.some((k) => k === value)) {
      renderSinglePreview(value, list);
    } else {
      doc.autoTable({
        theme: "grid", // "striped"
        headStyles: { fillColor: mainColor },
        bodyStyles: { fillColor: lightColor },
        footStyles: { fillColor: mainColor },
        // columnStyles: { name: { halign: "center", fillColor: grayColor } }, // Cells in first column centered and green
        // margin: { top: marginTop },
        startY: marginTop,
        body: list,
        columns: tableColumns(),
        foot: [tableColumns().map((e) => e.header)],
        didParseCell: function (data) {
          if (data.column.dataKey.indexOf(".") > 0 && data.section === "body") {
            // var prop = data.column.raw.displayProperty;
            let prop = data.column.raw.displayProperty.split(".");
            try {
              data.cell.text = data.row.raw[prop[0]][prop[1]];
            } catch (e) {
              console.log(e);
            }
            // data.cell.text = data.cell.raw[prop[0][prop[1]]];
          }
        },
      });
      finalY = doc.lastAutoTable.finalY;
      let d = new Date();
      d.toUTCString();
      doc.text(
        `Generated at ${d.toUTCString()}`,
        xText,
        Math.max(finalY, height - 20),
        {
          align: "right",
          // lineHeightFactor: 0.8,
          fontSize: "small"
        }
      );
      if (upload === false)
        doc.save(`report_${value}.pdf`);
      raw = doc.output("blob")
    }
  }
  main();
  return raw
};

export default GeneratePDF;
