"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * NXPrivacy API
 * REST API for NXPrivacy
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The CustomerPortal model module.
 * @module model/CustomerPortal
 * @version 1.0.0
 */
class CustomerPortal {
  /**
   * Constructs a new <code>CustomerPortal</code>.
   * @alias module:model/CustomerPortal
   * @param url {String} 
   */
  constructor(url) {
    CustomerPortal.initialize(this, url);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, url) {
    obj['url'] = url;
  }

  /**
   * Constructs a <code>CustomerPortal</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/CustomerPortal} obj Optional instance to populate.
   * @return {module:model/CustomerPortal} The populated <code>CustomerPortal</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CustomerPortal();
      if (data.hasOwnProperty('url')) {
        obj['url'] = _ApiClient.default.convertToType(data['url'], 'String');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>CustomerPortal</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>CustomerPortal</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of CustomerPortal.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['url'] && !(typeof data['url'] === 'string' || data['url'] instanceof String)) {
      throw new Error("Expected the field `url` to be a primitive type in the JSON string but got " + data['url']);
    }
    return true;
  }
}
CustomerPortal.RequiredProperties = ["url"];

/**
 * @member {String} url
 */
CustomerPortal.prototype['url'] = undefined;
var _default = exports.default = CustomerPortal;