"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _CheckoutRequest = _interopRequireDefault(require("../model/CheckoutRequest"));
var _CheckoutRequestRequest = _interopRequireDefault(require("../model/CheckoutRequestRequest"));
var _CustomerPortal = _interopRequireDefault(require("../model/CustomerPortal"));
var _Price = _interopRequireDefault(require("../model/Price"));
var _StripeEvent = _interopRequireDefault(require("../model/StripeEvent"));
var _StripeEventRequest = _interopRequireDefault(require("../model/StripeEventRequest"));
var _Subscription = _interopRequireDefault(require("../model/Subscription"));
var _SubscriptionItem = _interopRequireDefault(require("../model/SubscriptionItem"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * NXPrivacy API
 * REST API for NXPrivacy
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
* Billing service.
* @module api/BillingApi
* @version 1.0.0
*/
class BillingApi {
  /**
  * Constructs a new BillingApi. 
  * @alias module:api/BillingApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || _ApiClient.default.instance;
  }

  /**
   * Provides session for using Stripe hosted Checkout page.
   * @param {module:model/CheckoutRequestRequest} checkout_request_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CheckoutRequest} and HTTP response
   */
  billingCheckoutCreateWithHttpInfo(checkout_request_request) {
    let postBody = checkout_request_request;
    // verify the required parameter 'checkout_request_request' is set
    if (checkout_request_request === undefined || checkout_request_request === null) {
      throw new Error("Missing the required parameter 'checkout_request_request' when calling billingCheckoutCreate");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _CheckoutRequest.default;
    return this.apiClient.callApi('/api/billing/checkout/', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Provides session for using Stripe hosted Checkout page.
   * @param {module:model/CheckoutRequestRequest} checkout_request_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CheckoutRequest}
   */
  billingCheckoutCreate(checkout_request_request) {
    return this.billingCheckoutCreateWithHttpInfo(checkout_request_request).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Provides redirect URL for Stripe customer portal.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CustomerPortal} and HTTP response
   */
  billingCustomerPortalCreateWithHttpInfo() {
    let postBody = null;
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _CustomerPortal.default;
    return this.apiClient.callApi('/api/billing/customer-portal/', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Provides redirect URL for Stripe customer portal.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CustomerPortal}
   */
  billingCustomerPortalCreate() {
    return this.billingCustomerPortalCreateWithHttpInfo().then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Products that can be subscribed. Depending on whether this request is made with a bearer token, Anonymous user will receive a list of product and prices available to the public. Authenticated user will receive a list of products and prices available to the user, excluding any product prices the user has already been subscribed to.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Price>} and HTTP response
   */
  billingProductsListWithHttpInfo() {
    let postBody = null;
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [_Price.default];
    return this.apiClient.callApi('/api/billing/products/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Products that can be subscribed. Depending on whether this request is made with a bearer token, Anonymous user will receive a list of product and prices available to the public. Authenticated user will receive a list of products and prices available to the user, excluding any product prices the user has already been subscribed to.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Price>}
   */
  billingProductsList() {
    return this.billingProductsListWithHttpInfo().then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * SubscriptionItems of current user
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/SubscriptionItem>} and HTTP response
   */
  billingSubscriptionItemsListWithHttpInfo() {
    let postBody = null;
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [_SubscriptionItem.default];
    return this.apiClient.callApi('/api/billing/subscription-items/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * SubscriptionItems of current user
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/SubscriptionItem>}
   */
  billingSubscriptionItemsList() {
    return this.billingSubscriptionItemsListWithHttpInfo().then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Subscription of current user
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Subscription>} and HTTP response
   */
  billingSubscriptionListWithHttpInfo() {
    let postBody = null;
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [_Subscription.default];
    return this.apiClient.callApi('/api/billing/subscription/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Subscription of current user
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Subscription>}
   */
  billingSubscriptionList() {
    return this.billingSubscriptionListWithHttpInfo().then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Provides endpoint for Stripe webhooks
   * @param {module:model/StripeEventRequest} stripe_event_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/StripeEvent} and HTTP response
   */
  billingWebhookCreateWithHttpInfo(stripe_event_request) {
    let postBody = stripe_event_request;
    // verify the required parameter 'stripe_event_request' is set
    if (stripe_event_request === undefined || stripe_event_request === null) {
      throw new Error("Missing the required parameter 'stripe_event_request' when calling billingWebhookCreate");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _StripeEvent.default;
    return this.apiClient.callApi('/api/billing/webhook/', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Provides endpoint for Stripe webhooks
   * @param {module:model/StripeEventRequest} stripe_event_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/StripeEvent}
   */
  billingWebhookCreate(stripe_event_request) {
    return this.billingWebhookCreateWithHttpInfo(stripe_event_request).then(function (response_and_data) {
      return response_and_data.data;
    });
  }
}
exports.default = BillingApi;