"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _RiskLevelEnum = _interopRequireDefault(require("./RiskLevelEnum"));
var _SystemAdministratorEnum = _interopRequireDefault(require("./SystemAdministratorEnum"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * NXPrivacy API
 * REST API for NXPrivacy
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The PatchedDataControllerProcessingActivityRequest model module.
 * @module model/PatchedDataControllerProcessingActivityRequest
 * @version 1.0.0
 */
class PatchedDataControllerProcessingActivityRequest {
  /**
   * Constructs a new <code>PatchedDataControllerProcessingActivityRequest</code>.
   * @alias module:model/PatchedDataControllerProcessingActivityRequest
   */
  constructor() {
    PatchedDataControllerProcessingActivityRequest.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>PatchedDataControllerProcessingActivityRequest</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/PatchedDataControllerProcessingActivityRequest} obj Optional instance to populate.
   * @return {module:model/PatchedDataControllerProcessingActivityRequest} The populated <code>PatchedDataControllerProcessingActivityRequest</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new PatchedDataControllerProcessingActivityRequest();
      if (data.hasOwnProperty('internal_identifier')) {
        obj['internal_identifier'] = _ApiClient.default.convertToType(data['internal_identifier'], 'String');
      }
      if (data.hasOwnProperty('is_enabled')) {
        obj['is_enabled'] = _ApiClient.default.convertToType(data['is_enabled'], 'Boolean');
      }
      if (data.hasOwnProperty('info_supplied_by')) {
        obj['info_supplied_by'] = _ApiClient.default.convertToType(data['info_supplied_by'], 'String');
      }
      if (data.hasOwnProperty('processing_name')) {
        obj['processing_name'] = _ApiClient.default.convertToType(data['processing_name'], 'String');
      }
      if (data.hasOwnProperty('processing_description')) {
        obj['processing_description'] = _ApiClient.default.convertToType(data['processing_description'], 'String');
      }
      if (data.hasOwnProperty('processing_note')) {
        obj['processing_note'] = _ApiClient.default.convertToType(data['processing_note'], 'String');
      }
      if (data.hasOwnProperty('other_processing_purposes')) {
        obj['other_processing_purposes'] = _ApiClient.default.convertToType(data['other_processing_purposes'], 'String');
      }
      if (data.hasOwnProperty('other_gdpr_base')) {
        obj['other_gdpr_base'] = _ApiClient.default.convertToType(data['other_gdpr_base'], 'String');
      }
      if (data.hasOwnProperty('other_processing_types')) {
        obj['other_processing_types'] = _ApiClient.default.convertToType(data['other_processing_types'], 'String');
      }
      if (data.hasOwnProperty('other_data_subjects')) {
        obj['other_data_subjects'] = _ApiClient.default.convertToType(data['other_data_subjects'], 'String');
      }
      if (data.hasOwnProperty('other_data_types')) {
        obj['other_data_types'] = _ApiClient.default.convertToType(data['other_data_types'], 'String');
      }
      if (data.hasOwnProperty('other_eu_transfers')) {
        obj['other_eu_transfers'] = _ApiClient.default.convertToType(data['other_eu_transfers'], 'String');
      }
      if (data.hasOwnProperty('extra_eu_transfers')) {
        obj['extra_eu_transfers'] = _ApiClient.default.convertToType(data['extra_eu_transfers'], 'Boolean');
      }
      if (data.hasOwnProperty('other_storage_supports')) {
        obj['other_storage_supports'] = _ApiClient.default.convertToType(data['other_storage_supports'], 'String');
      }
      if (data.hasOwnProperty('instrument_description')) {
        obj['instrument_description'] = _ApiClient.default.convertToType(data['instrument_description'], 'String');
      }
      if (data.hasOwnProperty('interconnection_type')) {
        obj['interconnection_type'] = _ApiClient.default.convertToType(data['interconnection_type'], 'String');
      }
      if (data.hasOwnProperty('storage_period')) {
        obj['storage_period'] = _ApiClient.default.convertToType(data['storage_period'], 'String');
      }
      if (data.hasOwnProperty('storage_locations_description')) {
        obj['storage_locations_description'] = _ApiClient.default.convertToType(data['storage_locations_description'], 'String');
      }
      if (data.hasOwnProperty('processing_software')) {
        obj['processing_software'] = _ApiClient.default.convertToType(data['processing_software'], 'String');
      }
      if (data.hasOwnProperty('internal_person_in_charge')) {
        obj['internal_person_in_charge'] = _ApiClient.default.convertToType(data['internal_person_in_charge'], 'String');
      }
      if (data.hasOwnProperty('other_technical_measures')) {
        obj['other_technical_measures'] = _ApiClient.default.convertToType(data['other_technical_measures'], 'String');
      }
      if (data.hasOwnProperty('other_organizational_measures')) {
        obj['other_organizational_measures'] = _ApiClient.default.convertToType(data['other_organizational_measures'], 'String');
      }
      if (data.hasOwnProperty('system_administrator')) {
        obj['system_administrator'] = _SystemAdministratorEnum.default.constructFromObject(data['system_administrator']);
      }
      if (data.hasOwnProperty('internal_instructions')) {
        obj['internal_instructions'] = _ApiClient.default.convertToType(data['internal_instructions'], 'Boolean');
      }
      if (data.hasOwnProperty('risk_level')) {
        obj['risk_level'] = _RiskLevelEnum.default.constructFromObject(data['risk_level']);
      }
      if (data.hasOwnProperty('pia_needed')) {
        obj['pia_needed'] = _ApiClient.default.convertToType(data['pia_needed'], 'Boolean');
      }
      if (data.hasOwnProperty('art6_gdpr_base')) {
        obj['art6_gdpr_base'] = _ApiClient.default.convertToType(data['art6_gdpr_base'], 'Number');
      }
      if (data.hasOwnProperty('art9_gdpr_base')) {
        obj['art9_gdpr_base'] = _ApiClient.default.convertToType(data['art9_gdpr_base'], 'Number');
      }
      if (data.hasOwnProperty('internal_reference_structure')) {
        obj['internal_reference_structure'] = _ApiClient.default.convertToType(data['internal_reference_structure'], 'Number');
      }
      if (data.hasOwnProperty('processing_purposes')) {
        obj['processing_purposes'] = _ApiClient.default.convertToType(data['processing_purposes'], ['Number']);
      }
      if (data.hasOwnProperty('processing_types')) {
        obj['processing_types'] = _ApiClient.default.convertToType(data['processing_types'], ['Number']);
      }
      if (data.hasOwnProperty('advanced_processing_types')) {
        obj['advanced_processing_types'] = _ApiClient.default.convertToType(data['advanced_processing_types'], ['Number']);
      }
      if (data.hasOwnProperty('data_subjects')) {
        obj['data_subjects'] = _ApiClient.default.convertToType(data['data_subjects'], ['Number']);
      }
      if (data.hasOwnProperty('basic_data_types')) {
        obj['basic_data_types'] = _ApiClient.default.convertToType(data['basic_data_types'], ['Number']);
      }
      if (data.hasOwnProperty('special_data_categories')) {
        obj['special_data_categories'] = _ApiClient.default.convertToType(data['special_data_categories'], ['Number']);
      }
      if (data.hasOwnProperty('eu_transfers')) {
        obj['eu_transfers'] = _ApiClient.default.convertToType(data['eu_transfers'], ['Number']);
      }
      if (data.hasOwnProperty('storage_supports')) {
        obj['storage_supports'] = _ApiClient.default.convertToType(data['storage_supports'], ['Number']);
      }
      if (data.hasOwnProperty('storage_locations')) {
        obj['storage_locations'] = _ApiClient.default.convertToType(data['storage_locations'], ['Number']);
      }
      if (data.hasOwnProperty('data_warehouses')) {
        obj['data_warehouses'] = _ApiClient.default.convertToType(data['data_warehouses'], ['Number']);
      }
      if (data.hasOwnProperty('technical_measures')) {
        obj['technical_measures'] = _ApiClient.default.convertToType(data['technical_measures'], ['Number']);
      }
      if (data.hasOwnProperty('organizational_measures')) {
        obj['organizational_measures'] = _ApiClient.default.convertToType(data['organizational_measures'], ['Number']);
      }
      if (data.hasOwnProperty('external_processors')) {
        obj['external_processors'] = _ApiClient.default.convertToType(data['external_processors'], ['Number']);
      }
      if (data.hasOwnProperty('concurrent_structures')) {
        obj['concurrent_structures'] = _ApiClient.default.convertToType(data['concurrent_structures'], ['Number']);
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>PatchedDataControllerProcessingActivityRequest</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>PatchedDataControllerProcessingActivityRequest</code>.
   */
  static validateJSON(data) {
    // ensure the json data is a string
    if (data['internal_identifier'] && !(typeof data['internal_identifier'] === 'string' || data['internal_identifier'] instanceof String)) {
      throw new Error("Expected the field `internal_identifier` to be a primitive type in the JSON string but got " + data['internal_identifier']);
    }
    // ensure the json data is a string
    if (data['info_supplied_by'] && !(typeof data['info_supplied_by'] === 'string' || data['info_supplied_by'] instanceof String)) {
      throw new Error("Expected the field `info_supplied_by` to be a primitive type in the JSON string but got " + data['info_supplied_by']);
    }
    // ensure the json data is a string
    if (data['processing_name'] && !(typeof data['processing_name'] === 'string' || data['processing_name'] instanceof String)) {
      throw new Error("Expected the field `processing_name` to be a primitive type in the JSON string but got " + data['processing_name']);
    }
    // ensure the json data is a string
    if (data['processing_description'] && !(typeof data['processing_description'] === 'string' || data['processing_description'] instanceof String)) {
      throw new Error("Expected the field `processing_description` to be a primitive type in the JSON string but got " + data['processing_description']);
    }
    // ensure the json data is a string
    if (data['processing_note'] && !(typeof data['processing_note'] === 'string' || data['processing_note'] instanceof String)) {
      throw new Error("Expected the field `processing_note` to be a primitive type in the JSON string but got " + data['processing_note']);
    }
    // ensure the json data is a string
    if (data['other_processing_purposes'] && !(typeof data['other_processing_purposes'] === 'string' || data['other_processing_purposes'] instanceof String)) {
      throw new Error("Expected the field `other_processing_purposes` to be a primitive type in the JSON string but got " + data['other_processing_purposes']);
    }
    // ensure the json data is a string
    if (data['other_gdpr_base'] && !(typeof data['other_gdpr_base'] === 'string' || data['other_gdpr_base'] instanceof String)) {
      throw new Error("Expected the field `other_gdpr_base` to be a primitive type in the JSON string but got " + data['other_gdpr_base']);
    }
    // ensure the json data is a string
    if (data['other_processing_types'] && !(typeof data['other_processing_types'] === 'string' || data['other_processing_types'] instanceof String)) {
      throw new Error("Expected the field `other_processing_types` to be a primitive type in the JSON string but got " + data['other_processing_types']);
    }
    // ensure the json data is a string
    if (data['other_data_subjects'] && !(typeof data['other_data_subjects'] === 'string' || data['other_data_subjects'] instanceof String)) {
      throw new Error("Expected the field `other_data_subjects` to be a primitive type in the JSON string but got " + data['other_data_subjects']);
    }
    // ensure the json data is a string
    if (data['other_data_types'] && !(typeof data['other_data_types'] === 'string' || data['other_data_types'] instanceof String)) {
      throw new Error("Expected the field `other_data_types` to be a primitive type in the JSON string but got " + data['other_data_types']);
    }
    // ensure the json data is a string
    if (data['other_eu_transfers'] && !(typeof data['other_eu_transfers'] === 'string' || data['other_eu_transfers'] instanceof String)) {
      throw new Error("Expected the field `other_eu_transfers` to be a primitive type in the JSON string but got " + data['other_eu_transfers']);
    }
    // ensure the json data is a string
    if (data['other_storage_supports'] && !(typeof data['other_storage_supports'] === 'string' || data['other_storage_supports'] instanceof String)) {
      throw new Error("Expected the field `other_storage_supports` to be a primitive type in the JSON string but got " + data['other_storage_supports']);
    }
    // ensure the json data is a string
    if (data['instrument_description'] && !(typeof data['instrument_description'] === 'string' || data['instrument_description'] instanceof String)) {
      throw new Error("Expected the field `instrument_description` to be a primitive type in the JSON string but got " + data['instrument_description']);
    }
    // ensure the json data is a string
    if (data['interconnection_type'] && !(typeof data['interconnection_type'] === 'string' || data['interconnection_type'] instanceof String)) {
      throw new Error("Expected the field `interconnection_type` to be a primitive type in the JSON string but got " + data['interconnection_type']);
    }
    // ensure the json data is a string
    if (data['storage_period'] && !(typeof data['storage_period'] === 'string' || data['storage_period'] instanceof String)) {
      throw new Error("Expected the field `storage_period` to be a primitive type in the JSON string but got " + data['storage_period']);
    }
    // ensure the json data is a string
    if (data['storage_locations_description'] && !(typeof data['storage_locations_description'] === 'string' || data['storage_locations_description'] instanceof String)) {
      throw new Error("Expected the field `storage_locations_description` to be a primitive type in the JSON string but got " + data['storage_locations_description']);
    }
    // ensure the json data is a string
    if (data['processing_software'] && !(typeof data['processing_software'] === 'string' || data['processing_software'] instanceof String)) {
      throw new Error("Expected the field `processing_software` to be a primitive type in the JSON string but got " + data['processing_software']);
    }
    // ensure the json data is a string
    if (data['internal_person_in_charge'] && !(typeof data['internal_person_in_charge'] === 'string' || data['internal_person_in_charge'] instanceof String)) {
      throw new Error("Expected the field `internal_person_in_charge` to be a primitive type in the JSON string but got " + data['internal_person_in_charge']);
    }
    // ensure the json data is a string
    if (data['other_technical_measures'] && !(typeof data['other_technical_measures'] === 'string' || data['other_technical_measures'] instanceof String)) {
      throw new Error("Expected the field `other_technical_measures` to be a primitive type in the JSON string but got " + data['other_technical_measures']);
    }
    // ensure the json data is a string
    if (data['other_organizational_measures'] && !(typeof data['other_organizational_measures'] === 'string' || data['other_organizational_measures'] instanceof String)) {
      throw new Error("Expected the field `other_organizational_measures` to be a primitive type in the JSON string but got " + data['other_organizational_measures']);
    }
    // ensure the json data is an array
    if (!Array.isArray(data['processing_purposes'])) {
      throw new Error("Expected the field `processing_purposes` to be an array in the JSON data but got " + data['processing_purposes']);
    }
    // ensure the json data is an array
    if (!Array.isArray(data['processing_types'])) {
      throw new Error("Expected the field `processing_types` to be an array in the JSON data but got " + data['processing_types']);
    }
    // ensure the json data is an array
    if (!Array.isArray(data['advanced_processing_types'])) {
      throw new Error("Expected the field `advanced_processing_types` to be an array in the JSON data but got " + data['advanced_processing_types']);
    }
    // ensure the json data is an array
    if (!Array.isArray(data['data_subjects'])) {
      throw new Error("Expected the field `data_subjects` to be an array in the JSON data but got " + data['data_subjects']);
    }
    // ensure the json data is an array
    if (!Array.isArray(data['basic_data_types'])) {
      throw new Error("Expected the field `basic_data_types` to be an array in the JSON data but got " + data['basic_data_types']);
    }
    // ensure the json data is an array
    if (!Array.isArray(data['special_data_categories'])) {
      throw new Error("Expected the field `special_data_categories` to be an array in the JSON data but got " + data['special_data_categories']);
    }
    // ensure the json data is an array
    if (!Array.isArray(data['eu_transfers'])) {
      throw new Error("Expected the field `eu_transfers` to be an array in the JSON data but got " + data['eu_transfers']);
    }
    // ensure the json data is an array
    if (!Array.isArray(data['storage_supports'])) {
      throw new Error("Expected the field `storage_supports` to be an array in the JSON data but got " + data['storage_supports']);
    }
    // ensure the json data is an array
    if (!Array.isArray(data['storage_locations'])) {
      throw new Error("Expected the field `storage_locations` to be an array in the JSON data but got " + data['storage_locations']);
    }
    // ensure the json data is an array
    if (!Array.isArray(data['data_warehouses'])) {
      throw new Error("Expected the field `data_warehouses` to be an array in the JSON data but got " + data['data_warehouses']);
    }
    // ensure the json data is an array
    if (!Array.isArray(data['technical_measures'])) {
      throw new Error("Expected the field `technical_measures` to be an array in the JSON data but got " + data['technical_measures']);
    }
    // ensure the json data is an array
    if (!Array.isArray(data['organizational_measures'])) {
      throw new Error("Expected the field `organizational_measures` to be an array in the JSON data but got " + data['organizational_measures']);
    }
    // ensure the json data is an array
    if (!Array.isArray(data['external_processors'])) {
      throw new Error("Expected the field `external_processors` to be an array in the JSON data but got " + data['external_processors']);
    }
    // ensure the json data is an array
    if (!Array.isArray(data['concurrent_structures'])) {
      throw new Error("Expected the field `concurrent_structures` to be an array in the JSON data but got " + data['concurrent_structures']);
    }
    return true;
  }
}

/**
 * @member {String} internal_identifier
 */
PatchedDataControllerProcessingActivityRequest.prototype['internal_identifier'] = undefined;

/**
 * @member {Boolean} is_enabled
 */
PatchedDataControllerProcessingActivityRequest.prototype['is_enabled'] = undefined;

/**
 * @member {String} info_supplied_by
 */
PatchedDataControllerProcessingActivityRequest.prototype['info_supplied_by'] = undefined;

/**
 * @member {String} processing_name
 */
PatchedDataControllerProcessingActivityRequest.prototype['processing_name'] = undefined;

/**
 * @member {String} processing_description
 */
PatchedDataControllerProcessingActivityRequest.prototype['processing_description'] = undefined;

/**
 * @member {String} processing_note
 */
PatchedDataControllerProcessingActivityRequest.prototype['processing_note'] = undefined;

/**
 * @member {String} other_processing_purposes
 */
PatchedDataControllerProcessingActivityRequest.prototype['other_processing_purposes'] = undefined;

/**
 * @member {String} other_gdpr_base
 */
PatchedDataControllerProcessingActivityRequest.prototype['other_gdpr_base'] = undefined;

/**
 * @member {String} other_processing_types
 */
PatchedDataControllerProcessingActivityRequest.prototype['other_processing_types'] = undefined;

/**
 * @member {String} other_data_subjects
 */
PatchedDataControllerProcessingActivityRequest.prototype['other_data_subjects'] = undefined;

/**
 * @member {String} other_data_types
 */
PatchedDataControllerProcessingActivityRequest.prototype['other_data_types'] = undefined;

/**
 * @member {String} other_eu_transfers
 */
PatchedDataControllerProcessingActivityRequest.prototype['other_eu_transfers'] = undefined;

/**
 * @member {Boolean} extra_eu_transfers
 */
PatchedDataControllerProcessingActivityRequest.prototype['extra_eu_transfers'] = undefined;

/**
 * @member {String} other_storage_supports
 */
PatchedDataControllerProcessingActivityRequest.prototype['other_storage_supports'] = undefined;

/**
 * @member {String} instrument_description
 */
PatchedDataControllerProcessingActivityRequest.prototype['instrument_description'] = undefined;

/**
 * @member {String} interconnection_type
 */
PatchedDataControllerProcessingActivityRequest.prototype['interconnection_type'] = undefined;

/**
 * @member {String} storage_period
 */
PatchedDataControllerProcessingActivityRequest.prototype['storage_period'] = undefined;

/**
 * @member {String} storage_locations_description
 */
PatchedDataControllerProcessingActivityRequest.prototype['storage_locations_description'] = undefined;

/**
 * @member {String} processing_software
 */
PatchedDataControllerProcessingActivityRequest.prototype['processing_software'] = undefined;

/**
 * @member {String} internal_person_in_charge
 */
PatchedDataControllerProcessingActivityRequest.prototype['internal_person_in_charge'] = undefined;

/**
 * @member {String} other_technical_measures
 */
PatchedDataControllerProcessingActivityRequest.prototype['other_technical_measures'] = undefined;

/**
 * @member {String} other_organizational_measures
 */
PatchedDataControllerProcessingActivityRequest.prototype['other_organizational_measures'] = undefined;

/**
 * @member {module:model/SystemAdministratorEnum} system_administrator
 */
PatchedDataControllerProcessingActivityRequest.prototype['system_administrator'] = undefined;

/**
 * @member {Boolean} internal_instructions
 */
PatchedDataControllerProcessingActivityRequest.prototype['internal_instructions'] = undefined;

/**
 * @member {module:model/RiskLevelEnum} risk_level
 */
PatchedDataControllerProcessingActivityRequest.prototype['risk_level'] = undefined;

/**
 * @member {Boolean} pia_needed
 */
PatchedDataControllerProcessingActivityRequest.prototype['pia_needed'] = undefined;

/**
 * @member {Number} art6_gdpr_base
 */
PatchedDataControllerProcessingActivityRequest.prototype['art6_gdpr_base'] = undefined;

/**
 * @member {Number} art9_gdpr_base
 */
PatchedDataControllerProcessingActivityRequest.prototype['art9_gdpr_base'] = undefined;

/**
 * @member {Number} internal_reference_structure
 */
PatchedDataControllerProcessingActivityRequest.prototype['internal_reference_structure'] = undefined;

/**
 * @member {Array.<Number>} processing_purposes
 */
PatchedDataControllerProcessingActivityRequest.prototype['processing_purposes'] = undefined;

/**
 * @member {Array.<Number>} processing_types
 */
PatchedDataControllerProcessingActivityRequest.prototype['processing_types'] = undefined;

/**
 * @member {Array.<Number>} advanced_processing_types
 */
PatchedDataControllerProcessingActivityRequest.prototype['advanced_processing_types'] = undefined;

/**
 * @member {Array.<Number>} data_subjects
 */
PatchedDataControllerProcessingActivityRequest.prototype['data_subjects'] = undefined;

/**
 * @member {Array.<Number>} basic_data_types
 */
PatchedDataControllerProcessingActivityRequest.prototype['basic_data_types'] = undefined;

/**
 * @member {Array.<Number>} special_data_categories
 */
PatchedDataControllerProcessingActivityRequest.prototype['special_data_categories'] = undefined;

/**
 * @member {Array.<Number>} eu_transfers
 */
PatchedDataControllerProcessingActivityRequest.prototype['eu_transfers'] = undefined;

/**
 * @member {Array.<Number>} storage_supports
 */
PatchedDataControllerProcessingActivityRequest.prototype['storage_supports'] = undefined;

/**
 * @member {Array.<Number>} storage_locations
 */
PatchedDataControllerProcessingActivityRequest.prototype['storage_locations'] = undefined;

/**
 * @member {Array.<Number>} data_warehouses
 */
PatchedDataControllerProcessingActivityRequest.prototype['data_warehouses'] = undefined;

/**
 * @member {Array.<Number>} technical_measures
 */
PatchedDataControllerProcessingActivityRequest.prototype['technical_measures'] = undefined;

/**
 * @member {Array.<Number>} organizational_measures
 */
PatchedDataControllerProcessingActivityRequest.prototype['organizational_measures'] = undefined;

/**
 * @member {Array.<Number>} external_processors
 */
PatchedDataControllerProcessingActivityRequest.prototype['external_processors'] = undefined;

/**
 * @member {Array.<Number>} concurrent_structures
 */
PatchedDataControllerProcessingActivityRequest.prototype['concurrent_structures'] = undefined;
var _default = exports.default = PatchedDataControllerProcessingActivityRequest;