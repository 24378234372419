import React, { useState, useEffect } from 'react';
import { addToCart, getProducts, getUserSubscription, getControlPanel} from '../api';
import Box from "../components/Box";
import HomeHeader from "../components/HomeHeader";
import { history } from '../store';

import { useStyles } from "../styles/Home.style";
import {
  setError,
} from "../actions";
import Product from '../components/Product';
import { useDispatch } from "react-redux";
import Subscription from '../components/Subscription';

function ProductsLogged() {

  const dispatch = useDispatch();

  const classes = useStyles();
  const [products, setProducts] = useState([])
  const [subscriptions, setSubscriptions] = useState([])
  const [customerUrl, setCustomerUrl] = useState("")
  useEffect(() => {
    getProducts()
      .then((data) => setProducts(data))
      .catch((err) => dispatch(setError(err)))
      .finally(() => {
        if (products.length === 0) {
          getUserSubscription()
            .then((data) => setSubscriptions(data))
            .catch((err) => dispatch(setError(err)))
            getControlPanel()
            .then((data) => setCustomerUrl(data.url))
            .catch((err) => dispatch(setError(err)))
        }
      })
  }, [])
  const goToCheckout = (product) => {
    addToCart(product.price_id)
      .then((data) => window.open(data.checkout_url, '_blank', 'noreferrer'))
      .catch((err) => dispatch(setError(err)));
  }
  return (
    <div className={classes.container}>
      <HomeHeader value="subscriptions" />

      {(subscriptions.length > 0 && products.length == 0) && [<p>You are already subscribed to the following programs:</p>, <br />]}
      <div className={classes.inner}>
        {products.length > 0 &&
          products.map((pr) =>
            <Product key={pr.price_id} product={pr} onClick={() => goToCheckout(pr)} />)}
        {(subscriptions.length > 0 && products.length == 0) && subscriptions.map((sb) =>
          <Subscription key={sb.price_id} product={sb} customerUrl={customerUrl} />)


        }

        {/* <Box value="Data Controller Registries" onClick={() => history.push("/dcr")} />
        <Box value="Data Processor Registries" onClick={() => history.push("/dpr")} />
        <Box value="Data Protection Officers" onClick={() => history.push("/dpo")} /> */}
      </div>
    </div>
  );
}

export default ProductsLogged;
