
import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Visibility from "@material-ui/icons/Visibility";
import IconButton from "@material-ui/core/IconButton";
import CustomButton from "../components/CustomButton";
import ErrorFeedback from "../components/ErrorFeedback";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";
import { login, register } from "../api";
import { history } from "../store";
import { CssBaseline } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { doLogin, setError, resetAuth, doRegister } from "../actions";
import { resetPassword } from "../api";

import Link from "@material-ui/core/Link";

import loginLogo from "../images/loginLogo.png";
import { useStyles } from "../styles/Login.style";

const initialFormState = {
  email: "",
  password1: "",
  password2: "",
  showPassword: false,
};

function ResetPassword1({ errors, error, isError }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [state, setState] = useState(initialFormState);

  const handleChange = (prop) => (event) =>
    setState({ ...state, [prop]: event.target.value });
  const handleClickShowPassword = () =>
    setState({ ...state, showPassword: !state.showPassword });

  const enterReset = (e) => {
    e.preventDefault();

    resetPassword(state.email, state.password1, state.password2)
      .then((data) => {
        // dispatch(doRegister(data));
        history.push("/");

      })
      .catch((err) => {
        dispatch(setError(err));

        // dispatch(resetAuth());
        // history.push("/");
      });
  };

  return (
    <div className={classes.container}>
      <CssBaseline />
      <ErrorFeedback />

      <img className={classes.logo} alt="Logo" src={loginLogo} />
      <h2>Reset password</h2>

      <form onSubmit={enterReset} className={classes.form}>
        <TextField
          error={!!errors["email"]}
          helperText={!!errors["email"] && errors["email"]}
          required
          className={classes.form}
          label={t("E-mail")}
          value={state.email}
          variant="filled"
          type="text"
          onChange={handleChange("email")}
        />

        <div style={{ display: "grid" }}>

          <CustomButton
            name="RESET"
            style={{ marginTop: "20px" }}
            // onClick={enterReset}
            type="submit"
          />

        {isError && <p>{JSON.stringify(errors)}</p>}
        </div>
      </form>

    </div>
  );
}

const mapStateProps = (state) => ({
  isError: state.errorState.showError,
  error: state.errorState.error,
  errors: state.errorState.errors,
});

export default connect(mapStateProps, {})(ResetPassword1);
