"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } /**
 * NXPrivacy API
 * REST API for NXPrivacy
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */
/**
* Enum class CountryEnum.
* @enum {}
* @readonly
*/
class CountryEnum {
  constructor() {
    /**
     * value: "AF"
     * @const
     */
    _defineProperty(this, "AF", "AF");
    /**
     * value: "AX"
     * @const
     */
    _defineProperty(this, "AX", "AX");
    /**
     * value: "AL"
     * @const
     */
    _defineProperty(this, "AL", "AL");
    /**
     * value: "DZ"
     * @const
     */
    _defineProperty(this, "DZ", "DZ");
    /**
     * value: "AS"
     * @const
     */
    _defineProperty(this, "AS", "AS");
    /**
     * value: "AD"
     * @const
     */
    _defineProperty(this, "AD", "AD");
    /**
     * value: "AO"
     * @const
     */
    _defineProperty(this, "AO", "AO");
    /**
     * value: "AI"
     * @const
     */
    _defineProperty(this, "AI", "AI");
    /**
     * value: "AQ"
     * @const
     */
    _defineProperty(this, "AQ", "AQ");
    /**
     * value: "AG"
     * @const
     */
    _defineProperty(this, "AG", "AG");
    /**
     * value: "AR"
     * @const
     */
    _defineProperty(this, "AR", "AR");
    /**
     * value: "AM"
     * @const
     */
    _defineProperty(this, "AM", "AM");
    /**
     * value: "AW"
     * @const
     */
    _defineProperty(this, "AW", "AW");
    /**
     * value: "AU"
     * @const
     */
    _defineProperty(this, "AU", "AU");
    /**
     * value: "AT"
     * @const
     */
    _defineProperty(this, "AT", "AT");
    /**
     * value: "AZ"
     * @const
     */
    _defineProperty(this, "AZ", "AZ");
    /**
     * value: "BS"
     * @const
     */
    _defineProperty(this, "BS", "BS");
    /**
     * value: "BH"
     * @const
     */
    _defineProperty(this, "BH", "BH");
    /**
     * value: "BD"
     * @const
     */
    _defineProperty(this, "BD", "BD");
    /**
     * value: "BB"
     * @const
     */
    _defineProperty(this, "BB", "BB");
    /**
     * value: "BY"
     * @const
     */
    _defineProperty(this, "BY", "BY");
    /**
     * value: "BE"
     * @const
     */
    _defineProperty(this, "BE", "BE");
    /**
     * value: "BZ"
     * @const
     */
    _defineProperty(this, "BZ", "BZ");
    /**
     * value: "BJ"
     * @const
     */
    _defineProperty(this, "BJ", "BJ");
    /**
     * value: "BM"
     * @const
     */
    _defineProperty(this, "BM", "BM");
    /**
     * value: "BT"
     * @const
     */
    _defineProperty(this, "BT", "BT");
    /**
     * value: "BO"
     * @const
     */
    _defineProperty(this, "BO", "BO");
    /**
     * value: "BQ"
     * @const
     */
    _defineProperty(this, "BQ", "BQ");
    /**
     * value: "BA"
     * @const
     */
    _defineProperty(this, "BA", "BA");
    /**
     * value: "BW"
     * @const
     */
    _defineProperty(this, "BW", "BW");
    /**
     * value: "BV"
     * @const
     */
    _defineProperty(this, "BV", "BV");
    /**
     * value: "BR"
     * @const
     */
    _defineProperty(this, "BR", "BR");
    /**
     * value: "IO"
     * @const
     */
    _defineProperty(this, "IO", "IO");
    /**
     * value: "BN"
     * @const
     */
    _defineProperty(this, "BN", "BN");
    /**
     * value: "BG"
     * @const
     */
    _defineProperty(this, "BG", "BG");
    /**
     * value: "BF"
     * @const
     */
    _defineProperty(this, "BF", "BF");
    /**
     * value: "BI"
     * @const
     */
    _defineProperty(this, "BI", "BI");
    /**
     * value: "CV"
     * @const
     */
    _defineProperty(this, "CV", "CV");
    /**
     * value: "KH"
     * @const
     */
    _defineProperty(this, "KH", "KH");
    /**
     * value: "CM"
     * @const
     */
    _defineProperty(this, "CM", "CM");
    /**
     * value: "CA"
     * @const
     */
    _defineProperty(this, "CA", "CA");
    /**
     * value: "KY"
     * @const
     */
    _defineProperty(this, "KY", "KY");
    /**
     * value: "CF"
     * @const
     */
    _defineProperty(this, "CF", "CF");
    /**
     * value: "TD"
     * @const
     */
    _defineProperty(this, "TD", "TD");
    /**
     * value: "CL"
     * @const
     */
    _defineProperty(this, "CL", "CL");
    /**
     * value: "CN"
     * @const
     */
    _defineProperty(this, "CN", "CN");
    /**
     * value: "CX"
     * @const
     */
    _defineProperty(this, "CX", "CX");
    /**
     * value: "CC"
     * @const
     */
    _defineProperty(this, "CC", "CC");
    /**
     * value: "CO"
     * @const
     */
    _defineProperty(this, "CO", "CO");
    /**
     * value: "KM"
     * @const
     */
    _defineProperty(this, "KM", "KM");
    /**
     * value: "CG"
     * @const
     */
    _defineProperty(this, "CG", "CG");
    /**
     * value: "CD"
     * @const
     */
    _defineProperty(this, "CD", "CD");
    /**
     * value: "CK"
     * @const
     */
    _defineProperty(this, "CK", "CK");
    /**
     * value: "CR"
     * @const
     */
    _defineProperty(this, "CR", "CR");
    /**
     * value: "CI"
     * @const
     */
    _defineProperty(this, "CI", "CI");
    /**
     * value: "HR"
     * @const
     */
    _defineProperty(this, "HR", "HR");
    /**
     * value: "CU"
     * @const
     */
    _defineProperty(this, "CU", "CU");
    /**
     * value: "CW"
     * @const
     */
    _defineProperty(this, "CW", "CW");
    /**
     * value: "CY"
     * @const
     */
    _defineProperty(this, "CY", "CY");
    /**
     * value: "CZ"
     * @const
     */
    _defineProperty(this, "CZ", "CZ");
    /**
     * value: "DK"
     * @const
     */
    _defineProperty(this, "DK", "DK");
    /**
     * value: "DJ"
     * @const
     */
    _defineProperty(this, "DJ", "DJ");
    /**
     * value: "DM"
     * @const
     */
    _defineProperty(this, "DM", "DM");
    /**
     * value: "DO"
     * @const
     */
    _defineProperty(this, "DO", "DO");
    /**
     * value: "EC"
     * @const
     */
    _defineProperty(this, "EC", "EC");
    /**
     * value: "EG"
     * @const
     */
    _defineProperty(this, "EG", "EG");
    /**
     * value: "SV"
     * @const
     */
    _defineProperty(this, "SV", "SV");
    /**
     * value: "GQ"
     * @const
     */
    _defineProperty(this, "GQ", "GQ");
    /**
     * value: "ER"
     * @const
     */
    _defineProperty(this, "ER", "ER");
    /**
     * value: "EE"
     * @const
     */
    _defineProperty(this, "EE", "EE");
    /**
     * value: "SZ"
     * @const
     */
    _defineProperty(this, "SZ", "SZ");
    /**
     * value: "ET"
     * @const
     */
    _defineProperty(this, "ET", "ET");
    /**
     * value: "FK"
     * @const
     */
    _defineProperty(this, "FK", "FK");
    /**
     * value: "FO"
     * @const
     */
    _defineProperty(this, "FO", "FO");
    /**
     * value: "FJ"
     * @const
     */
    _defineProperty(this, "FJ", "FJ");
    /**
     * value: "FI"
     * @const
     */
    _defineProperty(this, "FI", "FI");
    /**
     * value: "FR"
     * @const
     */
    _defineProperty(this, "FR", "FR");
    /**
     * value: "GF"
     * @const
     */
    _defineProperty(this, "GF", "GF");
    /**
     * value: "PF"
     * @const
     */
    _defineProperty(this, "PF", "PF");
    /**
     * value: "TF"
     * @const
     */
    _defineProperty(this, "TF", "TF");
    /**
     * value: "GA"
     * @const
     */
    _defineProperty(this, "GA", "GA");
    /**
     * value: "GM"
     * @const
     */
    _defineProperty(this, "GM", "GM");
    /**
     * value: "GE"
     * @const
     */
    _defineProperty(this, "GE", "GE");
    /**
     * value: "DE"
     * @const
     */
    _defineProperty(this, "DE", "DE");
    /**
     * value: "GH"
     * @const
     */
    _defineProperty(this, "GH", "GH");
    /**
     * value: "GI"
     * @const
     */
    _defineProperty(this, "GI", "GI");
    /**
     * value: "GR"
     * @const
     */
    _defineProperty(this, "GR", "GR");
    /**
     * value: "GL"
     * @const
     */
    _defineProperty(this, "GL", "GL");
    /**
     * value: "GD"
     * @const
     */
    _defineProperty(this, "GD", "GD");
    /**
     * value: "GP"
     * @const
     */
    _defineProperty(this, "GP", "GP");
    /**
     * value: "GU"
     * @const
     */
    _defineProperty(this, "GU", "GU");
    /**
     * value: "GT"
     * @const
     */
    _defineProperty(this, "GT", "GT");
    /**
     * value: "GG"
     * @const
     */
    _defineProperty(this, "GG", "GG");
    /**
     * value: "GN"
     * @const
     */
    _defineProperty(this, "GN", "GN");
    /**
     * value: "GW"
     * @const
     */
    _defineProperty(this, "GW", "GW");
    /**
     * value: "GY"
     * @const
     */
    _defineProperty(this, "GY", "GY");
    /**
     * value: "HT"
     * @const
     */
    _defineProperty(this, "HT", "HT");
    /**
     * value: "HM"
     * @const
     */
    _defineProperty(this, "HM", "HM");
    /**
     * value: "VA"
     * @const
     */
    _defineProperty(this, "VA", "VA");
    /**
     * value: "HN"
     * @const
     */
    _defineProperty(this, "HN", "HN");
    /**
     * value: "HK"
     * @const
     */
    _defineProperty(this, "HK", "HK");
    /**
     * value: "HU"
     * @const
     */
    _defineProperty(this, "HU", "HU");
    /**
     * value: "IS"
     * @const
     */
    _defineProperty(this, "IS", "IS");
    /**
     * value: "IN"
     * @const
     */
    _defineProperty(this, "IN", "IN");
    /**
     * value: "ID"
     * @const
     */
    _defineProperty(this, "ID", "ID");
    /**
     * value: "IR"
     * @const
     */
    _defineProperty(this, "IR", "IR");
    /**
     * value: "IQ"
     * @const
     */
    _defineProperty(this, "IQ", "IQ");
    /**
     * value: "IE"
     * @const
     */
    _defineProperty(this, "IE", "IE");
    /**
     * value: "IM"
     * @const
     */
    _defineProperty(this, "IM", "IM");
    /**
     * value: "IL"
     * @const
     */
    _defineProperty(this, "IL", "IL");
    /**
     * value: "IT"
     * @const
     */
    _defineProperty(this, "IT", "IT");
    /**
     * value: "JM"
     * @const
     */
    _defineProperty(this, "JM", "JM");
    /**
     * value: "JP"
     * @const
     */
    _defineProperty(this, "JP", "JP");
    /**
     * value: "JE"
     * @const
     */
    _defineProperty(this, "JE", "JE");
    /**
     * value: "JO"
     * @const
     */
    _defineProperty(this, "JO", "JO");
    /**
     * value: "KZ"
     * @const
     */
    _defineProperty(this, "KZ", "KZ");
    /**
     * value: "KE"
     * @const
     */
    _defineProperty(this, "KE", "KE");
    /**
     * value: "KI"
     * @const
     */
    _defineProperty(this, "KI", "KI");
    /**
     * value: "KW"
     * @const
     */
    _defineProperty(this, "KW", "KW");
    /**
     * value: "KG"
     * @const
     */
    _defineProperty(this, "KG", "KG");
    /**
     * value: "LA"
     * @const
     */
    _defineProperty(this, "LA", "LA");
    /**
     * value: "LV"
     * @const
     */
    _defineProperty(this, "LV", "LV");
    /**
     * value: "LB"
     * @const
     */
    _defineProperty(this, "LB", "LB");
    /**
     * value: "LS"
     * @const
     */
    _defineProperty(this, "LS", "LS");
    /**
     * value: "LR"
     * @const
     */
    _defineProperty(this, "LR", "LR");
    /**
     * value: "LY"
     * @const
     */
    _defineProperty(this, "LY", "LY");
    /**
     * value: "LI"
     * @const
     */
    _defineProperty(this, "LI", "LI");
    /**
     * value: "LT"
     * @const
     */
    _defineProperty(this, "LT", "LT");
    /**
     * value: "LU"
     * @const
     */
    _defineProperty(this, "LU", "LU");
    /**
     * value: "MO"
     * @const
     */
    _defineProperty(this, "MO", "MO");
    /**
     * value: "MG"
     * @const
     */
    _defineProperty(this, "MG", "MG");
    /**
     * value: "MW"
     * @const
     */
    _defineProperty(this, "MW", "MW");
    /**
     * value: "MY"
     * @const
     */
    _defineProperty(this, "MY", "MY");
    /**
     * value: "MV"
     * @const
     */
    _defineProperty(this, "MV", "MV");
    /**
     * value: "ML"
     * @const
     */
    _defineProperty(this, "ML", "ML");
    /**
     * value: "MT"
     * @const
     */
    _defineProperty(this, "MT", "MT");
    /**
     * value: "MH"
     * @const
     */
    _defineProperty(this, "MH", "MH");
    /**
     * value: "MQ"
     * @const
     */
    _defineProperty(this, "MQ", "MQ");
    /**
     * value: "MR"
     * @const
     */
    _defineProperty(this, "MR", "MR");
    /**
     * value: "MU"
     * @const
     */
    _defineProperty(this, "MU", "MU");
    /**
     * value: "YT"
     * @const
     */
    _defineProperty(this, "YT", "YT");
    /**
     * value: "MX"
     * @const
     */
    _defineProperty(this, "MX", "MX");
    /**
     * value: "FM"
     * @const
     */
    _defineProperty(this, "FM", "FM");
    /**
     * value: "MD"
     * @const
     */
    _defineProperty(this, "MD", "MD");
    /**
     * value: "MC"
     * @const
     */
    _defineProperty(this, "MC", "MC");
    /**
     * value: "MN"
     * @const
     */
    _defineProperty(this, "MN", "MN");
    /**
     * value: "ME"
     * @const
     */
    _defineProperty(this, "ME", "ME");
    /**
     * value: "MS"
     * @const
     */
    _defineProperty(this, "MS", "MS");
    /**
     * value: "MA"
     * @const
     */
    _defineProperty(this, "MA", "MA");
    /**
     * value: "MZ"
     * @const
     */
    _defineProperty(this, "MZ", "MZ");
    /**
     * value: "MM"
     * @const
     */
    _defineProperty(this, "MM", "MM");
    /**
     * value: "NA"
     * @const
     */
    _defineProperty(this, "NA", "NA");
    /**
     * value: "NR"
     * @const
     */
    _defineProperty(this, "NR", "NR");
    /**
     * value: "NP"
     * @const
     */
    _defineProperty(this, "NP", "NP");
    /**
     * value: "NL"
     * @const
     */
    _defineProperty(this, "NL", "NL");
    /**
     * value: "NC"
     * @const
     */
    _defineProperty(this, "NC", "NC");
    /**
     * value: "NZ"
     * @const
     */
    _defineProperty(this, "NZ", "NZ");
    /**
     * value: "NI"
     * @const
     */
    _defineProperty(this, "NI", "NI");
    /**
     * value: "NE"
     * @const
     */
    _defineProperty(this, "NE", "NE");
    /**
     * value: "NG"
     * @const
     */
    _defineProperty(this, "NG", "NG");
    /**
     * value: "NU"
     * @const
     */
    _defineProperty(this, "NU", "NU");
    /**
     * value: "NF"
     * @const
     */
    _defineProperty(this, "NF", "NF");
    /**
     * value: "KP"
     * @const
     */
    _defineProperty(this, "KP", "KP");
    /**
     * value: "MK"
     * @const
     */
    _defineProperty(this, "MK", "MK");
    /**
     * value: "MP"
     * @const
     */
    _defineProperty(this, "MP", "MP");
    /**
     * value: "NO"
     * @const
     */
    _defineProperty(this, "NO", "NO");
    /**
     * value: "OM"
     * @const
     */
    _defineProperty(this, "OM", "OM");
    /**
     * value: "PK"
     * @const
     */
    _defineProperty(this, "PK", "PK");
    /**
     * value: "PW"
     * @const
     */
    _defineProperty(this, "PW", "PW");
    /**
     * value: "PS"
     * @const
     */
    _defineProperty(this, "PS", "PS");
    /**
     * value: "PA"
     * @const
     */
    _defineProperty(this, "PA", "PA");
    /**
     * value: "PG"
     * @const
     */
    _defineProperty(this, "PG", "PG");
    /**
     * value: "PY"
     * @const
     */
    _defineProperty(this, "PY", "PY");
    /**
     * value: "PE"
     * @const
     */
    _defineProperty(this, "PE", "PE");
    /**
     * value: "PH"
     * @const
     */
    _defineProperty(this, "PH", "PH");
    /**
     * value: "PN"
     * @const
     */
    _defineProperty(this, "PN", "PN");
    /**
     * value: "PL"
     * @const
     */
    _defineProperty(this, "PL", "PL");
    /**
     * value: "PT"
     * @const
     */
    _defineProperty(this, "PT", "PT");
    /**
     * value: "PR"
     * @const
     */
    _defineProperty(this, "PR", "PR");
    /**
     * value: "QA"
     * @const
     */
    _defineProperty(this, "QA", "QA");
    /**
     * value: "RE"
     * @const
     */
    _defineProperty(this, "RE", "RE");
    /**
     * value: "RO"
     * @const
     */
    _defineProperty(this, "RO", "RO");
    /**
     * value: "RU"
     * @const
     */
    _defineProperty(this, "RU", "RU");
    /**
     * value: "RW"
     * @const
     */
    _defineProperty(this, "RW", "RW");
    /**
     * value: "BL"
     * @const
     */
    _defineProperty(this, "BL", "BL");
    /**
     * value: "SH"
     * @const
     */
    _defineProperty(this, "SH", "SH");
    /**
     * value: "KN"
     * @const
     */
    _defineProperty(this, "KN", "KN");
    /**
     * value: "LC"
     * @const
     */
    _defineProperty(this, "LC", "LC");
    /**
     * value: "MF"
     * @const
     */
    _defineProperty(this, "MF", "MF");
    /**
     * value: "PM"
     * @const
     */
    _defineProperty(this, "PM", "PM");
    /**
     * value: "VC"
     * @const
     */
    _defineProperty(this, "VC", "VC");
    /**
     * value: "WS"
     * @const
     */
    _defineProperty(this, "WS", "WS");
    /**
     * value: "SM"
     * @const
     */
    _defineProperty(this, "SM", "SM");
    /**
     * value: "ST"
     * @const
     */
    _defineProperty(this, "ST", "ST");
    /**
     * value: "SA"
     * @const
     */
    _defineProperty(this, "SA", "SA");
    /**
     * value: "SN"
     * @const
     */
    _defineProperty(this, "SN", "SN");
    /**
     * value: "RS"
     * @const
     */
    _defineProperty(this, "RS", "RS");
    /**
     * value: "SC"
     * @const
     */
    _defineProperty(this, "SC", "SC");
    /**
     * value: "SL"
     * @const
     */
    _defineProperty(this, "SL", "SL");
    /**
     * value: "SG"
     * @const
     */
    _defineProperty(this, "SG", "SG");
    /**
     * value: "SX"
     * @const
     */
    _defineProperty(this, "SX", "SX");
    /**
     * value: "SK"
     * @const
     */
    _defineProperty(this, "SK", "SK");
    /**
     * value: "SI"
     * @const
     */
    _defineProperty(this, "SI", "SI");
    /**
     * value: "SB"
     * @const
     */
    _defineProperty(this, "SB", "SB");
    /**
     * value: "SO"
     * @const
     */
    _defineProperty(this, "SO", "SO");
    /**
     * value: "ZA"
     * @const
     */
    _defineProperty(this, "ZA", "ZA");
    /**
     * value: "GS"
     * @const
     */
    _defineProperty(this, "GS", "GS");
    /**
     * value: "KR"
     * @const
     */
    _defineProperty(this, "KR", "KR");
    /**
     * value: "SS"
     * @const
     */
    _defineProperty(this, "SS", "SS");
    /**
     * value: "ES"
     * @const
     */
    _defineProperty(this, "ES", "ES");
    /**
     * value: "LK"
     * @const
     */
    _defineProperty(this, "LK", "LK");
    /**
     * value: "SD"
     * @const
     */
    _defineProperty(this, "SD", "SD");
    /**
     * value: "SR"
     * @const
     */
    _defineProperty(this, "SR", "SR");
    /**
     * value: "SJ"
     * @const
     */
    _defineProperty(this, "SJ", "SJ");
    /**
     * value: "SE"
     * @const
     */
    _defineProperty(this, "SE", "SE");
    /**
     * value: "CH"
     * @const
     */
    _defineProperty(this, "CH", "CH");
    /**
     * value: "SY"
     * @const
     */
    _defineProperty(this, "SY", "SY");
    /**
     * value: "TW"
     * @const
     */
    _defineProperty(this, "TW", "TW");
    /**
     * value: "TJ"
     * @const
     */
    _defineProperty(this, "TJ", "TJ");
    /**
     * value: "TZ"
     * @const
     */
    _defineProperty(this, "TZ", "TZ");
    /**
     * value: "TH"
     * @const
     */
    _defineProperty(this, "TH", "TH");
    /**
     * value: "TL"
     * @const
     */
    _defineProperty(this, "TL", "TL");
    /**
     * value: "TG"
     * @const
     */
    _defineProperty(this, "TG", "TG");
    /**
     * value: "TK"
     * @const
     */
    _defineProperty(this, "TK", "TK");
    /**
     * value: "TO"
     * @const
     */
    _defineProperty(this, "TO", "TO");
    /**
     * value: "TT"
     * @const
     */
    _defineProperty(this, "TT", "TT");
    /**
     * value: "TN"
     * @const
     */
    _defineProperty(this, "TN", "TN");
    /**
     * value: "TR"
     * @const
     */
    _defineProperty(this, "TR", "TR");
    /**
     * value: "TM"
     * @const
     */
    _defineProperty(this, "TM", "TM");
    /**
     * value: "TC"
     * @const
     */
    _defineProperty(this, "TC", "TC");
    /**
     * value: "TV"
     * @const
     */
    _defineProperty(this, "TV", "TV");
    /**
     * value: "UG"
     * @const
     */
    _defineProperty(this, "UG", "UG");
    /**
     * value: "UA"
     * @const
     */
    _defineProperty(this, "UA", "UA");
    /**
     * value: "AE"
     * @const
     */
    _defineProperty(this, "AE", "AE");
    /**
     * value: "GB"
     * @const
     */
    _defineProperty(this, "GB", "GB");
    /**
     * value: "UM"
     * @const
     */
    _defineProperty(this, "UM", "UM");
    /**
     * value: "US"
     * @const
     */
    _defineProperty(this, "US", "US");
    /**
     * value: "UY"
     * @const
     */
    _defineProperty(this, "UY", "UY");
    /**
     * value: "UZ"
     * @const
     */
    _defineProperty(this, "UZ", "UZ");
    /**
     * value: "VU"
     * @const
     */
    _defineProperty(this, "VU", "VU");
    /**
     * value: "VE"
     * @const
     */
    _defineProperty(this, "VE", "VE");
    /**
     * value: "VN"
     * @const
     */
    _defineProperty(this, "VN", "VN");
    /**
     * value: "VG"
     * @const
     */
    _defineProperty(this, "VG", "VG");
    /**
     * value: "VI"
     * @const
     */
    _defineProperty(this, "VI", "VI");
    /**
     * value: "WF"
     * @const
     */
    _defineProperty(this, "WF", "WF");
    /**
     * value: "EH"
     * @const
     */
    _defineProperty(this, "EH", "EH");
    /**
     * value: "YE"
     * @const
     */
    _defineProperty(this, "YE", "YE");
    /**
     * value: "ZM"
     * @const
     */
    _defineProperty(this, "ZM", "ZM");
    /**
     * value: "ZW"
     * @const
     */
    _defineProperty(this, "ZW", "ZW");
  }
  /**
  * Returns a <code>CountryEnum</code> enum value from a Javascript object name.
  * @param {Object} data The plain JavaScript object containing the name of the enum value.
  * @return {module:model/CountryEnum} The enum <code>CountryEnum</code> value.
  */
  static constructFromObject(object) {
    return object;
  }
}
exports.default = CountryEnum;