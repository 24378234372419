import React from "react";
import { useTranslation } from "react-i18next";

import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

import { useStyles } from "../styles/Box.style";

function Product({ product, onClick }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const getCurrency = (str) => {
    switch (str) {
      case "eur":
        return "€"
      case "usd":
        return "$"
      default:
        return "€"
    }
  }
  const getFrequency = (str) => {
    switch (str) {
      case "year_1":
        return t("yearly")
      case "month_1":
        return t("monthly")
      default:
        return t("yearly")
    }
  }
  const getInnerBoxValue = (product) => {
    return <div >
      <p style={{ color: "#007a85" }}>{product.name}</p><br/>
      <p style={{}}>{product.price / 100} {getCurrency(product.currency)}</p><br/>
      <p style={{}}>{t("Subscribed")} {getFrequency(product.freq)}</p>
    </div>
  };
  
  return (
    <div className={classes.container}>
      <div className={classes.productContainer} onClick={onClick}>
        {getInnerBoxValue(product)}
      </div>
      {/* <p style={{ margin: "10px" }}>{t(value)}</p> */}
    </div>
  );
}

export default Product;
