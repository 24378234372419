"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * NXPrivacy API
 * REST API for NXPrivacy
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The Price model module.
 * @module model/Price
 * @version 1.0.0
 */
class Price {
  /**
   * Constructs a new <code>Price</code>.
   * @alias module:model/Price
   * @param price_id {String} 
   * @param product_id {String} 
   * @param name {String} 
   * @param price {Number} 
   * @param avail {Boolean} 
   * @param services {Array.<Object>} 
   * @param currency {String} 
   */
  constructor(price_id, product_id, name, price, avail, services, currency) {
    Price.initialize(this, price_id, product_id, name, price, avail, services, currency);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, price_id, product_id, name, price, avail, services, currency) {
    obj['price_id'] = price_id;
    obj['product_id'] = product_id;
    obj['name'] = name;
    obj['price'] = price;
    obj['avail'] = avail;
    obj['services'] = services;
    obj['currency'] = currency;
  }

  /**
   * Constructs a <code>Price</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/Price} obj Optional instance to populate.
   * @return {module:model/Price} The populated <code>Price</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Price();
      if (data.hasOwnProperty('price_id')) {
        obj['price_id'] = _ApiClient.default.convertToType(data['price_id'], 'String');
      }
      if (data.hasOwnProperty('product_id')) {
        obj['product_id'] = _ApiClient.default.convertToType(data['product_id'], 'String');
      }
      if (data.hasOwnProperty('name')) {
        obj['name'] = _ApiClient.default.convertToType(data['name'], 'String');
      }
      if (data.hasOwnProperty('price')) {
        obj['price'] = _ApiClient.default.convertToType(data['price'], 'Number');
      }
      if (data.hasOwnProperty('freq')) {
        obj['freq'] = _ApiClient.default.convertToType(data['freq'], 'String');
      }
      if (data.hasOwnProperty('avail')) {
        obj['avail'] = _ApiClient.default.convertToType(data['avail'], 'Boolean');
      }
      if (data.hasOwnProperty('services')) {
        obj['services'] = _ApiClient.default.convertToType(data['services'], [Object]);
      }
      if (data.hasOwnProperty('currency')) {
        obj['currency'] = _ApiClient.default.convertToType(data['currency'], 'String');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>Price</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>Price</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of Price.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['price_id'] && !(typeof data['price_id'] === 'string' || data['price_id'] instanceof String)) {
      throw new Error("Expected the field `price_id` to be a primitive type in the JSON string but got " + data['price_id']);
    }
    // ensure the json data is a string
    if (data['product_id'] && !(typeof data['product_id'] === 'string' || data['product_id'] instanceof String)) {
      throw new Error("Expected the field `product_id` to be a primitive type in the JSON string but got " + data['product_id']);
    }
    // ensure the json data is a string
    if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
      throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
    }
    // ensure the json data is a string
    if (data['freq'] && !(typeof data['freq'] === 'string' || data['freq'] instanceof String)) {
      throw new Error("Expected the field `freq` to be a primitive type in the JSON string but got " + data['freq']);
    }
    // ensure the json data is an array
    if (!Array.isArray(data['services'])) {
      throw new Error("Expected the field `services` to be an array in the JSON data but got " + data['services']);
    }
    // ensure the json data is a string
    if (data['currency'] && !(typeof data['currency'] === 'string' || data['currency'] instanceof String)) {
      throw new Error("Expected the field `currency` to be a primitive type in the JSON string but got " + data['currency']);
    }
    return true;
  }
}
Price.RequiredProperties = ["price_id", "product_id", "name", "price", "avail", "services", "currency"];

/**
 * @member {String} price_id
 */
Price.prototype['price_id'] = undefined;

/**
 * @member {String} product_id
 */
Price.prototype['product_id'] = undefined;

/**
 * @member {String} name
 */
Price.prototype['name'] = undefined;

/**
 * @member {Number} price
 */
Price.prototype['price'] = undefined;

/**
 * @member {String} freq
 */
Price.prototype['freq'] = undefined;

/**
 * @member {Boolean} avail
 */
Price.prototype['avail'] = undefined;

/**
 * @member {Array.<Object>} services
 */
Price.prototype['services'] = undefined;

/**
 * @member {String} currency
 */
Price.prototype['currency'] = undefined;
var _default = exports.default = Price;