import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import { useTranslation } from "react-i18next";

import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";

import { connect } from "react-redux";
import { useStyles } from "../styles/Input.style";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";
import {
  riskLevel,
  sysAdmin,
  manyToMany,
  oneToMany,
  addNewOption,
  othersToAdd,
} from "../api";

function Input({
  field,
  name,
  value,
  handle,
  InputProps,
  isError,
  error,
  errors,
  disabled
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const getOther = (field) => {
    if (othersToAdd.some((e) => e === field)) {
      return true;
    }
    return false;
  };
  const [input, setInput] = useState(!getOther(field) ? value : "");
  const [arr, setArr] = useState(
    getOther(field) && value.length > 0 ? value.split(", ") : []
  );
  const handleInput = (e) => {
    setInput(e.target.value);
    // console.log(input, !!!input || input.trim() === "");
    if (!getOther(field)) {
      handle(e);
    }
  };

  const handleAdd = (e) => {
    if (!!input && input.trim() !== "") {
      handle(e, null, [...arr, input].join(", "));
      setArr([...arr, input]);
    }
    setInput("");
  };
  const handleDelete = (chipToDelete) => () => {
    // console.log(chipToDelete);
    let newArr = arr.filter((e) => e !== chipToDelete);
    handle(null, null, newArr.join(", "));
    setArr(newArr);
  };
  // useEffect(() => {
  //   setInput(value);
  // }, [value]);
  return (
    <div className={classes.container} key={field}>
      <label htmlFor={name} className={classes.label}>
        {t(name).toUpperCase()}
      </label>
      <div style={{ display: "flex", alignItems: "center" }}>
        <TextField
          helperText={!!errors[field] && errors[field]}
          error={!!errors[field]}
          required
          value={input}
          // value={!getOther(name) ? input : value}
          variant="outlined"
          type={"text"}
          onChange={(e) => handleInput(e)}
          InputProps={{
            ...InputProps,
            className: classes.form,
          }}
          disabled={disabled}
        />
        {getOther(field) && (
          <IconButton
            color="primary"
            disabled={!!!input || input.trim() === ""}
            onClick={(e) => handleAdd(e)}
          >
            <AddIcon />
          </IconButton>
        )}
      </div>
      {getOther(field) && arr.length > 0 && (
        <Paper
          component="ul"
          elevation={false}
          className={classes.chipContainer}
        >
          {arr.map((data) => {
            // let icon;

            // if (data.label === "React") {
            //   icon = <TagFacesIcon />;
            // }

            return (
              <li key={data}>
                <Chip
                  // icon={icon}
                  label={data}
                  onDelete={handleDelete(data)}
                  className={classes.chip}
                  color="primary"
                />
              </li>
            );
          })}
        </Paper>
      )}
    </div>
  );
}

const mapStateProps = (state) => ({
  isError: state.errorState.showError,
  error: state.errorState.error,
  errors: state.errorState.errors,
});

export default connect(mapStateProps, {})(Input);
