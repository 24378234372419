import React from "react";
import { useTranslation } from "react-i18next";

import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

import { useStyles } from "../styles/Box.style";
import Boolean from "./Boolean";
import Link from "@material-ui/core/Link";

function Subscription({ product, onClick, customerUrl }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const getCurrency = (str) => {
    switch (str) {
      case "eur":
        return "€"
      case "usd":
        return "$"
      default:
        return "€"
    }
  }
  const getFrequency = (str) => {
    switch (str) {
      case "year_1":
        return t("yearly")
      case "month_1":
        return t("monthly")
      default:
        return t("yearly")
    }
  }
  const getInnerBoxValue = (product) => {
    return <div >
      <p style={{ color: "#007a85" }}>{product.product_name}</p>
      <p style={{ color: "#007a85" }}>{product.product_description}</p>
      <p style={{}}>{product.price / 100} {getCurrency(product.currency)}</p>
      <p style={{}}>{t("Subscribed")} {getFrequency(product.freq)}</p>
      <p style={{}}>{t("Start:")} {product.period_start}</p>
      <p style={{}}>{t("End:")} {product.period_end}</p>
      <p style={{}}>{t("Auto renew")} <Boolean value={!product.cancel_at_period_end}/></p>
      <Link
         target="_blank"
            component="a"
            //color="inherit"
             href={customerUrl}
          
            key={`cancel-plan`}
          >
           Cancel or pause plan

          </Link>
    </div>
  };
  
  return (
    <div className={classes.container}>
      <div className={classes.productContainer} onClick={onClick}>
        {getInnerBoxValue(product)}
      </div>
      {/* <p style={{ margin: "10px" }}>{t(value)}</p> */}
    </div>
  );
}

export default Subscription;
