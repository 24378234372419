import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: "#007a85",
    color: "white",
    fontSize: "18px",
    padding: "10px",
    borderRadius: "5px",
    minWidth: "150px",
    margin: "20px",
    maxHeight: "35px",
    boxShadow: "1px 1px 1px 1px grey",
    "&:hover": { backgroundColor: "#007a85" },
  },
  buttonBack: {
    border: "2px solid #007a85",
    color: "#007a85",
    fontSize: "18px",
    padding: "10px",
    borderRadius: "5px",
    minWidth: "150px",
    maxHeight: "35px",
    backgroundColor: "trasparent",
    margin: "20px",
    boxShadow: "1px 1px 1px 1px grey",
  },
  iconBack: {
    display: "flex",
    // border: "3px solid #007a85",
    // borderRadius: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  iconNext: {
    display: "flex",
    // border: "3px solid white",
    // borderRadius: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
}));
