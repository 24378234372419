import React, { useState } from "react";
import HomeHeader from "../components/HomeHeader";
import CustomButton from "../components/CustomButton";
import PreviewStep from "../components/steps/PreviewStep";
import PreviewDataController from "../components/steps/PreviewDataController";
import PreviewDpo from "../components/steps/PreviewDpo";
import { history } from "../store";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { resetDataController } from "../actions/dataControllerActions";
import { resetTreatment } from "../actions/treatmentActions";
import { resetDpo } from "../actions/dpoActions";
import SystemUpdateAltIcon from "@material-ui/icons/SystemUpdateAlt";
import GeneratePDF from "../components/reportGenerator";

import { useStyle } from "../styles/DisplayInfo.style";
import { resetDataProcessor, resetOrganization, resetOrganizationMembers, setOrganization, setError } from "../actions";
import PreviewDataProcessor from "../components/steps/PreviewDataProcessor";
import TableDisplay from "../components/TableDisplay";
import PreviewOrganization from "../components/steps/PreviewOrganization";
import { getDirectOrganizationMembers } from "../api"
import InviteModal from "../components/modals/InviteModal";
function DisplayOrganization({

  selectedOrganization,
  // selectedOrganizationMembers,
}) {
  const classes = useStyle();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleBack = () => history.goBack();
  const handleInvite = () => history.goBack();
  const [open, setOpen] = useState(false);


  const handleClose = () => {
    // dispatch(resetOrganization());
    setOpen(false);
  };

  const getHeader = () => {
    let val = "";

    if (Object.keys(selectedOrganization).length > 0)
      val = selectedOrganization?.name;

    return val;
  };

  React.useEffect(() => {
    if (

      Object.keys(selectedOrganization).length === 0

    ) {
      history.goBack();
    }

    // return () => {

    //   dispatch(resetOrganization());

    // };
  }, [
    dispatch,

    selectedOrganization,
  ]);

  return (
    <div className={classes.container}>
      <HomeHeader value={getHeader()} />
      <InviteModal open={open} handleClose={handleClose} />
      <div className={classes.backButton}>
        <CustomButton
          name="BACK"
          value="back"
          isIcon={true}
          onClick={() => handleBack()}
        />
        <CustomButton
          name="INVITE"
          value="invite"
          onClick={() => setOpen(!open)}
        />

      </div>


      {Object.keys(selectedOrganization).length > 0 && (
        <>
          <div className={classes.treatmentPreview}>
            <PreviewOrganization value={selectedOrganization} />

            {/* {selectedOrganizationMembers.length > 0 && (
            <TableDisplay value={"organization_members"} />
          )} */}
          </div>
          {/* <div className={classes.treatmentPreview}> */}
            <TableDisplay value={"organization_members"} />
          {/* </div> */}
        </>
      )}

    </div>
  );
}


const mapStateProps = (state) => ({

  selectedOrganization: state.organizationState.selectedOrganization,
  // selectedOrganizationMembers: state.organizationState.selectedOrganizationMembers.results,

});

export default connect(mapStateProps, {})(DisplayOrganization);
