"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * NXPrivacy API
 * REST API for NXPrivacy
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
* Invitations service.
* @module api/InvitationsApi
* @version 1.0.0
*/
class InvitationsApi {
  /**
  * Constructs a new InvitationsApi. 
  * @alias module:api/InvitationsApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || _ApiClient.default.instance;
  }

  /**
   * @param {String} key 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  invitationsAcceptInviteCreateWithHttpInfo(key) {
    let postBody = null;
    // verify the required parameter 'key' is set
    if (key === undefined || key === null) {
      throw new Error("Missing the required parameter 'key' when calling invitationsAcceptInviteCreate");
    }
    let pathParams = {
      'key': key
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/api/invitations/accept-invite/{key}/', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {String} key 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  invitationsAcceptInviteCreate(key) {
    return this.invitationsAcceptInviteCreateWithHttpInfo(key).then(function (response_and_data) {
      return response_and_data.data;
    });
  }
}
exports.default = InvitationsApi;