"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _DataProcessor = _interopRequireDefault(require("../model/DataProcessor"));
var _DataProcessorConcurrentStructure = _interopRequireDefault(require("../model/DataProcessorConcurrentStructure"));
var _DataProcessorConcurrentStructureRequest = _interopRequireDefault(require("../model/DataProcessorConcurrentStructureRequest"));
var _DataProcessorController = _interopRequireDefault(require("../model/DataProcessorController"));
var _DataProcessorControllerRequest = _interopRequireDefault(require("../model/DataProcessorControllerRequest"));
var _DataProcessorInternalReferenceStructure = _interopRequireDefault(require("../model/DataProcessorInternalReferenceStructure"));
var _DataProcessorInternalReferenceStructureRequest = _interopRequireDefault(require("../model/DataProcessorInternalReferenceStructureRequest"));
var _DataProcessorRequest = _interopRequireDefault(require("../model/DataProcessorRequest"));
var _PaginatedDataProcessorConcurrentStructureList = _interopRequireDefault(require("../model/PaginatedDataProcessorConcurrentStructureList"));
var _PaginatedDataProcessorControllerList = _interopRequireDefault(require("../model/PaginatedDataProcessorControllerList"));
var _PaginatedDataProcessorInternalReferenceStructureList = _interopRequireDefault(require("../model/PaginatedDataProcessorInternalReferenceStructureList"));
var _PaginatedDataProcessorList = _interopRequireDefault(require("../model/PaginatedDataProcessorList"));
var _PatchedDataProcessorConcurrentStructureRequest = _interopRequireDefault(require("../model/PatchedDataProcessorConcurrentStructureRequest"));
var _PatchedDataProcessorControllerRequest = _interopRequireDefault(require("../model/PatchedDataProcessorControllerRequest"));
var _PatchedDataProcessorInternalReferenceStructureRequest = _interopRequireDefault(require("../model/PatchedDataProcessorInternalReferenceStructureRequest"));
var _PatchedDataProcessorRequest = _interopRequireDefault(require("../model/PatchedDataProcessorRequest"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * NXPrivacy API
 * REST API for NXPrivacy
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
* DataProcessors service.
* @module api/DataProcessorsApi
* @version 1.0.0
*/
class DataProcessorsApi {
  /**
  * Constructs a new DataProcessorsApi. 
  * @alias module:api/DataProcessorsApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || _ApiClient.default.instance;
  }

  /**
   * @param {Number} data_processor_id 
   * @param {module:model/DataProcessorConcurrentStructureRequest} data_processor_concurrent_structure_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DataProcessorConcurrentStructure} and HTTP response
   */
  dataProcessorsConcurrentStructuresCreateWithHttpInfo(data_processor_id, data_processor_concurrent_structure_request) {
    let postBody = data_processor_concurrent_structure_request;
    // verify the required parameter 'data_processor_id' is set
    if (data_processor_id === undefined || data_processor_id === null) {
      throw new Error("Missing the required parameter 'data_processor_id' when calling dataProcessorsConcurrentStructuresCreate");
    }
    // verify the required parameter 'data_processor_concurrent_structure_request' is set
    if (data_processor_concurrent_structure_request === undefined || data_processor_concurrent_structure_request === null) {
      throw new Error("Missing the required parameter 'data_processor_concurrent_structure_request' when calling dataProcessorsConcurrentStructuresCreate");
    }
    let pathParams = {
      'data_processor_id': data_processor_id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _DataProcessorConcurrentStructure.default;
    return this.apiClient.callApi('/api/data_processors/{data_processor_id}/concurrent_structures/', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} data_processor_id 
   * @param {module:model/DataProcessorConcurrentStructureRequest} data_processor_concurrent_structure_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DataProcessorConcurrentStructure}
   */
  dataProcessorsConcurrentStructuresCreate(data_processor_id, data_processor_concurrent_structure_request) {
    return this.dataProcessorsConcurrentStructuresCreateWithHttpInfo(data_processor_id, data_processor_concurrent_structure_request).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} data_processor_id 
   * @param {Number} id A unique integer value identifying this DataProcessorConcurrentStructure.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  dataProcessorsConcurrentStructuresDestroyWithHttpInfo(data_processor_id, id) {
    let postBody = null;
    // verify the required parameter 'data_processor_id' is set
    if (data_processor_id === undefined || data_processor_id === null) {
      throw new Error("Missing the required parameter 'data_processor_id' when calling dataProcessorsConcurrentStructuresDestroy");
    }
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling dataProcessorsConcurrentStructuresDestroy");
    }
    let pathParams = {
      'data_processor_id': data_processor_id,
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/api/data_processors/{data_processor_id}/concurrent_structures/{id}/', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} data_processor_id 
   * @param {Number} id A unique integer value identifying this DataProcessorConcurrentStructure.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  dataProcessorsConcurrentStructuresDestroy(data_processor_id, id) {
    return this.dataProcessorsConcurrentStructuresDestroyWithHttpInfo(data_processor_id, id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} data_processor_id 
   * @param {Object} opts Optional parameters
   * @param {String} opts.ordering Which field to use when ordering the results.
   * @param {Number} opts.page A page number within the paginated result set.
   * @param {Number} opts.page_size Number of results to return per page.
   * @param {String} opts.search A search term.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PaginatedDataProcessorConcurrentStructureList} and HTTP response
   */
  dataProcessorsConcurrentStructuresListWithHttpInfo(data_processor_id, opts) {
    opts = opts || {};
    let postBody = null;
    // verify the required parameter 'data_processor_id' is set
    if (data_processor_id === undefined || data_processor_id === null) {
      throw new Error("Missing the required parameter 'data_processor_id' when calling dataProcessorsConcurrentStructuresList");
    }
    let pathParams = {
      'data_processor_id': data_processor_id
    };
    let queryParams = {
      'ordering': opts['ordering'],
      'page': opts['page'],
      'page_size': opts['page_size'],
      'search': opts['search']
    };
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _PaginatedDataProcessorConcurrentStructureList.default;
    return this.apiClient.callApi('/api/data_processors/{data_processor_id}/concurrent_structures/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} data_processor_id 
   * @param {Object} opts Optional parameters
   * @param {String} opts.ordering Which field to use when ordering the results.
   * @param {Number} opts.page A page number within the paginated result set.
   * @param {Number} opts.page_size Number of results to return per page.
   * @param {String} opts.search A search term.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PaginatedDataProcessorConcurrentStructureList}
   */
  dataProcessorsConcurrentStructuresList(data_processor_id, opts) {
    return this.dataProcessorsConcurrentStructuresListWithHttpInfo(data_processor_id, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} data_processor_id 
   * @param {Number} id A unique integer value identifying this DataProcessorConcurrentStructure.
   * @param {Object} opts Optional parameters
   * @param {module:model/PatchedDataProcessorConcurrentStructureRequest} opts.patched_data_processor_concurrent_structure_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DataProcessorConcurrentStructure} and HTTP response
   */
  dataProcessorsConcurrentStructuresPartialUpdateWithHttpInfo(data_processor_id, id, opts) {
    opts = opts || {};
    let postBody = opts['patched_data_processor_concurrent_structure_request'];
    // verify the required parameter 'data_processor_id' is set
    if (data_processor_id === undefined || data_processor_id === null) {
      throw new Error("Missing the required parameter 'data_processor_id' when calling dataProcessorsConcurrentStructuresPartialUpdate");
    }
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling dataProcessorsConcurrentStructuresPartialUpdate");
    }
    let pathParams = {
      'data_processor_id': data_processor_id,
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _DataProcessorConcurrentStructure.default;
    return this.apiClient.callApi('/api/data_processors/{data_processor_id}/concurrent_structures/{id}/', 'PATCH', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} data_processor_id 
   * @param {Number} id A unique integer value identifying this DataProcessorConcurrentStructure.
   * @param {Object} opts Optional parameters
   * @param {module:model/PatchedDataProcessorConcurrentStructureRequest} opts.patched_data_processor_concurrent_structure_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DataProcessorConcurrentStructure}
   */
  dataProcessorsConcurrentStructuresPartialUpdate(data_processor_id, id, opts) {
    return this.dataProcessorsConcurrentStructuresPartialUpdateWithHttpInfo(data_processor_id, id, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} data_processor_id 
   * @param {Number} id A unique integer value identifying this DataProcessorConcurrentStructure.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DataProcessorConcurrentStructure} and HTTP response
   */
  dataProcessorsConcurrentStructuresRetrieveWithHttpInfo(data_processor_id, id) {
    let postBody = null;
    // verify the required parameter 'data_processor_id' is set
    if (data_processor_id === undefined || data_processor_id === null) {
      throw new Error("Missing the required parameter 'data_processor_id' when calling dataProcessorsConcurrentStructuresRetrieve");
    }
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling dataProcessorsConcurrentStructuresRetrieve");
    }
    let pathParams = {
      'data_processor_id': data_processor_id,
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _DataProcessorConcurrentStructure.default;
    return this.apiClient.callApi('/api/data_processors/{data_processor_id}/concurrent_structures/{id}/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} data_processor_id 
   * @param {Number} id A unique integer value identifying this DataProcessorConcurrentStructure.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DataProcessorConcurrentStructure}
   */
  dataProcessorsConcurrentStructuresRetrieve(data_processor_id, id) {
    return this.dataProcessorsConcurrentStructuresRetrieveWithHttpInfo(data_processor_id, id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} data_processor_id 
   * @param {Number} id A unique integer value identifying this DataProcessorConcurrentStructure.
   * @param {module:model/DataProcessorConcurrentStructureRequest} data_processor_concurrent_structure_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DataProcessorConcurrentStructure} and HTTP response
   */
  dataProcessorsConcurrentStructuresUpdateWithHttpInfo(data_processor_id, id, data_processor_concurrent_structure_request) {
    let postBody = data_processor_concurrent_structure_request;
    // verify the required parameter 'data_processor_id' is set
    if (data_processor_id === undefined || data_processor_id === null) {
      throw new Error("Missing the required parameter 'data_processor_id' when calling dataProcessorsConcurrentStructuresUpdate");
    }
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling dataProcessorsConcurrentStructuresUpdate");
    }
    // verify the required parameter 'data_processor_concurrent_structure_request' is set
    if (data_processor_concurrent_structure_request === undefined || data_processor_concurrent_structure_request === null) {
      throw new Error("Missing the required parameter 'data_processor_concurrent_structure_request' when calling dataProcessorsConcurrentStructuresUpdate");
    }
    let pathParams = {
      'data_processor_id': data_processor_id,
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _DataProcessorConcurrentStructure.default;
    return this.apiClient.callApi('/api/data_processors/{data_processor_id}/concurrent_structures/{id}/', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} data_processor_id 
   * @param {Number} id A unique integer value identifying this DataProcessorConcurrentStructure.
   * @param {module:model/DataProcessorConcurrentStructureRequest} data_processor_concurrent_structure_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DataProcessorConcurrentStructure}
   */
  dataProcessorsConcurrentStructuresUpdate(data_processor_id, id, data_processor_concurrent_structure_request) {
    return this.dataProcessorsConcurrentStructuresUpdateWithHttpInfo(data_processor_id, id, data_processor_concurrent_structure_request).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} data_processor_id 
   * @param {module:model/DataProcessorControllerRequest} data_processor_controller_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DataProcessorController} and HTTP response
   */
  dataProcessorsControllersCreateWithHttpInfo(data_processor_id, data_processor_controller_request) {
    let postBody = data_processor_controller_request;
    // verify the required parameter 'data_processor_id' is set
    if (data_processor_id === undefined || data_processor_id === null) {
      throw new Error("Missing the required parameter 'data_processor_id' when calling dataProcessorsControllersCreate");
    }
    // verify the required parameter 'data_processor_controller_request' is set
    if (data_processor_controller_request === undefined || data_processor_controller_request === null) {
      throw new Error("Missing the required parameter 'data_processor_controller_request' when calling dataProcessorsControllersCreate");
    }
    let pathParams = {
      'data_processor_id': data_processor_id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _DataProcessorController.default;
    return this.apiClient.callApi('/api/data_processors/{data_processor_id}/controllers/', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} data_processor_id 
   * @param {module:model/DataProcessorControllerRequest} data_processor_controller_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DataProcessorController}
   */
  dataProcessorsControllersCreate(data_processor_id, data_processor_controller_request) {
    return this.dataProcessorsControllersCreateWithHttpInfo(data_processor_id, data_processor_controller_request).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} data_processor_id 
   * @param {Number} id A unique integer value identifying this DataProcessorController.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  dataProcessorsControllersDestroyWithHttpInfo(data_processor_id, id) {
    let postBody = null;
    // verify the required parameter 'data_processor_id' is set
    if (data_processor_id === undefined || data_processor_id === null) {
      throw new Error("Missing the required parameter 'data_processor_id' when calling dataProcessorsControllersDestroy");
    }
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling dataProcessorsControllersDestroy");
    }
    let pathParams = {
      'data_processor_id': data_processor_id,
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/api/data_processors/{data_processor_id}/controllers/{id}/', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} data_processor_id 
   * @param {Number} id A unique integer value identifying this DataProcessorController.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  dataProcessorsControllersDestroy(data_processor_id, id) {
    return this.dataProcessorsControllersDestroyWithHttpInfo(data_processor_id, id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} data_processor_id 
   * @param {Object} opts Optional parameters
   * @param {String} opts.ordering Which field to use when ordering the results.
   * @param {Number} opts.page A page number within the paginated result set.
   * @param {Number} opts.page_size Number of results to return per page.
   * @param {String} opts.search A search term.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PaginatedDataProcessorControllerList} and HTTP response
   */
  dataProcessorsControllersListWithHttpInfo(data_processor_id, opts) {
    opts = opts || {};
    let postBody = null;
    // verify the required parameter 'data_processor_id' is set
    if (data_processor_id === undefined || data_processor_id === null) {
      throw new Error("Missing the required parameter 'data_processor_id' when calling dataProcessorsControllersList");
    }
    let pathParams = {
      'data_processor_id': data_processor_id
    };
    let queryParams = {
      'ordering': opts['ordering'],
      'page': opts['page'],
      'page_size': opts['page_size'],
      'search': opts['search']
    };
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _PaginatedDataProcessorControllerList.default;
    return this.apiClient.callApi('/api/data_processors/{data_processor_id}/controllers/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} data_processor_id 
   * @param {Object} opts Optional parameters
   * @param {String} opts.ordering Which field to use when ordering the results.
   * @param {Number} opts.page A page number within the paginated result set.
   * @param {Number} opts.page_size Number of results to return per page.
   * @param {String} opts.search A search term.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PaginatedDataProcessorControllerList}
   */
  dataProcessorsControllersList(data_processor_id, opts) {
    return this.dataProcessorsControllersListWithHttpInfo(data_processor_id, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} data_processor_id 
   * @param {Number} id A unique integer value identifying this DataProcessorController.
   * @param {Object} opts Optional parameters
   * @param {module:model/PatchedDataProcessorControllerRequest} opts.patched_data_processor_controller_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DataProcessorController} and HTTP response
   */
  dataProcessorsControllersPartialUpdateWithHttpInfo(data_processor_id, id, opts) {
    opts = opts || {};
    let postBody = opts['patched_data_processor_controller_request'];
    // verify the required parameter 'data_processor_id' is set
    if (data_processor_id === undefined || data_processor_id === null) {
      throw new Error("Missing the required parameter 'data_processor_id' when calling dataProcessorsControllersPartialUpdate");
    }
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling dataProcessorsControllersPartialUpdate");
    }
    let pathParams = {
      'data_processor_id': data_processor_id,
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _DataProcessorController.default;
    return this.apiClient.callApi('/api/data_processors/{data_processor_id}/controllers/{id}/', 'PATCH', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} data_processor_id 
   * @param {Number} id A unique integer value identifying this DataProcessorController.
   * @param {Object} opts Optional parameters
   * @param {module:model/PatchedDataProcessorControllerRequest} opts.patched_data_processor_controller_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DataProcessorController}
   */
  dataProcessorsControllersPartialUpdate(data_processor_id, id, opts) {
    return this.dataProcessorsControllersPartialUpdateWithHttpInfo(data_processor_id, id, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} data_processor_id 
   * @param {Number} id A unique integer value identifying this DataProcessorController.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DataProcessorController} and HTTP response
   */
  dataProcessorsControllersRetrieveWithHttpInfo(data_processor_id, id) {
    let postBody = null;
    // verify the required parameter 'data_processor_id' is set
    if (data_processor_id === undefined || data_processor_id === null) {
      throw new Error("Missing the required parameter 'data_processor_id' when calling dataProcessorsControllersRetrieve");
    }
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling dataProcessorsControllersRetrieve");
    }
    let pathParams = {
      'data_processor_id': data_processor_id,
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _DataProcessorController.default;
    return this.apiClient.callApi('/api/data_processors/{data_processor_id}/controllers/{id}/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} data_processor_id 
   * @param {Number} id A unique integer value identifying this DataProcessorController.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DataProcessorController}
   */
  dataProcessorsControllersRetrieve(data_processor_id, id) {
    return this.dataProcessorsControllersRetrieveWithHttpInfo(data_processor_id, id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} data_processor_id 
   * @param {Number} id A unique integer value identifying this DataProcessorController.
   * @param {module:model/DataProcessorControllerRequest} data_processor_controller_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DataProcessorController} and HTTP response
   */
  dataProcessorsControllersUpdateWithHttpInfo(data_processor_id, id, data_processor_controller_request) {
    let postBody = data_processor_controller_request;
    // verify the required parameter 'data_processor_id' is set
    if (data_processor_id === undefined || data_processor_id === null) {
      throw new Error("Missing the required parameter 'data_processor_id' when calling dataProcessorsControllersUpdate");
    }
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling dataProcessorsControllersUpdate");
    }
    // verify the required parameter 'data_processor_controller_request' is set
    if (data_processor_controller_request === undefined || data_processor_controller_request === null) {
      throw new Error("Missing the required parameter 'data_processor_controller_request' when calling dataProcessorsControllersUpdate");
    }
    let pathParams = {
      'data_processor_id': data_processor_id,
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _DataProcessorController.default;
    return this.apiClient.callApi('/api/data_processors/{data_processor_id}/controllers/{id}/', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} data_processor_id 
   * @param {Number} id A unique integer value identifying this DataProcessorController.
   * @param {module:model/DataProcessorControllerRequest} data_processor_controller_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DataProcessorController}
   */
  dataProcessorsControllersUpdate(data_processor_id, id, data_processor_controller_request) {
    return this.dataProcessorsControllersUpdateWithHttpInfo(data_processor_id, id, data_processor_controller_request).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {module:model/DataProcessorRequest} data_processor_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DataProcessor} and HTTP response
   */
  dataProcessorsCreateWithHttpInfo(data_processor_request) {
    let postBody = data_processor_request;
    // verify the required parameter 'data_processor_request' is set
    if (data_processor_request === undefined || data_processor_request === null) {
      throw new Error("Missing the required parameter 'data_processor_request' when calling dataProcessorsCreate");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _DataProcessor.default;
    return this.apiClient.callApi('/api/data_processors/', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {module:model/DataProcessorRequest} data_processor_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DataProcessor}
   */
  dataProcessorsCreate(data_processor_request) {
    return this.dataProcessorsCreateWithHttpInfo(data_processor_request).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} id A unique integer value identifying this DataProcessor.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  dataProcessorsDestroyWithHttpInfo(id) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling dataProcessorsDestroy");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/api/data_processors/{id}/', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} id A unique integer value identifying this DataProcessor.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  dataProcessorsDestroy(id) {
    return this.dataProcessorsDestroyWithHttpInfo(id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} data_processor_id 
   * @param {module:model/DataProcessorInternalReferenceStructureRequest} data_processor_internal_reference_structure_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DataProcessorInternalReferenceStructure} and HTTP response
   */
  dataProcessorsInternalStructuresCreateWithHttpInfo(data_processor_id, data_processor_internal_reference_structure_request) {
    let postBody = data_processor_internal_reference_structure_request;
    // verify the required parameter 'data_processor_id' is set
    if (data_processor_id === undefined || data_processor_id === null) {
      throw new Error("Missing the required parameter 'data_processor_id' when calling dataProcessorsInternalStructuresCreate");
    }
    // verify the required parameter 'data_processor_internal_reference_structure_request' is set
    if (data_processor_internal_reference_structure_request === undefined || data_processor_internal_reference_structure_request === null) {
      throw new Error("Missing the required parameter 'data_processor_internal_reference_structure_request' when calling dataProcessorsInternalStructuresCreate");
    }
    let pathParams = {
      'data_processor_id': data_processor_id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _DataProcessorInternalReferenceStructure.default;
    return this.apiClient.callApi('/api/data_processors/{data_processor_id}/internal_structures/', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} data_processor_id 
   * @param {module:model/DataProcessorInternalReferenceStructureRequest} data_processor_internal_reference_structure_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DataProcessorInternalReferenceStructure}
   */
  dataProcessorsInternalStructuresCreate(data_processor_id, data_processor_internal_reference_structure_request) {
    return this.dataProcessorsInternalStructuresCreateWithHttpInfo(data_processor_id, data_processor_internal_reference_structure_request).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} data_processor_id 
   * @param {Number} id A unique integer value identifying this DataProcessorInternalReferenceStructure.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  dataProcessorsInternalStructuresDestroyWithHttpInfo(data_processor_id, id) {
    let postBody = null;
    // verify the required parameter 'data_processor_id' is set
    if (data_processor_id === undefined || data_processor_id === null) {
      throw new Error("Missing the required parameter 'data_processor_id' when calling dataProcessorsInternalStructuresDestroy");
    }
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling dataProcessorsInternalStructuresDestroy");
    }
    let pathParams = {
      'data_processor_id': data_processor_id,
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/api/data_processors/{data_processor_id}/internal_structures/{id}/', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} data_processor_id 
   * @param {Number} id A unique integer value identifying this DataProcessorInternalReferenceStructure.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  dataProcessorsInternalStructuresDestroy(data_processor_id, id) {
    return this.dataProcessorsInternalStructuresDestroyWithHttpInfo(data_processor_id, id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} data_processor_id 
   * @param {Object} opts Optional parameters
   * @param {String} opts.ordering Which field to use when ordering the results.
   * @param {Number} opts.page A page number within the paginated result set.
   * @param {Number} opts.page_size Number of results to return per page.
   * @param {String} opts.search A search term.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PaginatedDataProcessorInternalReferenceStructureList} and HTTP response
   */
  dataProcessorsInternalStructuresListWithHttpInfo(data_processor_id, opts) {
    opts = opts || {};
    let postBody = null;
    // verify the required parameter 'data_processor_id' is set
    if (data_processor_id === undefined || data_processor_id === null) {
      throw new Error("Missing the required parameter 'data_processor_id' when calling dataProcessorsInternalStructuresList");
    }
    let pathParams = {
      'data_processor_id': data_processor_id
    };
    let queryParams = {
      'ordering': opts['ordering'],
      'page': opts['page'],
      'page_size': opts['page_size'],
      'search': opts['search']
    };
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _PaginatedDataProcessorInternalReferenceStructureList.default;
    return this.apiClient.callApi('/api/data_processors/{data_processor_id}/internal_structures/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} data_processor_id 
   * @param {Object} opts Optional parameters
   * @param {String} opts.ordering Which field to use when ordering the results.
   * @param {Number} opts.page A page number within the paginated result set.
   * @param {Number} opts.page_size Number of results to return per page.
   * @param {String} opts.search A search term.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PaginatedDataProcessorInternalReferenceStructureList}
   */
  dataProcessorsInternalStructuresList(data_processor_id, opts) {
    return this.dataProcessorsInternalStructuresListWithHttpInfo(data_processor_id, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} data_processor_id 
   * @param {Number} id A unique integer value identifying this DataProcessorInternalReferenceStructure.
   * @param {Object} opts Optional parameters
   * @param {module:model/PatchedDataProcessorInternalReferenceStructureRequest} opts.patched_data_processor_internal_reference_structure_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DataProcessorInternalReferenceStructure} and HTTP response
   */
  dataProcessorsInternalStructuresPartialUpdateWithHttpInfo(data_processor_id, id, opts) {
    opts = opts || {};
    let postBody = opts['patched_data_processor_internal_reference_structure_request'];
    // verify the required parameter 'data_processor_id' is set
    if (data_processor_id === undefined || data_processor_id === null) {
      throw new Error("Missing the required parameter 'data_processor_id' when calling dataProcessorsInternalStructuresPartialUpdate");
    }
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling dataProcessorsInternalStructuresPartialUpdate");
    }
    let pathParams = {
      'data_processor_id': data_processor_id,
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _DataProcessorInternalReferenceStructure.default;
    return this.apiClient.callApi('/api/data_processors/{data_processor_id}/internal_structures/{id}/', 'PATCH', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} data_processor_id 
   * @param {Number} id A unique integer value identifying this DataProcessorInternalReferenceStructure.
   * @param {Object} opts Optional parameters
   * @param {module:model/PatchedDataProcessorInternalReferenceStructureRequest} opts.patched_data_processor_internal_reference_structure_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DataProcessorInternalReferenceStructure}
   */
  dataProcessorsInternalStructuresPartialUpdate(data_processor_id, id, opts) {
    return this.dataProcessorsInternalStructuresPartialUpdateWithHttpInfo(data_processor_id, id, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} data_processor_id 
   * @param {Number} id A unique integer value identifying this DataProcessorInternalReferenceStructure.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DataProcessorInternalReferenceStructure} and HTTP response
   */
  dataProcessorsInternalStructuresRetrieveWithHttpInfo(data_processor_id, id) {
    let postBody = null;
    // verify the required parameter 'data_processor_id' is set
    if (data_processor_id === undefined || data_processor_id === null) {
      throw new Error("Missing the required parameter 'data_processor_id' when calling dataProcessorsInternalStructuresRetrieve");
    }
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling dataProcessorsInternalStructuresRetrieve");
    }
    let pathParams = {
      'data_processor_id': data_processor_id,
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _DataProcessorInternalReferenceStructure.default;
    return this.apiClient.callApi('/api/data_processors/{data_processor_id}/internal_structures/{id}/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} data_processor_id 
   * @param {Number} id A unique integer value identifying this DataProcessorInternalReferenceStructure.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DataProcessorInternalReferenceStructure}
   */
  dataProcessorsInternalStructuresRetrieve(data_processor_id, id) {
    return this.dataProcessorsInternalStructuresRetrieveWithHttpInfo(data_processor_id, id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} data_processor_id 
   * @param {Number} id A unique integer value identifying this DataProcessorInternalReferenceStructure.
   * @param {module:model/DataProcessorInternalReferenceStructureRequest} data_processor_internal_reference_structure_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DataProcessorInternalReferenceStructure} and HTTP response
   */
  dataProcessorsInternalStructuresUpdateWithHttpInfo(data_processor_id, id, data_processor_internal_reference_structure_request) {
    let postBody = data_processor_internal_reference_structure_request;
    // verify the required parameter 'data_processor_id' is set
    if (data_processor_id === undefined || data_processor_id === null) {
      throw new Error("Missing the required parameter 'data_processor_id' when calling dataProcessorsInternalStructuresUpdate");
    }
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling dataProcessorsInternalStructuresUpdate");
    }
    // verify the required parameter 'data_processor_internal_reference_structure_request' is set
    if (data_processor_internal_reference_structure_request === undefined || data_processor_internal_reference_structure_request === null) {
      throw new Error("Missing the required parameter 'data_processor_internal_reference_structure_request' when calling dataProcessorsInternalStructuresUpdate");
    }
    let pathParams = {
      'data_processor_id': data_processor_id,
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _DataProcessorInternalReferenceStructure.default;
    return this.apiClient.callApi('/api/data_processors/{data_processor_id}/internal_structures/{id}/', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} data_processor_id 
   * @param {Number} id A unique integer value identifying this DataProcessorInternalReferenceStructure.
   * @param {module:model/DataProcessorInternalReferenceStructureRequest} data_processor_internal_reference_structure_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DataProcessorInternalReferenceStructure}
   */
  dataProcessorsInternalStructuresUpdate(data_processor_id, id, data_processor_internal_reference_structure_request) {
    return this.dataProcessorsInternalStructuresUpdateWithHttpInfo(data_processor_id, id, data_processor_internal_reference_structure_request).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Object} opts Optional parameters
   * @param {String} opts.ordering Which field to use when ordering the results.
   * @param {Number} opts.page A page number within the paginated result set.
   * @param {Number} opts.page_size Number of results to return per page.
   * @param {String} opts.search A search term.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PaginatedDataProcessorList} and HTTP response
   */
  dataProcessorsListWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = null;
    let pathParams = {};
    let queryParams = {
      'ordering': opts['ordering'],
      'page': opts['page'],
      'page_size': opts['page_size'],
      'search': opts['search']
    };
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _PaginatedDataProcessorList.default;
    return this.apiClient.callApi('/api/data_processors/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Object} opts Optional parameters
   * @param {String} opts.ordering Which field to use when ordering the results.
   * @param {Number} opts.page A page number within the paginated result set.
   * @param {Number} opts.page_size Number of results to return per page.
   * @param {String} opts.search A search term.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PaginatedDataProcessorList}
   */
  dataProcessorsList(opts) {
    return this.dataProcessorsListWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} id A unique integer value identifying this DataProcessor.
   * @param {Object} opts Optional parameters
   * @param {module:model/PatchedDataProcessorRequest} opts.patched_data_processor_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DataProcessor} and HTTP response
   */
  dataProcessorsPartialUpdateWithHttpInfo(id, opts) {
    opts = opts || {};
    let postBody = opts['patched_data_processor_request'];
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling dataProcessorsPartialUpdate");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _DataProcessor.default;
    return this.apiClient.callApi('/api/data_processors/{id}/', 'PATCH', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} id A unique integer value identifying this DataProcessor.
   * @param {Object} opts Optional parameters
   * @param {module:model/PatchedDataProcessorRequest} opts.patched_data_processor_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DataProcessor}
   */
  dataProcessorsPartialUpdate(id, opts) {
    return this.dataProcessorsPartialUpdateWithHttpInfo(id, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} id A unique integer value identifying this DataProcessor.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DataProcessor} and HTTP response
   */
  dataProcessorsRetrieveWithHttpInfo(id) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling dataProcessorsRetrieve");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _DataProcessor.default;
    return this.apiClient.callApi('/api/data_processors/{id}/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} id A unique integer value identifying this DataProcessor.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DataProcessor}
   */
  dataProcessorsRetrieve(id) {
    return this.dataProcessorsRetrieveWithHttpInfo(id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} id A unique integer value identifying this DataProcessor.
   * @param {module:model/DataProcessorRequest} data_processor_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DataProcessor} and HTTP response
   */
  dataProcessorsUpdateWithHttpInfo(id, data_processor_request) {
    let postBody = data_processor_request;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling dataProcessorsUpdate");
    }
    // verify the required parameter 'data_processor_request' is set
    if (data_processor_request === undefined || data_processor_request === null) {
      throw new Error("Missing the required parameter 'data_processor_request' when calling dataProcessorsUpdate");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _DataProcessor.default;
    return this.apiClient.callApi('/api/data_processors/{id}/', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} id A unique integer value identifying this DataProcessor.
   * @param {module:model/DataProcessorRequest} data_processor_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DataProcessor}
   */
  dataProcessorsUpdate(id, data_processor_request) {
    return this.dataProcessorsUpdateWithHttpInfo(id, data_processor_request).then(function (response_and_data) {
      return response_and_data.data;
    });
  }
}
exports.default = DataProcessorsApi;