"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _InvitationRead = _interopRequireDefault(require("../model/InvitationRead"));
var _InvitationWrite = _interopRequireDefault(require("../model/InvitationWrite"));
var _InvitationWriteRequest = _interopRequireDefault(require("../model/InvitationWriteRequest"));
var _Organization = _interopRequireDefault(require("../model/Organization"));
var _OrganizationRequest = _interopRequireDefault(require("../model/OrganizationRequest"));
var _PaginatedInvitationReadList = _interopRequireDefault(require("../model/PaginatedInvitationReadList"));
var _PaginatedOrganizationList = _interopRequireDefault(require("../model/PaginatedOrganizationList"));
var _PatchedOrganizationRequest = _interopRequireDefault(require("../model/PatchedOrganizationRequest"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * NXPrivacy API
 * REST API for NXPrivacy
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
* Organizations service.
* @module api/OrganizationsApi
* @version 1.0.0
*/
class OrganizationsApi {
  /**
  * Constructs a new OrganizationsApi. 
  * @alias module:api/OrganizationsApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || _ApiClient.default.instance;
  }

  /**
   * @param {module:model/OrganizationRequest} organization_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Organization} and HTTP response
   */
  organizationsCreateWithHttpInfo(organization_request) {
    let postBody = organization_request;
    // verify the required parameter 'organization_request' is set
    if (organization_request === undefined || organization_request === null) {
      throw new Error("Missing the required parameter 'organization_request' when calling organizationsCreate");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _Organization.default;
    return this.apiClient.callApi('/api/organizations/', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {module:model/OrganizationRequest} organization_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Organization}
   */
  organizationsCreate(organization_request) {
    return this.organizationsCreateWithHttpInfo(organization_request).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {String} organization_oid 
   * @param {module:model/InvitationWriteRequest} invitation_write_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InvitationWrite} and HTTP response
   */
  organizationsInvitationsCreateAndSendCreateWithHttpInfo(organization_oid, invitation_write_request) {
    let postBody = invitation_write_request;
    // verify the required parameter 'organization_oid' is set
    if (organization_oid === undefined || organization_oid === null) {
      throw new Error("Missing the required parameter 'organization_oid' when calling organizationsInvitationsCreateAndSendCreate");
    }
    // verify the required parameter 'invitation_write_request' is set
    if (invitation_write_request === undefined || invitation_write_request === null) {
      throw new Error("Missing the required parameter 'invitation_write_request' when calling organizationsInvitationsCreateAndSendCreate");
    }
    let pathParams = {
      'organization_oid': organization_oid
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _InvitationWrite.default;
    return this.apiClient.callApi('/api/organizations/{organization_oid}/invitations/create-and-send/', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {String} organization_oid 
   * @param {module:model/InvitationWriteRequest} invitation_write_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InvitationWrite}
   */
  organizationsInvitationsCreateAndSendCreate(organization_oid, invitation_write_request) {
    return this.organizationsInvitationsCreateAndSendCreateWithHttpInfo(organization_oid, invitation_write_request).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} id A unique integer value identifying this rest invitation.
   * @param {String} organization_oid 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  organizationsInvitationsDestroyWithHttpInfo(id, organization_oid) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling organizationsInvitationsDestroy");
    }
    // verify the required parameter 'organization_oid' is set
    if (organization_oid === undefined || organization_oid === null) {
      throw new Error("Missing the required parameter 'organization_oid' when calling organizationsInvitationsDestroy");
    }
    let pathParams = {
      'id': id,
      'organization_oid': organization_oid
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/api/organizations/{organization_oid}/invitations/{id}/', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} id A unique integer value identifying this rest invitation.
   * @param {String} organization_oid 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  organizationsInvitationsDestroy(id, organization_oid) {
    return this.organizationsInvitationsDestroyWithHttpInfo(id, organization_oid).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {String} organization_oid 
   * @param {Object} opts Optional parameters
   * @param {Number} opts.page A page number within the paginated result set.
   * @param {Number} opts.page_size Number of results to return per page.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PaginatedInvitationReadList} and HTTP response
   */
  organizationsInvitationsListWithHttpInfo(organization_oid, opts) {
    opts = opts || {};
    let postBody = null;
    // verify the required parameter 'organization_oid' is set
    if (organization_oid === undefined || organization_oid === null) {
      throw new Error("Missing the required parameter 'organization_oid' when calling organizationsInvitationsList");
    }
    let pathParams = {
      'organization_oid': organization_oid
    };
    let queryParams = {
      'page': opts['page'],
      'page_size': opts['page_size']
    };
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _PaginatedInvitationReadList.default;
    return this.apiClient.callApi('/api/organizations/{organization_oid}/invitations/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {String} organization_oid 
   * @param {Object} opts Optional parameters
   * @param {Number} opts.page A page number within the paginated result set.
   * @param {Number} opts.page_size Number of results to return per page.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PaginatedInvitationReadList}
   */
  organizationsInvitationsList(organization_oid, opts) {
    return this.organizationsInvitationsListWithHttpInfo(organization_oid, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} id A unique integer value identifying this rest invitation.
   * @param {String} organization_oid 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InvitationRead} and HTTP response
   */
  organizationsInvitationsRetrieveWithHttpInfo(id, organization_oid) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling organizationsInvitationsRetrieve");
    }
    // verify the required parameter 'organization_oid' is set
    if (organization_oid === undefined || organization_oid === null) {
      throw new Error("Missing the required parameter 'organization_oid' when calling organizationsInvitationsRetrieve");
    }
    let pathParams = {
      'id': id,
      'organization_oid': organization_oid
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _InvitationRead.default;
    return this.apiClient.callApi('/api/organizations/{organization_oid}/invitations/{id}/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} id A unique integer value identifying this rest invitation.
   * @param {String} organization_oid 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InvitationRead}
   */
  organizationsInvitationsRetrieve(id, organization_oid) {
    return this.organizationsInvitationsRetrieveWithHttpInfo(id, organization_oid).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {String} organization_oid 
   * @param {module:model/InvitationWriteRequest} invitation_write_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InvitationWrite} and HTTP response
   */
  organizationsInvitationsSendMultipleCreateWithHttpInfo(organization_oid, invitation_write_request) {
    let postBody = invitation_write_request;
    // verify the required parameter 'organization_oid' is set
    if (organization_oid === undefined || organization_oid === null) {
      throw new Error("Missing the required parameter 'organization_oid' when calling organizationsInvitationsSendMultipleCreate");
    }
    // verify the required parameter 'invitation_write_request' is set
    if (invitation_write_request === undefined || invitation_write_request === null) {
      throw new Error("Missing the required parameter 'invitation_write_request' when calling organizationsInvitationsSendMultipleCreate");
    }
    let pathParams = {
      'organization_oid': organization_oid
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _InvitationWrite.default;
    return this.apiClient.callApi('/api/organizations/{organization_oid}/invitations/send-multiple/', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {String} organization_oid 
   * @param {module:model/InvitationWriteRequest} invitation_write_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InvitationWrite}
   */
  organizationsInvitationsSendMultipleCreate(organization_oid, invitation_write_request) {
    return this.organizationsInvitationsSendMultipleCreateWithHttpInfo(organization_oid, invitation_write_request).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Object} opts Optional parameters
   * @param {String} opts.ordering Which field to use when ordering the results.
   * @param {Number} opts.page A page number within the paginated result set.
   * @param {Number} opts.page_size Number of results to return per page.
   * @param {String} opts.search A search term.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PaginatedOrganizationList} and HTTP response
   */
  organizationsListWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = null;
    let pathParams = {};
    let queryParams = {
      'ordering': opts['ordering'],
      'page': opts['page'],
      'page_size': opts['page_size'],
      'search': opts['search']
    };
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _PaginatedOrganizationList.default;
    return this.apiClient.callApi('/api/organizations/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Object} opts Optional parameters
   * @param {String} opts.ordering Which field to use when ordering the results.
   * @param {Number} opts.page A page number within the paginated result set.
   * @param {Number} opts.page_size Number of results to return per page.
   * @param {String} opts.search A search term.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PaginatedOrganizationList}
   */
  organizationsList(opts) {
    return this.organizationsListWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {String} oid 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Organization} and HTTP response
   */
  organizationsMembersRetrieveWithHttpInfo(oid) {
    let postBody = null;
    // verify the required parameter 'oid' is set
    if (oid === undefined || oid === null) {
      throw new Error("Missing the required parameter 'oid' when calling organizationsMembersRetrieve");
    }
    let pathParams = {
      'oid': oid
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _Organization.default;
    return this.apiClient.callApi('/api/organizations/{oid}/members/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {String} oid 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Organization}
   */
  organizationsMembersRetrieve(oid) {
    return this.organizationsMembersRetrieveWithHttpInfo(oid).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {String} oid 
   * @param {Object} opts Optional parameters
   * @param {module:model/PatchedOrganizationRequest} opts.patched_organization_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Organization} and HTTP response
   */
  organizationsPartialUpdateWithHttpInfo(oid, opts) {
    opts = opts || {};
    let postBody = opts['patched_organization_request'];
    // verify the required parameter 'oid' is set
    if (oid === undefined || oid === null) {
      throw new Error("Missing the required parameter 'oid' when calling organizationsPartialUpdate");
    }
    let pathParams = {
      'oid': oid
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _Organization.default;
    return this.apiClient.callApi('/api/organizations/{oid}/', 'PATCH', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {String} oid 
   * @param {Object} opts Optional parameters
   * @param {module:model/PatchedOrganizationRequest} opts.patched_organization_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Organization}
   */
  organizationsPartialUpdate(oid, opts) {
    return this.organizationsPartialUpdateWithHttpInfo(oid, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {String} oid 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Organization} and HTTP response
   */
  organizationsRetrieveWithHttpInfo(oid) {
    let postBody = null;
    // verify the required parameter 'oid' is set
    if (oid === undefined || oid === null) {
      throw new Error("Missing the required parameter 'oid' when calling organizationsRetrieve");
    }
    let pathParams = {
      'oid': oid
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _Organization.default;
    return this.apiClient.callApi('/api/organizations/{oid}/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {String} oid 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Organization}
   */
  organizationsRetrieve(oid) {
    return this.organizationsRetrieveWithHttpInfo(oid).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {String} oid 
   * @param {module:model/OrganizationRequest} organization_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Organization} and HTTP response
   */
  organizationsUpdateWithHttpInfo(oid, organization_request) {
    let postBody = organization_request;
    // verify the required parameter 'oid' is set
    if (oid === undefined || oid === null) {
      throw new Error("Missing the required parameter 'oid' when calling organizationsUpdate");
    }
    // verify the required parameter 'organization_request' is set
    if (organization_request === undefined || organization_request === null) {
      throw new Error("Missing the required parameter 'organization_request' when calling organizationsUpdate");
    }
    let pathParams = {
      'oid': oid
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _Organization.default;
    return this.apiClient.callApi('/api/organizations/{oid}/', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {String} oid 
   * @param {module:model/OrganizationRequest} organization_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Organization}
   */
  organizationsUpdate(oid, organization_request) {
    return this.organizationsUpdateWithHttpInfo(oid, organization_request).then(function (response_and_data) {
      return response_and_data.data;
    });
  }
}
exports.default = OrganizationsApi;