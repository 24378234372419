"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * NXPrivacy API
 * REST API for NXPrivacy
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The CustomRegisterRequest model module.
 * @module model/CustomRegisterRequest
 * @version 1.0.0
 */
class CustomRegisterRequest {
  /**
   * Constructs a new <code>CustomRegisterRequest</code>.
   * @alias module:model/CustomRegisterRequest
   * @param email {String} 
   * @param password1 {String} 
   * @param password2 {String} 
   */
  constructor(email, password1, password2) {
    CustomRegisterRequest.initialize(this, email, password1, password2);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, email, password1, password2) {
    obj['email'] = email;
    obj['password1'] = password1;
    obj['password2'] = password2;
  }

  /**
   * Constructs a <code>CustomRegisterRequest</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/CustomRegisterRequest} obj Optional instance to populate.
   * @return {module:model/CustomRegisterRequest} The populated <code>CustomRegisterRequest</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CustomRegisterRequest();
      if (data.hasOwnProperty('email')) {
        obj['email'] = _ApiClient.default.convertToType(data['email'], 'String');
      }
      if (data.hasOwnProperty('password1')) {
        obj['password1'] = _ApiClient.default.convertToType(data['password1'], 'String');
      }
      if (data.hasOwnProperty('password2')) {
        obj['password2'] = _ApiClient.default.convertToType(data['password2'], 'String');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>CustomRegisterRequest</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>CustomRegisterRequest</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of CustomRegisterRequest.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['email'] && !(typeof data['email'] === 'string' || data['email'] instanceof String)) {
      throw new Error("Expected the field `email` to be a primitive type in the JSON string but got " + data['email']);
    }
    // ensure the json data is a string
    if (data['password1'] && !(typeof data['password1'] === 'string' || data['password1'] instanceof String)) {
      throw new Error("Expected the field `password1` to be a primitive type in the JSON string but got " + data['password1']);
    }
    // ensure the json data is a string
    if (data['password2'] && !(typeof data['password2'] === 'string' || data['password2'] instanceof String)) {
      throw new Error("Expected the field `password2` to be a primitive type in the JSON string but got " + data['password2']);
    }
    return true;
  }
}
CustomRegisterRequest.RequiredProperties = ["email", "password1", "password2"];

/**
 * @member {String} email
 */
CustomRegisterRequest.prototype['email'] = undefined;

/**
 * @member {String} password1
 */
CustomRegisterRequest.prototype['password1'] = undefined;

/**
 * @member {String} password2
 */
CustomRegisterRequest.prototype['password2'] = undefined;
var _default = exports.default = CustomRegisterRequest;