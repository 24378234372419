import axios from "axios";
import { ApiClient, BillingApi, DataControllerRegistryUpload, DataProcessorRegistryUpload, DataProcessorConcurrentStructure, DataProcessorController, DataProcessorInternalReferenceStructure, DataProcessorProcessingActivity, DataProcessorProcessingActivityExtraEUTransfer, InvitationRead, InvitationsApi, OrganizationsApi, RegistrationApi, ResendEmailVerification, VerifyEmail, PasswordChangeRequest, CustomPasswordResetRequest, PasswordResetConfirmRequest, CustomRegisterRequest, ResendEmailVerificationRequest } from "./nxprivacy";

import {
  AuthApi,
  LoginRequest,
  // Logout,
  PasswordChange,
  // UsersApi,
  UserRequest,
  //   UserDetails,
  // PatchedUserDetails,
  CustomPasswordReset,
  PasswordResetConfirm,
} from "./nxprivacy";

import {
  CustomRegister
} from "./nxprivacy";
import { DataProtectionOfficersApi, DataProtectionOfficer, Organization, InvitationWrite } from "./nxprivacy";

import {
  DataProcessorsApi,
  DataProcessor,
  DataProcessorRegistriesApi,
  DataProcessorRegistry,
} from "./nxprivacy";

import {
  DataControllersApi,
  DataController,
  DataControllerRegistriesApi,
  DataControllerRegistry,
  DataControllerProcessingActivity,
  DataControllerProcessingActivityExtraEUTransfer,
  DataControllerConcurrentStructure,
  DataControllerExternalProcessor,
  DataControllerInternalReferenceStructure
} from "./nxprivacy"

import {
  // TODO: Remove it! -- Alle
  ConcurrentStructure,
  // TODO: Remove it! -- Alle
  ExternalProcessor,
  // TODO: Remove it! -- Alle
  InternalReferenceStructure,
} from "./nxprivacy";

import {
  // TODO: Remove it! -- Alle
  //alle RegistriesApi,
  // TODO: Remove it! -- Alle
  Registry,
  // TODO: Remove it! --  Alle
  ProcessingActivity,
  // TODO: Remove it! --  Alle
  ProcessingActivityExtraEUTransfer,
} from "./nxprivacy";

import {
  ProcessingAdvancedDataTypeApi,
  ProcessingAdvancedTypeApi,
  ProcessingArtNineApi,
  ProcessingArtSixApi,
  ProcessingBasicDataTypeApi,
  ProcessingBasicTypeApi,
  ProcessingDataSubjectApi,
  ProcessingDataWarehouseApi,
  ProcessingEuTransferApi,
  ProcessingOrgMeasuresApi,
  ProcessingPurposeApi,
  ProcessingStorageLocationApi,
  // ProcessingStorageMethodApi,
  ProcessingStorageSupportApi,
  ProcessingTecMeasuresApi,
  ProcessingWarrantyApi,
  // SchemaApi,
} from "./nxprivacy";

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";

axios.defaults.timeout = 15000;

// const baseURL = "http://localhost:8000/api/";
let defaultClient = ApiClient.instance;
defaultClient.basePath = process.env.REACT_APP_API_ENDPOINT;

let authApi = new AuthApi();
let registrationApi = new RegistrationApi();
let billingApi = new BillingApi();
let organizationsApi = new OrganizationsApi();
let invitationsApi = new InvitationsApi();
let dataControllersApi = new DataControllersApi();
let dataProcessorsApi = new DataProcessorsApi();
let dataProcessorRegistriesApi = new DataProcessorRegistriesApi()
let dataControllerRegistriesApi = new DataControllerRegistriesApi()
let dataProtectionOfficersApi = new DataProtectionOfficersApi();

let processingAdvancedDataTypeApi = new ProcessingAdvancedDataTypeApi();
let processingAdvancedTypeApi = new ProcessingAdvancedTypeApi();
let processingArtNineApi = new ProcessingArtNineApi();
let processingArtSixApi = new ProcessingArtSixApi();
let processingBasicDataTypeApi = new ProcessingBasicDataTypeApi();
let processingBasicTypeApi = new ProcessingBasicTypeApi();
let processingDataSubjectApi = new ProcessingDataSubjectApi();
let processingDataWarehouseApi = new ProcessingDataWarehouseApi();
let processingEuTransferApi = new ProcessingEuTransferApi();
let processingOrgMeasuresApi = new ProcessingOrgMeasuresApi();
let processingPurposeApi = new ProcessingPurposeApi();
let processingStorageLocationApi = new ProcessingStorageLocationApi();
// let processingStorageMethodApi = new ProcessingStorageMethodApi();
let processingStorageSupportApi = new ProcessingStorageSupportApi();
let processingTecMeasuresApi = new ProcessingTecMeasuresApi();
let processingWarrantyApi = new ProcessingWarrantyApi();
// let schemaApi = new SchemaApi();
// let usersApi = new UsersApi();
const MAX_PAGE_SIZE = 500;
export const riskLevel = {
  1: "Low",
  2: "Medium",
  3: "High",
  4: "Very High",
};
export const sysAdmin = {
  NTP: "Not Present",
  INT: "Internal",
  EXT: "External",
  UNK: "Unknown",
};
export const manyToMany = [
  "concurrent_structures",
  "processing_purposes",
  "processing_types",
  "advanced_processing_types",
  "data_subjects",
  "basic_data_types",
  "special_data_categories",
  "eu_transfers",
  "storage_supports",
  "storage_locations",
  "data_warehouses",
  "technical_measures",
  "organizational_measures",
  "external_processors",
  "controllers",
];
export const oneToMany = [
  "country",
  "warranty",
  "data_controller",
  "art6_gdpr_base",
  "art9_gdpr_base",
  "system_administrator",
  "risk_level",
  "data_protection_officers",
  "internal_reference_structure",
];
export const addNewOption = [
  "concurrent_structures",
  "external_processors",
  "controllers",
  "internal_reference_structure",
  "extra_eu_transfers",
  "data_protection_officers",
];
export const booleans = [
  "is_enabled",
  "extra_eu_transfers",
  "extra_ue",
  "juridical_person",
  "pia_needed",
  "internal_instructions",
];
export const othersToAdd = [
  "other_data_subjects",
  "other_data_types",
  "other_gdpr_base",
  "other_eu_transfers",
  "other_organizational_measures",
  "other_processing_purposes",
  "other_processing_types",
  "other_storage_supports",
  "other_technical_measures",
  "other_organizational_measures",
];

// --------------- USER API ---------------
export const login = (email, password) =>
  new Promise((resolve, reject) => {
    let login = new LoginRequest(password); // Login |
    login.email = email;
    authApi.authLoginCreateWithHttpInfo(login).then(
      (data) => {
        if (!!data.response.body.key) {
          let tokenAuth = defaultClient.authentications["tokenAuth"];
          tokenAuth.apiKey = data.response.body.key;
          tokenAuth.apiKeyPrefix = "Token ";
        }
        resolve(data.response.body);
      },
      (error) => {
        reject(error);
      }
    );
  });

export const logout = () =>
  new Promise((resolve, reject) => {
    authApi.authLogoutCreate().then(
      (data) => {
        let tokenAuth = defaultClient.authentications["tokenAuth"];
        tokenAuth.apiKey = null;
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });

export const changePassword = (newPasswordState) =>
  new Promise((resolve, reject) => {
    let passwordChange = new PasswordChangeRequest(
      newPasswordState.new_password1,
      newPasswordState.new_password2
    ); // PasswordChange |
    authApi.authPasswordChangeCreate(passwordChange).then(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });

export const resetPassword = (email) =>
  new Promise((resolve, reject) => {
    let passwordReset = new CustomPasswordResetRequest(email); // PasswordReset |

    authApi.authPasswordResetCreate(passwordReset).then(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });


export const confirmResetPassword = (token, uid, password1, password2) =>
  new Promise((resolve, reject) => {
    // newPassword1
    // newPassword2
    // uid
    // token
    let passwordResetConfirm = new PasswordResetConfirmRequest(); // PasswordResetConfirm |
    passwordResetConfirm.token = token
    passwordResetConfirm.uid = uid
    passwordResetConfirm.new_password1 = password1
    passwordResetConfirm.new_password2 = password2
    authApi.authPasswordResetConfirmCreate(passwordResetConfirm).then(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });

export const getUser = () =>
  new Promise((resolve, reject) => {
    authApi.authUserRetrieve().then(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });

export const updateUser = (data) =>
  new Promise((resolve, reject) => {
    let user = new UserRequest(); // User |
    user.first_name = data.first_name;
    user.last_name = data.last_name;

    authApi.authUserUpdate({user_request:user}).then(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });

// ------------------ REGISTRATION ------------------

export const register = (email, password1, password2) =>
  new Promise((resolve, reject) => {
    let register = new CustomRegisterRequest(); // Login |
    register.email = email;
    register.password1 = password1;
    register.password2 = password2;
    registrationApi.registrationCreateWithHttpInfo(register).then(
      (data) => {
        // console.log(`DATA: -> ${data.response.body}`)
        resolve(data.response.body);
      },
      (error) => {
        reject(error);
      }
    );
  });
export const verifyEmail = (key) =>
  new Promise((resolve, reject) => {
    let verify = new VerifyEmail(); // Login |
    verify.key = key;

    registrationApi.registrationVerifyEmailCreateWithHttpInfo(verify).then(
      (data) => {
        // console.log(`DATA: -> ${data.response.body}`)
        resolve(data.response.body);
      },
      (error) => {
        reject(error);
      }
    );
  });
export const resendEmail = (email) =>
  new Promise((resolve, reject) => {
    let resend = new ResendEmailVerificationRequest(); // Login |
    resend.email = email;

    registrationApi.registrationResendEmailCreateWithHttpInfo(resend).then(
      (data) => {
        // console.log(`DATA: -> ${data.response.body}`)
        resolve(data.response.body);
      },
      (error) => {
        reject(error);
      }
    );
  });
// ------------------------- BILLING --------------------------
export const getControlPanel = () =>
  new Promise((resolve, reject) => {
    let request = new BillingApi(); // Login |

    billingApi.billingCustomerPortalCreateWithHttpInfo(request).then(
      (data) => {
        // console.log(`DATA: -> ${data.response.body}`)
        resolve(data.response.body);
      },
      (error) => {
        reject(error);
      }
    );
  });
export const getProducts = () =>
  new Promise((resolve, reject) => {
    let request = new BillingApi(); // Login |

    billingApi.billingProductsListWithHttpInfo(request).then(
      (data) => {
        // console.log(`DATA: -> ${data.response.body}`)
        resolve(data.response.body);
      },
      (error) => {
        reject(error);
      }
    );
  });
export const getUserSubscription = () =>
  new Promise((resolve, reject) => {
    let request = new BillingApi(); // Login |

    billingApi.billingSubscriptionItemsListWithHttpInfo(request).then(
      (data) => {
        // console.log(`DATA: -> ${data.response.body}`)
        resolve(data.response.body);
      },
      (error) => {
        reject(error);
      }
    );
  });
export const addToCart = (price_id) =>
  new Promise((resolve, reject) => {
    let request = new BillingApi(); // Login |
    request.price_id = price_id
    billingApi.billingCheckoutCreateWithHttpInfo(request).then(
      (data) => {
        // console.log(`DATA: -> ${data.response.body}`)
        resolve(data.response.body);
      },
      (error) => {
        reject(error);
      }
    );
  });
// ----------------------- ORGANIZATION -----------------------
export const addDirectOrganization = (data) =>
  new Promise((resolve, reject) => {
    let organization = new Organization(); // Organization |
    organization.name = data.name;
    organization.email = data.email;
    organization.family_name = data.family_name;
    organization.company_name = data.company_name;
    organization.phone_number = data.phone_number;

    organizationsApi.organizationsCreate(organization).then(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });

export const getDirectOrganizations = (
  ordering = null,
  page = null,
  page_size = MAX_PAGE_SIZE,
  search = null
) =>
  new Promise((resolve, reject) => {

    let opts = {
      ordering: ordering, // String | Which field to use when ordering the results.
      page: page, // Number | A page number within the paginated result set.
      page_size: page_size, // Number | Number of results to return per page.
      search: search, // String | A search term.
    };

    organizationsApi.organizationsList(opts).then(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });

export const getDirectOrganization = (id) =>
  new Promise((resolve, reject) => {

    organizationsApi.organizationRetrieve(id).then(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });

export const updateDirectOrganization = (id, data) =>
  new Promise((resolve, reject) => {

    let organization = new Organization(); // Organization |
    organization.name = data.name;
    organization.email = data.email;
    organization.family_name = data.family_name;
    organization.company_name = data.company_name;
    organization.phone_number = data.phone_number;

    organizationsApi.organizationsUpdate(id, organization).then(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });

export const deleteDirectOrganization = (id) =>
  new Promise((resolve, reject) => {
      // TODO api
    organizationsApi.organizationsDestroy(id).then(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });

export const getDirectOrganizationMembers = (oid) =>
  new Promise((resolve, reject) => {
    // console.log("getting", oid)
    organizationsApi.organizationsMembersRetrieveWithHttpInfo(oid).then(
      (data) => {
        // console.log("ok ", data.response.body)
        resolve(data.response.body);
      },
      (error) => {
        // console.log("error ", error)

        reject(error);
      }
    );
  });
export const inviteDirectOrganizationMember = (id, data) =>
  new Promise((resolve, reject) => {
    let invitation = new InvitationWrite(); // Organization |
    invitation.email = data.email;


    organizationsApi.organizationsInvitationsCreateAndSendCreate(id, invitation).then(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });
export const acceptInvite = (key) => new Promise((resolve, reject) => {
  let verify = new InvitationRead(); // Login |
  verify.key = key;

  invitationsApi.invitationsAcceptInviteCreateWithHttpInfo(key).then(
    (data) => {
      // console.log(`DATA: -> ${data.response.body}`)
      resolve(data.response.body);
    },
    (error) => {
      reject(error);
    }
  );
});
// --------------- DATA PROTECTION OFFICERS API ---------------
export const addDirectDataProtectionOfficer = (data) =>
  new Promise((resolve, reject) => {
    let dataProtectionOfficer = new DataProtectionOfficer(); // DataProtectionOfficer |
    dataProtectionOfficer.name = data.name;
    dataProtectionOfficer.email = data.email;
    dataProtectionOfficer.family_name = data.family_name;
    dataProtectionOfficer.company_name = data.company_name;
    dataProtectionOfficer.phone_number = data.phone_number;
    dataProtectionOfficer.owner = "string" //data.owner;

    dataProtectionOfficersApi.dataProtectionOfficersCreate(dataProtectionOfficer).then(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });

export const getDirectDataProtectionOfficers = (
  ordering = null,
  page = null,
  page_size = MAX_PAGE_SIZE,
  search = null
) =>
  new Promise((resolve, reject) => {
    let opts = {
      ordering: ordering, // String | Which field to use when ordering the results.
      page: page, // Number | A page number within the paginated result set.
      page_size: page_size, // Number | Number of results to return per page.
      search: search, // String | A search term.
    };

    dataProtectionOfficersApi.dataProtectionOfficersList(opts).then(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });

export const getDirectDataProtectionOfficer = (id) =>
  new Promise((resolve, reject) => {
    dataProtectionOfficersApi.dataProtectionOfficersRetrieve(id).then(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });

export const updateDirectDataProtectionOfficer = (id, data) =>
  new Promise((resolve, reject) => {
    let dataProtectionOfficer = new DataProtectionOfficer(); // DataProtectionOfficer |
    dataProtectionOfficer.name = data.name;
    dataProtectionOfficer.email = data.email;
    dataProtectionOfficer.family_name = data.family_name;
    dataProtectionOfficer.company_name = data.company_name;
    dataProtectionOfficer.phone_number = data.phone_number;
    dataProtectionOfficer.owner = "string" //data.owner;

    dataProtectionOfficersApi.dataProtectionOfficersUpdate(id, dataProtectionOfficer).then(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });

export const deleteDirectDataProtectionOfficer = (id) =>
  new Promise((resolve, reject) => {
    dataProtectionOfficersApi.dataProtectionOfficersDestroy(id).then(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });

// --------------- DATA CONTROLLER API ---------------
export const addDataController = (data) =>
  new Promise((resolve, reject) => {
    let dataController = new DataController(); // DataController |
    dataController.name = data.name;
    dataController.email = data.email;
    dataController.street_name = data.street_name;
    dataController.street_number = data.street_number;
    dataController.city = data.city;
    dataController.postal_code = data.postal_code;
    dataController.country = data.country;
    dataController.vat_number = data.vat_number;
    dataController.phone_number = data.phone_number;
    dataControllersApi.dataControllersCreate(dataController).then(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });

export const getDataControllers = (
  ordering = null,
  page = null,
  page_size = MAX_PAGE_SIZE,
  search = null
) =>
  new Promise((resolve, reject) => {
    let opts = {
      ordering: ordering, // String | Which field to use when ordering the results.
      page: page, // Number | A page number within the paginated result set.
      page_size: page_size, // Number | Number of results to return per page.
      search: search, // String | A search term.
    };
    dataControllersApi.dataControllersList(opts).then(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });

export const getDataController = (id) =>
  new Promise((resolve, reject) => {
    dataControllersApi.dataControllersRetrieve(id).then(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });

export const updateDataController = (id, data) =>
  new Promise((resolve, reject) => {
    let dataController = new DataController(); // DataController |
    dataController.name = data.name;
    dataController.email = data.email;
    dataController.street_name = data.street_name;
    dataController.street_number = data.street_number;
    dataController.city = data.city;
    dataController.postal_code = data.postal_code;
    dataController.country = data.country;
    dataController.vat_number = data.vat_number;
    dataController.phone_number = data.phone_number;

    dataControllersApi.dataControllersUpdate(id, dataController).then(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });

export const deleteDataController = (id) =>
  new Promise((resolve, reject) => {
    dataControllersApi.dataControllersDestroy(id).then(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });

// --------------- DATA PROCESSOR API ---------------
export const addDataProcessor = (data) =>
  new Promise((resolve, reject) => {
    let dataProcessor = new DataProcessor(); // DataProcessor |
    dataProcessor.name = data.name;
    dataProcessor.email = data.email;
    dataProcessor.street_name = data.street_name;
    dataProcessor.street_number = data.street_number;
    dataProcessor.city = data.city;
    dataProcessor.postal_code = data.postal_code;
    dataProcessor.country = data.country;
    dataProcessor.vat_number = data.vat_number;
    dataProcessor.phone_number = data.phone_number;
    dataProcessorsApi.dataProcessorsCreate(dataProcessor).then(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });

export const getDataProcessors = (
  ordering = null,
  page = null,
  page_size = MAX_PAGE_SIZE,
  search = null
) =>
  new Promise((resolve, reject) => {
    let opts = {
      ordering: ordering, // String | Which field to use when ordering the results.
      page: page, // Number | A page number within the paginated result set.
      page_size: page_size, // Number | Number of results to return per page.
      search: search, // String | A search term.
    };
    dataProcessorsApi.dataProcessorsList(opts).then(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });

export const getDataProcessor = (id) =>
  new Promise((resolve, reject) => {
    dataControllersApi.dataProcessorsRetrieve(id).then(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });

export const updateDataProcessor = (id, data) =>
  new Promise((resolve, reject) => {
    let dataProcessor = new DataProcessor(); // DataProcessor |
    dataProcessor.name = data.name;
    dataProcessor.email = data.email;
    dataProcessor.street_name = data.street_name;
    dataProcessor.street_number = data.street_number;
    dataProcessor.city = data.city;
    dataProcessor.postal_code = data.postal_code;
    dataProcessor.country = data.country;
    dataProcessor.vat_number = data.vat_number;
    dataProcessor.phone_number = data.phone_number;

    dataProcessorsApi.dataProcessorsUpdate(id, dataProcessor).then(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });

export const deleteDataProcessor = (id) =>
  new Promise((resolve, reject) => {
    dataProcessorsApi.dataProcessorsDestroy(id).then(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });

// ---------------OTHER ---------------
// TODO: Remove it! - Alle.
export const getConcurrentStructures = (
  data_controller_id,
  ordering = null,
  page = null,
  page_size = MAX_PAGE_SIZE,
  search = null
) =>
  new Promise((resolve, reject) => {
    let opts = {
      ordering: ordering, // String | Which field to use when ordering the results.
      page: page, // Number | A page number within the paginated result set.
      page_size: page_size, // Number | Number of results to return per page.
      search: search, // String | A search term.
    };
    dataControllersApi
      .dataControllersConcurrentStructuresList(data_controller_id, opts)
      .then(
        (data) => {
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
  });

// - getDcConcurrentStructures.
export const getDcConcurrentStructures = (
  data_controller_id,
  ordering = null,
  page = null,
  page_size = MAX_PAGE_SIZE,
  search = null
) =>
  new Promise((resolve, reject) => {
    let opts = {
      ordering: ordering, // String | Which field to use when ordering the results.
      page: page, // Number | A page number within the paginated result set.
      page_size: page_size, // Number | Number of results to return per page.
      search: search, // String | A search term.
    };
    dataControllersApi
      .dataControllersConcurrentStructuresList(data_controller_id, opts)
      .then(
        (data) => {
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
  });

// - getDpConcurrentStructures.
export const getDpConcurrentStructures = (
  data_processor_id,
  ordering = null,
  page = null,
  page_size = MAX_PAGE_SIZE,
  search = null
) =>
  new Promise((resolve, reject) => {
    let opts = {
      ordering: ordering, // String | Which field to use when ordering the results.
      page: page, // Number | A page number within the paginated result set.
      page_size: page_size, // Number | Number of results to return per page.
      search: search, // String | A search term.
    };
    dataProcessorsApi
      .dataProcessorsConcurrentStructuresList(data_processor_id, opts)
      .then(
        (data) => {
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
  });

// TODO: Remove it! - Alle.
export const addConcurrentStructure = (data_controller_id, data) =>
  new Promise((resolve, reject) => {
    let concurrentStructure = new ConcurrentStructure(); // ConcurrentStructure |
    concurrentStructure.name = data.name;
    concurrentStructure.description = data.description;
    concurrentStructure.data_controller = data.data_controller;

    dataControllersApi
      .dataControllersConcurrentStructuresCreate(
        data_controller_id,
        concurrentStructure
      )
      .then(
        (data) => {
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
  });

// - addDcConcurrentStructure.
export const addDcConcurrentStructure = (data_controller_id, data) =>
  new Promise((resolve, reject) => {
    let dcConcurrentStructure = new DataControllerConcurrentStructure(); // DataControllerConcurrentStructure |
    dcConcurrentStructure.name = data.name;
    dcConcurrentStructure.description = data.description;
    dcConcurrentStructure.data_controller = data.data_controller;

    dataControllersApi
      .dataControllersConcurrentStructuresCreate(
        data_controller_id,
        dcConcurrentStructure
      )
      .then(
        (data) => {
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
  });

// - addDpConcurrentStructure.
export const addDpConcurrentStructure = (data_processor_id, data) =>
  new Promise((resolve, reject) => {
    let dpConcurrentStructure = new DataProcessorConcurrentStructure(); // DataProcessorConcurrentStructure |
    dpConcurrentStructure.name = data.name;
    dpConcurrentStructure.description = data.description;
    dpConcurrentStructure.data_controller = data.data_processor;

    dataProcessorsApi
      .dataProcessorsConcurrentStructuresCreate(
        data_processor_id,
        dpConcurrentStructure
      )
      .then(
        (data) => {
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
  });

export const getConcurrentStructure = (data_controller_id, cs_id) =>
  new Promise((resolve, reject) => {
    dataControllersApi
      .dataControllersConcurrentStructuresRetrieve(data_controller_id, cs_id)
      .then(
        (data) => {
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
  });

export const updateConcurrentStructure = (data_controller_id, cs_id, data) =>
  new Promise((resolve, reject) => {
    let concurrentStructure = new ConcurrentStructure(); // ConcurrentStructure |
    concurrentStructure.name = data.name;
    concurrentStructure.description = data.description;
    concurrentStructure.data_controller = data.data_controller;
    dataControllersApi
      .dataControllersConcurrentStructuresUpdate(
        data_controller_id,
        cs_id,
        concurrentStructure
      )
      .then(
        (data) => {
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
  });
export const deleteConcurrentStructure = (data_controller_id, cs_id) =>
  new Promise((resolve, reject) => {
    dataControllersApi
      .dataControllersConcurrentStructuresDestroy(data_controller_id, cs_id)
      .then(
        (data) => {
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
  });

export const getDataProtectionOfficers = (
  data_controller_id,
  ordering = null,
  page = null,
  page_size = MAX_PAGE_SIZE,
  search = null
) =>
  new Promise((resolve, reject) => {
    let opts = {
      ordering: ordering, // String | Which field to use when ordering the results.
      page: page, // Number | A page number within the paginated result set.
      page_size: page_size, // Number | Number of results to return per page.
      search: search, // String | A search term.
    };
    dataControllersApi
      .dataControllersDataProtectionOfficersList(data_controller_id, opts)
      .then(
        (data) => {
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
  });

export const getDataProtectionOfficer = (data_controller_id, id) =>
  new Promise((resolve, reject) => {
    dataControllersApi
      .dataControllersDataProtectionOfficersRetrieve(data_controller_id, id)
      .then(
        (data) => {
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
  });
export const addDataProtectionOfficer = (data_controller_id, data) =>
  new Promise((resolve, reject) => {
    let data_protection_officer = new DataProtectionOfficer(); // DataProtectionOfficer |
    data_protection_officer.name = data.name;
    data_protection_officer.family_name = data.family_name;
    data_protection_officer.email = data.email;
    data_protection_officer.phone_number = data.phone_number;
    data_protection_officer.data_controller = data.data_controller;
    dataControllersApi
      .dataControllersDataProtectionOfficersCreate(
        data_controller_id,
        data_protection_officer
      )
      .then(
        (data) => {
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
  });
export const updateDataProtectionOfficer = (data_controller_id, id, data) =>
  new Promise((resolve, reject) => {
    let data_protection_officer = new DataProtectionOfficer(); // DataProtectionOfficer |
    data_protection_officer.name = data.name;
    data_protection_officer.family_name = data.family_name;
    data_protection_officer.email = data.email;
    data_protection_officer.phone_number = data.phone_number;
    data_protection_officer.data_controller = data.data_controller;
    dataControllersApi
      .dataControllersDataProtectionOfficersUpdate(
        data_controller_id,
        id,
        data_protection_officer
      )
      .then(
        (data) => {
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
  });
export const deleteDataProtectionOfficer = (data_controller_id, id) =>
  new Promise((resolve, reject) => {
    dataControllersApi
      .dataControllersDataProtectionOfficersDestroy(data_controller_id, id)
      .then(
        (data) => {
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
  });

// TODO: Remove it! -- Alle.
export const getExternalProcessors = (
  data_controller_id,
  ordering = null,
  page = null,
  page_size = MAX_PAGE_SIZE,
  search = null
) =>
  new Promise((resolve, reject) => {
    let opts = {
      ordering: ordering, // String | Which field to use when ordering the results.
      page: page, // Number | A page number within the paginated result set.
      page_size: page_size, // Number | Number of results to return per page.
      search: search, // String | A search term.
    };
    dataControllersApi
      .dataControllersExternalProcessorsList(data_controller_id, opts)
      .then(
        (data) => {
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
  });


// - getDcExternalProcessors.
export const getDcExternalProcessors = (
  data_controller_id,
  ordering = null,
  page = null,
  page_size = MAX_PAGE_SIZE,
  search = null
) =>
  new Promise((resolve, reject) => {
    let opts = {
      ordering: ordering, // String | Which field to use when ordering the results.
      page: page, // Number | A page number within the paginated result set.
      page_size: page_size, // Number | Number of results to return per page.
      search: search, // String | A search term.
    };
    dataControllersApi
      .dataControllersExternalProcessorsList(data_controller_id, opts)
      .then(
        (data) => {
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
  });

// - getDpControllers.
export const getDpControllers = (
  data_processor_id,
  ordering = null,
  page = null,
  page_size = MAX_PAGE_SIZE,
  search = null
) =>
  new Promise((resolve, reject) => {
    let opts = {
      ordering: ordering, // String | Which field to use when ordering the results.
      page: page, // Number | A page number within the paginated result set.
      page_size: page_size, // Number | Number of results to return per page.
      search: search, // String | A search term.
    };

    dataProcessorsApi
      .dataProcessorsControllersList(data_processor_id, opts)
      .then(
        (data) => {
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
  });

// - addDpController.
export const addDpController = (data_processor_id, data) =>
  new Promise((resolve, reject) => {
    let dpController = new DataProcessorController(); // DataProcessorController() |
    dpController.name = data.name;
    dpController.city = data.city;
    dpController.country = data.country;
    dpController.email = data.email;
    dpController.extra_ue = data.extra_ue;
    dpController.juridical_person = data.juridical_person;
    dpController.phone_number = data.phone_number;
    dpController.postal_code = data.postal_code;
    dpController.street_name = data.street_name;
    dpController.street_number = data.street_number;
    dpController.ue_representative = data.ue_representative;
    dataProcessorsApi
      .dataProcessorsControllersCreate(
        data_processor_id,
        dpController
      )
      .then(
        (data) => {
          // console.log(data);
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
  });
export const deleteDpController = (data_controller_id, id) =>
  new Promise((resolve, reject) => {
    dataProcessorsApi
      .dataProcessorsControllersDestroy(data_controller_id, id)
      .then(
        (data) => {
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
  });

export const getExternalProcessor = (data_controller_id, id) =>
  new Promise((resolve, reject) => {
    dataControllersApi
      .dataControllersExternalProcessorsRetrieve(data_controller_id, id)
      .then(
        (data) => {
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
  });

// TODO Remove it ! -- Alle

export const addExternalProcessor = (data_controller_id, data) =>
  new Promise((resolve, reject) => {
    let externalProcessor = new ExternalProcessor(); // ExternalProcessor |
    externalProcessor.name = data.name;
    externalProcessor.data_controller = data.data_controller;
    dataControllersApi
      .dataControllersExternalProcessorsCreate(
        data_controller_id,
        externalProcessor
      )
      .then(
        (data) => {
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
  });


// - addDcExternalProcessor.
export const addDcExternalProcessor = (data_controller_id, data) =>
  new Promise((resolve, reject) => {
    let dcExternalProcessor = new DataControllerExternalProcessor(); // DataControllerExternalProcessor |
    dcExternalProcessor.name = data.name;
    dcExternalProcessor.data_controller = data.data_controller;
    dataControllersApi
      .dataControllersExternalProcessorsCreate(
        data_controller_id,
        dcExternalProcessor
      )
      .then(
        (data) => {
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
  });

export const updateExternalProcessor = (data_controller_id, id, data) =>
  new Promise((resolve, reject) => {
    let externalProcessor = new ExternalProcessor(); // ExternalProcessor |
    externalProcessor.name = data.name;
    externalProcessor.data_controller = data.data_controller;

    dataControllersApi
      .dataControllersExternalProcessorsUpdate(
        data_controller_id,
        id,
        externalProcessor
      )
      .then(
        (data) => {
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
  });
export const deleteExternalProcessor = (data_controller_id, id) =>
  new Promise((resolve, reject) => {
    dataControllersApi
      .dataControllersExternalProcessorsDestroy(data_controller_id, id)
      .then(
        (data) => {
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
  });

// TODO: Remove it! -- Alle
export const getInternalStructures = (
  data_controller_id,
  ordering = null,
  page = null,
  page_size = MAX_PAGE_SIZE,
  search = null
) =>
  new Promise((resolve, reject) => {
    let opts = {
      ordering: ordering, // String | Which field to use when ordering the results.
      page: page, // Number | A page number within the paginated result set.
      page_size: page_size, // Number | Number of results to return per page.
      search: search, // String | A search term.
    };
    dataControllersApi
      .dataControllersInternalStructuresList(data_controller_id, opts)
      .then(
        (data) => {
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
  });

//- getDcInternalStructures.
export const getDcInternalStructures = (
  data_controller_id,
  ordering = null,
  page = null,
  page_size = MAX_PAGE_SIZE,
  search = null
) =>
  new Promise((resolve, reject) => {
    let opts = {
      ordering: ordering, // String | Which field to use when ordering the results.
      page: page, // Number | A page number within the paginated result set.
      page_size: page_size, // Number | Number of results to return per page.
      search: search, // String | A search term.
    };
    dataControllersApi
      .dataControllersInternalStructuresList(data_controller_id, opts)
      .then(
        (data) => {
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
  });

//- getDpInternalStructures.
export const getDpInternalStructures = (
  data_processor_id,
  ordering = null,
  page = null,
  page_size = MAX_PAGE_SIZE,
  search = null
) =>
  new Promise((resolve, reject) => {
    let opts = {
      ordering: ordering, // String | Which field to use when ordering the results.
      page: page, // Number | A page number within the paginated result set.
      page_size: page_size, // Number | Number of results to return per page.
      search: search, // String | A search term.
    };
    dataProcessorsApi
      .dataProcessorsInternalStructuresList(data_processor_id, opts)
      .then(
        (data) => {
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
  });

export const getInternalStructure = (data_controller_id, id) =>
  new Promise((resolve, reject) => {
    dataControllersApi
      .dataControllersInternalStructuresRetrieve(data_controller_id, id)
      .then(
        (data) => {
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
  });

// TODO: Remove it! -- Alle
export const addInternalStructure = (data_controller_id, data) =>
  new Promise((resolve, reject) => {
    let internalReferenceStructure = new InternalReferenceStructure(); // InternalReferenceStructure |
    internalReferenceStructure.name = data.name;
    internalReferenceStructure.data_controller = data.data_controller;
    dataControllersApi
      .dataControllersInternalStructuresCreate(
        data_controller_id,
        internalReferenceStructure
      )
      .then(
        (data) => {
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
  });

// - addDcInternalStructure.
export const addDcInternalStructure = (data_controller_id, data) =>
  new Promise((resolve, reject) => {
    let dcInternalReferenceStructure = new DataControllerInternalReferenceStructure(); // DataControllerInternalReferenceStructure |
    dcInternalReferenceStructure.name = data.name;
    dcInternalReferenceStructure.data_controller = data.data_controller;
    dataControllersApi
      .dataControllersInternalStructuresCreate(
        data_controller_id,
        dcInternalReferenceStructure
      )
      .then(
        (data) => {
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
  });

// - addDpInternalStructure.
export const addDpInternalStructure = (data_processor_id, data) =>
  new Promise((resolve, reject) => {
    let dpInternalReferenceStructure = new DataProcessorInternalReferenceStructure(); // DataProcessorInternalReferenceStructure |
    dpInternalReferenceStructure.name = data.name;
    dpInternalReferenceStructure.data_controller = data.data_processor;
    dataProcessorsApi
      .dataProcessorsInternalStructuresCreate(
        data_processor_id,
        dpInternalReferenceStructure
      )
      .then(
        (data) => {
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
  });


export const updateInternalStructure = (data_controller_id, id, data) =>
  new Promise((resolve, reject) => {
    let internalReferenceStructure = new InternalReferenceStructure(); // InternalReferenceStructure |
    internalReferenceStructure.name = data.name;
    internalReferenceStructure.data_controller = data.data_controller;

    dataControllersApi
      .dataControllersRetrieve(
        data_controller_id,
        id,
        internalReferenceStructure
      )
      .then(
        (data) => {
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
  });
export const deleteInternalStructure = (data_controller_id, id) =>
  new Promise((resolve, reject) => {
    dataControllersApi
      .dataControllersInternalStructuresDestroy(data_controller_id, id)
      .then(
        (data) => {
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
  });

// --------------- DCR REGISTRY API ---------------
// TODO: Remove it after creation of DCR REGISTRY and DPR REGISTRY. - Alle

export const getRegistries = (
  ordering = null,
  page = null,
  page_size = MAX_PAGE_SIZE,
  search = null
) =>
  new Promise((resolve, reject) => {
    let opts = {
      ordering: ordering, // String | Which field to use when ordering the results.
      page: page, // Number | A page number within the paginated result set.
      page_size: page_size, // Number | Number of results to return per page.
      search: search, // String | A search term.
    };
    dataControllerRegistriesApi.registriesList(opts).then(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });

export const addRegistry = (data) =>
  new Promise((resolve, reject) => {
    let registry = new Registry(); // Registry |
    registry.name = data.name;
    registry.data_controller = data.data_controller;
    registry.data_protection_officer = data.data_protection_officer;

    dataControllerRegistriesApi.registriesCreate(registry).then(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });

export const getRegistry = (id) =>
  new Promise((resolve, reject) => {
    dataControllerRegistriesApi.registriesRetrieve(id).then(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });

export const updateRegistry = (id, data) =>
  new Promise((resolve, reject) => {
    let registry = new Registry(); // Registry |
    registry.name = data.name;
    registry.data_controller = data.data_controller;
    registry.data_protection_officer = data.data_protection_officer;

    dataControllerRegistriesApi.registriesUpdate(id, registry).then(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });

export const deleteRegistry = (id) =>
  new Promise((resolve, reject) => {
    dataControllerRegistriesApi.registriesDestroy(id).then(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });

export const getDcrRegistries = (
  ordering = null,
  page = null,
  page_size = MAX_PAGE_SIZE,
  search = null
) =>
  new Promise((resolve, reject) => {
    let opts = {
      ordering: ordering, // String | Which field to use when ordering the results.
      page: page, // Number | A page number within the paginated result set.
      page_size: page_size, // Number | Number of results to return per page.
      search: search, // String | A search term.
    };
    dataControllerRegistriesApi.dataControllerRegistriesList(opts).then(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });

export const addDcrRegistry = (data) =>
  new Promise((resolve, reject) => {
    let dataControllerRegistry = new DataControllerRegistry(); // DataControllerRegistry |
    dataControllerRegistry.name = data.name;
    dataControllerRegistry.data_controller = data.data_controller;
    dataControllerRegistry.data_protection_officer = data.data_protection_officer;

    dataControllerRegistriesApi.dataControllerRegistriesCreate(dataControllerRegistry).then(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });

export const getDcrRegistry = (id) =>
  new Promise((resolve, reject) => {
    dataControllerRegistriesApi.dataControllerRegistriesRetrieve(id).then(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });

export const updateDcrRegistry = (id, data) =>
  new Promise((resolve, reject) => {
    let dataControllerRegistry = new DataControllerRegistry(); // DataControllerRegistry|
    dataControllerRegistry.name = data.name;
    dataControllerRegistry.data_controller = data.data_controller;
    dataControllerRegistry.data_protection_officer = data.data_protection_officer;

    dataControllerRegistriesApi.dataControllerRegistriesUpdate(id, dataControllerRegistry).then(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });

export const deleteDcrRegistry = (id) =>
  new Promise((resolve, reject) => {
    dataControllerRegistriesApi.dataControllerRegistriesDestroy(id).then(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });
  export const getS3DcrRegistries = (
    registry_id,
    ordering = null,
    page = null,
    page_size = MAX_PAGE_SIZE,
    search = null
  ) =>
    new Promise((resolve, reject) => {
      let opts = {
        ordering: ordering, // String | Which field to use when ordering the results.
        page: page, // Number | A page number within the paginated result set.
        page_size: page_size, // Number | Number of results to return per page.
        search: search, // String | A search term.
      };
      dataControllerRegistriesApi.dataControllerRegistriesUploadsList(registry_id,opts).then(
        (data) => {
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
    });
  
    export const getS3DcrRegistry = (id, registry_id) =>
    new Promise((resolve, reject) => {
      dataControllerRegistriesApi.dataControllerRegistriesUploadsRetrieve(id, registry_id).then(
        (data) => {
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
    });
  export const uploadS3DcrRegistry = (registry_id, document) =>
  new Promise((resolve, reject) => {
    // let dataControllerRegistry = new DataControllerRegistryUpload(); // DataControllerRegistry|
    // dataControllerRegistry.registry = data.registry;
    // dataControllerRegistry.document = data.document;
    // dataControllerRegistry.created_at = data.created_at;
    // dataControllerRegistry.updated_at = data.updated_at;
    // dataControllerRegistry.id = Date.now()
    // console.log(dataControllerRegistry)
    // console.log(document)

    dataControllerRegistriesApi.dataControllerRegistriesUploadsCreate(registry_id, document).then(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });
  export const getS3DcrRegistryDocument = (id,registry_id) =>
  new Promise((resolve, reject) => {
    dataControllerRegistriesApi.dataControllerRegistriesUploadsDocumentRetrieveWithHttpInfo(id,registry_id).then(
      (data) => {
      
        resolve(data.response.text);
      },
      (error) => {
        reject(error);
      }
    );
  });
export const deleteS3DcrRegistry = (id,registry_id) =>
  new Promise((resolve, reject) => {
    dataControllerRegistriesApi.dataControllerRegistriesUploadsDestroy(id,registry_id).then(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });
  
// --------------- DPR REGISTRY API ---------------
export const getDprRegistries = (
  ordering = null,
  page = null,
  page_size = MAX_PAGE_SIZE,
  search = null
) =>
  new Promise((resolve, reject) => {
    let opts = {
      ordering: ordering, // String | Which field to use when ordering the results.
      page: page, // Number | A page number within the paginated result set.
      page_size: page_size, // Number | Number of results to return per page.
      search: search, // String | A search term.
    };
    dataProcessorRegistriesApi.dataProcessorRegistriesList(opts).then(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });

export const addDprRegistry = (data) =>
  new Promise((resolve, reject) => {
    let dataProcessorRegistry = new DataProcessorRegistry(); // DataProcessorRegistry |
    dataProcessorRegistry.name = data.name;
    dataProcessorRegistry.data_processor = data.data_processor;
    dataProcessorRegistry.data_protection_officer = data.data_protection_officer;

    dataProcessorRegistriesApi.dataProcessorRegistriesCreate(dataProcessorRegistry).then(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });

export const getDprRegistry = (id) =>
  new Promise((resolve, reject) => {
    dataProcessorRegistriesApi.dataProcessorRegistriesRetrieve(id).then(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });

export const updateDprRegistry = (id, data) =>
  new Promise((resolve, reject) => {
    let dataProcessorRegistry = new DataProcessorRegistry(); // DataProcessorRegistry |
    dataProcessorRegistry.name = data.name;
    dataProcessorRegistry.data_processor = data.data_processor;
    dataProcessorRegistry.data_protection_officer = data.data_protection_officer;

    dataProcessorRegistriesApi.dataProcessorRegistriesUpdate(id, dataProcessorRegistry).then(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });

export const deleteDprRegistry = (id) =>
  new Promise((resolve, reject) => {
    dataProcessorRegistriesApi.dataProcessorRegistriesDestroy(id).then(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });
  export const getS3DprRegistries = (
    registry_id,
    ordering = null,
    page = null,
    page_size = MAX_PAGE_SIZE,
    search = null
  ) =>
    new Promise((resolve, reject) => {
      let opts = {
        ordering: ordering, // String | Which field to use when ordering the results.
        page: page, // Number | A page number within the paginated result set.
        page_size: page_size, // Number | Number of results to return per page.
        search: search, // String | A search term.
      };
      dataProcessorRegistriesApi.dataProcessorRegistriesUploadsList(registry_id,opts).then(
        (data) => {
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
    });
  
  export const getS3DprRegistry = (id, registry_id) =>
  new Promise((resolve, reject) => {
    dataProcessorRegistriesApi.dataProcessorRegistriesUploadsRetrieve(id, registry_id).then(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });
  export const uploadS3DprRegistry = (registry_id, document) =>
  new Promise((resolve, reject) => {
    // let dataProcessorRegistry = new DataProcessorRegistryUpload(); // DataProcessorRegistry|
    // dataProcessorRegistry.registry = data.registry;
    // dataProcessorRegistry.document = data.document;
    // dataProcessorRegistry.created_at = data.created_at;
    // dataProcessorRegistry.updated_at = data.updated_at;
    // dataProcessorRegistry.id = Date.now()
    // console.log(dataProcessorRegistry)
    dataProcessorRegistriesApi.dataProcessorRegistriesUploadsCreate(registry_id, document).then(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });
  // TODO SERVER SIDE
  export const getS3DprRegistryDocument = (id,registry_id) =>
  new Promise((resolve, reject) => {
    // console.log(id,registry_id)
    dataProcessorRegistriesApi.dataProcessorRegistriesUploadsDocumentRetrieveWithHttpInfo(id,registry_id).then(
      (data) => {
        resolve(data.response.text);
      },
      (error) => {
        reject(error);
      }
    );
  });
  export const deleteS3DprRegistry = (id,registry_id) =>
  new Promise((resolve, reject) => {
    dataProcessorRegistriesApi.dataProcessorRegistriesUploadsDestroy(id,registry_id).then(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });
// --------------- p TREATMENT API / PROCESSING ACTS ---------------
export const getDcrTreatments = (
  registry_id,
  ordering = null,
  page = null,
  page_size = MAX_PAGE_SIZE,
  search = null
) =>
  new Promise((resolve, reject) => {
    let opts = {
      ordering: ordering, // String | Which field to use when ordering the results.
      page: page, // Number | A page number within the paginated result set.
      page_size: page_size, // Number | Number of results to return per page.
      search: search, // String | A search term.
    };

    dataControllerRegistriesApi.dataControllerRegistriesProcessingActsList(registry_id, opts).then(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });

// --------------- DPR TREATMENT API / PROCESSING ACTS ---------------
// - getDprTreatments.
export const getDprTreatments = (
  registry_id,
  ordering = null,
  page = null,
  page_size = MAX_PAGE_SIZE,
  search = null
) =>
  new Promise((resolve, reject) => {
    let opts = {
      ordering: ordering, // String | Which field to use when ordering the results.
      page: page, // Number | A page number within the paginated result set.
      page_size: page_size, // Number | Number of results to return per page.
      search: search, // String | A search term.
    };

    dataProcessorRegistriesApi.dataProcessorRegistriesProcessingActsList(registry_id, opts).then(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });

// --------------- TREATMENT API / PROCESSING ACTS ---------------
export const getTreatments = (
  registry_id,
  ordering = null,
  page = null,
  page_size = MAX_PAGE_SIZE,
  search = null
) =>
  new Promise((resolve, reject) => {
    let opts = {
      ordering: ordering, // String | Which field to use when ordering the results.
      page: page, // Number | A page number within the paginated result set.
      page_size: page_size, // Number | Number of results to return per page.
      search: search, // String | A search term.
    };
    dataControllerRegistriesApi.registriesProcessingActsList(registry_id, opts).then(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });

export const addTreatment = (registry_id, data) =>
  new Promise((resolve, reject) => {
    let processingActivity = new ProcessingActivity(); // ProcessingActivity |
    processingActivity.internal_identifier = data.internal_identifier;
    processingActivity.is_enabled = data.is_enabled;
    processingActivity.filling_date = data.filling_date;
    processingActivity.info_supplied_by = data.info_supplied_by;
    processingActivity.processing_name = data.processing_name;
    processingActivity.processing_description = data.processing_description;
    processingActivity.processing_note = data.processing_note;
    processingActivity.other_gdpr_base = data.other_gdpr_base;
    processingActivity.other_processing_types = data.other_processing_types;
    processingActivity.other_processing_purposes =
      data.other_processing_purposes;
    processingActivity.other_data_types = data.other_data_types;
    processingActivity.other_data_subjects = data.other_data_subjects;
    // processingActivity.data_collection_description =
    //   data.data_collection_description;
    processingActivity.extra_eu_transfers = data.extra_eu_transfers;
    processingActivity.other_storage_supports = data.other_storage_supports;
    processingActivity.instrument_description = data.instrument_description;
    processingActivity.interconnection_type = data.interconnection_type;
    processingActivity.storage_period = data.storage_period;
    processingActivity.storage_locations_description =
      data.storage_locations_description;
    processingActivity.processing_software = data.processing_software;
    processingActivity.internal_person_in_charge =
      data.internal_person_in_charge;
    processingActivity.other_technical_measures = data.other_technical_measures;
    processingActivity.other_organizational_measures =
      data.other_organizational_measures;
    processingActivity.system_administrator = data.system_administrator;
    processingActivity.internal_instructions = data.internal_instructions;
    processingActivity.risk_level = data.risk_level;
    processingActivity.pia_needed = data.pia_needed;
    // processingActivity.registry_id = data.registry_id;
    processingActivity.internal_reference_structure =
      data.internal_reference_structure;
    processingActivity.art6_gdpr_base = data.art6_gdpr_base;
    processingActivity.art9_gdpr_base = data.art9_gdpr_base;
    processingActivity.processing_purposes = data.processing_purposes;
    processingActivity.concurrent_structures = data.concurrent_structures;
    processingActivity.processing_types = data.processing_types;
    processingActivity.advanced_processing_types =
      data.advanced_processing_types;
    processingActivity.data_subjects = data.data_subjects;
    processingActivity.basic_data_types = data.basic_data_types;
    processingActivity.special_data_categories = data.special_data_categories;
    processingActivity.eu_transfers = data.eu_transfers;
    processingActivity.other_eu_transfers = data.other_eu_transfers;
    processingActivity.storage_supports = data.storage_supports;
    processingActivity.storage_locations = data.storage_locations;
    processingActivity.data_warehouses = data.data_warehouses;
    processingActivity.technical_measures = data.technical_measures;
    processingActivity.organizational_measures = data.organizational_measures;
    processingActivity.external_processors = data.external_processors;
    processingActivity.internal_reference_structure =
      data.internal_reference_structure;

    dataControllerRegistriesApi
      .registriesProcessingActsCreate(registry_id, processingActivity)
      .then(
        (data) => {
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
  });

// - addDcrTreatment.
export const addDcrTreatment = (registry_id, data) =>
  new Promise((resolve, reject) => {
    let dcProcessingActivity = new DataControllerProcessingActivity(); // DataControllerProcessingActivity |
    dcProcessingActivity.internal_identifier = data.internal_identifier;
    dcProcessingActivity.is_enabled = data.is_enabled;
    dcProcessingActivity.filling_date = data.filling_date;
    dcProcessingActivity.info_supplied_by = data.info_supplied_by;
    dcProcessingActivity.processing_name = data.processing_name;
    dcProcessingActivity.processing_description = data.processing_description;
    dcProcessingActivity.processing_note = data.processing_note;
    dcProcessingActivity.other_gdpr_base = data.other_gdpr_base;
    dcProcessingActivity.other_processing_types = data.other_processing_types;
    dcProcessingActivity.other_processing_purposes =
      data.other_processing_purposes;
    dcProcessingActivity.other_data_types = data.other_data_types;
    dcProcessingActivity.other_data_subjects = data.other_data_subjects;
    // dcProcessingActivity.data_collection_description =
    //   data.data_collection_description;
    dcProcessingActivity.extra_eu_transfers = data.extra_eu_transfers;
    dcProcessingActivity.other_storage_supports = data.other_storage_supports;
    dcProcessingActivity.instrument_description = data.instrument_description;
    dcProcessingActivity.interconnection_type = data.interconnection_type;
    dcProcessingActivity.storage_period = data.storage_period;
    dcProcessingActivity.storage_locations_description =
      data.storage_locations_description;
    dcProcessingActivity.processing_software = data.processing_software;
    dcProcessingActivity.internal_person_in_charge =
      data.internal_person_in_charge;
    dcProcessingActivity.other_technical_measures = data.other_technical_measures;
    dcProcessingActivity.other_organizational_measures =
      data.other_organizational_measures;
    dcProcessingActivity.system_administrator = data.system_administrator;
    dcProcessingActivity.internal_instructions = data.internal_instructions;
    dcProcessingActivity.risk_level = data.risk_level;
    dcProcessingActivity.pia_needed = data.pia_needed;
    // dcProcessingActivity.registry_id = data.registry_id;
    dcProcessingActivity.internal_reference_structure =
      data.internal_reference_structure;
    dcProcessingActivity.art6_gdpr_base = data.art6_gdpr_base;
    dcProcessingActivity.art9_gdpr_base = data.art9_gdpr_base;
    dcProcessingActivity.processing_purposes = data.processing_purposes;
    dcProcessingActivity.concurrent_structures = data.concurrent_structures;
    dcProcessingActivity.processing_types = data.processing_types;
    dcProcessingActivity.advanced_processing_types =
      data.advanced_processing_types;
    dcProcessingActivity.data_subjects = data.data_subjects;
    dcProcessingActivity.basic_data_types = data.basic_data_types;
    dcProcessingActivity.special_data_categories = data.special_data_categories;
    dcProcessingActivity.eu_transfers = data.eu_transfers;
    dcProcessingActivity.other_eu_transfers = data.other_eu_transfers;
    dcProcessingActivity.storage_supports = data.storage_supports;
    dcProcessingActivity.storage_locations = data.storage_locations;
    dcProcessingActivity.data_warehouses = data.data_warehouses;
    dcProcessingActivity.technical_measures = data.technical_measures;
    dcProcessingActivity.organizational_measures = data.organizational_measures;
    dcProcessingActivity.external_processors = data.external_processors;
    dcProcessingActivity.internal_reference_structure =
      data.internal_reference_structure;

    dataControllerRegistriesApi
      .dataControllerRegistriesProcessingActsCreate(registry_id, dcProcessingActivity)
      .then(
        (data) => {
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
  });

// - addDprTreatment.
export const addDprTreatment = (registry_id, data) =>
  new Promise((resolve, reject) => {
    let dpProcessingActivity = new DataProcessorProcessingActivity(); // DataProcessorProcessingActivity |
    dpProcessingActivity.internal_identifier = data.internal_identifier;
    dpProcessingActivity.is_enabled = data.is_enabled;
    dpProcessingActivity.filling_date = data.filling_date;
    dpProcessingActivity.info_supplied_by = data.info_supplied_by;
    dpProcessingActivity.processing_name = data.processing_name;
    dpProcessingActivity.processing_description = data.processing_description;
    dpProcessingActivity.processing_note = data.processing_note;
    dpProcessingActivity.other_gdpr_base = data.other_gdpr_base;
    dpProcessingActivity.other_processing_types = data.other_processing_types;
    dpProcessingActivity.other_processing_purposes =
      data.other_processing_purposes;
    dpProcessingActivity.other_data_types = data.other_data_types;
    dpProcessingActivity.other_data_subjects = data.other_data_subjects;
    // dpProcessingActivity.data_collection_description =
    //   data.data_collection_description;
    dpProcessingActivity.extra_eu_transfers = data.extra_eu_transfers;
    dpProcessingActivity.other_storage_supports = data.other_storage_supports;
    dpProcessingActivity.instrument_description = data.instrument_description;
    dpProcessingActivity.interconnection_type = data.interconnection_type;
    dpProcessingActivity.storage_period = data.storage_period;
    dpProcessingActivity.storage_locations_description =
      data.storage_locations_description;
    dpProcessingActivity.processing_software = data.processing_software;
    dpProcessingActivity.internal_person_in_charge =
      data.internal_person_in_charge;
    dpProcessingActivity.other_technical_measures = data.other_technical_measures;
    dpProcessingActivity.other_organizational_measures =
      data.other_organizational_measures;
    dpProcessingActivity.system_administrator = data.system_administrator;
    dpProcessingActivity.internal_instructions = data.internal_instructions;
    dpProcessingActivity.risk_level = data.risk_level;
    dpProcessingActivity.pia_needed = data.pia_needed;
    // dpProcessingActivity.registry_id = data.registry_id;
    dpProcessingActivity.internal_reference_structure =
      data.internal_reference_structure;
    dpProcessingActivity.art6_gdpr_base = data.art6_gdpr_base;
    dpProcessingActivity.art9_gdpr_base = data.art9_gdpr_base;
    dpProcessingActivity.processing_purposes = data.processing_purposes;
    dpProcessingActivity.concurrent_structures = data.concurrent_structures;
    dpProcessingActivity.processing_types = data.processing_types;
    dpProcessingActivity.advanced_processing_types =
      data.advanced_processing_types;
    dpProcessingActivity.data_subjects = data.data_subjects;
    dpProcessingActivity.basic_data_types = data.basic_data_types;
    dpProcessingActivity.special_data_categories = data.special_data_categories;
    dpProcessingActivity.eu_transfers = data.eu_transfers;
    dpProcessingActivity.other_eu_transfers = data.other_eu_transfers;
    dpProcessingActivity.storage_supports = data.storage_supports;
    dpProcessingActivity.storage_locations = data.storage_locations;
    dpProcessingActivity.data_warehouses = data.data_warehouses;
    dpProcessingActivity.technical_measures = data.technical_measures;
    dpProcessingActivity.organizational_measures = data.organizational_measures;
    dpProcessingActivity.controllers = data.controllers;
    dpProcessingActivity.internal_reference_structure =
      data.internal_reference_structure;

    dataProcessorRegistriesApi
      .dataProcessorRegistriesProcessingActsCreate(registry_id, dpProcessingActivity)
      .then(
        (data) => {
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
  });

export const getTreatment = (id, registry_id) =>
  new Promise((resolve, reject) => {
    dataControllerRegistriesApi.registriesProcessingActsRetrieve(id, registry_id).then(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });

export const updateTreatment = (id, registry_id, data) =>
  new Promise((resolve, reject) => {
    let processingActivity = new ProcessingActivity(); // ProcessingActivity |
    processingActivity.internal_identifier = data.internal_identifier;
    processingActivity.is_enabled = data.is_enabled;
    processingActivity.filling_date = data.filling_date;
    processingActivity.info_supplied_by = data.info_supplied_by;
    processingActivity.processing_name = data.processing_name;
    processingActivity.processing_description = data.processing_description;
    processingActivity.processing_note = data.processing_note;
    processingActivity.other_gdpr_base = data.other_gdpr_base;
    processingActivity.other_processing_types = data.other_processing_types;
    processingActivity.other_data_types = data.other_data_types;
    processingActivity.other_data_subjects = data.other_data_subjects;
    // processingActivity.data_collection_description =
    //   data.data_collection_description;
    processingActivity.extra_eu_transfers = data.extra_eu_transfers;
    processingActivity.other_storage_supports = data.other_storage_supports;
    processingActivity.instrument_description = data.instrument_description;
    processingActivity.interconnection_type = data.interconnection_type;
    processingActivity.storage_period = data.storage_period;
    processingActivity.storage_locations_description =
      data.storage_locations_description;
    processingActivity.processing_software = data.processing_software;
    processingActivity.internal_person_in_charge =
      data.internal_person_in_charge;
    processingActivity.other_technical_measures = data.other_technical_measures;
    processingActivity.other_organizational_measures =
      data.other_organizational_measures;
    processingActivity.system_administrator = data.system_administrator;
    processingActivity.internal_instructions = data.internal_instructions;
    processingActivity.risk_level = data.risk_level;
    processingActivity.pia_needed = data.pia_needed;
    // processingActivity.registry_id = data.registry_id;
    processingActivity.internal_reference_structure =
      data.internal_reference_structure;
    processingActivity.art6_gdpr_base = data.art6_gdpr_base;
    processingActivity.art9_gdpr_base = data.art9_gdpr_base;
    processingActivity.processing_purposes = data.processing_purposes;
    processingActivity.concurrent_structures = data.concurrent_structures;
    processingActivity.processing_types = data.processing_types;
    processingActivity.advanced_processing_types =
      data.advanced_processing_types;
    processingActivity.data_subjects = data.data_subjects;
    processingActivity.basic_data_types = data.basic_data_types;
    processingActivity.special_data_categories = data.special_data_categories;
    processingActivity.eu_transfers = data.eu_transfers;
    processingActivity.other_eu_transfers = data.other_eu_transfers;
    processingActivity.storage_supports = data.storage_supports;
    processingActivity.storage_locations = data.storage_locations;
    processingActivity.data_warehouses = data.data_warehouses;
    processingActivity.technical_measures = data.technical_measures;
    processingActivity.organizational_measures = data.organizational_measures;
    processingActivity.external_processors = data.external_processors;

    dataControllerRegistriesApi
      .registriesProcessingActsUpdate(id, registry_id, processingActivity)
      .then(
        (data) => {
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
  });

// - updateDprTreatment.
export const updateDprTreatment = (id, registry_id, data) =>
  new Promise((resolve, reject) => {
    let processingActivity = new DataProcessorProcessingActivity(); // DataProcessorProcessingActivity |
    processingActivity.internal_identifier = data.internal_identifier;
    processingActivity.is_enabled = data.is_enabled;
    processingActivity.filling_date = data.filling_date;
    processingActivity.info_supplied_by = data.info_supplied_by;
    processingActivity.processing_name = data.processing_name;
    processingActivity.processing_description = data.processing_description;
    processingActivity.processing_note = data.processing_note;
    processingActivity.other_gdpr_base = data.other_gdpr_base;
    processingActivity.other_processing_types = data.other_processing_types;
    processingActivity.other_data_types = data.other_data_types;
    processingActivity.other_data_subjects = data.other_data_subjects;
    // processingActivity.data_collection_description =
    //   data.data_collection_description;
    processingActivity.extra_eu_transfers = data.extra_eu_transfers;
    processingActivity.other_storage_supports = data.other_storage_supports;
    processingActivity.instrument_description = data.instrument_description;
    processingActivity.interconnection_type = data.interconnection_type;
    processingActivity.storage_period = data.storage_period;
    processingActivity.storage_locations_description =
      data.storage_locations_description;
    processingActivity.processing_software = data.processing_software;
    processingActivity.internal_person_in_charge =
      data.internal_person_in_charge;
    processingActivity.other_technical_measures = data.other_technical_measures;
    processingActivity.other_organizational_measures =
      data.other_organizational_measures;
    processingActivity.system_administrator = data.system_administrator;
    processingActivity.internal_instructions = data.internal_instructions;
    processingActivity.risk_level = data.risk_level;
    processingActivity.pia_needed = data.pia_needed;
    // processingActivity.registry_id = data.registry_id;
    processingActivity.internal_reference_structure =
      data.internal_reference_structure;
    processingActivity.art6_gdpr_base = data.art6_gdpr_base;
    processingActivity.art9_gdpr_base = data.art9_gdpr_base;
    processingActivity.processing_purposes = data.processing_purposes;
    processingActivity.concurrent_structures = data.concurrent_structures;
    processingActivity.processing_types = data.processing_types;
    processingActivity.advanced_processing_types =
      data.advanced_processing_types;
    processingActivity.data_subjects = data.data_subjects;
    processingActivity.basic_data_types = data.basic_data_types;
    processingActivity.special_data_categories = data.special_data_categories;
    processingActivity.eu_transfers = data.eu_transfers;
    processingActivity.other_eu_transfers = data.other_eu_transfers;
    processingActivity.storage_supports = data.storage_supports;
    processingActivity.storage_locations = data.storage_locations;
    processingActivity.data_warehouses = data.data_warehouses;
    processingActivity.technical_measures = data.technical_measures;
    processingActivity.organizational_measures = data.organizational_measures;
    //processingActivity.external_processors = data.external_processors;
    processingActivity.controllers = data.controllers;


    dataProcessorRegistriesApi
      .dataProcessorRegistriesProcessingActsUpdate(id, registry_id, processingActivity)
      .then(
        (data) => {
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
  });

// - updateDcrTreatment.
export const updateDcrTreatment = (id, registry_id, data) =>
  new Promise((resolve, reject) => {
    let processingActivity = new DataControllerProcessingActivity(); // DataControllerProcessingActivity |
    processingActivity.internal_identifier = data.internal_identifier;
    processingActivity.is_enabled = data.is_enabled;
    processingActivity.filling_date = data.filling_date;
    processingActivity.info_supplied_by = data.info_supplied_by;
    processingActivity.processing_name = data.processing_name;
    processingActivity.processing_description = data.processing_description;
    processingActivity.processing_note = data.processing_note;
    processingActivity.other_gdpr_base = data.other_gdpr_base;
    processingActivity.other_processing_types = data.other_processing_types;
    processingActivity.other_data_types = data.other_data_types;
    processingActivity.other_data_subjects = data.other_data_subjects;
    // processingActivity.data_collection_description =
    //   data.data_collection_description;
    processingActivity.extra_eu_transfers = data.extra_eu_transfers;
    processingActivity.other_storage_supports = data.other_storage_supports;
    processingActivity.instrument_description = data.instrument_description;
    processingActivity.interconnection_type = data.interconnection_type;
    processingActivity.storage_period = data.storage_period;
    processingActivity.storage_locations_description =
      data.storage_locations_description;
    processingActivity.processing_software = data.processing_software;
    processingActivity.internal_person_in_charge =
      data.internal_person_in_charge;
    processingActivity.other_technical_measures = data.other_technical_measures;
    processingActivity.other_organizational_measures =
      data.other_organizational_measures;
    processingActivity.system_administrator = data.system_administrator;
    processingActivity.internal_instructions = data.internal_instructions;
    processingActivity.risk_level = data.risk_level;
    processingActivity.pia_needed = data.pia_needed;
    // processingActivity.registry_id = data.registry_id;
    processingActivity.internal_reference_structure =
      data.internal_reference_structure;
    processingActivity.art6_gdpr_base = data.art6_gdpr_base;
    processingActivity.art9_gdpr_base = data.art9_gdpr_base;
    processingActivity.processing_purposes = data.processing_purposes;
    processingActivity.concurrent_structures = data.concurrent_structures;
    processingActivity.processing_types = data.processing_types;
    processingActivity.advanced_processing_types =
      data.advanced_processing_types;
    processingActivity.data_subjects = data.data_subjects;
    processingActivity.basic_data_types = data.basic_data_types;
    processingActivity.special_data_categories = data.special_data_categories;
    processingActivity.eu_transfers = data.eu_transfers;
    processingActivity.other_eu_transfers = data.other_eu_transfers;
    processingActivity.storage_supports = data.storage_supports;
    processingActivity.storage_locations = data.storage_locations;
    processingActivity.data_warehouses = data.data_warehouses;
    processingActivity.technical_measures = data.technical_measures;
    processingActivity.organizational_measures = data.organizational_measures;
    processingActivity.external_processors = data.external_processors;
    //processingActivity.controllers = data.controllers;


    dataControllerRegistriesApi
      .dataControllerRegistriesProcessingActsUpdate(id, registry_id, processingActivity)
      .then(
        (data) => {
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
  });

export const deleteTreatment = (id, registry_id) =>
  new Promise((resolve, reject) => {
    dataControllerRegistriesApi.registriesProcessingActsDestroy(id, registry_id).then(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });
export const deleteDprTreatment = (id, registry_id) =>
  new Promise((resolve, reject) => {
    dataProcessorRegistriesApi.dataProcessorRegistriesProcessingActsDestroy(id, registry_id).then(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });
export const deleteDcrTreatment = (id, registry_id) =>
  new Promise((resolve, reject) => {
    dataControllerRegistriesApi.dataControllerRegistriesProcessingActsDestroy(id, registry_id).then(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });
  export const duplicateDpr = (id) =>
  new Promise((resolve, reject) => {
    let dataProcessorRegistry = new DataProcessorRegistry();
    dataProcessorRegistriesApi.dataProcessorRegistriesDuplicateRegistryUpdate(id, dataProcessorRegistry).then(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });
export const duplicateDcr = (id) =>
  new Promise((resolve, reject) => {
    let dataControllerRegistry = new DataControllerRegistry();
    dataControllerRegistriesApi.dataControllerRegistriesDuplicateRegistryUpdate(id, dataControllerRegistry).then(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });
  export const duplicateDprTreatment = (id,registry_id) =>
  new Promise((resolve, reject) => {
    let dataProcessorRegistryTreatment = new DataProcessorProcessingActivity();
    dataProcessorRegistriesApi.dataProcessorRegistriesProcessingActsDuplicateProcessingUpdate(id, registry_id, dataProcessorRegistryTreatment).then(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });
export const duplicateDcrTreatment = (id,registry_id) =>
  new Promise((resolve, reject) => {
    let dataControllerRegistryTreatment = new DataControllerProcessingActivity();
    dataControllerRegistriesApi.dataControllerRegistriesProcessingActsDuplicateProcessingUpdate(id, registry_id, dataControllerRegistryTreatment).then(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });
// -------------------- EXTRA EU TRANSFER
// TODO: Remove it! - Alle.
export const addExtraEuTransfer = (treatment_id, registry_id, data) =>
  new Promise((resolve, reject) => {
    let processingActivityExtraEUTransfer =
      new ProcessingActivityExtraEUTransfer(); // ProcessingActivityExtraEUTransfer |
    processingActivityExtraEUTransfer.country = data.country;
    // processingActivityExtraEUTransfer.processing_id = data.processing_id;
    processingActivityExtraEUTransfer.warranty = data.warranty;
    dataControllerRegistriesApi
      .registriesProcessingActsExtraEuTransfersCreate(
        treatment_id,
        registry_id,
        processingActivityExtraEUTransfer
      )
      .then(
        (data) => {
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
  });

//- addDcrExtraEuTransfer.
export const addDcrExtraEuTransfer = (treatment_id, registry_id, data) =>
  new Promise((resolve, reject) => {
    let dcProcessingActivityExtraEUTransfer =
      new DataControllerProcessingActivityExtraEUTransfer(); // DataControllerProcessingActivityExtraEUTransfer |
    dcProcessingActivityExtraEUTransfer.country = data.country;
    // processingActivityExtraEUTransfer.processing_id = data.processing_id;
    dcProcessingActivityExtraEUTransfer.warranty = data.warranty;
    dataControllerRegistriesApi
      .dataControllerRegistriesProcessingActsExtraEuTransfersCreate(
        treatment_id,
        registry_id,
        dcProcessingActivityExtraEUTransfer
      )
      .then(
        (data) => {
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
  });

//- addDprExtraEuTransfer.
export const addDprExtraEuTransfer = (treatment_id, registry_id, data) =>
  new Promise((resolve, reject) => {
    let dpProcessingActivityExtraEUTransfer =
      new DataProcessorProcessingActivityExtraEUTransfer(); // DataProcessorProcessingActivityExtraEUTransfer |
    dpProcessingActivityExtraEUTransfer.country = data.country;
    // processingActivityExtraEUTransfer.processing_id = data.processing_id;
    dpProcessingActivityExtraEUTransfer.warranty = data.warranty;
    dataProcessorRegistriesApi
      .dataProcessorRegistriesProcessingActsExtraEuTransfersCreate(
        treatment_id,
        registry_id,
        dpProcessingActivityExtraEUTransfer
      )
      .then(
        (data) => {
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
  });

// TODO: Remove it! - Alle.
export const getExtraEuTransfers = (
  treatment_id,
  registry_id,
  ordering = null,
  page = null,
  page_size = MAX_PAGE_SIZE,
  search = null
) =>
  new Promise((resolve, reject) => {
    let opts = {
      ordering: ordering, // String | Which field to use when ordering the results.
      page: page, // Number | A page number within the paginated result set.
      page_size: page_size, // Number | Number of results to return per page.
      search: search, // String | A search term.
    };
    dataControllerRegistriesApi
      .registriesProcessingActsExtraEuTransfersList(
        treatment_id,
        registry_id,
        opts
      )
      .then(
        (data) => {
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
  });

// - getDcrExtraEuTransfers.
export const getDcrExtraEuTransfers = (
  treatment_id,
  registry_id,
  ordering = null,
  page = null,
  page_size = MAX_PAGE_SIZE,
  search = null
) =>
  new Promise((resolve, reject) => {
    let opts = {
      ordering: ordering, // String | Which field to use when ordering the results.
      page: page, // Number | A page number within the paginated result set.
      page_size: page_size, // Number | Number of results to return per page.
      search: search, // String | A search term.
    };
    dataControllerRegistriesApi
      .dataControllerRegistriesProcessingActsExtraEuTransfersList(
        treatment_id,
        registry_id,
        opts
      )
      .then(
        (data) => {
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
  });

// - getDprExtraEuTransfers.
export const getDprExtraEuTransfers = (
  treatment_id,
  registry_id,
  ordering = null,
  page = null,
  page_size = MAX_PAGE_SIZE,
  search = null
) =>
  new Promise((resolve, reject) => {
    let opts = {
      ordering: ordering, // String | Which field to use when ordering the results.
      page: page, // Number | A page number within the paginated result set.
      page_size: page_size, // Number | Number of results to return per page.
      search: search, // String | A search term.
    };
    dataProcessorRegistriesApi
      .dataProcessorRegistriesProcessingActsExtraEuTransfersList(
        treatment_id,
        registry_id,
        opts
      )
      .then(
        (data) => {
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
  });

export const getExtraEuTransfer = (id, treatment_id, registry_id) =>
  new Promise((resolve, reject) => {
    dataControllerRegistriesApi
      .registriesProcessingActsExtraEuTransfersList(
        id,
        treatment_id,
        registry_id
      )
      .then(
        (data) => {
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
  });

export const updateExtraEuTransfer = (id, treatment_id, registry_id, data) =>
  new Promise((resolve, reject) => {
    let processingActivityExtraEUTransfer =
      new ProcessingActivityExtraEUTransfer(); // ProcessingActivityExtraEUTransfer |
    processingActivityExtraEUTransfer.country = data.country;
    // processingActivityExtraEUTransfer.processing_id = data.processing_id;
    processingActivityExtraEUTransfer.warranty = data.warranty;
    dataControllerRegistriesApi
      .registriesProcessingActsExtraEuTransfersUpdate(
        id,
        treatment_id,
        registry_id,
        processingActivityExtraEUTransfer
      )
      .then(
        (data) => {
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
  });

// TODO: Remove it! - Alle.
export const deleteExtraEuTransfer = (id, treatment_id, registry_id) =>
  new Promise((resolve, reject) => {
    dataControllerRegistriesApi
      .registriesProcessingActsExtraEuTransfersDestroy(
        id,
        treatment_id,
        registry_id
      )
      .then((res) => resolve(res?.data))
      .catch((err) => reject(err?.response));
  });

// - deleteDcrExtraEuTransfer.
export const deleteDcrExtraEuTransfer = (id, treatment_id, registry_id) =>
  new Promise((resolve, reject) => {
    dataControllerRegistriesApi
      .dataControllerRegistriesProcessingActsExtraEuTransfersDestroy(
        id,
        treatment_id,
        registry_id
      )
      .then((res) => resolve(res?.data))
      .catch((err) => reject(err?.response));
  });

// - deleteDprExtraEuTransfer.
export const deleteDprExtraEuTransfer = (id, treatment_id, registry_id) =>
  new Promise((resolve, reject) => {
    dataProcessorRegistriesApi
      .dataProcessorRegistriesProcessingActsExtraEuTransfersDestroy(
        id,
        treatment_id,
        registry_id
      )
      .then((res) => resolve(res?.data))
      .catch((err) => reject(err?.response));
  });

// --------------- PROCESSING API ---------------
export const getProcessingAdvancedDataTypeApi = (
  ordering = null,
  page = null,
  page_size = MAX_PAGE_SIZE,
  search = null
) =>
  new Promise((resolve, reject) => {
    let opts = {
      ordering: ordering, // String | Which field to use when ordering the results.
      page: page, // Number | A page number within the paginated result set.
      page_size: page_size, // Number | Number of results to return per page.
      search: search, // String | A search term.
    };

    processingAdvancedDataTypeApi.processingAdvancedDataTypeList(opts).then(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });

export const getProcessingAdvancedTypeApi = (
  ordering = null,
  page = null,
  page_size = MAX_PAGE_SIZE,
  search = null
) =>
  new Promise((resolve, reject) => {
    let opts = {
      ordering: ordering, // String | Which field to use when ordering the results.
      page: page, // Number | A page number within the paginated result set.
      page_size: page_size, // Number | Number of results to return per page.
      search: search, // String | A search term.
    };

    processingAdvancedTypeApi.processingAdvancedTypeList(opts).then(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });

export const getProcessingDataTypeApi = (
  ordering = null,
  page = null,
  page_size = MAX_PAGE_SIZE,
  search = null
) =>
  new Promise((resolve, reject) => {
    let opts = {
      ordering: ordering, // String | Which field to use when ordering the results.
      page: page, // Number | A page number within the paginated result set.
      page_size: page_size, // Number | Number of results to return per page.
      search: search, // String | A search term.
    };
    processingBasicDataTypeApi.processingBasicDataTypeList(opts).then(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });

export const getProcessingArtNineApi = (
  ordering = null,
  page = null,
  page_size = MAX_PAGE_SIZE,
  search = null
) =>
  new Promise((resolve, reject) => {
    let opts = {
      ordering: ordering, // String | Which field to use when ordering the results.
      page: page, // Number | A page number within the paginated result set.
      page_size: page_size, // Number | Number of results to return per page.
      search: search, // String | A search term.
    };

    processingArtNineApi.processingArtNineList(opts).then(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });

export const getProcessingArtSixApi = (
  ordering = null,
  page = null,
  page_size = MAX_PAGE_SIZE,
  search = null
) =>
  new Promise((resolve, reject) => {
    let opts = {
      ordering: ordering, // String | Which field to use when ordering the results.
      page: page, // Number | A page number within the paginated result set.
      page_size: page_size, // Number | Number of results to return per page.
      search: search, // String | A search term.
    };

    processingArtSixApi.processingArtSixList(opts).then(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });

export const getProcessingBasicDataTypeApi = (
  ordering = null,
  page = null,
  page_size = MAX_PAGE_SIZE,
  search = null
) =>
  new Promise((resolve, reject) => {
    let opts = {
      ordering: ordering, // String | Which field to use when ordering the results.
      page: page, // Number | A page number within the paginated result set.
      page_size: page_size, // Number | Number of results to return per page.
      search: search, // String | A search term.
    };

    processingBasicDataTypeApi.processingBasicDataTypeList(opts).then(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });

export const getProcessingBasicTypeApi = (
  ordering = null,
  page = null,
  page_size = MAX_PAGE_SIZE,
  search = null
) =>
  new Promise((resolve, reject) => {
    let opts = {
      ordering: ordering, // String | Which field to use when ordering the results.
      page: page, // Number | A page number within the paginated result set.
      page_size: page_size, // Number | Number of results to return per page.
      search: search, // String | A search term.
    };

    processingBasicTypeApi.processingBasicTypeList(opts).then(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });

export const getProcessingDataSubjectApi = (
  ordering = null,
  page = null,
  page_size = MAX_PAGE_SIZE,
  search = null
) =>
  new Promise((resolve, reject) => {
    let opts = {
      ordering: ordering, // String | Which field to use when ordering the results.
      page: page, // Number | A page number within the paginated result set.
      page_size: page_size, // Number | Number of results to return per page.
      search: search, // String | A search term.
    };

    processingDataSubjectApi.processingDataSubjectList(opts).then(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });

export const getProcessingDataWarehouseApi = (
  ordering = null,
  page = null,
  page_size = MAX_PAGE_SIZE,
  search = null
) =>
  new Promise((resolve, reject) => {
    let opts = {
      ordering: ordering, // String | Which field to use when ordering the results.
      page: page, // Number | A page number within the paginated result set.
      page_size: page_size, // Number | Number of results to return per page.
      search: search, // String | A search term.
    };

    processingDataWarehouseApi.processingDataWarehouseList(opts).then(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });

export const getProcessingEuTransferApi = (
  ordering = null,
  page = null,
  page_size = MAX_PAGE_SIZE,
  search = null
) =>
  new Promise((resolve, reject) => {
    let opts = {
      ordering: ordering, // String | Which field to use when ordering the results.
      page: page, // Number | A page number within the paginated result set.
      page_size: page_size, // Number | Number of results to return per page.
      search: search, // String | A search term.
    };

    processingEuTransferApi.processingEuTransferList(opts).then(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });

export const getProcessingOrgMeasuresApi = (
  ordering = null,
  page = null,
  page_size = MAX_PAGE_SIZE,
  search = null
) =>
  new Promise((resolve, reject) => {
    let opts = {
      ordering: ordering, // String | Which field to use when ordering the results.
      page: page, // Number | A page number within the paginated result set.
      page_size: page_size, // Number | Number of results to return per page.
      search: search, // String | A search term.
    };

    processingOrgMeasuresApi.processingOrgMeasuresList(opts).then(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });

export const getProcessingPurposeApi = (
  ordering = null,
  page = null,
  page_size = MAX_PAGE_SIZE,
  search = null
) =>
  new Promise((resolve, reject) => {
    let opts = {
      ordering: ordering, // String | Which field to use when ordering the results.
      page: page, // Number | A page number within the paginated result set.
      page_size: page_size, // Number | Number of results to return per page.
      search: search, // String | A search term.
    };

    processingPurposeApi.processingPurposeList(opts).then(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });

export const getProcessingStorageLocationApi = (
  ordering = null,
  page = null,
  page_size = MAX_PAGE_SIZE,
  search = null
) =>
  new Promise((resolve, reject) => {
    let opts = {
      ordering: ordering, // String | Which field to use when ordering the results.
      page: page, // Number | A page number within the paginated result set.
      page_size: page_size, // Number | Number of results to return per page.
      search: search, // String | A search term.
    };

    processingStorageLocationApi.processingStorageLocationList(opts).then(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });

// export const getProcessingStorageMethodApi = (
//   ordering = null,
//   page = null,
//   page_size = MAX_PAGE_SIZE,
//   search = null
// ) =>
//   new Promise((resolve, reject) => {
//     let opts = {
//       ordering: ordering, // String | Which field to use when ordering the results.
//       page: page, // Number | A page number within the paginated result set.
//       page_size: page_size, // Number | Number of results to return per page.
//       search: search, // String | A search term.
//     };

//     processingStorageMethodApi.processingStorageMethodList(opts).then(
//       (data) => {
//         resolve(data);
//       },
//       (error) => {
//         reject(error);
//       }
//     );
//   });

export const getProcessingStorageSupportApi = (
  ordering = null,
  page = null,
  page_size = MAX_PAGE_SIZE,
  search = null
) =>
  new Promise((resolve, reject) => {
    let opts = {
      ordering: ordering, // String | Which field to use when ordering the results.
      page: page, // Number | A page number within the paginated result set.
      page_size: page_size, // Number | Number of results to return per page.
      search: search, // String | A search term.
    };

    processingStorageSupportApi.processingStorageSupportList(opts).then(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });

export const getProcessingTecMeasuresApi = (
  ordering = null,
  page = null,
  page_size = MAX_PAGE_SIZE,
  search = null
) =>
  new Promise((resolve, reject) => {
    let opts = {
      ordering: ordering, // String | Which field to use when ordering the results.
      page: page, // Number | A page number within the paginated result set.
      page_size: page_size, // Number | Number of results to return per page.
      search: search, // String | A search term.
    };

    processingTecMeasuresApi.processingTecMeasuresList(opts).then(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });

export const getProcessingWarrantyApi = (
  ordering = null,
  page = null,
  page_size = MAX_PAGE_SIZE,
  search = null
) =>
  new Promise((resolve, reject) => {
    let opts = {
      ordering: ordering, // String | Which field to use when ordering the results.
      page: page, // Number | A page number within the paginated result set.
      page_size: page_size, // Number | Number of results to return per page.
      search: search, // String | A search term.
    };

    processingWarrantyApi.processingWarrantyList(opts).then(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });
