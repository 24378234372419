import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    button: {
        backgroundColor: "#007a85",
        color: "white",
        fontSize: "18px",
        padding: "10px",
        borderRadius: "5px",
        width: "150px",
        margin: "20px",
    },
    buttonBack: {
        border: "2px solid #007a85",
        color: "#007a85",
        fontSize: "18px",
        padding: "10px",
        borderRadius: "5px",
        width: "150px",
        backgroundColor: "trasparent",
        margin: "20px",
    }
}));