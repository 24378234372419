import React from "react";  
import { useTranslation } from "react-i18next";

import CustomButton from "../CustomButton";


import { useStyles } from "../../styles/Modals.style";

function DuplicateModal({
  value = null,
  handleClose,
  handleConfirmModal,
  back = null,
}) {
  const classes = useStyles(); 
const { t } = useTranslation();
  

  return (
    <div className={classes.delete}>
      <p style={{ textAlign: "center"}}>{t(`Are you sure you want to ${value}?`)}</p>
      {!!back && <p style={{ textAlign: "center"}}>{t(`This last form will be erased.`)}</p>}

      <div style={{ marginTop: "40px" }}>
        <CustomButton name="YES" onClick={handleConfirmModal} />
        <CustomButton name="NO" value="back" onClick={handleClose} />
      </div>
    </div>
  );
}

export default DuplicateModal;
