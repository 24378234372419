"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _CountryEnum = _interopRequireDefault(require("./CountryEnum"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * NXPrivacy API
 * REST API for NXPrivacy
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The PatchedDataControllerRequest model module.
 * @module model/PatchedDataControllerRequest
 * @version 1.0.0
 */
class PatchedDataControllerRequest {
  /**
   * Constructs a new <code>PatchedDataControllerRequest</code>.
   * @alias module:model/PatchedDataControllerRequest
   */
  constructor() {
    PatchedDataControllerRequest.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>PatchedDataControllerRequest</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/PatchedDataControllerRequest} obj Optional instance to populate.
   * @return {module:model/PatchedDataControllerRequest} The populated <code>PatchedDataControllerRequest</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new PatchedDataControllerRequest();
      if (data.hasOwnProperty('country')) {
        obj['country'] = _CountryEnum.default.constructFromObject(data['country']);
      }
      if (data.hasOwnProperty('name')) {
        obj['name'] = _ApiClient.default.convertToType(data['name'], 'String');
      }
      if (data.hasOwnProperty('email')) {
        obj['email'] = _ApiClient.default.convertToType(data['email'], 'String');
      }
      if (data.hasOwnProperty('street_name')) {
        obj['street_name'] = _ApiClient.default.convertToType(data['street_name'], 'String');
      }
      if (data.hasOwnProperty('street_number')) {
        obj['street_number'] = _ApiClient.default.convertToType(data['street_number'], 'String');
      }
      if (data.hasOwnProperty('city')) {
        obj['city'] = _ApiClient.default.convertToType(data['city'], 'String');
      }
      if (data.hasOwnProperty('postal_code')) {
        obj['postal_code'] = _ApiClient.default.convertToType(data['postal_code'], 'String');
      }
      if (data.hasOwnProperty('vat_number')) {
        obj['vat_number'] = _ApiClient.default.convertToType(data['vat_number'], 'String');
      }
      if (data.hasOwnProperty('phone_number')) {
        obj['phone_number'] = _ApiClient.default.convertToType(data['phone_number'], 'String');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>PatchedDataControllerRequest</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>PatchedDataControllerRequest</code>.
   */
  static validateJSON(data) {
    // ensure the json data is a string
    if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
      throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
    }
    // ensure the json data is a string
    if (data['email'] && !(typeof data['email'] === 'string' || data['email'] instanceof String)) {
      throw new Error("Expected the field `email` to be a primitive type in the JSON string but got " + data['email']);
    }
    // ensure the json data is a string
    if (data['street_name'] && !(typeof data['street_name'] === 'string' || data['street_name'] instanceof String)) {
      throw new Error("Expected the field `street_name` to be a primitive type in the JSON string but got " + data['street_name']);
    }
    // ensure the json data is a string
    if (data['street_number'] && !(typeof data['street_number'] === 'string' || data['street_number'] instanceof String)) {
      throw new Error("Expected the field `street_number` to be a primitive type in the JSON string but got " + data['street_number']);
    }
    // ensure the json data is a string
    if (data['city'] && !(typeof data['city'] === 'string' || data['city'] instanceof String)) {
      throw new Error("Expected the field `city` to be a primitive type in the JSON string but got " + data['city']);
    }
    // ensure the json data is a string
    if (data['postal_code'] && !(typeof data['postal_code'] === 'string' || data['postal_code'] instanceof String)) {
      throw new Error("Expected the field `postal_code` to be a primitive type in the JSON string but got " + data['postal_code']);
    }
    // ensure the json data is a string
    if (data['vat_number'] && !(typeof data['vat_number'] === 'string' || data['vat_number'] instanceof String)) {
      throw new Error("Expected the field `vat_number` to be a primitive type in the JSON string but got " + data['vat_number']);
    }
    // ensure the json data is a string
    if (data['phone_number'] && !(typeof data['phone_number'] === 'string' || data['phone_number'] instanceof String)) {
      throw new Error("Expected the field `phone_number` to be a primitive type in the JSON string but got " + data['phone_number']);
    }
    return true;
  }
}

/**
 * @member {module:model/CountryEnum} country
 */
PatchedDataControllerRequest.prototype['country'] = undefined;

/**
 * @member {String} name
 */
PatchedDataControllerRequest.prototype['name'] = undefined;

/**
 * @member {String} email
 */
PatchedDataControllerRequest.prototype['email'] = undefined;

/**
 * @member {String} street_name
 */
PatchedDataControllerRequest.prototype['street_name'] = undefined;

/**
 * @member {String} street_number
 */
PatchedDataControllerRequest.prototype['street_number'] = undefined;

/**
 * @member {String} city
 */
PatchedDataControllerRequest.prototype['city'] = undefined;

/**
 * @member {String} postal_code
 */
PatchedDataControllerRequest.prototype['postal_code'] = undefined;

/**
 * @member {String} vat_number
 */
PatchedDataControllerRequest.prototype['vat_number'] = undefined;

/**
 * @member {String} phone_number
 */
PatchedDataControllerRequest.prototype['phone_number'] = undefined;
var _default = exports.default = PatchedDataControllerRequest;