"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * NXPrivacy API
 * REST API for NXPrivacy
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The CheckoutRequestRequest model module.
 * @module model/CheckoutRequestRequest
 * @version 1.0.0
 */
class CheckoutRequestRequest {
  /**
   * Constructs a new <code>CheckoutRequestRequest</code>.
   * Handles request data to create a Stripe checkout session.
   * @alias module:model/CheckoutRequestRequest
   * @param price_id {String} 
   */
  constructor(price_id) {
    CheckoutRequestRequest.initialize(this, price_id);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, price_id) {
    obj['price_id'] = price_id;
  }

  /**
   * Constructs a <code>CheckoutRequestRequest</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/CheckoutRequestRequest} obj Optional instance to populate.
   * @return {module:model/CheckoutRequestRequest} The populated <code>CheckoutRequestRequest</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CheckoutRequestRequest();
      if (data.hasOwnProperty('price_id')) {
        obj['price_id'] = _ApiClient.default.convertToType(data['price_id'], 'String');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>CheckoutRequestRequest</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>CheckoutRequestRequest</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of CheckoutRequestRequest.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['price_id'] && !(typeof data['price_id'] === 'string' || data['price_id'] instanceof String)) {
      throw new Error("Expected the field `price_id` to be a primitive type in the JSON string but got " + data['price_id']);
    }
    return true;
  }
}
CheckoutRequestRequest.RequiredProperties = ["price_id"];

/**
 * @member {String} price_id
 */
CheckoutRequestRequest.prototype['price_id'] = undefined;
var _default = exports.default = CheckoutRequestRequest;