import { makeStyles } from "@material-ui/core/styles";

export const useStyle = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    marginTop: "20px",
    marginBottom: "80px",
    width: "100%",
    overflowY: "scroll",
    overflowX: "hidden"
  },
  backButton: {
    display: "flex",
    // width: "70%",
    // float: "left",
    marginBottom: "1%"
  },
  treatmentPreview: {
    display: "flex",
    width: "70%",
    alignContent: "center",
    border: "2px solid black",
    borderRadius: "20px",
    backgroundColor: "white",
    padding: "2%",
    marginBottom: "50px"
  },
  tablePreview: {
    display: "flex",
    alignContent: "center",
    border: "2px solid black",
    borderRadius: "20px",
    backgroundColor: "white",
    padding: "2%",
    marginBottom: "50px"
  },
  inner: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  input: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
}));
