"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * NXPrivacy API
 * REST API for NXPrivacy
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The PatchedDataProtectionOfficerRequest model module.
 * @module model/PatchedDataProtectionOfficerRequest
 * @version 1.0.0
 */
class PatchedDataProtectionOfficerRequest {
  /**
   * Constructs a new <code>PatchedDataProtectionOfficerRequest</code>.
   * @alias module:model/PatchedDataProtectionOfficerRequest
   */
  constructor() {
    PatchedDataProtectionOfficerRequest.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>PatchedDataProtectionOfficerRequest</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/PatchedDataProtectionOfficerRequest} obj Optional instance to populate.
   * @return {module:model/PatchedDataProtectionOfficerRequest} The populated <code>PatchedDataProtectionOfficerRequest</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new PatchedDataProtectionOfficerRequest();
      if (data.hasOwnProperty('name')) {
        obj['name'] = _ApiClient.default.convertToType(data['name'], 'String');
      }
      if (data.hasOwnProperty('family_name')) {
        obj['family_name'] = _ApiClient.default.convertToType(data['family_name'], 'String');
      }
      if (data.hasOwnProperty('company_name')) {
        obj['company_name'] = _ApiClient.default.convertToType(data['company_name'], 'String');
      }
      if (data.hasOwnProperty('email')) {
        obj['email'] = _ApiClient.default.convertToType(data['email'], 'String');
      }
      if (data.hasOwnProperty('phone_number')) {
        obj['phone_number'] = _ApiClient.default.convertToType(data['phone_number'], 'String');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>PatchedDataProtectionOfficerRequest</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>PatchedDataProtectionOfficerRequest</code>.
   */
  static validateJSON(data) {
    // ensure the json data is a string
    if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
      throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
    }
    // ensure the json data is a string
    if (data['family_name'] && !(typeof data['family_name'] === 'string' || data['family_name'] instanceof String)) {
      throw new Error("Expected the field `family_name` to be a primitive type in the JSON string but got " + data['family_name']);
    }
    // ensure the json data is a string
    if (data['company_name'] && !(typeof data['company_name'] === 'string' || data['company_name'] instanceof String)) {
      throw new Error("Expected the field `company_name` to be a primitive type in the JSON string but got " + data['company_name']);
    }
    // ensure the json data is a string
    if (data['email'] && !(typeof data['email'] === 'string' || data['email'] instanceof String)) {
      throw new Error("Expected the field `email` to be a primitive type in the JSON string but got " + data['email']);
    }
    // ensure the json data is a string
    if (data['phone_number'] && !(typeof data['phone_number'] === 'string' || data['phone_number'] instanceof String)) {
      throw new Error("Expected the field `phone_number` to be a primitive type in the JSON string but got " + data['phone_number']);
    }
    return true;
  }
}

/**
 * @member {String} name
 */
PatchedDataProtectionOfficerRequest.prototype['name'] = undefined;

/**
 * @member {String} family_name
 */
PatchedDataProtectionOfficerRequest.prototype['family_name'] = undefined;

/**
 * @member {String} company_name
 */
PatchedDataProtectionOfficerRequest.prototype['company_name'] = undefined;

/**
 * @member {String} email
 */
PatchedDataProtectionOfficerRequest.prototype['email'] = undefined;

/**
 * @member {String} phone_number
 */
PatchedDataProtectionOfficerRequest.prototype['phone_number'] = undefined;
var _default = exports.default = PatchedDataProtectionOfficerRequest;