export const doRegister = (payload) => {
  return {
    type: 'DO_REGISTER',
    payload: payload,
  }
}

export const doResendEmail = (payload) => {
  return {
    type: 'DO_RESEND_EMAIL',
    payload: payload,
  }
}

export const doVerifyEmail = (payload) => {
  return {
    type: 'DO_VERIFY_EMAIL',
    payload: payload,
  }
}

export const doAcceptInvite = (payload) => {
  return {
    type: 'DO_ACCEPT_INVITE',
    payload: payload,
  }
}

export const doVerifyReset = (payload) => {
  return {
    type: 'DO_VERIFY_RESET',
    payload: payload,
  }
}

