"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _DataControllerProcessingActivity = _interopRequireDefault(require("../model/DataControllerProcessingActivity"));
var _DataControllerProcessingActivityExtraEUTransfer = _interopRequireDefault(require("../model/DataControllerProcessingActivityExtraEUTransfer"));
var _DataControllerProcessingActivityExtraEUTransferRequest = _interopRequireDefault(require("../model/DataControllerProcessingActivityExtraEUTransferRequest"));
var _DataControllerProcessingActivityRequest = _interopRequireDefault(require("../model/DataControllerProcessingActivityRequest"));
var _DataControllerRegistry = _interopRequireDefault(require("../model/DataControllerRegistry"));
var _DataControllerRegistryRequest = _interopRequireDefault(require("../model/DataControllerRegistryRequest"));
var _DataControllerRegistryUpload = _interopRequireDefault(require("../model/DataControllerRegistryUpload"));
var _PaginatedDataControllerProcessingActivityExtraEUTransferList = _interopRequireDefault(require("../model/PaginatedDataControllerProcessingActivityExtraEUTransferList"));
var _PaginatedDataControllerProcessingActivityList = _interopRequireDefault(require("../model/PaginatedDataControllerProcessingActivityList"));
var _PaginatedDataControllerRegistryList = _interopRequireDefault(require("../model/PaginatedDataControllerRegistryList"));
var _PaginatedDataControllerRegistryUploadList = _interopRequireDefault(require("../model/PaginatedDataControllerRegistryUploadList"));
var _PatchedDataControllerProcessingActivityExtraEUTransferRequest = _interopRequireDefault(require("../model/PatchedDataControllerProcessingActivityExtraEUTransferRequest"));
var _PatchedDataControllerProcessingActivityRequest = _interopRequireDefault(require("../model/PatchedDataControllerProcessingActivityRequest"));
var _PatchedDataControllerRegistryRequest = _interopRequireDefault(require("../model/PatchedDataControllerRegistryRequest"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * NXPrivacy API
 * REST API for NXPrivacy
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
* DataControllerRegistries service.
* @module api/DataControllerRegistriesApi
* @version 1.0.0
*/
class DataControllerRegistriesApi {
  /**
  * Constructs a new DataControllerRegistriesApi. 
  * @alias module:api/DataControllerRegistriesApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || _ApiClient.default.instance;
  }

  /**
   * @param {module:model/DataControllerRegistryRequest} data_controller_registry_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DataControllerRegistry} and HTTP response
   */
  dataControllerRegistriesCreateWithHttpInfo(data_controller_registry_request) {
    let postBody = data_controller_registry_request;
    // verify the required parameter 'data_controller_registry_request' is set
    if (data_controller_registry_request === undefined || data_controller_registry_request === null) {
      throw new Error("Missing the required parameter 'data_controller_registry_request' when calling dataControllerRegistriesCreate");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _DataControllerRegistry.default;
    return this.apiClient.callApi('/api/data_controller_registries/', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {module:model/DataControllerRegistryRequest} data_controller_registry_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DataControllerRegistry}
   */
  dataControllerRegistriesCreate(data_controller_registry_request) {
    return this.dataControllerRegistriesCreateWithHttpInfo(data_controller_registry_request).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} id A unique integer value identifying this Registry.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  dataControllerRegistriesDestroyWithHttpInfo(id) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling dataControllerRegistriesDestroy");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/api/data_controller_registries/{id}/', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} id A unique integer value identifying this Registry.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  dataControllerRegistriesDestroy(id) {
    return this.dataControllerRegistriesDestroyWithHttpInfo(id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} id A unique integer value identifying this Registry.
   * @param {module:model/DataControllerRegistryRequest} data_controller_registry_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DataControllerRegistry} and HTTP response
   */
  dataControllerRegistriesDuplicateRegistryUpdateWithHttpInfo(id, data_controller_registry_request) {
    let postBody = data_controller_registry_request;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling dataControllerRegistriesDuplicateRegistryUpdate");
    }
    // verify the required parameter 'data_controller_registry_request' is set
    if (data_controller_registry_request === undefined || data_controller_registry_request === null) {
      throw new Error("Missing the required parameter 'data_controller_registry_request' when calling dataControllerRegistriesDuplicateRegistryUpdate");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _DataControllerRegistry.default;
    return this.apiClient.callApi('/api/data_controller_registries/{id}/duplicate_registry/', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} id A unique integer value identifying this Registry.
   * @param {module:model/DataControllerRegistryRequest} data_controller_registry_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DataControllerRegistry}
   */
  dataControllerRegistriesDuplicateRegistryUpdate(id, data_controller_registry_request) {
    return this.dataControllerRegistriesDuplicateRegistryUpdateWithHttpInfo(id, data_controller_registry_request).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Object} opts Optional parameters
   * @param {String} opts.ordering Which field to use when ordering the results.
   * @param {Number} opts.page A page number within the paginated result set.
   * @param {Number} opts.page_size Number of results to return per page.
   * @param {String} opts.search A search term.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PaginatedDataControllerRegistryList} and HTTP response
   */
  dataControllerRegistriesListWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = null;
    let pathParams = {};
    let queryParams = {
      'ordering': opts['ordering'],
      'page': opts['page'],
      'page_size': opts['page_size'],
      'search': opts['search']
    };
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _PaginatedDataControllerRegistryList.default;
    return this.apiClient.callApi('/api/data_controller_registries/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Object} opts Optional parameters
   * @param {String} opts.ordering Which field to use when ordering the results.
   * @param {Number} opts.page A page number within the paginated result set.
   * @param {Number} opts.page_size Number of results to return per page.
   * @param {String} opts.search A search term.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PaginatedDataControllerRegistryList}
   */
  dataControllerRegistriesList(opts) {
    return this.dataControllerRegistriesListWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} id A unique integer value identifying this Registry.
   * @param {Object} opts Optional parameters
   * @param {module:model/PatchedDataControllerRegistryRequest} opts.patched_data_controller_registry_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DataControllerRegistry} and HTTP response
   */
  dataControllerRegistriesPartialUpdateWithHttpInfo(id, opts) {
    opts = opts || {};
    let postBody = opts['patched_data_controller_registry_request'];
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling dataControllerRegistriesPartialUpdate");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _DataControllerRegistry.default;
    return this.apiClient.callApi('/api/data_controller_registries/{id}/', 'PATCH', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} id A unique integer value identifying this Registry.
   * @param {Object} opts Optional parameters
   * @param {module:model/PatchedDataControllerRegistryRequest} opts.patched_data_controller_registry_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DataControllerRegistry}
   */
  dataControllerRegistriesPartialUpdate(id, opts) {
    return this.dataControllerRegistriesPartialUpdateWithHttpInfo(id, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} registry_id 
   * @param {module:model/DataControllerProcessingActivityRequest} data_controller_processing_activity_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DataControllerProcessingActivity} and HTTP response
   */
  dataControllerRegistriesProcessingActsCreateWithHttpInfo(registry_id, data_controller_processing_activity_request) {
    let postBody = data_controller_processing_activity_request;
    // verify the required parameter 'registry_id' is set
    if (registry_id === undefined || registry_id === null) {
      throw new Error("Missing the required parameter 'registry_id' when calling dataControllerRegistriesProcessingActsCreate");
    }
    // verify the required parameter 'data_controller_processing_activity_request' is set
    if (data_controller_processing_activity_request === undefined || data_controller_processing_activity_request === null) {
      throw new Error("Missing the required parameter 'data_controller_processing_activity_request' when calling dataControllerRegistriesProcessingActsCreate");
    }
    let pathParams = {
      'registry_id': registry_id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _DataControllerProcessingActivity.default;
    return this.apiClient.callApi('/api/data_controller_registries/{registry_id}/processing_acts/', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} registry_id 
   * @param {module:model/DataControllerProcessingActivityRequest} data_controller_processing_activity_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DataControllerProcessingActivity}
   */
  dataControllerRegistriesProcessingActsCreate(registry_id, data_controller_processing_activity_request) {
    return this.dataControllerRegistriesProcessingActsCreateWithHttpInfo(registry_id, data_controller_processing_activity_request).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} id A unique integer value identifying this ProcessingActivity.
   * @param {Number} registry_id 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  dataControllerRegistriesProcessingActsDestroyWithHttpInfo(id, registry_id) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling dataControllerRegistriesProcessingActsDestroy");
    }
    // verify the required parameter 'registry_id' is set
    if (registry_id === undefined || registry_id === null) {
      throw new Error("Missing the required parameter 'registry_id' when calling dataControllerRegistriesProcessingActsDestroy");
    }
    let pathParams = {
      'id': id,
      'registry_id': registry_id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/api/data_controller_registries/{registry_id}/processing_acts/{id}/', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} id A unique integer value identifying this ProcessingActivity.
   * @param {Number} registry_id 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  dataControllerRegistriesProcessingActsDestroy(id, registry_id) {
    return this.dataControllerRegistriesProcessingActsDestroyWithHttpInfo(id, registry_id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} id A unique integer value identifying this ProcessingActivity.
   * @param {Number} registry_id 
   * @param {module:model/DataControllerProcessingActivityRequest} data_controller_processing_activity_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DataControllerProcessingActivity} and HTTP response
   */
  dataControllerRegistriesProcessingActsDuplicateProcessingUpdateWithHttpInfo(id, registry_id, data_controller_processing_activity_request) {
    let postBody = data_controller_processing_activity_request;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling dataControllerRegistriesProcessingActsDuplicateProcessingUpdate");
    }
    // verify the required parameter 'registry_id' is set
    if (registry_id === undefined || registry_id === null) {
      throw new Error("Missing the required parameter 'registry_id' when calling dataControllerRegistriesProcessingActsDuplicateProcessingUpdate");
    }
    // verify the required parameter 'data_controller_processing_activity_request' is set
    if (data_controller_processing_activity_request === undefined || data_controller_processing_activity_request === null) {
      throw new Error("Missing the required parameter 'data_controller_processing_activity_request' when calling dataControllerRegistriesProcessingActsDuplicateProcessingUpdate");
    }
    let pathParams = {
      'id': id,
      'registry_id': registry_id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _DataControllerProcessingActivity.default;
    return this.apiClient.callApi('/api/data_controller_registries/{registry_id}/processing_acts/{id}/duplicate_processing/', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} id A unique integer value identifying this ProcessingActivity.
   * @param {Number} registry_id 
   * @param {module:model/DataControllerProcessingActivityRequest} data_controller_processing_activity_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DataControllerProcessingActivity}
   */
  dataControllerRegistriesProcessingActsDuplicateProcessingUpdate(id, registry_id, data_controller_processing_activity_request) {
    return this.dataControllerRegistriesProcessingActsDuplicateProcessingUpdateWithHttpInfo(id, registry_id, data_controller_processing_activity_request).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} processing_id 
   * @param {Number} registry_id 
   * @param {module:model/DataControllerProcessingActivityExtraEUTransferRequest} data_controller_processing_activity_extra_eu_transfer_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DataControllerProcessingActivityExtraEUTransfer} and HTTP response
   */
  dataControllerRegistriesProcessingActsExtraEuTransfersCreateWithHttpInfo(processing_id, registry_id, data_controller_processing_activity_extra_eu_transfer_request) {
    let postBody = data_controller_processing_activity_extra_eu_transfer_request;
    // verify the required parameter 'processing_id' is set
    if (processing_id === undefined || processing_id === null) {
      throw new Error("Missing the required parameter 'processing_id' when calling dataControllerRegistriesProcessingActsExtraEuTransfersCreate");
    }
    // verify the required parameter 'registry_id' is set
    if (registry_id === undefined || registry_id === null) {
      throw new Error("Missing the required parameter 'registry_id' when calling dataControllerRegistriesProcessingActsExtraEuTransfersCreate");
    }
    // verify the required parameter 'data_controller_processing_activity_extra_eu_transfer_request' is set
    if (data_controller_processing_activity_extra_eu_transfer_request === undefined || data_controller_processing_activity_extra_eu_transfer_request === null) {
      throw new Error("Missing the required parameter 'data_controller_processing_activity_extra_eu_transfer_request' when calling dataControllerRegistriesProcessingActsExtraEuTransfersCreate");
    }
    let pathParams = {
      'processing_id': processing_id,
      'registry_id': registry_id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _DataControllerProcessingActivityExtraEUTransfer.default;
    return this.apiClient.callApi('/api/data_controller_registries/{registry_id}/processing_acts/{processing_id}/extra_eu_transfers/', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} processing_id 
   * @param {Number} registry_id 
   * @param {module:model/DataControllerProcessingActivityExtraEUTransferRequest} data_controller_processing_activity_extra_eu_transfer_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DataControllerProcessingActivityExtraEUTransfer}
   */
  dataControllerRegistriesProcessingActsExtraEuTransfersCreate(processing_id, registry_id, data_controller_processing_activity_extra_eu_transfer_request) {
    return this.dataControllerRegistriesProcessingActsExtraEuTransfersCreateWithHttpInfo(processing_id, registry_id, data_controller_processing_activity_extra_eu_transfer_request).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} id A unique integer value identifying this ProcessingActivityExtraEUTransfer.
   * @param {Number} processing_id 
   * @param {Number} registry_id 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  dataControllerRegistriesProcessingActsExtraEuTransfersDestroyWithHttpInfo(id, processing_id, registry_id) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling dataControllerRegistriesProcessingActsExtraEuTransfersDestroy");
    }
    // verify the required parameter 'processing_id' is set
    if (processing_id === undefined || processing_id === null) {
      throw new Error("Missing the required parameter 'processing_id' when calling dataControllerRegistriesProcessingActsExtraEuTransfersDestroy");
    }
    // verify the required parameter 'registry_id' is set
    if (registry_id === undefined || registry_id === null) {
      throw new Error("Missing the required parameter 'registry_id' when calling dataControllerRegistriesProcessingActsExtraEuTransfersDestroy");
    }
    let pathParams = {
      'id': id,
      'processing_id': processing_id,
      'registry_id': registry_id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/api/data_controller_registries/{registry_id}/processing_acts/{processing_id}/extra_eu_transfers/{id}/', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} id A unique integer value identifying this ProcessingActivityExtraEUTransfer.
   * @param {Number} processing_id 
   * @param {Number} registry_id 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  dataControllerRegistriesProcessingActsExtraEuTransfersDestroy(id, processing_id, registry_id) {
    return this.dataControllerRegistriesProcessingActsExtraEuTransfersDestroyWithHttpInfo(id, processing_id, registry_id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} processing_id 
   * @param {Number} registry_id 
   * @param {Object} opts Optional parameters
   * @param {String} opts.ordering Which field to use when ordering the results.
   * @param {Number} opts.page A page number within the paginated result set.
   * @param {Number} opts.page_size Number of results to return per page.
   * @param {String} opts.search A search term.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PaginatedDataControllerProcessingActivityExtraEUTransferList} and HTTP response
   */
  dataControllerRegistriesProcessingActsExtraEuTransfersListWithHttpInfo(processing_id, registry_id, opts) {
    opts = opts || {};
    let postBody = null;
    // verify the required parameter 'processing_id' is set
    if (processing_id === undefined || processing_id === null) {
      throw new Error("Missing the required parameter 'processing_id' when calling dataControllerRegistriesProcessingActsExtraEuTransfersList");
    }
    // verify the required parameter 'registry_id' is set
    if (registry_id === undefined || registry_id === null) {
      throw new Error("Missing the required parameter 'registry_id' when calling dataControllerRegistriesProcessingActsExtraEuTransfersList");
    }
    let pathParams = {
      'processing_id': processing_id,
      'registry_id': registry_id
    };
    let queryParams = {
      'ordering': opts['ordering'],
      'page': opts['page'],
      'page_size': opts['page_size'],
      'search': opts['search']
    };
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _PaginatedDataControllerProcessingActivityExtraEUTransferList.default;
    return this.apiClient.callApi('/api/data_controller_registries/{registry_id}/processing_acts/{processing_id}/extra_eu_transfers/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} processing_id 
   * @param {Number} registry_id 
   * @param {Object} opts Optional parameters
   * @param {String} opts.ordering Which field to use when ordering the results.
   * @param {Number} opts.page A page number within the paginated result set.
   * @param {Number} opts.page_size Number of results to return per page.
   * @param {String} opts.search A search term.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PaginatedDataControllerProcessingActivityExtraEUTransferList}
   */
  dataControllerRegistriesProcessingActsExtraEuTransfersList(processing_id, registry_id, opts) {
    return this.dataControllerRegistriesProcessingActsExtraEuTransfersListWithHttpInfo(processing_id, registry_id, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} id A unique integer value identifying this ProcessingActivityExtraEUTransfer.
   * @param {Number} processing_id 
   * @param {Number} registry_id 
   * @param {Object} opts Optional parameters
   * @param {module:model/PatchedDataControllerProcessingActivityExtraEUTransferRequest} opts.patched_data_controller_processing_activity_extra_eu_transfer_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DataControllerProcessingActivityExtraEUTransfer} and HTTP response
   */
  dataControllerRegistriesProcessingActsExtraEuTransfersPartialUpdateWithHttpInfo(id, processing_id, registry_id, opts) {
    opts = opts || {};
    let postBody = opts['patched_data_controller_processing_activity_extra_eu_transfer_request'];
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling dataControllerRegistriesProcessingActsExtraEuTransfersPartialUpdate");
    }
    // verify the required parameter 'processing_id' is set
    if (processing_id === undefined || processing_id === null) {
      throw new Error("Missing the required parameter 'processing_id' when calling dataControllerRegistriesProcessingActsExtraEuTransfersPartialUpdate");
    }
    // verify the required parameter 'registry_id' is set
    if (registry_id === undefined || registry_id === null) {
      throw new Error("Missing the required parameter 'registry_id' when calling dataControllerRegistriesProcessingActsExtraEuTransfersPartialUpdate");
    }
    let pathParams = {
      'id': id,
      'processing_id': processing_id,
      'registry_id': registry_id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _DataControllerProcessingActivityExtraEUTransfer.default;
    return this.apiClient.callApi('/api/data_controller_registries/{registry_id}/processing_acts/{processing_id}/extra_eu_transfers/{id}/', 'PATCH', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} id A unique integer value identifying this ProcessingActivityExtraEUTransfer.
   * @param {Number} processing_id 
   * @param {Number} registry_id 
   * @param {Object} opts Optional parameters
   * @param {module:model/PatchedDataControllerProcessingActivityExtraEUTransferRequest} opts.patched_data_controller_processing_activity_extra_eu_transfer_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DataControllerProcessingActivityExtraEUTransfer}
   */
  dataControllerRegistriesProcessingActsExtraEuTransfersPartialUpdate(id, processing_id, registry_id, opts) {
    return this.dataControllerRegistriesProcessingActsExtraEuTransfersPartialUpdateWithHttpInfo(id, processing_id, registry_id, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} id A unique integer value identifying this ProcessingActivityExtraEUTransfer.
   * @param {Number} processing_id 
   * @param {Number} registry_id 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DataControllerProcessingActivityExtraEUTransfer} and HTTP response
   */
  dataControllerRegistriesProcessingActsExtraEuTransfersRetrieveWithHttpInfo(id, processing_id, registry_id) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling dataControllerRegistriesProcessingActsExtraEuTransfersRetrieve");
    }
    // verify the required parameter 'processing_id' is set
    if (processing_id === undefined || processing_id === null) {
      throw new Error("Missing the required parameter 'processing_id' when calling dataControllerRegistriesProcessingActsExtraEuTransfersRetrieve");
    }
    // verify the required parameter 'registry_id' is set
    if (registry_id === undefined || registry_id === null) {
      throw new Error("Missing the required parameter 'registry_id' when calling dataControllerRegistriesProcessingActsExtraEuTransfersRetrieve");
    }
    let pathParams = {
      'id': id,
      'processing_id': processing_id,
      'registry_id': registry_id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _DataControllerProcessingActivityExtraEUTransfer.default;
    return this.apiClient.callApi('/api/data_controller_registries/{registry_id}/processing_acts/{processing_id}/extra_eu_transfers/{id}/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} id A unique integer value identifying this ProcessingActivityExtraEUTransfer.
   * @param {Number} processing_id 
   * @param {Number} registry_id 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DataControllerProcessingActivityExtraEUTransfer}
   */
  dataControllerRegistriesProcessingActsExtraEuTransfersRetrieve(id, processing_id, registry_id) {
    return this.dataControllerRegistriesProcessingActsExtraEuTransfersRetrieveWithHttpInfo(id, processing_id, registry_id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} id A unique integer value identifying this ProcessingActivityExtraEUTransfer.
   * @param {Number} processing_id 
   * @param {Number} registry_id 
   * @param {module:model/DataControllerProcessingActivityExtraEUTransferRequest} data_controller_processing_activity_extra_eu_transfer_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DataControllerProcessingActivityExtraEUTransfer} and HTTP response
   */
  dataControllerRegistriesProcessingActsExtraEuTransfersUpdateWithHttpInfo(id, processing_id, registry_id, data_controller_processing_activity_extra_eu_transfer_request) {
    let postBody = data_controller_processing_activity_extra_eu_transfer_request;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling dataControllerRegistriesProcessingActsExtraEuTransfersUpdate");
    }
    // verify the required parameter 'processing_id' is set
    if (processing_id === undefined || processing_id === null) {
      throw new Error("Missing the required parameter 'processing_id' when calling dataControllerRegistriesProcessingActsExtraEuTransfersUpdate");
    }
    // verify the required parameter 'registry_id' is set
    if (registry_id === undefined || registry_id === null) {
      throw new Error("Missing the required parameter 'registry_id' when calling dataControllerRegistriesProcessingActsExtraEuTransfersUpdate");
    }
    // verify the required parameter 'data_controller_processing_activity_extra_eu_transfer_request' is set
    if (data_controller_processing_activity_extra_eu_transfer_request === undefined || data_controller_processing_activity_extra_eu_transfer_request === null) {
      throw new Error("Missing the required parameter 'data_controller_processing_activity_extra_eu_transfer_request' when calling dataControllerRegistriesProcessingActsExtraEuTransfersUpdate");
    }
    let pathParams = {
      'id': id,
      'processing_id': processing_id,
      'registry_id': registry_id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _DataControllerProcessingActivityExtraEUTransfer.default;
    return this.apiClient.callApi('/api/data_controller_registries/{registry_id}/processing_acts/{processing_id}/extra_eu_transfers/{id}/', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} id A unique integer value identifying this ProcessingActivityExtraEUTransfer.
   * @param {Number} processing_id 
   * @param {Number} registry_id 
   * @param {module:model/DataControllerProcessingActivityExtraEUTransferRequest} data_controller_processing_activity_extra_eu_transfer_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DataControllerProcessingActivityExtraEUTransfer}
   */
  dataControllerRegistriesProcessingActsExtraEuTransfersUpdate(id, processing_id, registry_id, data_controller_processing_activity_extra_eu_transfer_request) {
    return this.dataControllerRegistriesProcessingActsExtraEuTransfersUpdateWithHttpInfo(id, processing_id, registry_id, data_controller_processing_activity_extra_eu_transfer_request).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} registry_id 
   * @param {Object} opts Optional parameters
   * @param {String} opts.ordering Which field to use when ordering the results.
   * @param {Number} opts.page A page number within the paginated result set.
   * @param {Number} opts.page_size Number of results to return per page.
   * @param {String} opts.search A search term.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PaginatedDataControllerProcessingActivityList} and HTTP response
   */
  dataControllerRegistriesProcessingActsListWithHttpInfo(registry_id, opts) {
    opts = opts || {};
    let postBody = null;
    // verify the required parameter 'registry_id' is set
    if (registry_id === undefined || registry_id === null) {
      throw new Error("Missing the required parameter 'registry_id' when calling dataControllerRegistriesProcessingActsList");
    }
    let pathParams = {
      'registry_id': registry_id
    };
    let queryParams = {
      'ordering': opts['ordering'],
      'page': opts['page'],
      'page_size': opts['page_size'],
      'search': opts['search']
    };
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _PaginatedDataControllerProcessingActivityList.default;
    return this.apiClient.callApi('/api/data_controller_registries/{registry_id}/processing_acts/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} registry_id 
   * @param {Object} opts Optional parameters
   * @param {String} opts.ordering Which field to use when ordering the results.
   * @param {Number} opts.page A page number within the paginated result set.
   * @param {Number} opts.page_size Number of results to return per page.
   * @param {String} opts.search A search term.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PaginatedDataControllerProcessingActivityList}
   */
  dataControllerRegistriesProcessingActsList(registry_id, opts) {
    return this.dataControllerRegistriesProcessingActsListWithHttpInfo(registry_id, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} id A unique integer value identifying this ProcessingActivity.
   * @param {Number} registry_id 
   * @param {Object} opts Optional parameters
   * @param {module:model/PatchedDataControllerProcessingActivityRequest} opts.patched_data_controller_processing_activity_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DataControllerProcessingActivity} and HTTP response
   */
  dataControllerRegistriesProcessingActsPartialUpdateWithHttpInfo(id, registry_id, opts) {
    opts = opts || {};
    let postBody = opts['patched_data_controller_processing_activity_request'];
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling dataControllerRegistriesProcessingActsPartialUpdate");
    }
    // verify the required parameter 'registry_id' is set
    if (registry_id === undefined || registry_id === null) {
      throw new Error("Missing the required parameter 'registry_id' when calling dataControllerRegistriesProcessingActsPartialUpdate");
    }
    let pathParams = {
      'id': id,
      'registry_id': registry_id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _DataControllerProcessingActivity.default;
    return this.apiClient.callApi('/api/data_controller_registries/{registry_id}/processing_acts/{id}/', 'PATCH', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} id A unique integer value identifying this ProcessingActivity.
   * @param {Number} registry_id 
   * @param {Object} opts Optional parameters
   * @param {module:model/PatchedDataControllerProcessingActivityRequest} opts.patched_data_controller_processing_activity_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DataControllerProcessingActivity}
   */
  dataControllerRegistriesProcessingActsPartialUpdate(id, registry_id, opts) {
    return this.dataControllerRegistriesProcessingActsPartialUpdateWithHttpInfo(id, registry_id, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} id A unique integer value identifying this ProcessingActivity.
   * @param {Number} registry_id 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DataControllerProcessingActivity} and HTTP response
   */
  dataControllerRegistriesProcessingActsRetrieveWithHttpInfo(id, registry_id) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling dataControllerRegistriesProcessingActsRetrieve");
    }
    // verify the required parameter 'registry_id' is set
    if (registry_id === undefined || registry_id === null) {
      throw new Error("Missing the required parameter 'registry_id' when calling dataControllerRegistriesProcessingActsRetrieve");
    }
    let pathParams = {
      'id': id,
      'registry_id': registry_id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _DataControllerProcessingActivity.default;
    return this.apiClient.callApi('/api/data_controller_registries/{registry_id}/processing_acts/{id}/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} id A unique integer value identifying this ProcessingActivity.
   * @param {Number} registry_id 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DataControllerProcessingActivity}
   */
  dataControllerRegistriesProcessingActsRetrieve(id, registry_id) {
    return this.dataControllerRegistriesProcessingActsRetrieveWithHttpInfo(id, registry_id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} id A unique integer value identifying this ProcessingActivity.
   * @param {Number} registry_id 
   * @param {module:model/DataControllerProcessingActivityRequest} data_controller_processing_activity_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DataControllerProcessingActivity} and HTTP response
   */
  dataControllerRegistriesProcessingActsUpdateWithHttpInfo(id, registry_id, data_controller_processing_activity_request) {
    let postBody = data_controller_processing_activity_request;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling dataControllerRegistriesProcessingActsUpdate");
    }
    // verify the required parameter 'registry_id' is set
    if (registry_id === undefined || registry_id === null) {
      throw new Error("Missing the required parameter 'registry_id' when calling dataControllerRegistriesProcessingActsUpdate");
    }
    // verify the required parameter 'data_controller_processing_activity_request' is set
    if (data_controller_processing_activity_request === undefined || data_controller_processing_activity_request === null) {
      throw new Error("Missing the required parameter 'data_controller_processing_activity_request' when calling dataControllerRegistriesProcessingActsUpdate");
    }
    let pathParams = {
      'id': id,
      'registry_id': registry_id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _DataControllerProcessingActivity.default;
    return this.apiClient.callApi('/api/data_controller_registries/{registry_id}/processing_acts/{id}/', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} id A unique integer value identifying this ProcessingActivity.
   * @param {Number} registry_id 
   * @param {module:model/DataControllerProcessingActivityRequest} data_controller_processing_activity_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DataControllerProcessingActivity}
   */
  dataControllerRegistriesProcessingActsUpdate(id, registry_id, data_controller_processing_activity_request) {
    return this.dataControllerRegistriesProcessingActsUpdateWithHttpInfo(id, registry_id, data_controller_processing_activity_request).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} id A unique integer value identifying this Registry.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DataControllerRegistry} and HTTP response
   */
  dataControllerRegistriesRetrieveWithHttpInfo(id) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling dataControllerRegistriesRetrieve");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _DataControllerRegistry.default;
    return this.apiClient.callApi('/api/data_controller_registries/{id}/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} id A unique integer value identifying this Registry.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DataControllerRegistry}
   */
  dataControllerRegistriesRetrieve(id) {
    return this.dataControllerRegistriesRetrieveWithHttpInfo(id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} id A unique integer value identifying this Registry.
   * @param {module:model/DataControllerRegistryRequest} data_controller_registry_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DataControllerRegistry} and HTTP response
   */
  dataControllerRegistriesUpdateWithHttpInfo(id, data_controller_registry_request) {
    let postBody = data_controller_registry_request;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling dataControllerRegistriesUpdate");
    }
    // verify the required parameter 'data_controller_registry_request' is set
    if (data_controller_registry_request === undefined || data_controller_registry_request === null) {
      throw new Error("Missing the required parameter 'data_controller_registry_request' when calling dataControllerRegistriesUpdate");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _DataControllerRegistry.default;
    return this.apiClient.callApi('/api/data_controller_registries/{id}/', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} id A unique integer value identifying this Registry.
   * @param {module:model/DataControllerRegistryRequest} data_controller_registry_request 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DataControllerRegistry}
   */
  dataControllerRegistriesUpdate(id, data_controller_registry_request) {
    return this.dataControllerRegistriesUpdateWithHttpInfo(id, data_controller_registry_request).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} registry_id 
   * @param {File} document 
   * @param {Object} opts Optional parameters
   * @param {Boolean} opts.is_verified 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DataControllerRegistryUpload} and HTTP response
   */
  dataControllerRegistriesUploadsCreateWithHttpInfo(registry_id, document, opts) {
    opts = opts || {};
    let postBody = null;
    // verify the required parameter 'registry_id' is set
    if (registry_id === undefined || registry_id === null) {
      throw new Error("Missing the required parameter 'registry_id' when calling dataControllerRegistriesUploadsCreate");
    }
    // verify the required parameter 'document' is set
    if (document === undefined || document === null) {
      throw new Error("Missing the required parameter 'document' when calling dataControllerRegistriesUploadsCreate");
    }
    let pathParams = {
      'registry_id': registry_id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {
      'document': document,
      'is_verified': opts['is_verified']
    };
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = ['multipart/form-data'];
    let accepts = ['application/json'];
    let returnType = _DataControllerRegistryUpload.default;
    return this.apiClient.callApi('/api/data_controller_registries/{registry_id}/uploads/', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} registry_id 
   * @param {File} document 
   * @param {Object} opts Optional parameters
   * @param {Boolean} opts.is_verified 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DataControllerRegistryUpload}
   */
  dataControllerRegistriesUploadsCreate(registry_id, document, opts) {
    return this.dataControllerRegistriesUploadsCreateWithHttpInfo(registry_id, document, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} id A unique integer value identifying this DataControllerRegistryUpload.
   * @param {Number} registry_id 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  dataControllerRegistriesUploadsDestroyWithHttpInfo(id, registry_id) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling dataControllerRegistriesUploadsDestroy");
    }
    // verify the required parameter 'registry_id' is set
    if (registry_id === undefined || registry_id === null) {
      throw new Error("Missing the required parameter 'registry_id' when calling dataControllerRegistriesUploadsDestroy");
    }
    let pathParams = {
      'id': id,
      'registry_id': registry_id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/api/data_controller_registries/{registry_id}/uploads/{id}/', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} id A unique integer value identifying this DataControllerRegistryUpload.
   * @param {Number} registry_id 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  dataControllerRegistriesUploadsDestroy(id, registry_id) {
    return this.dataControllerRegistriesUploadsDestroyWithHttpInfo(id, registry_id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} id A unique integer value identifying this DataControllerRegistryUpload.
   * @param {Number} registry_id 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DataControllerRegistryUpload} and HTTP response
   */
  dataControllerRegistriesUploadsDocumentRetrieveWithHttpInfo(id, registry_id) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling dataControllerRegistriesUploadsDocumentRetrieve");
    }
    // verify the required parameter 'registry_id' is set
    if (registry_id === undefined || registry_id === null) {
      throw new Error("Missing the required parameter 'registry_id' when calling dataControllerRegistriesUploadsDocumentRetrieve");
    }
    let pathParams = {
      'id': id,
      'registry_id': registry_id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _DataControllerRegistryUpload.default;
    return this.apiClient.callApi('/api/data_controller_registries/{registry_id}/uploads/{id}/document/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} id A unique integer value identifying this DataControllerRegistryUpload.
   * @param {Number} registry_id 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DataControllerRegistryUpload}
   */
  dataControllerRegistriesUploadsDocumentRetrieve(id, registry_id) {
    return this.dataControllerRegistriesUploadsDocumentRetrieveWithHttpInfo(id, registry_id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} registry_id 
   * @param {Object} opts Optional parameters
   * @param {String} opts.ordering Which field to use when ordering the results.
   * @param {Number} opts.page A page number within the paginated result set.
   * @param {Number} opts.page_size Number of results to return per page.
   * @param {String} opts.search A search term.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PaginatedDataControllerRegistryUploadList} and HTTP response
   */
  dataControllerRegistriesUploadsListWithHttpInfo(registry_id, opts) {
    opts = opts || {};
    let postBody = null;
    // verify the required parameter 'registry_id' is set
    if (registry_id === undefined || registry_id === null) {
      throw new Error("Missing the required parameter 'registry_id' when calling dataControllerRegistriesUploadsList");
    }
    let pathParams = {
      'registry_id': registry_id
    };
    let queryParams = {
      'ordering': opts['ordering'],
      'page': opts['page'],
      'page_size': opts['page_size'],
      'search': opts['search']
    };
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _PaginatedDataControllerRegistryUploadList.default;
    return this.apiClient.callApi('/api/data_controller_registries/{registry_id}/uploads/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} registry_id 
   * @param {Object} opts Optional parameters
   * @param {String} opts.ordering Which field to use when ordering the results.
   * @param {Number} opts.page A page number within the paginated result set.
   * @param {Number} opts.page_size Number of results to return per page.
   * @param {String} opts.search A search term.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PaginatedDataControllerRegistryUploadList}
   */
  dataControllerRegistriesUploadsList(registry_id, opts) {
    return this.dataControllerRegistriesUploadsListWithHttpInfo(registry_id, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * @param {Number} id A unique integer value identifying this DataControllerRegistryUpload.
   * @param {Number} registry_id 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DataControllerRegistryUpload} and HTTP response
   */
  dataControllerRegistriesUploadsRetrieveWithHttpInfo(id, registry_id) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling dataControllerRegistriesUploadsRetrieve");
    }
    // verify the required parameter 'registry_id' is set
    if (registry_id === undefined || registry_id === null) {
      throw new Error("Missing the required parameter 'registry_id' when calling dataControllerRegistriesUploadsRetrieve");
    }
    let pathParams = {
      'id': id,
      'registry_id': registry_id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['cookieAuth', 'tokenAuth'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _DataControllerRegistryUpload.default;
    return this.apiClient.callApi('/api/data_controller_registries/{registry_id}/uploads/{id}/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * @param {Number} id A unique integer value identifying this DataControllerRegistryUpload.
   * @param {Number} registry_id 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DataControllerRegistryUpload}
   */
  dataControllerRegistriesUploadsRetrieve(id, registry_id) {
    return this.dataControllerRegistriesUploadsRetrieveWithHttpInfo(id, registry_id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }
}
exports.default = DataControllerRegistriesApi;