"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * NXPrivacy API
 * REST API for NXPrivacy
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The Organization model module.
 * @module model/Organization
 * @version 1.0.0
 */
class Organization {
  /**
   * Constructs a new <code>Organization</code>.
   * @alias module:model/Organization
   * @param oid {String} 
   * @param is_active {Boolean} 
   * @param name {String} 
   * @param is_owner {Boolean} 
   */
  constructor(oid, is_active, name, is_owner) {
    Organization.initialize(this, oid, is_active, name, is_owner);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, oid, is_active, name, is_owner) {
    obj['oid'] = oid;
    obj['is_active'] = is_active;
    obj['name'] = name;
    obj['is_owner'] = is_owner;
  }

  /**
   * Constructs a <code>Organization</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/Organization} obj Optional instance to populate.
   * @return {module:model/Organization} The populated <code>Organization</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Organization();
      if (data.hasOwnProperty('oid')) {
        obj['oid'] = _ApiClient.default.convertToType(data['oid'], 'String');
      }
      if (data.hasOwnProperty('is_active')) {
        obj['is_active'] = _ApiClient.default.convertToType(data['is_active'], 'Boolean');
      }
      if (data.hasOwnProperty('name')) {
        obj['name'] = _ApiClient.default.convertToType(data['name'], 'String');
      }
      if (data.hasOwnProperty('is_owner')) {
        obj['is_owner'] = _ApiClient.default.convertToType(data['is_owner'], 'Boolean');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>Organization</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>Organization</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of Organization.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['oid'] && !(typeof data['oid'] === 'string' || data['oid'] instanceof String)) {
      throw new Error("Expected the field `oid` to be a primitive type in the JSON string but got " + data['oid']);
    }
    // ensure the json data is a string
    if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
      throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
    }
    return true;
  }
}
Organization.RequiredProperties = ["oid", "is_active", "name", "is_owner"];

/**
 * @member {String} oid
 */
Organization.prototype['oid'] = undefined;

/**
 * @member {Boolean} is_active
 */
Organization.prototype['is_active'] = undefined;

/**
 * @member {String} name
 */
Organization.prototype['name'] = undefined;

/**
 * @member {Boolean} is_owner
 */
Organization.prototype['is_owner'] = undefined;
var _default = exports.default = Organization;