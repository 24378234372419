const initialState = {
    dprRegistryList: { results: [] },
    dprRegistryUploadList: { results: [] },
    dprSelectedRegistryUploadedReport: {},
    dprSelectedRegistry: {},
    dprSelectedRegistryUploads: { results: [] },
    err: null,
}

const dprRegistryState = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_DPR_REGISTRY_LIST':
            return {
                ...state,
                dprRegistryList: action.payload,
            }
        case 'RESET_DPR_REGISTRY_LIST':
            return {
                ...state,
                dprRegistryList: {},
            }
        case 'FAIL_DPR_REGISTRY_LIST':
            return {
                ...state,
                err: action.payload.err,
            }
        case 'SET_DPR_REGISTRY':
            return {
                ...state,
                dprSelectedRegistry: action.payload,
            }
        case 'RESET_DPR_REGISTRY':
            return {
                ...state,
                dprSelectedRegistry: {},
            }
        case 'FAIL_DPR_REGISTRY':
            return {
                ...state,
                err: action.payload.err,
            }
        case 'SET_DPR_REGISTRY_UPLOADS':

            return {
                ...state,
                dprSelectedRegistryUploads: action.payload,

            }
        case 'RESET_DPR_REGISTRY_UPLOADS':

            return {
                ...state,
                dprSelectedRegistryUploads: { results: [] },
            }
        case 'FAIL_DPR_REGISTRY_UPLOADS':
            return {
                ...state,
                err: action.payload.err,
            }
        case 'SET_DPR_REGISTRY_UPLOADED_REPORT':

            return {
                ...state,
                dprSelectedRegistryUploadedReport: action.payload,
            }
        case 'RESET_DPR_REGISTRY_UPLOADED_REPORT':

            return {
                ...state,
                dprSelectedRegistryUploadedReport: {},
            }
        case 'FAIL_DPR_REGISTRY_UPLOADED_REPORT':
            return {
                ...state,
                err: action.payload.err,
            }
        default:
            return state
    }
}

export default dprRegistryState
