import React,{ useState } from "react";
import Modal from "@material-ui/core/Modal";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { updateDirectOrganization } from "../../api";
import {
  setError,
  resetOrganization,
  setOrganizationsList,
} from "../../actions";
import {
  deleteDirectOrganization,
  addDirectOrganization,
  getDirectOrganizations,
} from "../../api";

import { useStyles } from "../../styles/Modals.style";
import NewOrganizationModal from "./NewOrganizationModal";
import DeleteModal from "./DeleteModal";



function OrganizationModal ({
  handleClose,
  open,
  selectedOrganization,
  value = null
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [optionData,setOptionData] = useState({});

  const handleOptionChange = (prop) => (event) => {
    setOptionData({
      ...selectedOrganization,
      ...optionData,
      [prop]: event.target.value,
    });
  };

  const handleConfirmModal = () => {
    switch (value) {
      case "update":
        updateDirectOrganization(selectedOrganization.oid,optionData)
          .then((data) => {
            getDirectOrganizations()
              .then((data1) => dispatch(setOrganizationsList(data1)))
              .catch((err1) => dispatch(setError(err1)));
          })
          .catch((err) => dispatch(setError(err)));

        handleClose();

        break;
      case "delete":
        // First close the modal.
        handleClose();

        deleteDirectOrganization(selectedOrganization.oid)
          .then((data) => {
            dispatch(resetOrganization());
            getDirectOrganizations()
              .then((data1) => dispatch(setOrganizationsList(data1)))
              .catch((err1) => dispatch(setError(err1)));
          })
          .catch((err) => dispatch(setError(err)));
        break;

      default:
        addDirectOrganization(optionData)
          .then((data1) => {
            getDirectOrganizations()
              .then((data2) => dispatch(setOrganizationsList(data2)))
              .catch((err) => dispatch(setError(err)));
          })
          .catch((err) => {
            dispatch(setError(err));
          });

        handleClose();

        break;
    }
  };

  const getBodyModal = (value) => {
    switch (value) {
      case "update":
        return (
          <NewOrganizationModal
            value={value}
            data={{ ...(selectedOrganization || null) }}
            handleChange={handleOptionChange}
            handleConfirmModal={handleConfirmModal}
            handleClose={handleClose}
          />
        )
      case "delete":
        return (
          <DeleteModal
            value={value}
            handleConfirmModal={handleConfirmModal}
            handleClose={handleClose}
          />
        )
      default:
        return (
          <NewOrganizationModal
            data={{ ...optionData }}
            handleChange={handleOptionChange}
            handleConfirmModal={handleConfirmModal}
            handleClose={handleClose}
          />
        )
    }
  }


  return (
    <div className={classes.container}>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classes.modal}
      >
        <>
          {getBodyModal(value)}
        </>
      </Modal>
    </div>
  );
}

const mapStateProps = (state,dispatch) => ({
  //user: state.authState.user,
  selectedOrganization: state.organizationState.selectedOrganization,
});

export default connect(mapStateProps,{})(OrganizationModal);
