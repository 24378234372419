import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import Input from "../components/Input";
import CustomButton from "../components/CustomButton";
import TextField from "@material-ui/core/TextField";
import HomeHeader from "../components/HomeHeader";
import PersonIcon from "@material-ui/icons/Person";
import Visibility from "@material-ui/icons/Visibility";
import IconButton from "@material-ui/core/IconButton";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";
import { connect } from "react-redux";
import { updateUser, changePassword } from "../api";
import { setAuth, setError } from "../actions";
import { useDispatch } from "react-redux";
import Modals from "../components/modals/Modals";
import useWindowSize from "../utils";
import { history } from '../store';

import { useStyles } from "../styles/Profile.style";

const getInitState = (user) => {
  return {
    pk: user.pk,
    email: user.email,
    first_name: user.first_name,
    last_name: user.last_name,
    showPassword: false,
  };
};

function Profile({ user }) {
  const initState = getInitState(user);
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { width } = useWindowSize();
  const isOwner = Object.values(user?.subscriptions || {}).some((sub) => sub.status === "active") &&
    (user?.organizations || {}).some((sub) => sub.owner === true)
  const isInvited = !isOwner && (user?.organizations).length > 0
  const [open, setOpen] = useState(false);
  const [updatedUser, setUpdatedUser] = useState(initState);
  const [updatedPassword, setUpdatedPassword] = useState({
    new_password1: "",
    new_password2: "",
  });
  const [pwdKey, setPwdKey] = useState(1);
  const [key, setKey] = useState(1);
  const handleModal = () => setOpen(!open);
  const handleChange = (prop) => (event) =>
    setUpdatedUser({ ...updatedUser, [prop]: event.target.value });
  const handlePasswordChange = (prop) => (event) =>
    setUpdatedPassword({ ...updatedPassword, [prop]: event.target.value });
  const handleClickShowPassword = () =>
    setUpdatedUser({ ...updatedUser, showPassword: !updatedUser.showPassword });

  const storeUser = () => {
    updateUser(updatedUser)
      .then((data) => dispatch(setAuth(data)))
      .catch((err) => dispatch(setError(err)));
  };

  const changeUserPassword = () => {
    changePassword(updatedPassword)
      .then((data) => {
        dispatch(setAuth({ ...updatedUser, data }));
        setUpdatedPassword({
          ...updatedPassword,
          new_password1: "",
          new_password2: "",
        });
        setPwdKey((prev) => prev + 2);
      })
      .catch((err) => dispatch(setError(err)));
  };

  const getCapitalize = (str) => {
    console.log(str);
    if (!!!str) str = ""
    return str?.charAt(0).toUpperCase() + str?.slice(1);
  };

  useEffect(() => {
    const initState = getInitState(user);
    setUpdatedUser({...initState});
    setKey(key+1)
  }, [user]);
  return (
    <div className={classes.container}>
      <PersonIcon className={classes.icon} />
      <HomeHeader value="profile" />

      <Modals value="cancel" open={open} handleClose={handleModal} />
      {/* <p>Email:  {updatedUser.email}</p> */}


      {(isInvited && user?.organizations && user?.organizations.length > 0 && "org_name" in user?.organizations[0]) && <>
      <p>Organization: {user?.organizations[0].org_name}</p></>}
      <div className={width > 500 ? classes.inner : {}}>
        <div className={width < 500 ? classes.inner : {}}>
          {/* <CustomButton name="SAVE" onClick={storeUser} /> */}
          <CustomButton name="CANCEL" value="back" onClick={handleModal} />
          {
            !isInvited && <CustomButton name="BUY SUBSCRIPTIONS" value="back" onClick={() => history.push("/subscriptions")} />

          }
        </div>
        <div className={width > 500 ? classes.input : classes.inner} key={key}>
        {/* <TextField
            key={"email"}
            className={classes.form}
            style={{ maxWidth: "300px" }}
            label={t("Email")}
            disabled={true}
            value={updatedUser.email}
            variant="outlined"
            type={ "text"  }
            
            onChange={handleChange("email")}
         
          />
           <TextField
            key={"first_name"}
            className={classes.form}
            style={{ maxWidth: "300px" }}
            label={t("First name")}
            value={updatedUser.first_name}
            variant="outlined"
            type={ "text"  }
            onChange={handleChange("first_name")}
         
          />
           <TextField
            key={"last_name"}
            className={classes.form}
            style={{ maxWidth: "300px" }}
            label={t("Last name")}
            value={updatedUser.last_name}
            variant="outlined"
            type={ "text"  }
            onChange={handleChange("last_name")}
         
          /> */}
        <Input
            field="email"
            name="EMAIL"
            value={updatedUser.email}
            handle={handleChange("email")}
            disabled={true}
          />
          <Input
            field="first_name"
            name="FIRST NAME"
            value={updatedUser.first_name}
            handle={handleChange("first_name")}
          />
          <Input
            field="last_name"
            name="LAST NAME"
            value={updatedUser.last_name}

            handle={handleChange("last_name")}
          />
                    <CustomButton disabled={ 
                      !!!updatedUser ||
                      !!!updatedUser?.first_name ||
                      !!!updatedUser?.last_name ||
                       updatedUser.first_name.length === 0 ||
                        updatedUser.last_name.length === 0} name="SAVE" onClick={storeUser} />

        </div>

        <div
          className={width > 500 ? classes.input : classes.inner}
          style={{ marginTop: "20px", marginBottom: "20px" }}
        >
          {t("Use this form to change your password.")}
          <br />
          {t(
            "Remember that it must be at least 8 characters long and must not be a common password."
          )}
        </div>

        <div className={width > 500 ? classes.input : classes.inner}>
          <TextField
            key={pwdKey}
            className={classes.form}
            style={{ maxWidth: "300px" }}
            label={t("Password")}
            value={updatedPassword.new_password1}
            variant="outlined"
            type={updatedUser.showPassword ? "text" : "password"}
            onChange={handlePasswordChange("new_password1")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                  >
                    {updatedUser.showPassword ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <TextField
            key={pwdKey + 1}
            className={classes.form}
            style={{ maxWidth: "300px" }}
            label={t("Confirm Password")}
            value={updatedUser.new_password2}
            variant="outlined"
            type={updatedUser.showPassword ? "text" : "password"}
            onChange={handlePasswordChange("new_password2")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                  >
                    {updatedUser.showPassword ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <CustomButton name="CHANGE" onClick={changeUserPassword} />
        </div>
      </div>
    </div>
  );
}

const mapStateProps = (state) => ({
  user: state.authState.user,
});

export default connect(mapStateProps, {})(Profile);
