const initialState = {
    organizationList: { results: [] },
    selectedOrganization: {},
    selectedOrganizationMembers: { results: [] },
    err: null,
}

const organizationState = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_ORGANIZATION_LIST':
            return {
                ...state,
                organizationList: action.payload,
            }
        case 'RESET_ORGANIZATION_LIST':
            return {
                ...state,
                organizationList: {},
            }
        case 'FAIL_ORGANIZATION_LIST':
            return {
                ...state,
                err: action.payload.err,
            }
        case 'SET_ORGANIZATION':
            return {
                ...state,
                selectedOrganization: action.payload,
            }
        case 'RESET_ORGANIZATION':
            return {
                ...state,
                selectedOrganization: {},
            }
        case 'FAIL_ORGANIZATION':
            return {
                ...state,
                err: action.payload.err,
            }
        case 'SET_ORGANIZATION_MEMBERS':
            return {
                ...state,
                selectedOrganizationMembers: { results: action.payload
            },
            }
        case 'RESET_ORGANIZATION_MEMBERS':
return {
    ...state,
    selectedOrganizationMembers: { results: [] },
}
        case 'FAIL_ORGANIZATION_MEMBERS':
return {
    ...state,
    err: action.payload.err,
}
        default:
return state
    }
}

export default organizationState